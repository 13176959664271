import React from "react";
import SVGIcon from "../../../shared/components/SVGIcon";
import { makeStyles } from "@material-ui/core";
import { SECONDARY_COLOR, BRIGHT_BLUE } from "../../../theme";

const SaleTypeIcons = props => {
  const { selected, icon, isMobile } = props;
  const classes = useStyles();

  return (
    <SVGIcon
      src={icon}
      className={
        isMobile
          ? `mt-3 ${selected && classes.selectedColor} ${classes.tabIconMobileSize}`
          : `mt-2 mb-2 ${selected && classes.selectedColor} ${classes.tabIcon}`
      }
    />
  );
};

const useStyles = makeStyles(theme => ({
  tabIcon: {
    height: theme.spacing(4.5),
    width: theme.spacing(7.5),
    backgroundColor: BRIGHT_BLUE,
    marginLeft: "auto"
  },
  tabIconMobileSize: {
    height: theme.spacing(4.5),
    width: theme.spacing(7.5),
    backgroundColor: BRIGHT_BLUE,
    marginLeft: "auto",
    marginRight: "auto"
  },
  selectedColor: {
    backgroundColor: SECONDARY_COLOR
  }
}));

export default SaleTypeIcons;
