import { useState, useEffect } from "react";

const useCreateMessageModalStandard = selectedVendors => {
  const [standardSelectedReason, setStandardSelectedReason] = useState();
  const [standardSelectedType, setStandardSelectedtype] = useState();
  const [standardDescription, setStandardDescription] = useState("");

  const standardFinishButtonDisabled =
    !standardSelectedReason || !standardSelectedType || !standardDescription;

  useEffect(() => {
    setStandardSelectedReason();
    setStandardSelectedtype();
    setStandardDescription("");
  }, [selectedVendors]);

  return {
    standardFinishButtonDisabled,
    standardSelectedReason,
    setStandardSelectedReason,
    standardSelectedType,
    setStandardSelectedtype,
    standardDescription,
    setStandardDescription
  };
};

export default useCreateMessageModalStandard;
