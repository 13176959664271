export const FormatDataToEditVendor = (initials, description, active, management, states) => {
  return {
    vendor: {
      initials: initials,
      description: description,
      active: active !== "inative",
      management: management
    },
    stateVendors: mapStates(states)
  };
};

const mapStates = states =>
  states &&
  states.map(s => ({
    crops: s.crops,
    competitors: s.competitors,
    customers: s.customers,
    state: {
      id: s.id,
      initials: s.initials,
      name: s.name,
      stateVendorId: s.stateVendorId
    }
  }));
