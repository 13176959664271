import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { PALE_BLUE_GRAY } from "../../../theme";
import NoMessagesCreatedIcon from "../../../shared/img/NoMessagesCreatedIcon.svg";

const CustomTableNotReadMessage = props => {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      xs={12}
      className={`${classes.noMessagesBorder} ${classes.noMessagesBorderTop}`}
    >
      <Grid item xs={12} className="pt-6 mb-2">
        <img
          src={NoMessagesCreatedIcon}
          alt="Nenhuma mensagem cadastrada"
          className={classes.noMessagesIcon}
        />
      </Grid>
      <Grid item xs={12} className="pb-6">
        <Typography className="h7" align="center" color="primary">
          Não existem mensagens <br /> enviadas no período indicado.
        </Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  noMessagesBorder: {
    borderColor: PALE_BLUE_GRAY,
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "8px",
    borderCollapse: "inherit",
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "0px"
  },
  noMessagesBorderTop: {
    borderTop: "none"
  },
  noMessagesIcon: {
    width: 50,
    height: 50,
    display: "flex",
    margin: "auto"
  }
}));

export default CustomTableNotReadMessage;
