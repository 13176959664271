import { protectedResourcesOptions } from "../authProvider";
import { msalInstances } from "../shared/utils/AuthUtil";
import { IdentityProviders } from "../shared/utils/enum";

export const AuthenticationActions = {
  SetIdentityProvider: "auth/SET_IDENTITY_PROVIDER"
};

const INITIAL_STATE = {
  identityProvider: IdentityProviders.B2B,
  msalInstance: msalInstances.B2B,
  protectedResources: protectedResourcesOptions.B2B
};

export default (state = INITIAL_STATE, action) => {
  if (action.type === AuthenticationActions.SetIdentityProvider) {
    let identityProvider = action.payload || IdentityProviders.B2B;
    
    if (identityProvider !== state.identityProvider) {
      let instance = msalInstances.B2B;
      let protectedResources = protectedResourcesOptions.B2B;
      
      if (identityProvider !== IdentityProviders.B2B) {
        instance = msalInstances.B2C;
        protectedResources = protectedResourcesOptions.B2C;
      }
  
      return {
        ...state,
        identityProvider: identityProvider,
        msalInstance: instance,
        protectedResources: protectedResources
      }
    }

    return {
      ...state,
    };
  }
  else
    return state;
};

export const ActionCreators = {
  setIdentityProvider: (identityProvider) => {
    return {
      type: AuthenticationActions.SetIdentityProvider,
      payload: identityProvider
    }
  }
}
