import React from "react";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { WHITE, LIGHT_GRAY2 } from "../../../theme";
import { useLocation, Link } from "react-router-dom";
import { PathRoutesMapper } from "../../../shared/utils/PathRoutesMapper";
import { preRegisteredTypes } from "../../../shared/utils/enum";

const Breadcrumb = () => {
  const classes = useStyles();
  const location = useLocation();

  const pathArray = location.pathname
    .split("/")
    .filter(
      item =>
        isNaN(item) &&
        item !== preRegisteredTypes.competitor &&
        item !== preRegisteredTypes.customer
    );
  const pathParameter = location.pathname.split("/").filter(item => !isNaN(item) && item !== "");

  return (
    <Breadcrumbs aria-label="breadcrumb" separator="|">
      {pathArray &&
        pathArray.map((item, index) => {
          const isLastItem = pathArray.length - 1 === index;
          const to = `/${pathArray.slice(0, index + 1).join("/")}`;

          return isLastItem ? (
            <Typography key={index} variant="subtitle1" className={`${classes.lastItem} bold`}>
              {PathRoutesMapper(item, pathParameter.length > 0)}
            </Typography>
          ) : (
            <Link key={index} className={classes.item} to={to}>
              {PathRoutesMapper(item)}
            </Link>
          );
        })}
    </Breadcrumbs>
  );
};

const useStyles = makeStyles(() => ({
  lastItem: {
    color: WHITE
  },
  item: {
    color: LIGHT_GRAY2,
    textDecoration: "none"
  }
}));

export default Breadcrumb;
