import { useState } from "react";
import CompetitorCustomerCropManager from "../CompetitorCustomerCropManager";

const useUpdateCompetitor = () => {
  const [loadingData, setLoadingData] = useState(false);

  const handleUpdateCompetitor = async (competitor, competitorId) => {
    setLoadingData(true);

    const response = await CompetitorCustomerCropManager.updateCompetitor(competitor, competitorId);

    setLoadingData(false);

    return response;
  };

  return {
    handleUpdateCompetitor,
    loadingData
  };
};

export default useUpdateCompetitor;
