import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { GRAY } from "../../theme";

const PageTitle = props => {
  const { icon, title, subtitle, isMobile } = props;
  const classes = useStyles();

  return (
    <Grid container item xs={!isMobile ? 10 : 12}>
      <Grid item xs={12} className={!isMobile ? "pb-2" : null}>
        {icon}
        <Typography variant={!isMobile ? "h4" : "h5"} className={classes.title}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.containerSubtitle}>
        <Typography variant={!isMobile ? "body2" : "caption"} className={classes.subtitle}>
          {subtitle}
        </Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    display: "inline-block",
    verticalAlign: "middle"
  },
  icon: {
    verticalAlign: "bottom"
  },
  subtitle: {
    color: GRAY,
    marginLeft: theme.spacing(5)
  },
  containerSubtitle: {
    marginTop: theme.spacing(-1)
  }
}));

export default PageTitle;
