import React, { useState, useEffect } from "react";
import { Typography, makeStyles, Checkbox, Grid } from "@material-ui/core";
import Modal from "../../../shared/components/Modal";
import { GRAY, PRIMARY_COLOR, SECONDARY_COLOR } from "../../../theme";
import Loading from "../../../shared/components/Loading";
import { AlertTriangle } from "react-feather";
import CheckedCheckbox from "../../../shared/img/CheckedCheckbox.svg";

const AcceptTermModal = props => {
  const classes = useStyles();

  const {
    openedModal,
    handleOpenClose,
    secondaryAction,
    primaryAction,
    loading,
    isNewRegister,
    preRegisteredData,
    selectedItem
  } = props;

  const [checkedTerm, setCheckedTerm] = useState(false);
  useEffect(() => {
    setCheckedTerm(false);
  }, [openedModal]);
  return (
    openedModal && (
      <Modal
        primaryButton={{
          action: () => !loading && primaryAction(),
          text: loading ? (
            <Loading size={20} loading={loading} className={classes.loading} />
          ) : (
            "TENHO CERTEZA"
          ),
          variant: "contained",
          className: classes.primaryButtonColor,
          disabled: !checkedTerm || loading
        }}
        secondaryButton={{
          action: () => !loading && secondaryAction(),
          text: "NÃO",
          variant: "outlined",
          disabled: loading
        }}
        openedModal={openedModal}
        closeModal={() => !loading && handleOpenClose()}
        title={isNewRegister ? "Aprovar novo cadastro" : "Confirmar associação de volume"}
        titleIcon={<AlertTriangle size={48} color={SECONDARY_COLOR} />}
        closeIcon
      >
        <Typography variant="body1" align="center" className={classes.colorSubtitle}>
          {isNewRegister ? (
            <>
              Tem certeza que{" "}
              <span className={`${classes.secondaryColor} bold`}>
                "{preRegisteredData && preRegisteredData.itemName}"
              </span>{" "}
              é um novo cadastro e que não há nenhum correspondente a ele no sistema? Essa ação não
              poderá ser desfeita.
              <br />
              <br />
              Volume total de mercado sólido:{" "}
              <span className={`${classes.secondaryColor} bold`}>
                {preRegisteredData && preRegisteredData.totalVolume} KT
              </span>
            </>
          ) : (
            <>
              Tem certeza que deseja transferir o volume de{" "}
              <span className={`${classes.secondaryColor} bold`}>
                "{preRegisteredData && preRegisteredData.itemName}"
              </span>{" "}
              para{" "}
              <span className={`${classes.secondaryColor} bold`}>
                "{selectedItem && selectedItem.name}"
              </span>{" "}
              ? O volume total a ser transferido é de{" "}
              <span className={`${classes.secondaryColor} bold`}>
                {preRegisteredData && preRegisteredData.totalVolume} KT
              </span>{" "}
              e essa ação não poderá ser desfeita.
            </>
          )}
        </Typography>
        <Grid container className="pointer mt-5" onClick={() => setCheckedTerm(!checkedTerm)}>
          <Grid item xs={1}>
            <Checkbox
              checked={checkedTerm}
              onChange={() => setCheckedTerm(!checkedTerm)}
              inputProps={{ "aria-label": "select option" }}
              className={`p-0 ${classes.checkbox}`}
              checkedIcon={
                <img src={CheckedCheckbox} alt="Checked icon" className={classes.checkdIcon} />
              }
            />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body1" className={classes.colorSubtitle}>
              Li e entendo as consêquencias que uma associação de volumes pode causar se for feita
              para o destino errado.
            </Typography>
          </Grid>
        </Grid>
      </Modal>
    )
  );
};

const useStyles = makeStyles(theme => ({
  colorSubtitle: {
    color: GRAY
  },
  primaryButtonColor: {
    backgroundColor: PRIMARY_COLOR,
    width: "100%",
    height: "56px"
  },
  loading: {
    margin: "auto",
    display: "flex",
    color: SECONDARY_COLOR
  },
  secondaryColor: {
    color: SECONDARY_COLOR
  },
  checkdIcon: {
    width: "22px",
    height: "24px",
    marginLeft: "1px",
    marginRight: "1px"
  },
  checkbox: {
    display: "flex"
  }
}));

export default AcceptTermModal;
