import React from "react";
import { Grid, makeStyles, Typography, MobileStepper, Tab, Tabs } from "@material-ui/core";
import { PRIMARY_COLOR, SECONDARY_COLOR, BRIGHT_BLUE, PALE_BLUE_GRAY, WHITE } from "../../../theme";
import { numberWithSeparators } from "../../../shared/utils/Utils";
import Loading from "../../../shared/components/Loading";

const StateTabs = props => {
  const {
    isMobile,
    numSteppers,
    pageShouldRerender,
    statesInfo,
    selectedStateTab,
    setSelectedStateTab
  } = props;

  const classes = useStyles({ numberOfStates: numSteppers, selectedStateId: selectedStateTab });

  const getLabelTabColor = index =>
    selectedStateTab === index ? classes.typographyLabelSelected : classes.typographyLabel;

  const getLabelTab = (state, index) => {
    return (
      <>
        <Typography variant={!isMobile ? "subtitle1" : "h6"} className={getLabelTabColor(index)}>
          {!isMobile ? state.name : state.initials}
        </Typography>
        <Typography
          variant={!isMobile ? "subtitle1" : "caption"}
          className={getLabelTabColor(index)}
        >
          {state.totals ? numberWithSeparators(state.totals) : "0,00"}
        </Typography>
      </>
    );
  };

  return (
    !pageShouldRerender && (
      <Grid container item xs={12} className={!isMobile ? "pt-6" : "pt-4"}>
        <Grid container className={classes.contentAligment}>
          <Grid item xs={12} align="center">
            {statesInfo && statesInfo.length > 0 ? (
              <Tabs
                value={selectedStateTab}
                variant="scrollable"
                scrollButtons="auto"
                onChange={(event, newValue) => setSelectedStateTab(newValue)}
                className={classes.tabs}
              >
                {statesInfo &&
                  statesInfo.map((state, index) => {
                    return (
                      <Tab
                        key={state.id}
                        id={state.id}
                        label={getLabelTab(state, index)}
                        className={`${classes.tabWidth}  ${classes.tabRadius} ${
                          selectedStateTab === index ? classes.tabColorSelected : classes.tabColor
                        } ${index < numSteppers - 1 && classes.borderBetweenTabs}
                `}
                      />
                    );
                  })}
              </Tabs>
            ) : (
              <Loading size={25} loading className={classes.loadingPosition} />
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <MobileStepper
              variant="dots"
              align="center"
              steps={numSteppers}
              position="static"
              activeStep={selectedStateTab}
              className={classes.stepper}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  );
};

const useStyles = makeStyles(theme => ({
  tabs: {
    "& .MuiTabs-fixed": {
      margin: "auto",
      display: "flex",
      maxWidth: "100%"
    },
    "& .MuiTabs-flexContainer": {
      margin: "auto",
      display: "flex",
      width: "100%"
    },
    "& .MuiTabs-indicator": {
      height: "0px"
    }
  },
  borderBetweenTabs: {
    borderRight: `1px solid ${PALE_BLUE_GRAY}`,
    borderImage: `radial-gradient(${PALE_BLUE_GRAY} 80%, ${WHITE} 20%)`,
    borderImageSlice: 1
  },
  tabWidth: props => ({
    maxWidth: `${
      props.numberOfStates > 2 ? "calc(100% /3)" : `calc(100% /${props.numberOfStates})`
    }`,
    minWidth: `${
      props.numberOfStates > 2 ? "calc(100% /3)" : `calc(100% /${props.numberOfStates})`
    }`
  }),
  tabRadius: props => ({
    borderTopLeftRadius: props.selectedStateId === 0 ? "8px" : "0px",
    borderBottomLeftRadius: props.selectedStateId === 0 ? "8px" : "0px",
    borderTopRightRadius: props.selectedStateId === props.numberOfStates - 1 ? "8px" : "0px",
    borderBottomRightRadius: props.selectedStateId === props.numberOfStates - 1 ? "8px" : "0px"
  }),
  tabColor: {
    color: WHITE
  },
  tabColorSelected: {
    backgroundColor: PRIMARY_COLOR
  },
  typographyLabelSelected: {
    color: WHITE
  },
  typographyLabel: {
    color: BRIGHT_BLUE
  },
  stepper: {
    "& .MuiMobileStepper-dots": {
      margin: "auto",
      display: "flex"
    },
    "& .MuiMobileStepper-dotActive": {
      backgroundColor: PRIMARY_COLOR
    }
  },
  contentAligment: {
    border: `1px solid ${PALE_BLUE_GRAY}`,
    borderRadius: theme.spacing(1)
  },
  loadingPosition: {
    margin: theme.spacing(1),
    color: SECONDARY_COLOR
  }
}));

export default StateTabs;
