import React, { useEffect, useState, useRef } from "react";
import { Typography, Divider, Grid, Tabs, Tab, makeStyles } from "@material-ui/core";
import { PALE_BLUE_GRAY, WHITE, MID_BLUE } from "../../../theme";
import ItemMessage from "./ItemMessage";
import useListMessages from "../customHooks/useListMessages";
import { messageTypeSending } from "../../../shared/utils/enum";
import Loading from "../../../shared/components/Loading";
import InfiniteScroll from "react-infinite-scroller";
import { Send, Inbox } from "react-feather";
import NoMessageIconMidBlue from "../../../shared/img/NoMessageIconMidBlue.svg";

const ListMessages = props => {
  const {
    authenticatedUserId,
    selectedVendorId,
    setCountUnreadMessages,
    loading,
    setLoading,
    shouldReloadData,
    setShouldReloadData,
    isMobile,
    isConsultant
  } = props;

  const classes = UseStyles({ isConsultant });
  const [selectedTab, setSelectedTab] = useState(messageTypeSending.received.id);
  const [listMessages, setListMessages] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const {
    listReceivedMessages,
    handleListReceivedMessages,
    listSentMessages,
    handleListSentMessages
  } = useListMessages(setLoading, setHasMore);

  const getData = async actualPage => {
    if (selectedTab === messageTypeSending.received.id)
      await handleListReceivedMessages(authenticatedUserId, selectedVendorId, actualPage);
    else await handleListSentMessages(selectedVendorId, actualPage);

    setShouldReloadData(false);
  };

  const refSelectedVendorId = useRef(selectedVendorId);
  const refGetData = useRef(getData);
  const refSelectedTab = useRef(selectedTab);
  const refSetCountUnreadMessages = useRef(setCountUnreadMessages);
  const refListMessages = useRef(listMessages);
  useEffect(() => {
    refSelectedVendorId.current = selectedVendorId;
    refGetData.current = getData;
    refSelectedTab.current = selectedTab;
    refSetCountUnreadMessages.current = setCountUnreadMessages;
    refListMessages.current = listMessages;
  });

  useEffect(() => {
    setListMessages([]);
    setHasMore(true);

    if (selectedVendorId !== 0 && selectedVendorId !== null) refGetData.current(0);
  }, [selectedTab, selectedVendorId]);

  useEffect(() => {
    if (!shouldReloadData) return;

    setListMessages([]);
    setHasMore(true);

    if (refSelectedVendorId !== 0 && refSelectedVendorId !== null) refGetData.current(0);
  }, [shouldReloadData]);

  useEffect(() => {
    setListMessages(
      refSelectedTab.current === messageTypeSending.received.id
        ? refListMessages.current.concat(listReceivedMessages)
        : refListMessages.current.concat(listSentMessages)
    );
  }, [listReceivedMessages, listSentMessages]);

  useEffect(() => {
    const unreadMessages =
      messageTypeSending.sent.id !== refSelectedTab.current
        ? listMessages.filter(message => !message.recipientMessage[0].readDate)
        : [];
    refSetCountUnreadMessages.current(unreadMessages.length);
  }, [listMessages]);

  const handleReadMessage = messageId => {
    const newListMessage = listMessages.map(message => {
      if (message.id === messageId) message.recipientMessage[0].readDate = new Date();
      return message;
    });

    setListMessages(newListMessage);
  };

  return (
    <Grid container className={classes.contentAligment}>
      <Grid item xs={12}>
        <Tabs
          style={{ width: "100%" }}
          value={selectedTab}
          onChange={(event, value) => setSelectedTab(value)}
          indicatorColor="secondary"
          textColor="secondary"
          className={classes.tabs}
        >
          <Tab
            icon={!isMobile && <Inbox className="mr-4 mb-0" />}
            value={messageTypeSending.received.id}
            label={messageTypeSending.received.description}
            className={`${isMobile ? "h7" : "h5"} ${isConsultant && classes.borderBetweenTabs} ${classes.tab}`}
          />
          {isConsultant && 
            <Tab
              icon={!isMobile && <Send className="mr-4 mb-0" />}
              value={messageTypeSending.sent.id}
              label={messageTypeSending.sent.description}
              className={`${isMobile ? "h7" : "h5"} ${classes.tab}`}
            />
          }
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>

      {loading && (
        <Grid item xs={12}>
          <Loading loading />
        </Grid>
      )}

      {!loading && (
        <Grid item xs={12}>
          <InfiniteScroll
            initialLoad={false}
            threshold={250}
            hasMore={hasMore}
            pageStart={0}
            loadMore={getData}
            loader={<Loading key={"loadingKey"} loading />}
          >
            {listMessages.map((message, index) => (
              <ItemMessage
                key={index}
                authenticatedUserId={authenticatedUserId}
                useWindow={false}
                message={message}
                handleReadMessage={handleReadMessage}
                selectedTab={selectedTab}
              />
            ))}
          </InfiniteScroll>
        </Grid>
      )}

      {!loading && listMessages.length <= 0 && (
        <Grid container item xs={12}>
          <Grid item xs={12} className="pt-6 mb-2">
            <img
              src={NoMessageIconMidBlue}
              alt="Nenhuma mensagem cadastrada"
              className={classes.noMessagesIcon}
            />
          </Grid>
          <Grid item xs={12} className="pb-6 pr-7 pl-7">
            <Typography className={`h7 ${classes.noMessagesText}`} align="center">
              Não existem mensagens{" "}
              {`${selectedTab === messageTypeSending.sent.id ? "enviadas" : "recebidas"}`}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const UseStyles = makeStyles(theme => ({
  tabs: {
    "& .MuiTabs-fixed": {
      margin: "auto",
      display: "flex",
      maxWidth: "100%"
    },
    "& .MuiTabs-flexContainer": {
      margin: "auto",
      display: "flex",
      width: "100%"
    },
    "& .MuiTabs-indicator": {
      top: 0,
      height: "4px"
    },
    "& .MuiTab-wrapper": {
      flexDirection: "row"
    },

    "& .MuiTab-wrapper > *:first-child": {
      marginBottom: 0
    }
  },
  borderBetweenTabs: {
    borderRight: `1px solid ${PALE_BLUE_GRAY}`,
    borderImage: `radial-gradient(${PALE_BLUE_GRAY} 80%, ${WHITE} 20%)`,
    borderImageSlice: 1
  },
  tab: ({ isConsultant }) => ({
    maxWidth: isConsultant ? "calc(100% /2)" : "100%",
    minWidth: isConsultant ? "calc(100% /2)" : "100%",
    textTransform: "none",
    flexDirection: "row"
  }),
  contentAligment: {
    border: `1px solid ${PALE_BLUE_GRAY}`,
    borderRadius: theme.spacing(1)
  },
  divider: {
    height: theme.spacing(0.25),
    backgroundColor: PALE_BLUE_GRAY
  },
  noMessagesText: {
    color: MID_BLUE
  },
  noMessagesIcon: {
    width: 50,
    height: 50,
    display: "flex",
    margin: "auto"
  }
}));

export default ListMessages;
