import { useState, useEffect } from "react";
import NextYearOrderbookManager from "../NextYearOrderbookManager";

export const useStateTabs = selectedVendor => {
  const [updateStateTotals, setUpdateStateTotals] = useState(false);
  const [selectedStateVendor, setSelectedStateVendor] = useState();
  const [statesInfo, setStatesInfo] = useState([]);
  const [numSteppers, setNumSteppers] = useState(1);
  const [selectedStateTab, setSelectedStateTab] = useState(0);
  const [pageShouldRerender, setPageShouldReRender] = useState(false);
  const [stateTabsAreReady, setStateTabsAreReady] = useState(false);

  useEffect(() => {
    setUpdateStateTotals(true);
  }, []);

  useEffect(() => {
    if (statesInfo && statesInfo.length > 0) {
      setSelectedStateVendor(statesInfo[selectedStateTab].stateVendorId);
      setStateTabsAreReady(true);
    }
  }, [selectedStateTab, setSelectedStateVendor, statesInfo]);

  useEffect(() => {
    const GetVendorStates = async () => {
      if (selectedVendor) {
        const data = await NextYearOrderbookManager.getStatesFromVendorId(selectedVendor);
        if (data) {
          setSelectedStateTab(0);
          setStatesInfo(data);
          setNumSteppers(data.length);
          setUpdateStateTotals(true);
        }
      }
    };

    GetVendorStates();
  }, [selectedVendor, setUpdateStateTotals]);

  useEffect(() => {
    const handleTotals = totals => {
      let statesInfoCopy = statesInfo;

      statesInfo.forEach((s, index) => {
        const indexData = totals.findIndex(d => d.stateVendorId === s.stateVendorId);
        if (indexData >= 0) {
          const currentStateTotals = totals[indexData].totals;
          if (currentStateTotals === null) {
            statesInfoCopy[index] = { ...s, totals: null };
          } else {
            statesInfoCopy[index] = { ...s, totals: currentStateTotals };
          }
        }
      });

      setStatesInfo(statesInfoCopy);
      setPageShouldReRender(true);
      setPageShouldReRender(false);
    };

    const getStateTotals = async () => {
      if (!statesInfo) {
        setUpdateStateTotals(false);
        return;
      }

      const year = new Date().getFullYear();
      const states = statesInfo.map(s => s.stateVendorId).join(",");

      setUpdateStateTotals(false);
      if (year && states) {
        const totals = await NextYearOrderbookManager.listStatesNextYearOrderbookTotals(
          year,
          states
        );
        handleTotals(totals);
      }
    };
    updateStateTotals && getStateTotals();
  }, [statesInfo, updateStateTotals, setUpdateStateTotals]);

  return {
    selectedStateVendor,
    setUpdateStateTotals,
    numSteppers,
    pageShouldRerender,
    statesInfo,
    selectedStateTab,
    setSelectedStateTab,
    stateTabsAreReady
  };
};
