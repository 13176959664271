import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Typography
} from "@material-ui/core";
import { PRIMARY_COLOR, PALE_BLUE_GRAY } from "../../../theme";
import ExpandableRow from "../ExpandableRow";
import NoAssociationsCreatedIcon from "../../img/NoAssociationsCreatedIcon.svg";
import Loading from "../Loading";

const CollapsibleTable = props => {
  const {
    headers,
    data,
    edit,
    remove,
    hover,
    buttonsDisabled,
    loading,
    expandableEditAction,
    openTabs,
    closeTab,
    setCloseTab
  } = props;

  const classes = useStyles();

  const generateCollapsibleHeader = vendorsList => {
    let management = vendorsList[0]["management"];
    let states;
    let statesArray = [];
    vendorsList.map((vendor, index) => statesArray.push(vendor.states));
    statesArray.map((state, index) =>
      states ? (states = states.concat(state)) : (states = state)
    );
    states = states.filter((a, b) => states.indexOf(a) === b).join(" • ");

    const collapsibleHeader = {
      management: management,
      vendor: " ",
      states: states
    };

    return collapsibleHeader;
  };

  const generarteCollapsibleBody = vendorsList => {
    let collapsibleHeader = {};
    let collapsibleBody = [];
    vendorsList.forEach((vendor, index) => {
      collapsibleHeader = {
        management: " ",
        vendor: vendor["vendor"],
        states: vendor["states"].join(" • "),
        vendorId: vendor["vendorId"],
        active: vendor["active"]
      };
      collapsibleBody.push(collapsibleHeader);
    });

    return collapsibleBody;
  };
  const shouldRenderBody = data && data.length > 0;
  return (
    <Grid container item xs={12}>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow className="pr-2 pl-2">
            {headers &&
              headers.map((header, index) => (
                <TableCell
                  className={`${classes.tableHeaderColor} ${index === 0 && "pl-4"}`}
                  key={header.name + index + "header"}
                  align={header.align || "left"}
                  width={header.width}
                >
                  {header.name}
                </TableCell>
              ))}

            {(edit || remove) && (
              <TableCell
                className={`${classes.tableHeaderColor} ${
                  edit && remove ? classes.paddingHeaderTwoActions : classes.paddingHeaderOneActions
                }`}
                key={"action-header"}
                align={"right"}
              >
                Ações
              </TableCell>
            )}
          </TableRow>
        </TableHead>

        {shouldRenderBody && (
          <TableBody>
            {data.map((managementArray, managementIndex) => (
              <ExpandableRow
                headers={headers}
                edit={edit}
                remove={remove}
                hover={hover}
                managementIndex={managementIndex}
                collapsibleHeader={generateCollapsibleHeader(managementArray)}
                collapsibleBody={generarteCollapsibleBody(managementArray)}
                disabled={buttonsDisabled}
                key={"row " + managementIndex}
                expandableEditAction={expandableEditAction}
                openTabs={openTabs}
                closeTab={closeTab}
                setCloseTab={setCloseTab}
              />
            ))}
          </TableBody>
        )}
      </Table>
      {!shouldRenderBody &&
        (loading ? (
          <Grid
            item
            xs={12}
            className={`${classes.noAssociationBorder} ${classes.noAssociationBorderTop}`}
          >
            <Loading loading />
          </Grid>
        ) : (
          <Grid
            container
            item
            xs={12}
            className={`${classes.noAssociationBorder} ${classes.noAssociationBorderTop}`}
          >
            <Grid item xs={12} className="pt-6">
              <img
                src={NoAssociationsCreatedIcon}
                alt="Nenhuma associação feita"
                className={classes.noAssociationIcon}
              />
            </Grid>
            <Grid item xs={12} className="pb-6">
              <Typography className="h7" align="center" color="primary">
                Ainda não possuem gerências <br />- consultorias associadas!
              </Typography>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  table: {
    [theme.breakpoints.down("xs")]: {
      display: "block",
      width: "100%",
      overflowX: "auto"
    },
    "&.MuiTable-root": {
      borderCollapse: "inherit",
      borderWidth: "1px",
      borderColor: PALE_BLUE_GRAY,
      borderStyle: "solid",
      borderRadius: theme.spacing(1)
    }
  },
  tableHeaderColor: {
    color: PRIMARY_COLOR
  },
  paddingHeaderTwoActions: {
    "&.MuiTableCell-sizeSmall:last-child": {
      paddingRight: "60px"
    }
  },
  paddingHeaderOneActions: {
    "&.MuiTableCell-sizeSmall:last-child": {
      paddingRight: "44px"
    }
  },
  noAssociationIcon: {
    width: 50,
    height: 50,
    display: "flex",
    margin: "auto"
  },
  noAssociationBorder: {
    borderColor: PALE_BLUE_GRAY,
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "8px",
    borderCollapse: "inherit",
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "0px"
  },
  noAssociationBorderTop: {
    borderTop: "none"
  }
}));

export default CollapsibleTable;
