import { makeStyles, Tab, Tabs, Typography } from "@material-ui/core";
import React from "react";
import SVGIcon from "../../../shared/components/SVGIcon";
import { DataType } from "../../../shared/utils/enum";
import { BRIGHT_BLUE, LIGHT_GRAY, PALE_BLUE_GRAY, SECONDARY_COLOR, WHITE } from "../../../theme";

const EditTypeTabs = props => {
  const { selectedTypeTab, handleChangeTab, disabled } = props;

  const classes = useStyles();

  const getLabelTab = type => {
    return (
      <>
        <SVGIcon
          src={DataType[type].smallIcon}
          className={`${classes.tabIcon} ${
            selectedTypeTab === DataType[type].id && classes.selectedIconColor
          } ${disabled && classes.disabledIconColor}`}
        />
        <Typography
          variant="body2"
          className={`${classes.tabTyphography} ${
            selectedTypeTab === DataType[type].id
              ? classes.textTabColorSelected
              : classes.textTabColor
          } ${disabled && classes.disabledColor}`}
        >
          {DataType[type].name}
        </Typography>
      </>
    );
  };

  return (
    <>
      <Tabs
        value={selectedTypeTab}
        indicatorColor="secondary"
        textColor="secondary"
        onChange={handleChangeTab}
        className={disabled && classes.tabsDisabled}
      >
        <Tab
          label={getLabelTab("competitor")}
          className={`${classes.borderBetweenTabs} ${classes.tab}`}
          disabled={disabled}
        />
        <Tab
          label={getLabelTab("customer")}
          className={`${classes.borderBetweenTabs} ${classes.tab}`}
          disabled={disabled}
        />
        <Tab label={getLabelTab("crop")} className={classes.tab} disabled={disabled} />
      </Tabs>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  borderBetweenTabs: {
    borderRight: `1px solid ${PALE_BLUE_GRAY}`,
    borderImage: `radial-gradient(${PALE_BLUE_GRAY} 80%, ${WHITE} 20%)`,
    borderImageSlice: 1
  },
  tabTyphography: {
    margin: "auto"
  },
  textTabColor: {
    color: BRIGHT_BLUE,
    textTransform: "none"
  },
  textTabColorSelected: {
    color: SECONDARY_COLOR,
    textTransform: "none"
  },
  tab: {
    maxWidth: "176px",
    minWidth: "176px",
    "& .MuiTab-wrapper": {
      flexDirection: "row"
    }
  },
  tabIcon: {
    height: theme.spacing(4.5),
    width: theme.spacing(7.5),
    backgroundColor: BRIGHT_BLUE
  },
  selectedIconColor: {
    backgroundColor: SECONDARY_COLOR
  },
  disabledColor: {
    color: LIGHT_GRAY
  },
  disabledIconColor: {
    backgroundColor: LIGHT_GRAY
  },
  tabsDisabled: {
    "& .MuiTabs-indicator": {
      backgroundColor: LIGHT_GRAY
    }
  }
}));
export default EditTypeTabs;
