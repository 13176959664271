import { useState } from "react";

const useCreateMessageModalStepper = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNextStep = () => setActiveStep(activeStep + 1);

  const handleBackStep = () => activeStep > 0 && setActiveStep(activeStep - 1);

  const isFirstStep = activeStep === 0;

  return {
    activeStep,
    setActiveStep,
    handleNextStep,
    handleBackStep,
    isFirstStep
  };
};

export default useCreateMessageModalStepper;
