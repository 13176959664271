import SellingBalanceService from "../../services/SellingBalanceService";

const FIRST_DAY_OF_YEAR = new Date(new Date().getFullYear(), 0, 1);
const LAST_DAY_OF_NEXT_YEAR = new Date(new Date().getFullYear() + 1, 11, 31);

class SellingBalanceManager {
  async getSellingLimits(vendorInitials, startDate, endDate) {
    if (!this.validateDates(startDate, endDate)) {
      return;
    }
    const answer = await SellingBalanceService.getSellingLimits(vendorInitials, startDate, endDate);

    return answer && answer.data;
  }
  
  async getForecastAndEvolutions(vendorInitials, startDate, endDate) {
    if (!this.validateDates(startDate, endDate)) {
      return;
    }
    const answer = await SellingBalanceService.getForecastAndEvolutions(vendorInitials, startDate, endDate);
    
    return answer && answer.data;
  }

  validateDates(startDate, endDate) {
    if (startDate >= FIRST_DAY_OF_YEAR
      && startDate <= LAST_DAY_OF_NEXT_YEAR
      && startDate <= endDate
      && endDate <= new Date(startDate.getFullYear() + 1, startDate.getMonth(), 0)
      && endDate <= LAST_DAY_OF_NEXT_YEAR
    ){
      return true;
    }
    return false;
  }
}

export default new SellingBalanceManager();
