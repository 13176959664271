import {
  Box,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Tooltip
} from "@material-ui/core";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState, useRef } from "react";
import { Check, Edit, X } from "react-feather";
import { DataType } from "../../../shared/utils/enum";
import { DARK_GREEN, GRAY, LIGHT_GRAY, MID_BLUE, RED } from "../../../theme";
import useListCropType from "../customHooks/useListCropType";
import useListCustomerType from "../customHooks/useListCustomerType";
import useUpdateCompetitor from "../customHooks/useUpdateCompetitor";
import useUpdateCrop from "../customHooks/useUpdateCrop";
import useUpdateCustomer from "../customHooks/useUpdateCustomer";

const EditableLine = ({
  index,
  entity,
  selectedTabHasType,
  selectedTypeTab,
  authenticatedUserId,
  loadData,
  showSuccessNotification
}) => {
  const classes = useStyles();
  const [editable, setEditable] = useState(false);
  const [listType, setListType] = useState([]);
  const [name, setName] = useState(entity.name);
  const [type, setType] = useState(entity.type && entity.type.id);

  const { listCustomerTypes, handleListCustomerTypes } = useListCustomerType();
  const { listCropType, handleListCropType } = useListCropType();
  const { handleUpdateCompetitor } = useUpdateCompetitor();
  const { handleUpdateCustomer } = useUpdateCustomer();
  const { handleUpdateCrop } = useUpdateCrop();

  const refSelectedTypeTab = useRef(selectedTypeTab);
  const refHandleListCustomerType = useRef(handleListCustomerTypes);
  const refHandleListCropType = useRef(handleListCropType);
  useEffect(() => {
    refSelectedTypeTab.current = selectedTypeTab;
    refHandleListCustomerType.current = handleListCustomerTypes;
    refHandleListCropType.current = handleListCropType;
  });

  const handleEditLine = () => {
    setEditable(true);
  };

  const handleCancelEditLine = () => {
    setEditable(false);
    setName(entity.name);
    if (selectedTabHasType) setType(entity.type.id);
  };

  const handleSaveEditLine = async entityId => {
    const newEntity = {
      Name: name,
      lastChangeUserId: authenticatedUserId
    };

    let idUpdated;

    if (selectedTypeTab === DataType["competitor"].id)
      idUpdated = await handleUpdateCompetitor(newEntity, entityId);

    if (selectedTypeTab === DataType["customer"].id) {
      newEntity.CustomerTypeId = type;
      idUpdated = await handleUpdateCustomer(newEntity, entityId);
    }

    if (selectedTypeTab === DataType["crop"].id) {
      newEntity.CropTypeId = type;
      idUpdated = await handleUpdateCrop(newEntity, entityId);
    }

    if (idUpdated) {
      showSuccessNotification("Registro atualizado com sucesso.");
      loadData();
    }
  };

  useEffect(() => {
    if (refSelectedTypeTab.current === DataType["customer"].id) setListType(listCustomerTypes);
    if (refSelectedTypeTab.current === DataType["crop"].id) setListType(listCropType);
  }, [listCustomerTypes, listCropType]);

  useEffect(() => {
    if (editable) {
      if (refSelectedTypeTab.current === DataType["customer"].id)
        refHandleListCustomerType.current();
      if (refSelectedTypeTab.current === DataType["crop"].id) refHandleListCropType.current();
    }
  }, [editable]);

  return (
    <TableRow>
      <TableCell style={{ height: "100%" }}>
        <label
          className={`${!editable ? classes.lightGrayColor : classes.midBlue} ${classes.label} ${
            classes.editableLabel
          } pr-4`}
        >
          Nome
        </label>
        {editable ? (
          <TextField
            className={classes.field}
            value={name}
            onChange={e => setName(e.target.value.toUpperCase())}
          />
        ) : (
          <Tooltip title={entity.name.toUpperCase()} placement="top-start">
            <span className={classes.truncateField}>{entity.name.toUpperCase()}</span>
          </Tooltip>
        )}
      </TableCell>

      {selectedTabHasType && (
        <TableCell style={{ height: "100%" }}>
          <label
            className={`${!editable ? classes.lightGrayColor : classes.midBlue} ${classes.label} ${
              classes.editableLabel
            } pr-4`}
          >
            Tipo
          </label>

          {editable ? (
            <Select className={classes.field} value={type} onChange={e => setType(e.target.value)}>
              {listType.map((itemType, itemIndex) => (
                <MenuItem key={`type ${entity.id} ${itemIndex}`} value={itemType.id}>
                  {itemType.description}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Tooltip title={entity.type.description} placement="top-start">
              <span className={classes.truncateField}>{entity.type.description}</span>
            </Tooltip>
          )}
        </TableCell>
      )}

      <TableCell>
        <label className={`${classes.lightGrayColor} ${classes.label} pr-4`}>
          Última alteração
        </label>
        {format(parseISO(entity.lastChangeDate), "dd/MM/yyyy")}
      </TableCell>

      <TableCell>
        <label className={`${classes.lightGrayColor} ${classes.label} pr-4`}>Por</label>
        <Tooltip title={entity.lastChangeUser.name} placement="top-start">
          <span className={classes.truncateField}>{entity.lastChangeUser.name}</span>
        </Tooltip>
      </TableCell>

      <TableCell>
        <label className={`${classes.lightGrayColor} ${classes.label} pr-4`}>Status</label>
        <span style={{ color: `${entity.active ? DARK_GREEN : RED}` }}>
          {entity.active ? "Ativo" : "Inativo"}
        </span>
      </TableCell>

      <TableCell className={classes.lightGrayColor} align="right">
        <Box className={classes.containerActions}>
          <label
            style={{ float: "none" }}
            className={`${classes.lightGrayColor} ${classes.label} pr-4`}
          >
            Ações
          </label>
          {editable ? (
            <>
              <IconButton className="p-0" onClick={() => handleCancelEditLine()}>
                <X size={24} color={RED} />
              </IconButton>
              <IconButton className="p-0 ml-4" onClick={() => handleSaveEditLine(entity.id)}>
                <Check size={24} color={DARK_GREEN} />
              </IconButton>
            </>
          ) : (
            <IconButton className="p-0" onClick={() => handleEditLine()}>
              <Edit size={24} color={GRAY} />
            </IconButton>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles(theme => ({
  label: {
    textTransform: "uppercase",
    float: "left"
  },
  editableLabel: {
    height: "100%",
    display: "flex",
    alignItems: "center"
  },
  truncateField: {
    display: "block",
    float: "left",
    width: "65%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  lightGrayColor: {
    color: LIGHT_GRAY
  },
  midBlue: {
    color: MID_BLUE
  },
  field: {
    width: "calc(100% - 62px)"
  },
  containerActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  }
}));

export default EditableLine;
