import { useState } from "react";

const useEditUserTextFields = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [ADUserId, setADUserId] = useState("");

  return {
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    ADUserId,
    setADUserId
  };
};

export default useEditUserTextFields;
