import React, { useState } from "react";
import {
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Collapse,
  List,
  makeStyles
} from "@material-ui/core";
import { WHITE } from "../../../theme";
import { ChevronUp, ChevronDown } from "react-feather";

const NestedSidebarListItems = props => {
  const { children, name, divider, icon } = props;

  const [openNestedMenu, setOpenNestedMenu] = useState(false);

  const classes = useStyles();

  return (
    <>
      <ListItem className={`pr-0 pl-${icon ? "4" : "5"}`}  button onClick={() => setOpenNestedMenu(!openNestedMenu)}>
        {icon}
        <ListItemText
          primary={
            <Typography variant="subtitle1" className={classes.typographyColorWhite}>
              {name}
            </Typography>
          }
        />
        {openNestedMenu ? (
          <ChevronUp className="mr-4" color={WHITE} size={28} />
        ) : (
          <ChevronDown className="mr-4" color={WHITE} size={28} />
        )}
      </ListItem>
      <Collapse in={openNestedMenu} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
      {divider && <Divider className={classes.divider} />}
    </>
  );
};

const useStyles = makeStyles(() => ({
  divider: {
    backgroundColor: WHITE
  },
  typographyColorWhite: {
    color: WHITE
  }
}));

export default NestedSidebarListItems;
