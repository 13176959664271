import React from "react";
import { Tabs, Tab, makeStyles, Typography, Grid, Box } from "@material-ui/core";
import { DataType } from "../../../shared/utils/enum";
import PotentialTypeIcons from "./PotentialTypeIcons";
import { numberWithSeparators } from "../../../shared/utils/Utils";
import { SECONDARY_COLOR, BRIGHT_BLUE, PALE_BLUE_GRAY, WHITE, LIGHT_GRAY3 } from "../../../theme";

const PotentialTypeTabs = props => {
  const {
    selectedTypeTab,
    handleChangeTab,
    customerTotalVolume,
    competitorTotalVolume,
    cropTotalVolume,
    isMobile,
    customerYaraTotalVolume,
    competitorYaraTotalVolume,
    cropYaraTotalVolume
  } = props;

  const classes = useStyles({ isMobile: isMobile });

  const getLabelTab = type => {
    return (
      <>
        <Grid container>
          <Grid item xs={!isMobile ? 6 : 12}>
            <PotentialTypeIcons
              selected={selectedTypeTab === DataType[type].id}
              icon={DataType[type].icon}
              isMobile={isMobile}
            />
          </Grid>
          <Grid item container xs={!isMobile ? 6 : 12} className={!isMobile && "pl-2 pt-4"}>
            <Grid item xs={12}>
              <Typography
                variant={!isMobile ? "body1" : "caption"}
                className={
                  selectedTypeTab === DataType[type].id
                    ? classes.textTabColorSelected
                    : classes.textTabColor
                }
                align={!isMobile ? "left" : "center"}
              >
                {DataType[type].name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };
  const lineTotal = (competitorValue, customerValue, cropValue, type) => {
    const isYaraVolume = type && type.includes("Yara");
    return (
      <Grid container>
        <Grid item xs={4} className={classes.adjustTotalPosition}>
          <Typography
            variant={!isMobile ? "body1" : "body2"}
            className={classes.textTabColor}
            align="center"
          >
            {numberWithSeparators(competitorValue)}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography
            variant={!isMobile ? "body1" : "body2"}
            className={classes.textTabColor}
            align="center"
          >
            <span
              className={`${classes.lineLabel} ${
                isYaraVolume ? classes.totalYaraLabelPosition : classes.totalLabelPosition
              }`}
            >
              {type}
            </span>
            <span className={isYaraVolume ? classes.totalYaraPosition : classes.totalPosition}>
              {numberWithSeparators(customerValue)}
            </span>
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography
            variant={!isMobile ? "body1" : "body2"}
            className={classes.textTabColor}
            align="center"
          >
            <span
              className={`${classes.lineLabel} ${
                isYaraVolume ? classes.totalYaraLabelPosition : classes.totalLabelPosition
              }`}
            >
              {type}
            </span>
            <span className={isYaraVolume ? classes.totalYaraPosition : classes.totalPosition}>
              {numberWithSeparators(cropValue)}
            </span>
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box className="pb-2">
      <Tabs
        value={selectedTypeTab}
        indicatorColor="secondary"
        textColor="secondary"
        onChange={handleChangeTab}
        className={classes.tabs}
      >
        <Tab
          label={getLabelTab("competitor")}
          className={`${classes.borderBetweenTabs} ${classes.tabWidth}`}
        />
        <Tab
          label={getLabelTab("customer")}
          className={`${classes.borderBetweenTabs} ${classes.tabWidth}`}
        />
        <Tab label={getLabelTab("crop")} className={classes.tabWidth} />
      </Tabs>
      {lineTotal(
        competitorTotalVolume,
        customerTotalVolume,
        cropTotalVolume,
        isMobile ? "Total" : "Vol. Total"
      )}
      {lineTotal(
        competitorYaraTotalVolume,
        customerYaraTotalVolume,
        cropYaraTotalVolume,
        isMobile ? "Yara" : "Vol. Yara"
      )}
    </Box>
  );
};
const useStyles = makeStyles(theme => ({
  tabs: {
    "& .MuiTabs-fixed": {
      margin: "auto",
      display: "flex",
      maxWidth: "100%"
    },
    "& .MuiTabs-flexContainer": {
      margin: "auto",
      display: "flex",
      width: "100%"
    },
    "& .MuiTabs-indicator": {
      top: 0,
      height: "4px"
    }
  },
  borderBetweenTabs: {
    borderRight: `1px solid ${PALE_BLUE_GRAY}`,
    borderImage: `radial-gradient(${PALE_BLUE_GRAY} 80%, ${WHITE} 20%)`,
    borderImageSlice: 1
  },
  tabWidth: {
    maxWidth: "calc(100% /3)",
    minWidth: "calc(100% /3)"
  },
  textTabColor: props => ({
    color: BRIGHT_BLUE,
    textTransform: "none",
    fontSize: props.isMobile ? "0.75rem" : "1rem"
  }),
  textTabColorSelected: props => ({
    color: SECONDARY_COLOR,
    textTransform: "none",
    fontSize: props.isMobile ? "0.75rem" : "1rem"
  }),
  lineLabel: {
    color: LIGHT_GRAY3,
    float: "left"
  },
  adjustTotalPosition: props => ({
    marginRight: props.isMobile ? "-16px" : "-20px"
  }),
  totalLabelPosition: props => ({
    marginLeft: !props.isMobile ? "-12px" : "0px"
  }),
  totalYaraLabelPosition: props => ({
    marginLeft: !props.isMobile ? "-9px" : "3px"
  }),
  totalPosition: props => ({
    marginLeft: !props.isMobile ? "4px" : "0px"
  }),
  totalYaraPosition: props => ({
    marginLeft: !props.isMobile ? "8px" : "0px"
  })
}));

export default PotentialTypeTabs;
