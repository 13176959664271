import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  makeStyles,
  TableFooter
} from "@material-ui/core";
import { Edit, Trash2 } from "react-feather";
import TablePaginationActions from "./TablePaginationActions";
import { PRIMARY_COLOR, PALE_BLUE_GRAY, GRAY, SECONDARY_COLOR, WHITE } from "../../../theme";
import ToolTipItem from "../ToolTipItem";

const CustomTable = props => {
  const {
    headers,
    data,
    options,
    total,
    pagination,
    page,
    edit,
    remove,
    hover,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    onClickEdit,
    onClickRemove,
    footerData,
    shouldOverflow = true,
  } = props;

  const classes = useStyles({ shouldOverflow });

  const setOnClickFunction = function (row, fn, params) {
    const paramsValues = [];

    for (let i = 0; i < params.length; i++) paramsValues.push(row[params[i]]);

    return fn(...paramsValues);
  };

  const renderRow = (row, field) => {
    if (
      options[field] &&
      options[field].hasTooltip &&
      row[field] &&
      row[field].length >= options[field].toolTipLength
    ) {
      return <ToolTipItem width={options[field].toolTipWidth} content={row[field]} />;
    } else if (options[field] && options[field].hasImage && options[field].image) {
      return (
        <img
          className={`${classes.img} hover-icon`}
          src={options[field].image}
          alt={options[field].alt}
        />
      );
    } else if (options[field] && options[field].hasImageToggle) {
      return (
        <img
          className={`${classes.img} hover-icon`}
          src={
            options[field].fieldToggle && row[options[field].fieldToggle]
              ? options[field].imageSuccess
              : options[field].imageError
          }
          alt={
            options[field].fieldToggle
              ? options[field].imageSuccessAlt
              : options[field].imageErrorAlt
          }
        />
      );
    } else {
      return <>{row[field]}</>;
    }
  };

  const mapDataToRows = (dataToMap, isFooter) => {
    return dataToMap.map((line, rowIndex) => {
      return (
        <TableRow className="pr-2 pl-2" key={rowIndex} hover={hover}>
          {headers &&
            headers.map((header, cellIndex) => (
              <TableCell
                className={`${options[header.field]?.columnClassName} ${isFooter && classes.footerText}`}
                key={`${header.field}-${cellIndex}`}
                align={header.alignContent}
                onClick={
                  options[header.field] &&
                  options[header.field].onClick &&
                  options[header.field].onClickParams
                    ? () =>
                        setOnClickFunction(
                          line,
                          options[header.field].onClick,
                          options[header.field].onClickParams
                        )
                    : undefined
                }
              >
                {renderRow(line, header.field)}
              </TableCell>
            ))}

          {(edit || remove) && (
            <TableCell className={classes.action} key={"action"}>
              {edit && (
                <IconButton onClick={() => onClickEdit(line.id)} className="p-0">
                  <Edit className="mr-2 ml-2" />
                </IconButton>
              )}
              {remove && (
                <IconButton onClick={() => onClickRemove(line.id)} disabled className="p-0">
                  <Trash2 className="mr-2 ml-2" />
                </IconButton>
              )}
            </TableCell>
          )}
        </TableRow>
      );
    });
  }

  return (
    <Grid item xs={12}>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow className="pr-2 pl-2">
            {headers &&
              headers.map((header, index) => (
                <TableCell
                  className={`${classes.tableHeaderColor} ${options[header.field]?.columnClassName}`}
                  key={header.name + index + "header"}
                  align={header.align || "left"}
                  width={header.width}
                >
                  {header.name}
                </TableCell>
              ))}

            {(edit || remove) && (
              <TableCell
                className={`${classes.tableHeaderColor}`}
                key={"action-header"}
                align={"center"}
              >
                Ações
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data && mapDataToRows(data)}
        </TableBody>
        {footerData &&
          <TableFooter className={classes.footer}>
            {mapDataToRows(footerData, true)}
          </TableFooter>
        }
      </Table>

      {pagination && (
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          labelRowsPerPage="Itens por página:"
          classes={{ caption: classes.caption }}
        />
      )}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  table: ({ shouldOverflow }) => ({
    [theme.breakpoints.down("xs")]: {
      display: shouldOverflow ? "block" : "table",
      width: "100%",
      overflowX: "auto"
    },
    "&.MuiTable-root": {
      borderCollapse: "inherit",
      borderWidth: "1px",
      borderColor: PALE_BLUE_GRAY,
      borderStyle: "solid",
      borderRadius: theme.spacing(1)
    }
  }),
  tableHeaderColor: {
    color: PRIMARY_COLOR
  },
  action: {
    [theme.breakpoints.down("xs")]: {
      display: "table-cell"
    },
    display: "cell-table",
    textAlign: "center",
    justifyContent: "space-around"
  },
  caption: {
    color: GRAY
  },
  footer: {
    backgroundColor: SECONDARY_COLOR,
  },
  footerText: {
    color: WHITE,
  }
}));

export default CustomTable;
