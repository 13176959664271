import React, { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { SECONDARY_COLOR } from "../../../theme";
import { BarChart2 } from "react-feather";
import PageFilter from "./PageFilter";
import FilterButtons from "../../../shared/components/FilterButtons";
import PotentialExportManager from "../PotentialExportManager";
import PageTitle from "../../../shared/components/PageTitle";

const PotentialManager = props => {
  const classes = UseStyles();

  const [exporting, setExporting] = useState(false);
  const [disableExport, setDisableExport] = useState(false);

  const getPotentialExport = async () => {
    setExporting(true);
    setDisableExport(true);

    await PotentialExportManager.getPotentialReport();

    setExporting(false);
    setDisableExport(false);
  };

  return (
    <Grid container className={`pl-8 pt-8 pr-8 pb-4`}>
      <Grid item xs={12}>
        <PageTitle
          title="Gestão de Potencial"
          icon={<BarChart2 color={SECONDARY_COLOR} size={56} className={classes.iconBarChart2} />}
        />
      </Grid>

      <PageFilter />
      <FilterButtons
        secondaryDisabled
        secondaryText="FILTRAR"
        primaryText="EXPORTAR"
        primaryAction={getPotentialExport}
        primaryLoading={exporting}
        primaryDisabled={disableExport}
      />
    </Grid>
  );
};

const UseStyles = makeStyles(theme => ({
  iconBarChart2: {
    verticalAlign: "bottom"
  }
}));

export default PotentialManager;
