import store from "../../store";
import { alertMessageActions } from "../../features/alertMessage/redux";
import axios from "axios";

const defaultErrorMessage = "Ocorreu um erro ao executar a operação.";
export const authenticationErrorMessage = "Erro de autenticação. Favor tentar novamente.";

export function handleRequestError(error) {
  if (axios.isCancel(error)) return;
  
  let message = error.response && error.response.data && error.response.data.message;

  store.dispatch(alertMessageActions.showNotification(message || defaultErrorMessage));
}
