import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import Select from "../../../shared/components/Select";
import { GRAY } from "../../../theme";

const PageFilter = () => {
  const classes = UseStyles();
  return (
    <>
      <Grid item xs={12} className="pb-2">
        <Typography variant="h5" align="left">
          Filtros
        </Typography>
      </Grid>
      <Grid item xs={12} className="pb-2 pt-1">
        <Typography align="left" className={classes.subtitlePage} variant="body1">
          Selecione os filtros abaixo para exportar ou filtrar as consultorias desejadas.
        </Typography>
      </Grid>
      <Grid container item className="pb-4 pt-1" spacing={2}>
        <Grid item sm={3} xs={12}>
          <Select label="Anos" placeholder={new Date().getFullYear()} disabled />
        </Grid>
        <Grid item sm={3} xs={12}>
          <Select label="Gerência" placeholder="Todas" disabled />
        </Grid>
        <Grid item sm={3} xs={12}>
          <Select label="Consultorias" placeholder="Todas" disabled />
        </Grid>
        <Grid item sm={3} xs={12}>
          <Select label="Estados" placeholder="Todos" disabled />
        </Grid>
      </Grid>
    </>
  );
};

const UseStyles = makeStyles(theme => ({
  subtitlePage: {
    color: GRAY
  }
}));

export default PageFilter;
