import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  makeStyles,
  Tooltip
} from "@material-ui/core";
import { TrendingUp, TrendingDown, Check, Clock } from "react-feather";
import TablePaginationActions from "../../../shared/components/CustomTable/TablePaginationActions";
import { PRIMARY_COLOR, PALE_BLUE_GRAY, GRAY, DARK_GREEN } from "../../../theme";
import { format, parseISO } from "date-fns";
import { range } from "lodash";
import Loading from "../../../shared/components/Loading";
import CustomTableNotReadMessage from "./CustomTableNotReadMessage";
import { formatManagements, formatVendors } from "../FormatTableData";

const CustomTableAdjustmentMessage = props => {
  const { messages = [], loading = false } = props;

  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [optionsRowsPerPage, setOptionRowPerPage] = useState([]);

  useEffect(() => {
    let items = [];

    const skip = page * rowsPerPage;

    for (let i = skip; i < skip + rowsPerPage; i++) {
      if (messages[i]) items.push(messages[i]);
    }

    setData(items);
  }, [messages, page, rowsPerPage]);

  useEffect(() => {
    const defaultRowsPerPage = 5;
    const options = range(
      defaultRowsPerPage,
      messages.length + defaultRowsPerPage,
      defaultRowsPerPage
    );
    setOptionRowPerPage(options);
    setPage(0);
  }, [messages]);

  return (
    <Grid item xs={12}>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow className="pr-2 pl-2">
            <TableCell className={`${classes.tableHeaderColor}`} align="left" width="8%">
              Gerências
            </TableCell>
            <TableCell className={`${classes.tableHeaderColor}`} align="left" width="8%">
              Consultorias
            </TableCell>
            <TableCell className={`${classes.tableHeaderColor}`} align="left" width="16%">
              Gerente/Consultor
            </TableCell>
            <TableCell className={`${classes.tableHeaderColor}`} align="left" width="14%">
              Telefone
            </TableCell>
            <TableCell className={`${classes.tableHeaderColor}`} align="left" width="9%">
              Data do envio
            </TableCell>
            <TableCell className={`${classes.tableHeaderColor}`} align="left" width="4%">
              Estado
            </TableCell>
            <TableCell className={`${classes.tableHeaderColor}`} align="left" width="14%">
              Cultura
            </TableCell>
            <TableCell className={`${classes.tableHeaderColor}`} align="left" width="8%">
              Ajuste
            </TableCell>
            <TableCell className={`${classes.tableHeaderColor}`} align="left" width="10%">
              Status de leitura
            </TableCell>
            <TableCell className={`${classes.tableHeaderColor}`} align="left" width="9%">
              Data da leitura
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading &&
            data &&
            data.map((message, indexMessage) => {
              return (
                <TableRow className="pr-2 pl-2" key={indexMessage} hover>
                  <TableCell align="left">{formatManagements(message.vendors)}</TableCell>
                  <TableCell align="left">{formatVendors(message.vendors)}</TableCell>
                  <TableCell align="left">
                    <Tooltip title={message.recipientMessage[0].user.name} placement="top-start">
                      <span className={classes.truncateField}>
                        {message.recipientMessage[0].user.name}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left">{message.recipientMessage[0].user.phone}</TableCell>
                  <TableCell align="left">
                    {format(parseISO(message.sendDate), "dd/MM/yyyy")}
                  </TableCell>
                  <TableCell align="left">{message.adjustmentMessage.state.initials}</TableCell>
                  <TableCell align="left">
                    <Tooltip title={message.adjustmentMessage.crop.name} placement="top-start">
                      <span className={classes.truncateField}>
                        {message.adjustmentMessage.crop.name}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left">
                    <span className={classes.containerIcon}>
                      {message.adjustmentMessage.isIncreasing ? (
                        <TrendingUp size={16} className={`mr-2`} />
                      ) : (
                        <TrendingDown size={16} className={`mr-2`} />
                      )}
                      {message.adjustmentMessage.volume}
                    </span>
                  </TableCell>
                  <TableCell align="left">
                    {message.recipientMessage[0].readDate ? (
                      <span className={`${classes.containerIcon} ${classes.read}`}>
                        <Check size={16} className={`mr-2`} />
                        Lida
                      </span>
                    ) : (
                      <span className={classes.containerIcon}>
                        <Clock size={16} className={`mr-2`} />
                        Não lida
                      </span>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {message.recipientMessage[0].readDate &&
                      format(parseISO(message.recipientMessage[0].readDate), "dd/MM/yyyy")}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>

      {loading && (
        <Grid item xs={12} className={`${classes.noMessagesBorder} ${classes.noMessagesBorderTop}`}>
          <Loading loading />
        </Grid>
      )}

      {!loading && data.length <= 0 && <CustomTableNotReadMessage />}

      <TablePagination
        rowsPerPageOptions={optionsRowsPerPage}
        component="div"
        count={messages.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(e, newPage) => setPage(newPage)}
        onChangeRowsPerPage={e => {
          setRowsPerPage(e.target.value);
          setPage(0);
        }}
        ActionsComponent={TablePaginationActions}
        labelRowsPerPage="Itens por página:"
        classes={{ caption: classes.caption }}
      />
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  table: {
    tableLayout: "fixed",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      width: "100%",
      overflowX: "auto"
    },
    "&.MuiTable-root": {
      borderCollapse: "inherit",
      borderWidth: "1px",
      borderColor: PALE_BLUE_GRAY,
      borderStyle: "solid",
      borderRadius: theme.spacing(1)
    }
  },
  tableHeaderColor: {
    color: PRIMARY_COLOR
  },
  action: {
    [theme.breakpoints.down("xs")]: {
      display: "table-cell"
    },
    display: "cell-table",
    textAlign: "center",
    justifyContent: "space-around"
  },
  caption: {
    color: GRAY
  },
  containerIcon: {
    display: "flex",
    alignItems: "center"
  },
  read: {
    color: DARK_GREEN
  },
  truncateField: {
    display: "block",
    width: "calc(100% - 20px)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}));

export default CustomTableAdjustmentMessage;
