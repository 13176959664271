import React from "react";
import {
  Grid,
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Button,
  Typography
} from "@material-ui/core";
import { SECONDARY_COLOR, GRAY } from "../../../theme";
import { Plus } from "react-feather";
import { routes } from "../../../shared/utils/Constants";
import PageTitle from "../../../shared/components/PageTitle";
import { Redirect } from "react-router-dom";
import AboutAutocomplete from "../../aboutAutoComplete";
import { screenSizes } from "../../../shared/utils/enum";
import { connect } from "react-redux";
import useNewDirectMessageConsultant from "../customHooks/useNewDirectMessageConsultant";
import useNewDirectMessageInputs from "../customHooks/useNewDirectMessageInputs";
import Loading from "../../../shared/components/Loading";
import Modal from "../../../shared/components/Modal";
import SuccessMessage from "../../../shared/img/SuccessMessage.svg";

const NewDirectMessageConsultant = props => {
  const classes = useStyles();

  const { selectedVendorId, windowWidth, userSenderId } = props;

  const isMobile = windowWidth < screenSizes.webScreenSize;

  const {
    selectedReason,
    description,
    selectedAbout,
    setSelectedReason,
    listDirectMessageReasons,
    setSelectedAbout,
    setDescription
  } = useNewDirectMessageInputs();

  const {
    setRedirect,
    createMessage,
    sendButtonDisabled,
    sendingMessage,
    successModalOpen,
    redirect,
    fetchAboutOptions
  } = useNewDirectMessageConsultant(
    userSenderId,
    selectedVendorId,
    selectedReason,
    description,
    selectedAbout
  );

  return (
    <Grid container className={`pl-8 pt-8 pr-8 pb-4`}>
      <PageTitle
        title="Nova mensagem"
        icon={<Plus color={SECONDARY_COLOR} size={36} className={`${classes.icon} mr-1`} />}
        isMobile={isMobile}
      />

      <Grid item xs={12} sm={6} className={`pb-4 pt-4 ${!isMobile ? "pr-2" : null}`}>
        <FormControl variant="outlined" className={classes.formControl} required>
          <InputLabel id="direct-message-reason" className={classes.label}>
            Motivo
          </InputLabel>
          <Select
            labelId="direct-message-reason"
            value={selectedReason}
            onChange={e => setSelectedReason(e.target.value)}
            label="Motivo *"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
          >
            {listDirectMessageReasons.map((item, index) => (
              <MenuItem key={`${index}`} value={item.id}>
                {item.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} className={`pb-4 ${!isMobile ? "pl-2 pt-4" : null}`}>
        <AboutAutocomplete
          handleChangeItem={e => setSelectedAbout(e)}
          fetchOptions={fetchAboutOptions}
          placeholder={"Concorrente, Cliente ou Cultura"}
          shouldClear
          width="100%"
          disabled={false}
          required
        />
      </Grid>
      <Grid item xs={12} className="pb-4">
        <TextField
          className={classes.textField}
          variant="outlined"
          label="Mensagem"
          inputProps={{ maxLength: 255 }}
          value={description}
          onChange={e => setDescription(e.target.value)}
          multiline
          InputLabelProps={{
            className: classes.label
          }}
          rows={8}
          required
        />
      </Grid>
      <Grid item xs={6} className="pl-4 pr-4">
        <Button
          variant="outlined"
          color="primary"
          className={`${classes.button}`}
          onClick={() => setRedirect(<Redirect to={routes.consultantMessage} />)}
        >
          VOLTAR
        </Button>
      </Grid>
      <Grid item xs={6} className="pl-4 pr-4">
        <Button
          variant="contained"
          color="primary"
          className={`${classes.button}`}
          onClick={() => createMessage()}
          disabled={sendButtonDisabled}
        >
          {sendingMessage ? (
            <Loading size={20} loading={sendingMessage} className={classes.loadingPosition} />
          ) : (
            "ENVIAR"
          )}
        </Button>
      </Grid>

      <Modal
        primaryButton={{
          action: () => setRedirect(<Redirect to={routes.consultantMessage} />),
          text: "FECHAR"
        }}
        openedModal={successModalOpen}
        closeModal={() => setRedirect(<Redirect to={routes.consultantMessage} />)}
        title="Mensagem enviada!"
        titleIcon={<img src={SuccessMessage} alt="Success Message" className={`pl-1`} />}
        closeIcon
      >
        <Typography align="center" className={classes.colorSubtitle}>
          A mensagem foi enviada com sucesso.
        </Typography>
      </Modal>

      {redirect}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  formControl: {
    width: "100%"
  },
  textField: {
    width: "100%"
  },
  button: {
    width: "100%",
    height: "56px"
  },
  icon: {
    width: 32,
    height: 32,
    verticalAlign: "middle",
    display: "inline-block"
  },
  label: {
    color: `rgba(0,0,0,0.54)`
  },
  loadingPosition: {
    margin: theme.spacing(2),
    position: "relative",
    color: SECONDARY_COLOR
  },
  colorSubtitle: {
    color: GRAY
  }
}));

const mapStateToProps = state => {
  return {
    userSenderId: state.user.id,
    selectedVendorId: state.selectedVendor.id,
    windowWidth: state.window.width
  };
};

export default connect(mapStateToProps, null)(NewDirectMessageConsultant);
