import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Tooltip } from "@material-ui/core";
import { GRAY } from "../../../../theme";
import { TrendingUp, TrendingDown } from "react-feather";
import { dialogNotificationBox } from "../../../utils/Constants";

const ItemAdjustmentMessage = props => {
  const { state, crop, volume, isIncreasing, description } = props;

  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container item xs={12} className="pt-4">
        <Grid item xs={3} align="center">
          <Typography className={`${classes.details} body3`}>{state}</Typography>
        </Grid>
        <Grid item xs={3} align="center">
          <Typography className={`${classes.details} body3`}>
            {crop.length > dialogNotificationBox.maxCropCharacters ? (
              <Tooltip title={crop} placement="top-start">
                <span className={classes.truncateField}>{crop}</span>
              </Tooltip>
            ) : (
              crop
            )}
          </Typography>
        </Grid>
        <Grid item xs={3} align="center">
          {isIncreasing ? (
            <TrendingUp className={classes.details} />
          ) : (
            <TrendingDown className={classes.details} />
          )}
        </Grid>
        <Grid item xs={3} align="center">
          <Typography className={`${classes.details} body3`}>{volume}</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} className="pb-4">
        <Grid item xs={3} align="center">
          <Typography variant="caption" className={classes.details}>
            Estado
          </Typography>
        </Grid>
        <Grid item xs={3} align="center">
          <Typography variant="caption" className={classes.details}>
            Cultura
          </Typography>
        </Grid>
        <Grid item xs={3} align="center">
          <Typography variant="caption" className={classes.details}>
            {isIncreasing ? "Aumentar" : "Diminuir"}
          </Typography>
        </Grid>
        <Grid item xs={3} align="center">
          <Typography variant="caption" className={classes.details}>
            Volume (kt)
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption" className={classes.details}>
          {description}
        </Typography>
      </Grid>
    </React.Fragment>
  );
};

const useStyles = makeStyles(() => ({
  details: {
    color: GRAY
  },
  truncateField: {
    display: "block",
    width: "calc(100%)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}));

export default ItemAdjustmentMessage;
