import React, { useEffect, useState } from 'react';
import { Grid, Typography, makeStyles } from "@material-ui/core";
import CustomTable from '../../../shared/components/CustomTable/CustomTable';
import Loading from "../../../shared/components/Loading";
import CompletionPercentageAndBalance from './CompletionPercentageAndBalance';
import { SECONDARY_COLOR } from '../../../theme';
import YearPicker from './YearPicker';
import WeekLabel from '../../../shared/components/WeekLabel/index';
import { useWeeks } from '../../solidMarket/customHooks/useWeeks';
import CustomMessage from "../../../shared/components/CustomMessage";
import { AlertCircle } from "react-feather";

const SellingLimitTab = props => {
  const {
    isLoading,
    sellingLimitsData,
    isMobile,
    onChangeDates,
    startDate,
    endDate
  } = props;

  const classes = useStyles();

  const [sellingLimits, setSellingLimits] = useState([]);
  const [totals, setTotals] = useState({});
  const { weekNumber, setWeekNumber } = useWeeks();

  useEffect(() => {
    if (sellingLimitsData) {
      setTotals({
        rawMaterial: <b>Total</b>,
        limit: sellingLimitsData.limit,
        submitted: sellingLimitsData.submitted,
        completionPercentageAndBalance: <CompletionPercentageAndBalance {...sellingLimitsData} isFooter />,
      });

      const limits = sellingLimitsData.sellingLimits;

      if (limits) {
        setSellingLimits(limits.map(item => {
          return {
            ...item,
            limit: item.limit,
            completionPercentageAndBalance: <CompletionPercentageAndBalance {...item} />,
          }
        }));
      }
    }
  }, [sellingLimitsData]);

  const tableHeaders = [
    {
      field: "rawMaterial",
      name: <Typography className={classes.headers}>Matéria prima</Typography>
    },
    {
      field: "limit",
      name: <Typography className={classes.headers}>Limite</Typography>
    },
    {
      field: "submitted",
      name: <Typography className={classes.headers}>Ingresso sem.</Typography>
    },
    {
      field: "completionPercentageAndBalance",
      name: <Typography className={classes.headers}>% realizado/ Saldo</Typography>
    },
  ]

  return (
    <>
      {isLoading ? (
        <Loading size={32} loading={isLoading} />
      ) : (
        <Grid container justify="center">
          <Grid container item xs={12} alignItems="center" spacing={2} className="pt-4 pb-4 pl-2">
            <Grid item xs={7}>
              <WeekLabel
                setWeekNumber={setWeekNumber}
                weekNumber={weekNumber}
                isMobile={isMobile}
              />
            </Grid>
            <Grid item xs={5}>
              <YearPicker onChangeDates={onChangeDates} startDate={startDate} endDate={endDate} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {sellingLimits.length ? (
              <CustomTable
                headers={tableHeaders}
                data={sellingLimits}
                footerData={[totals]}
                options={{}}
              />
            ) : (
              <CustomMessage
                icon={
                  <AlertCircle
                    color={SECONDARY_COLOR}
                    size={!isMobile ? 26 : 24}
                  />
                }
                description="Não existem dados para os filtros selecionados."
                isMobile={isMobile}
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}

const useStyles = makeStyles(theme => ({
  headers: {
    color: SECONDARY_COLOR,
    fontSize: "0.875rem",
  },
}));

export default SellingLimitTab;