import { useState, useEffect } from "react";
import NewDirectMessageConsultantManager from "../NewDirectMessageConsultantManager";

const useNewDirectMessageInputs = () => {
  const [listDirectMessageReasons, setListDirectMessageReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState("");
  const [selectedAbout, setSelectedAbout] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    const getDirectMessageReasons = async () => {
      const result = await NewDirectMessageConsultantManager.listDirectMessageReasons();

      if (result) setListDirectMessageReasons(result);
    };

    getDirectMessageReasons();
  }, []);

  return {
    selectedReason,
    description,
    selectedAbout,
    setSelectedReason,
    listDirectMessageReasons,
    setSelectedAbout,
    setDescription
  };
};

export default useNewDirectMessageInputs;
