import React from "react";
import { Tooltip, Box } from "@material-ui/core";

const ToolTipItem = props => {
  const { width, placement, content } = props;

  return (
    <Tooltip title={content} placement={placement || "top"}>
      <Box className="text-overflow-ellipsis" style={{ width: width || 135 }}>
        {content}
      </Box>
    </Tooltip>
  );
};

export default ToolTipItem;
