import React, { useState, useEffect, useCallback } from "react";
import { Grid, MobileStepper, makeStyles, Divider } from "@material-ui/core";
import { SECONDARY_COLOR, PALE_BLUE_GRAY } from "../../../theme";
import { connect } from "react-redux";
import {
  productGroupOptions,
  screenSizes,
  sellingBalanceSearchPeriods,
  siteInitialsOptions,
} from "../../../shared/utils/enum";
import PageTitle from "../../../shared/components/PageTitle";
import CustomMessage from "../../../shared/components/CustomMessage";
import Tabs from "../../../shared/components/Tabs";
import { ShoppingBag, Search, AlertCircle } from "react-feather";
import { createSellingBalanceSearchTabsEnum } from "../searchTabsEnum";
import SearchFilters from "./SearchFilters";
import SellingBalanceSearchManager from "../SellingBalanceSearchManager";
import ForecastGroupsTable from "./ForecastGroupsTable";

const SellingBalanceSearch = (props) => {
  const { windowWidth, selectedVendorInitials, location } = props;

  const isMobile = windowWidth < screenSizes.webScreenSize;
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState(0);
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [filterOptions, setFilterOptions] = useState(INITIAL_FILTER_OPTIONS);
  const [forecastGroups, setForecastGroups] = useState([]);
  const [isLoadingForecastGroups, setIsLoadingForecastGroups] = useState(false);

  const fetchInitialFilterOptions = useCallback(async () => {
    let familyGroups = await SellingBalanceSearchManager.getFamilyGroupOptions();
    let siteInitials = await SellingBalanceSearchManager.getSiteInitialsOptions();

    if (
      familyGroups && familyGroups.length &&
      siteInitials && siteInitials.length
    ) {
      siteInitials = [siteInitialsOptions.all].concat(siteInitials);

      setFilterOptions({
        ...INITIAL_FILTER_OPTIONS,
        familyGroup: familyGroups,
        siteInitials: siteInitials,
      });

    }
  }, []);

  const fetchProductGroupOptions = useCallback(
    async (familyGroupId) => {
      let productGroups = await SellingBalanceSearchManager.getProductGroupOptions(familyGroupId);

      if (productGroups && productGroups.length) {
        productGroups = [productGroupOptions.all].concat(
          productGroups
        );

        setFilterOptions({
          familyGroup: filterOptions.familyGroup,
          productGroup: productGroups,
          siteInitials: filterOptions.siteInitials,
        });
      }
    },
    [filterOptions.familyGroup, filterOptions.siteInitials]
  );

  useEffect(() => {
    if(location.state?.familyGroup) {
      setIsLoadingForecastGroups(true);
      setFilters({
        ...INITIAL_FILTERS,
        familyGroup: location.state.familyGroup
      });
      setFilterOptions({
        ...INITIAL_FILTER_OPTIONS,
        familyGroup: [location.state.familyGroup],
      });
    }

    fetchInitialFilterOptions();
  }, [fetchInitialFilterOptions, location]);

  useEffect(() => {
    if (filters.familyGroup) {
      fetchProductGroupOptions(filters.familyGroup);
    }
  }, [filters.familyGroup, fetchProductGroupOptions]);

  const handleChangeFilters = (newFilters) => {
    if (newFilters.familyGroup !== filters.familyGroup) {
      if (!newFilters.siteInitials) {
        newFilters.siteInitials = filterOptions.siteInitials[0];
      }

      newFilters.productGroup = filterOptions.productGroup[0];
    }
    
    setIsLoadingForecastGroups(true);
    setFilters(newFilters);
  };

  const getSearchPeriod = useCallback((tab) => {
    return tab === 1
      ? sellingBalanceSearchPeriods.weekly
      : sellingBalanceSearchPeriods.monthly;
  }, []);

  const fetchForecastGroups = useCallback(async (vendorInitials, searchFilters, searchPeriod) => {
    setIsLoadingForecastGroups(true);

    const forecastGroupsData =
      await SellingBalanceSearchManager.getForecastGroups(
        vendorInitials,
        searchFilters,
        searchPeriod
      );

    if (forecastGroupsData) {
      setForecastGroups(forecastGroupsData);
    }

    setIsLoadingForecastGroups(false);
  }, []);

  useEffect(() => {
    if (selectedVendorInitials && filters.familyGroup) {
      const searchPeriod = getSearchPeriod(selectedTab);

      fetchForecastGroups(selectedVendorInitials, filters, searchPeriod);
    }
  }, [
    selectedTab,
    selectedVendorInitials,
    filters,
    fetchForecastGroups,
    getSearchPeriod,
  ]);

  const handleChangeTab = (_, newTab) => {
    setSelectedTab(newTab);
  };

  return (
    <Grid
      container
      justify="center"
      className={!isMobile ? "pl-8 pt-8 pr-8 pb-4" : "pl-4 pt-8 pr-4 pb-4"}
    >
      <Grid container justify="center" item xs={12} className="mb-4">
        <Grid item xs={isMobile ? 12 : 6}>
          <PageTitle
            title="Saldo a Vender"
            subtitle="Volume em toneladas"
            isMobile={isMobile}
            icon={
              <ShoppingBag
                color={SECONDARY_COLOR}
                size={!isMobile ? 36 : 32}
                className={`${classes.iconSalesBalance} pr-1`}
              />
            }
          />
        </Grid>
      </Grid>
      <Grid container item xs={isMobile ? 12 : 6}>
        <Grid item xs={12}>
          <SearchFilters
            isMobile={isMobile}
            filters={filters}
            filterOptions={filterOptions}
            onChangeFilters={handleChangeFilters}
          />
        </Grid>
        <Grid container item xs={12} className={classes.contentAligment}>
          <Grid item xs={12}>
            <Tabs
              selectedTab={selectedTab}
              handleChangeTab={handleChangeTab}
              isMobile={isMobile}
              tabsEnum={createSellingBalanceSearchTabsEnum({
                selectedTab,
                isMobile,
              })}
            />
            <Divider className={classes.divider} />
          </Grid>
          <Grid item xs={12}>
            {!filters.familyGroup ? (
              <CustomMessage
                icon={
                  <Search color={SECONDARY_COLOR} size={!isMobile ? 26 : 24} />
                }
                description="Selecione um dos filtros para iniciar a busca."
                isMobile={isMobile}
              />
            ) : (
              <>
                {!isLoadingForecastGroups && !forecastGroups.length ? (
                  <CustomMessage
                    icon={
                      <AlertCircle
                        color={SECONDARY_COLOR}
                        size={!isMobile ? 26 : 24}
                      />
                    }
                    description="Não existem dados para os filtros selecionados."
                    isMobile={isMobile}
                  />
                ) : (
                  <ForecastGroupsTable
                    isMobile={isMobile}
                    forecastGroupsData={forecastGroups}
                    isLoading={isLoadingForecastGroups}
                    searchPeriod={getSearchPeriod(selectedTab)}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} className="pb-4">
          <MobileStepper
            variant="dots"
            steps={2}
            position="static"
            activeStep={selectedTab}
            className={classes.stepper}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const INITIAL_FILTERS = {
  familyGroup: "",
  productGroup: "",
  siteInitials: "",
};

const INITIAL_FILTER_OPTIONS = {
  familyGroup: [],
  productGroup: [productGroupOptions.all],
  siteInitials: [],
};

const useStyles = makeStyles((theme) => ({
  iconSalesBalance: {
    verticalAlign: "bottom",
  },
  stepper: {
    "& .MuiMobileStepper-dots": {
      margin: "auto",
      display: "flex",
    },
    "& .MuiMobileStepper-dotActive": {
      backgroundColor: SECONDARY_COLOR,
    },
  },
  contentAligment: {
    margin: "auto",
    display: "flex",
    width: "100%",
    border: `1px solid ${PALE_BLUE_GRAY}`,
    borderRadius: theme.spacing(1),
  },
  divider: {
    height: theme.spacing(0.25),
    backgroundColor: PALE_BLUE_GRAY,
  },
}));

const mapStateToProps = (state) => {
  return {
    windowWidth: state.window.width,
    selectedVendorInitials: state.selectedVendor.initials,
  };
};

export default connect(mapStateToProps, null)(SellingBalanceSearch);
