import React from "react";
import Modal from "../../../shared/components/Modal";
import Select from "../../../shared/components/Select";
import { Typography, makeStyles } from "@material-ui/core";
import { GRAY } from "../../../theme";

const AddStateModal = props => {
  const {
    handleOpenCloseAddStateModal,
    handleAddState,
    addStateModalOpen,
    statesList,
    addSelectedState,
    setAddSelectedState
  } = props;

  const classes = useStyles();

  return (
    <Modal
      secondaryButton={{
        action: () => handleOpenCloseAddStateModal(),
        text: "FECHAR",
        variant: "outlined"
      }}
      primaryButton={{
        action: () => handleAddState(),
        text: "ADICIONAR",
        variant: "contained",
        color: "primary",
        disabled: !addSelectedState
      }}
      openedModal={addStateModalOpen}
      closeModal={handleOpenCloseAddStateModal}
      title="Adicionar Estado"
      titleAlign="left"
      closeIcon
      id="addStateModal"
    >
      <Typography align="left" variant="body1" className={`${classes.colorSubtitle} pb-3`}>
        Selecione o estado a ser adicionado a consultoria
      </Typography>
      <Select
        label="Estado"
        options={statesList}
        code={addSelectedState && addSelectedState.id}
        handleChange={e => setAddSelectedState(e)}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        maxHeightMenu="150"
        returnFullObject
        menuPortalTarget={document.getElementById("addStateModal")}
      />
    </Modal>
  );
};

const useStyles = makeStyles(theme => ({
  colorSubtitle: {
    color: GRAY
  }
}));

export default AddStateModal;
