import { Button, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { routes } from '../../../shared/utils/Constants'

const FamilyGroupButton = props => {
  const {
    familyGroup,
    isMobile,
  } = props;

  const classes = useStyles({ isMobile });
  const [redirect, setRedirect] = useState(null);

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        className={`${classes.familyGroup} ${!isMobile && " ml-4"}`}
        onClick={() => 
          setRedirect(
            <Redirect 
              to={{
                pathname: routes.sellingBalanceSearch,
                state: { familyGroup: familyGroup }
              }}
              push
            />
          )
        }
      >
        {familyGroup}
      </Button>
      {redirect}
    </>
  );
}

const useStyles = makeStyles(theme => ({
  familyGroup: ({ isMobile }) => ({
    width: isMobile ? "80%" : "60%",
  }),
}));

export default FamilyGroupButton;