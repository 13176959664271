import VendorService from "../../services/VendorService";
import ManagementService from "../../services/ManagementService";
import StateService from "../../services/StateService";
import StateVendorCompetitorService from "../../services/StateVendorCompetitorService";
import StateVendorCropService from "../../services/StateVendorCropService";
import StateVendorCustomerService from "../../services/StateVendorCustomerService";

class AssociationsManager {
  async listManagements() {
    return ManagementService.listManagements().then(response => response && response.data);
  }
  async listVendorsByManagementIdsOrActive(managementIds, active) {
    return VendorService.listVendorsByManagementIds(managementIds, active).then(
      response => response && response.data
    );
  }
  async getVendorByVendorId(vendorId) {
    return VendorService.getVendorByVendorId(vendorId).then(
      response => response && response.data
    );
  }
  async listStates() {
    return StateService.listStates().then(response => response && response.data);
  }
  async listVendorStates(vendorId) {
    return StateService.getStatesFromVendorIds(vendorId, false).then(
      response => response && response.data
    );
  }
  async listStateCompetitors(stateVendorId) {
    return StateVendorCompetitorService.listCompetitorsByStateVendor(stateVendorId).then(
      response => response && response.data
    );
  }
  async listStateCrops(stateVendorId) {
    return StateVendorCropService.listCropsByStateVendor(stateVendorId).then(
      response => response && response.data
    );
  }
  async listStateCustomers(stateVendorId) {
    return StateVendorCustomerService.listCustomersByStateVendor(stateVendorId).then(
      response => response && response.data
    );
  }
  async saveEditVendor(data, vendorId) {
    return VendorService.saveEditVendor(data, vendorId).then(
      response => response && response.data
    );
  }
  async createVendor(data) {
    return VendorService.createVendor(data).then(response => response && response.data);
  }

  async createManagement(data) {
    return ManagementService.createManagement(data).then(
      response => response && response.data
    );
  }
  async verifyCompetitorHasVolume(stateVendorCompetitorId) {
    return StateVendorCompetitorService.verifyCompetitorHasVolume(
      stateVendorCompetitorId
    ).then(response => response && response.data);
  }
  async verifyCropHasVolume(stateVendorCropId) {
    return StateVendorCropService.verifyCropHasVolume(stateVendorCropId).then(
      response => response && response.data
    );
  }
  async verifyCustomerHasVolume(stateVendorCustomerId) {
    return StateVendorCustomerService.verifyCustomerHasVolume(stateVendorCustomerId).then(
      response => response && response.data
    );
  }
}

export default new AssociationsManager();
