import { useState } from "react";
import CompetitorCustomerCropManager from "../CompetitorCustomerCropManager";

const useListCrop = () => {
  const [listCrop, setListCrop] = useState({});
  const [loadingData, setLoadingData] = useState(false);

  const handleListCrop = async (searchName, rowsPerPage, page) => {
    setLoadingData(true);

    const result = await CompetitorCustomerCropManager.listCrop(searchName, rowsPerPage, page);
    if (result) setListCrop(result);

    setLoadingData(false);
  };

  return {
    listCrop,
    loadingData,
    handleListCrop
  };
};

export default useListCrop;
