import React from "react";
import {
  Grid,
  Typography,
  makeStyles,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import { GRAY, PRIMARY_COLOR } from "../../../theme";

const Treatment = props => {
  const { treatmentRadio, setTreatmentRadio } = props;
  const classes = useStyles();
  return (
    <Grid container item xs={12}>
      <Typography variant="h5" className="pb-4">
        Tratativa
      </Typography>
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.textColor}>
          Com base no que há de cadastros no sistema, essa solicitação se trata de um novo cliente
          ou de um cliente que já existe e foi cadastrado como novo por engano?
        </Typography>
      </Grid>
      <Grid item xs={12} className="pt-3">
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="type"
            name="type"
            value={treatmentRadio}
            onChange={e => setTreatmentRadio(e.target.value)}
          >
            <FormControlLabel
              value={"new"}
              control={<Radio className={classes.radio} />}
              label={<Typography className={classes.textColor}>Novo Cadastro</Typography>}
              labelPlacement="end"
              color="primary"
            />
            <FormControlLabel
              value={"exists"}
              control={<Radio className={classes.radio} />}
              label={<Typography className={classes.textColor}>Cadastro já existente</Typography>}
              labelPlacement="end"
              color="primary"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  textColor: {
    color: GRAY
  },
  radio: {
    "&.Mui-checked": {
      color: PRIMARY_COLOR
    }
  }
}));

export default Treatment;
