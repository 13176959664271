import { useState, useEffect } from "react";
import BackofficeMessageManager from "../BackofficeMessageManager";

const useCreateMessageModalAdjustment = selectedVendors => {
  const [adjustmentSelectedReason, setAdjustmentSelectedReason] = useState();
  const [adjustmentSelectedState, setAdjustmentSelectedState] = useState();
  const [adjustmentSelectedCrop, setAdjustmentSelectedCrop] = useState();
  const [adjustmentReasons, setAdjustmentReasons] = useState([]);
  const [adjustmentStates, setAdjustmentStates] = useState([]);
  const [adjustmentCrops, setAdjustmentCrops] = useState([]);
  const [adjustmentIsActiveCropsSelect, setAdjustmentIsActiveCropsSelect] = useState(false);
  const [adjustmentIsIncreasing, setAdjustmentIsIncreasing] = useState(null);
  const [adjustmentVolume, setAdjustmentVolume] = useState("");
  const [adjustmentDescription, setAdjustmentDescription] = useState("");

  const adjustmentFinishButtonDisabled =
    !adjustmentSelectedReason ||
    !adjustmentSelectedCrop ||
    !adjustmentIsIncreasing ||
    !adjustmentVolume ||
    !adjustmentSelectedState ||
    !adjustmentDescription;

  useEffect(() => {
    const getReasons = async () => {
      const data = await BackofficeMessageManager.listAdjustmentReasons();
      if (data) setAdjustmentReasons(data);
    };
    getReasons();
  }, []);

  useEffect(() => {
    setAdjustmentSelectedReason();
    setAdjustmentIsIncreasing(null);
    setAdjustmentVolume("");
    setAdjustmentDescription("");
  }, [selectedVendors]);

  useEffect(() => {
    const getCrops = async () => {
      const data = await BackofficeMessageManager.listCropsByStateVendorId(
        adjustmentSelectedState.stateVendorId
      );
      if (data) setAdjustmentCrops(data);
      setAdjustmentIsActiveCropsSelect(true);
    };
    setAdjustmentIsActiveCropsSelect(false);
    setAdjustmentSelectedCrop();
    adjustmentSelectedState && getCrops();
  }, [adjustmentSelectedState]);

  useEffect(() => {
    const getStates = async () => {
      const data = await BackofficeMessageManager.listStatesVendorByVendorIds(selectedVendors.map(vendor => vendor.id));
      if (data) setAdjustmentStates(data);
      setAdjustmentSelectedState();
    };
    selectedVendors && selectedVendors.length && getStates();
  }, [selectedVendors]);

  return {
    adjustmentSelectedReason,
    adjustmentSelectedCrop,
    adjustmentSelectedState,
    adjustmentStates,
    adjustmentReasons,
    adjustmentCrops,
    adjustmentIsActiveCropsSelect,
    adjustmentIsIncreasing,
    adjustmentVolume,
    adjustmentDescription,
    setAdjustmentIsIncreasing,
    setAdjustmentVolume,
    setAdjustmentSelectedReason,
    setAdjustmentDescription,
    setAdjustmentSelectedState,
    setAdjustmentSelectedCrop,
    adjustmentFinishButtonDisabled
  };
};

export default useCreateMessageModalAdjustment;
