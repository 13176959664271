import { useState } from "react";
import { DataType } from "../../../shared/utils/enum";

export const useSaleTypeTabs = () => {
  const [customerTotalVolume, setCustomerTotalVolume] = useState(0);
  const [competitorTotalVolume, setCompetitorTotalVolume] = useState(0);
  const [cropTotalVolume, setCropTotalVolume] = useState(0);

  const [selectedTypeTab, setSelectedTypeTabs] = useState(DataType["competitor"].id);

  const handleChangeTab = (event, newValue) => setSelectedTypeTabs(newValue);

  const updateTotalVolume = (
    index,
    newValueCurrentYear,
    newValueFirstNextYear,
    newValueSecondNextYear,
    newValueThirdNextYear
  ) => {
    const totalValue = parseFloat(
      newValueCurrentYear + newValueFirstNextYear + newValueSecondNextYear + newValueThirdNextYear
    ).toFixed(2);

    switch (index) {
      case DataType["competitor"].id:
        setCompetitorTotalVolume(parseFloat(totalValue));
        return;
      case DataType["customer"].id:
        setCustomerTotalVolume(parseFloat(totalValue));
        return;
      case DataType["crop"].id:
        setCropTotalVolume(parseFloat(totalValue));
        return;
      default:
        return;
    }
  };

  return {
    selectedTypeTab,
    setSelectedTypeTabs,
    handleChangeTab,
    customerTotalVolume,
    competitorTotalVolume,
    cropTotalVolume,
    updateTotalVolume
  };
};
