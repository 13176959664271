import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { routes } from "../../../shared/utils/Constants";

const useRedirectListAssociations = () => {
  const [redirect, setRedirect] = useState(null);

  const handleRedrectToEditVendor = vendorId => {
    setRedirect(<Redirect to={`${routes.editVendor}/${vendorId}`} />);
  };

  return {
    redirect,
    handleRedrectToEditVendor
  };
};

export default useRedirectListAssociations;
