import React from "react";
import {
  Grid,
  Typography,
  makeStyles,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField
} from "@material-ui/core";
import { GRAY, PRIMARY_COLOR } from "../../../theme";
import Select from "../../../shared/components/Select";
import { acceptOnlyNumbers } from "../../../shared/utils/Utils";

const CreateMessageModalAdjustment = props => {
  const classes = useStyles();

  const {
    selectedReason,
    selectedCrop,
    selectedState,
    states,
    reasons,
    crops,
    isActiveCropsSelect,
    isIncreasing,
    volume,
    description,
    setIsIncreasing,
    setVolume,
    setSelectedReason,
    setDescription,
    setSelectedState,
    setSelectedCrop
  } = props;

  return (
    <Grid container className="pt-4">
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.colorGray}>
          Preencha qual o motivo, estado, cultura e volume que é para ser ajustado
        </Typography>
      </Grid>
      <Grid container item xs={12} spacing={3} className="mt-0">
        <Grid item xs={5}>
          <Select
            label="Motivo"
            code={selectedReason && selectedReason.id}
            maxHeightMenu="150"
            options={reasons}
            handleChange={e => setSelectedReason(e)}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.description}
            returnFullObject
            required
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            label="Estado"
            code={selectedState && selectedState.id}
            maxHeightMenu="150"
            options={states}
            handleChange={e => setSelectedState(e)}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.initials}
            returnFullObject
            required
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            label="Cultura"
            code={selectedCrop && selectedCrop.id}
            maxHeightMenu="150"
            options={crops}
            handleChange={e => setSelectedCrop(e)}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            returnFullObject
            disabled={!isActiveCropsSelect}
            required
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={3} className="mt-4">
        <Grid item xs={5}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="increasing"
              name="increasing"
              value={isIncreasing}
              onChange={e => setIsIncreasing(e.target.value)}
            >
              <FormControlLabel
                value={"increasing"}
                control={<Radio className={classes.radio} />}
                label={
                  <Typography className={isIncreasing !== "increasing" && classes.colorGray}>
                    Aumentar
                  </Typography>
                }
                labelPlacement="end"
                color="primary"
                className="mt-2"
              />
              <FormControlLabel
                value={"notIncreasing"}
                control={<Radio className={classes.radio} />}
                label={
                  <Typography className={isIncreasing !== "notIncreasing" && classes.colorGray}>
                    Diminuir
                  </Typography>
                }
                labelPlacement="end"
                color="primary"
                className="mr-0 mt-2"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Volume"
            variant="outlined"
            value={volume}
            onChange={e => setVolume(acceptOnlyNumbers(e.target.value))}
            required
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt-7">
        <TextField
          label="Descricão"
          variant="outlined"
          className={`${classes.descriptionTextField}`}
          InputProps={{ className: `${classes.descriptionTextField} ${classes.descriptionInput}` }}
          inputProps={{ maxLength: 255 }}
          InputLabelProps={{ className: classes.colorGray }}
          multiline
          value={description}
          onChange={e => setDescription(e.target.value)}
          required
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  colorGray: {
    color: GRAY
  },
  radio: {
    "&.Mui-checked": {
      color: PRIMARY_COLOR
    }
  },
  descriptionTextField: {
    width: "100%",
    height: "104px"
  },
  descriptionInput: {
    display: "block",
    paddingTop: "16px"
  }
}));

export default CreateMessageModalAdjustment;
