import { addDays } from "date-fns";
import { dayWeek, preRegisteredTypes } from "./enum";

export const numberWithSeparators = x => {
  return parseFloat(x)
    .toFixed(2)
    .toString()
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const acceptOnlyNumbers = text => {
  return text.replace(/[^0-9,.]/, "");
};

export const acceptOnlyNumbersWithoutDot = text => {
  return text.replace(/[^0-9]/, "");
};

export const formatWithTwoDecimalDigits = text => {
  return parseFloat(text).toLocaleString("pt-BR", { minimumFractionDigits: 2 });
};

export const getFirstDateYear = year => {
  let firstDayOfYear = new Date(year, 0, 1);

  if (firstDayOfYear.getDay() > dayWeek.wednesday) {
    firstDayOfYear = addDays(firstDayOfYear, 7 - firstDayOfYear.getDay());
  } else {
    firstDayOfYear = addDays(firstDayOfYear, -firstDayOfYear.getDay());
  }

  return firstDayOfYear;
};

export const getLastDateYear = year => {
  return addDays(new Date(getFirstDateYear(year + 1)), -1);
};

export const validateEmail = email => {
  const regex = new RegExp(/^\S+@\S+\.[a-zA-Z]+/);
  return regex.test(email);
};

export const validateADUserId = ADUserId => {
  const regex = new RegExp(/^[a-zA-Z][0-9]+$/);
  return regex.test(ADUserId) && ADUserId.length >= 7;
};

export const validatePhone = phone => {
  return onlyNumbers(phone).length < 10;
};

export const onlyNumbers = text => {
  return text && text.replace(/\D+/g, "");
};

export const getEvolution = (yearSolidSale, potential) =>
  ((yearSolidSale / potential) * 100).toFixed(2);

export const generateId = () => Math.random().toString(36).substr(2, 9);

export const removeAccent = text => {
  const accentedCharacters = "ÄÅÁÂÀÃäáâàãÉÊËÈéêëèÍÎÏÌíîïìÖÓÔÒÕöóôòõÜÚÛüúûùÇç";
  const nonAccentedCharacters = "AAAAAAaaaaaEEEEeeeeIIIIiiiiOOOOOoooooUUUuuuuCc";

  for (let i = 0; i < accentedCharacters.length; i++) {
    text = text.replaceAll(accentedCharacters[i].toString(), nonAccentedCharacters[i].toString());
  }

  return text;
};

export const isValidTypePillar = type => {
  return type === preRegisteredTypes.competitor || type === preRegisteredTypes.customer;
};
