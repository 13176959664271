import CropService from "../../services/CropService";
import CompetitorService from "../../services/CompetitorService";
import CustomerService from "../../services/CustomerService";

class TypeAutoCompleteManager {
  async searchCropByTerm(term) {
    return await CropService.searchCropByTerm(term).then(response => response && response.data);
  }
  async searchCompetitorByTerm(term) {
    return await CompetitorService.searchCompetitorByTerm(term).then(
      response => response && response.data
    );
  }
  async searchCustomerByTerm(term) {
    return await CustomerService.searchCustomerByTerm(term).then(
      response => response && response.data
    );
  }
}

export default new TypeAutoCompleteManager();
