import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const SVGIcon = props => {
  const useStyles = makeStyles({
    loadIcon: {
      "-webkit-mask": `url(${props.src}) no-repeat center`,
      mask: `url(${props.src}) no-repeat center`
    }
  });

  const classes = useStyles();

  return <div className={` ${classes.loadIcon} ${props.className ? props.className : ""}`} />;
};

export default SVGIcon;
