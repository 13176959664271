import { useState, useEffect } from "react";

export const usePageScroll = () => {
  const [isEndOfScreen, setIsEndOfScreen] = useState(
    window.innerHeight + window.scrollY >= document.body.offsetHeight
  );
  const [pageHeight, setPageHeight] = useState(document.body.offsetHeight);

  const resizeObserver = new ResizeObserver(entries =>
    setPageHeight(entries[0].target.clientHeight)
  );
  resizeObserver.observe(document.body);

  useEffect(() => {
    setIsEndOfScreen(window.innerHeight + window.scrollY >= document.body.offsetHeight);
  }, [pageHeight]);

  window.onscroll = ev => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) setIsEndOfScreen(true);
    else if (isEndOfScreen) setIsEndOfScreen(false);
  };

  return { isEndOfScreen };
};
