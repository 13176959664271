import axios from "../axiosInstance";

class CustomerService {
  async searchCustomerByTerm(term) {
    return await axios.get(`customer/search`, {
      params: { term }
    });
  }

  async listCustomer(searchName, rowsPerPage, page) {
    return await axios.get(
      `customer?${
        searchName ? `searchName=${searchName.replace(/&/g, "%26")}&` : ""
      }rowsPerPage=${rowsPerPage}&pageNumber=${page}`
    );
  }

  async createCustomer(customer) {
    return await axios.post("customer", customer);
  }

  async updateCustomer(customer, customerId) {
    return await axios.put(`customer/${customerId}`, customer);
  }

  async getPreRegisteredCustomer(preRegisteredId) {
    return await axios.get(`customer/preregistered/${preRegisteredId}`);
  }

  async completePreRegisteredCustomer(preRegisteredCustomerId, userId, correctCustomerId) {
    return await axios.put(`customer/approvepreregistered/${preRegisteredCustomerId}`, null, {
      params: { userId, correctCustomerId }
    });
  }
}

export default new CustomerService();
