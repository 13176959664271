export const NOTIFY = "notification/NOTIFY";
export const NOTIFY_SUCCESS = "notification/NOTIFY_SUCCESS";
export const HIDE_NOTIFICATION = "notification/HIDE_NOTIFICATION";

const showNotification = message => {
  return {
    type: NOTIFY,
    payload: { message }
  };
};

const showSuccessNotification = message => {
  return {
    type: NOTIFY_SUCCESS,
    payload: { message }
  };
};

const hideNotification = () => {
  return {
    type: HIDE_NOTIFICATION
  };
};

export { showNotification, hideNotification, showSuccessNotification };
