import MessageService from "../../../services/MessageService";

class MessageManager {
  async listRecentMessagesByUserIdAndVendorId(userId, vendorId) {
    return await MessageService.listRecentMessagesByUserIdAndVendorId(userId, vendorId).then(
      response => response && response.data
    );
  }

  async insertMessageReadDate(messageId, userId) {
    return await MessageService.insertMessageReadDate(messageId, userId).then(
      response => response && response.data
    );
  }
}

export default new MessageManager();
