import axios from "../axiosInstance";

class PotentialReportService {
  async getPotentialReport(importEventId) {
    return await axios.get(`potential/exportdataxlsx/${importEventId}`, {
      responseType: "blob"
    });
  }
}

export default new PotentialReportService();
