import { numberWithSeparators, getEvolution } from "../../shared/utils/Utils";

export const getCurrentAndNextsYearsTotal = data => {
  if (!data) return;

  let currentYearTotal = 0,
    firstNextYearTotal = 0,
    secondNextYearTotal = 0,
    thirdNextYearTotal = 0;

  data.forEach(d => {
    currentYearTotal = parseFloat((d.currentYearSale + currentYearTotal).toFixed(2));
    firstNextYearTotal = parseFloat((d.firstNextYearSale + firstNextYearTotal).toFixed(2));
    secondNextYearTotal = parseFloat((d.secondNextYearSale + secondNextYearTotal).toFixed(2));
    thirdNextYearTotal = parseFloat((d.thirdNextYearSale + thirdNextYearTotal).toFixed(2));
  });

  return { currentYearTotal, firstNextYearTotal, secondNextYearTotal, thirdNextYearTotal };
};

export const generateBody = (sales, totals, isMobile, nextYearSelect) => {
  if (!sales || !totals) return;

  const getLastColumn = obj => {
    const currentYear = new Date().getFullYear();

    if (nextYearSelect === currentYear + 1) return parseFloat(obj.firstNextYearSale).toFixed(2);
    else if (nextYearSelect === currentYear + 2)
      return parseFloat(obj.secondNextYearSale).toFixed(2);
    else if (nextYearSelect === currentYear + 3)
      return parseFloat(obj.thirdNextYearSale).toFixed(2);
  };

  const lines = sales.map(s => {
    let quota = (
      ((s.currentYearSale + s.firstNextYearSale + s.secondNextYearSale + s.thirdNextYearSale) /
        (totals.currentYearTotal +
          totals.firstNextYearTotal +
          totals.secondNextYearTotal +
          totals.thirdNextYearTotal)) *
      100
    ).toFixed(2);

    let evolution = getEvolution(s.yearSolidSale, s.potentialSelling);

    evolution = !isFinite(evolution) ? "-" : evolution + "%";
    isNaN(quota) ? (quota = "0,00%") : (quota = quota + "%");

    if (!isMobile)
      return [
        s.id,
        s.name,
        evolution,
        quota.replace(".", ","),
        parseFloat(s.currentYearSale).toFixed(2),
        getLastColumn(s)
      ];
    return [
      s.id,
      s.name,
      quota.replace(".", ","),
      parseFloat(s.currentYearSale).toFixed(2),
      getLastColumn(s)
    ];
  });

  return lines;
};

export const generateFooter = (totals, isMobile, nextYearSelect) => {
  if (!totals) return;

  const getLastColumn = obj => {
    const currentYear = new Date().getFullYear();

    if (nextYearSelect === currentYear + 1) return parseFloat(obj.firstNextYearTotal).toFixed(2);
    else if (nextYearSelect === currentYear + 2)
      return parseFloat(obj.secondNextYearTotal).toFixed(2);
    else if (nextYearSelect === currentYear + 3)
      return parseFloat(obj.thirdNextYearTotal).toFixed(2);
  };

  if (!isMobile)
    return ["TOTAL", "", " ", numberWithSeparators(totals.currentYearTotal), getLastColumn(totals)];
  return ["TOTAL", "", numberWithSeparators(totals.currentYearTotal), getLastColumn(totals)];
};

export const generateHeader = (name, isMobile) => {
  if (!isMobile)
    return [
      { name: name, width: 5 },
      { name: "Evolução", alignCenter: true, width: 1, differentBackground: true },
      { name: "Participação", alignRight: true, width: 1 },
      {
        name: new Date().getFullYear().toString(),
        alignRight: true,
        editable: true,
        width: 1.5,
        inputMode: "decimal",
        labelAbove: "Ano atual"
      },
      {
        name: (new Date().getFullYear() + 1).toString(),
        alignRight: true,
        editable: true,
        width: 1.5,
        inputMode: "decimal",
        isSelect: true,
        labelAbove: "Próximos anos"
      }
    ];

  return [
    { name: name, width: 4 },
    { name: "Market Share", alignRight: true, width: 2 },
    {
      name: new Date().getFullYear().toString(),
      alignRight: true,
      editable: true,
      width: 2,
      inputMode: "decimal",
      labelAbove: "Ano atual"
    },
    {
      name: (new Date().getFullYear() + 1).toString(),
      alignRight: true,
      editable: true,
      width: 2,
      inputMode: "decimal",
      isSelect: true,
      labelAbove: "Próximos anos"
    }
  ];
};

export const updateBodyInfo = (data, rowId, colId, newValue, isMobile, nextYear) => {
  if (data[rowId]) {
    let dataCopy = [...data];

    if (!isMobile) colId--;
    if (colId === 0) {
      if (newValue) {
        dataCopy[rowId] = {
          ...dataCopy[rowId],
          name: newValue
        };
      } else {
        dataCopy[rowId] = {
          ...dataCopy[rowId],
          name: "",
          currentYearSale: 0,
          firstNextYearSale: 0,
          secondNextYearSale: 0,
          thirdNextYearSale: 0
        };
      }
    } else if (colId === 3) {
      dataCopy[rowId] = {
        ...dataCopy[rowId],
        currentYearSale: parseFloat(newValue.toFixed(2))
      };
    } else if (colId === 4) {
      const currentYear = new Date().getFullYear();

      if (nextYear === currentYear + 1) {
        dataCopy[rowId] = {
          ...dataCopy[rowId],
          firstNextYearSale: parseFloat(newValue.toFixed(2))
        };
      } else if (nextYear === currentYear + 2) {
        dataCopy[rowId] = {
          ...dataCopy[rowId],
          secondNextYearSale: parseFloat(newValue.toFixed(2))
        };
      } else if (nextYear === currentYear + 3) {
        dataCopy[rowId] = {
          ...dataCopy[rowId],
          thirdNextYearSale: parseFloat(newValue.toFixed(2))
        };
      }
    }
    return dataCopy;
  }
  return null;
};
