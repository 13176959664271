import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Box, makeStyles } from "@material-ui/core";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { connect } from "react-redux";
import * as WindowSizeStoreActions from "../../../store/WindowSizeStore";
import useWindowDimensions from "../../../shared/utils/useWindowDimensions";

const Layout = props => {
  const classes = useStyles();
  const {
    showHeader,
    showSidebar,
    logout,
    children,
    updateWindowWidth
  } = props;

  const [openDrawer, setOpenDrawer] = useState(false);

  const { width } = useWindowDimensions();
  updateWindowWidth(width);

  return (
    <Grid>
      {showHeader && (
        <>
          <Header openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
          <Box className={classes.headerArea} />
        </>
      )}
      {showSidebar && (
        <Sidebar openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} logout={logout} />
      )}
      {children}
    </Grid>
  );
};
const useStyles = makeStyles(theme => ({
  headerArea: theme.mixins.toolbar
}));

const mapDispatchToProps = dispatch => {
  return {
    updateWindowWidth: width => dispatch(WindowSizeStoreActions.updateWindowWidth(width))
  };
};

export default connect(null, mapDispatchToProps)(withRouter(Layout));
