// Types
const UPDATE_WINDOW_WIDTH = "window/UPDATE_WINDOW_WIDTH";

// Reducer
export const INITIAL_STATE = {
  width: null
};

export default function reducer(state = INITIAL_STATE, action) {
  if (action.type === UPDATE_WINDOW_WIDTH) return { width: action.payload };
  return state;
}

// Action creators

export const updateWindowWidth = width => {
  return {
    type: UPDATE_WINDOW_WIDTH,
    payload: width
  };
};
