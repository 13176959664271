import React, { useState, useEffect } from "react";
import { TableCell, TableRow, makeStyles, IconButton } from "@material-ui/core";
import { Edit, Trash2, ChevronDown, ChevronRight, ToggleRight, ToggleLeft } from "react-feather";
import { SECONDARY_COLOR, GRAY, LIGHT_GRAY } from "../../theme";

const ExpandableRow = props => {
  const {
    headers,
    edit,
    remove,
    hover,
    managementIndex,
    collapsibleHeader,
    collapsibleBody,
    disabled,
    expandableEditAction,
    openTabs,
    closeTab,
    setCloseTab
  } = props;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (closeTab === true) {
      setOpen(false);
      setCloseTab(false);
    } else {
      setOpen(openTabs);
    }
  }, [openTabs, closeTab, setCloseTab]);

  const hasTwoActions = edit && remove;
  const classes = useStyles({ hasTwoActions: hasTwoActions });

  const getActiveComponent = active => {
    if (active === true)
      return (
        <div className={classes.alignLine}>
          <ToggleRight className="mr-2 ml-2" color={SECONDARY_COLOR} size="20" />
          <span className={classes.colorActive}> Ativo</span>
        </div>
      );
    return (
      <div className={classes.alignLine}>
        <ToggleLeft size="20" className="mr-2 ml-2" />
        <span> Inativo</span>
      </div>
    );
  };

  return (
    <React.Fragment>
      <TableRow className="pr-2 pl-2" key={managementIndex} hover={hover}>
        {headers &&
          headers.map((header, cellIndex) => (
            <TableCell key={`row-${header}-${cellIndex}`} align={header.alignContent}>
              {cellIndex === 0 ? (
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                  {open ? <ChevronDown /> : <ChevronRight />}
                </IconButton>
              ) : undefined}

              {collapsibleHeader[header.field]}
            </TableCell>
          ))}
        {(edit || remove) && (
          <TableCell className={`${classes.action}  ${classes.paddingHeader}`} key={"action"}>
            {edit && (
              <IconButton className="p-0" disabled>
                <Edit className="mr-2 ml-2" color={LIGHT_GRAY} />
              </IconButton>
            )}
            {remove && (
              <IconButton
                onClick={() => remove(collapsibleHeader["management"], null)}
                className="p-0"
              >
                <Trash2 className="mr-2 ml-2" color={GRAY} />
              </IconButton>
            )}
          </TableCell>
        )}
      </TableRow>
      {open &&
        collapsibleBody &&
        collapsibleBody.map((object, objectIndex) => (
          <TableRow
            key={`colapsiblerow-${object}-${objectIndex}`}
            className={classes.collapsibleBody}
          >
            {headers &&
              headers.map((header, cellIndex) => (
                <TableCell
                  key={`${objectIndex}-colapsiblefield-${header}-${cellIndex}`}
                  align={header.alignContent}
                >
                  {header.field === "active"
                    ? getActiveComponent(object[header.field])
                    : object[header.field]}
                </TableCell>
              ))}

            {(edit || remove) && (
              <TableCell className={`${classes.action} ${classes.paddingHeader}`} key={"action"}>
                {edit && (
                  <IconButton
                    className="p-0"
                    disabled={!expandableEditAction}
                    onClick={() => expandableEditAction && expandableEditAction(object.vendorId)}
                  >
                    <Edit className="mr-2 ml-2" color={!expandableEditAction ? LIGHT_GRAY : GRAY} />
                  </IconButton>
                )}
                {remove && (
                  <IconButton
                    onClick={() => remove(null, object["vendor"])}
                    className="p-0"
                    disabled={disabled}
                  >
                    <Trash2 className="mr-2 ml-2" color={disabled ? LIGHT_GRAY : GRAY} />
                  </IconButton>
                )}
              </TableCell>
            )}
          </TableRow>
        ))}
    </React.Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  action: props => ({
    [theme.breakpoints.down("xs")]: {
      display: "table-cell"
    },
    display: "cell-table",
    textAlign: props.hasTwoActions ? "right" : "center",
    justifyContent: "space-around"
  }),
  paddingHeader: {
    "&.MuiTableCell-sizeSmall:last-child": {
      paddingRight: "32px"
    }
  },
  collapsibleBody: {
    backgroundColor: SECONDARY_COLOR + "0D",
    height: "47px"
  },
  colorActive: {
    color: "#2777b8"
  },
  alignLine: {
    display: "flex"
  }
}));

export default ExpandableRow;
