import { useState } from "react";

const useEditVendorData = () => {
  const [initials, setInitials] = useState("");
  const [description, setDescription] = useState("");
  const [managements, setManagements] = useState([]);
  const [selectedManagement, setSelectedManagement] = useState();
  const [status, setStatus] = useState("inative");

  return {
    initials,
    setInitials,
    description,
    setDescription,
    managements,
    setManagements,
    selectedManagement,
    setSelectedManagement,
    status,
    setStatus
  };
};

export default useEditVendorData;
