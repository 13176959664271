import React from "react";
import {
  Grid,
  Typography,
  makeStyles,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import { GRAY } from "../../../theme";
import AboutAutocomplete from "../../aboutAutoComplete/views/AboutAutocomplete";

const CreateMessageModalStandard = props => {
  const classes = useStyles();

  const {
    selectedReason,
    reasons,
    description,
    setSelectedReason,
    setDescription,
    setSelectedType,
    fetchAboutOptions,
    title,
    aboutPlaceholder
  } = props;

  return (
    <Grid container className="pt-4">
      <Grid item xs={12} className="pb-4">
        <Typography variant="body1" className={classes.colorGray}>
          {title}
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={6} className="pb-4 pr-3">
          <FormControl variant="outlined" className={classes.formControl} required>
            <InputLabel id="message-reason" className={classes.label}>
              Motivo
            </InputLabel>
            <Select
              labelId="message-reason"
              className={classes.field}
              value={selectedReason}
              onChange={e => setSelectedReason(e.target.value)}
              label="Motivo *"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
            >
              {reasons.map((item, index) => (
                <MenuItem key={`${index}`} value={item.id}>
                  {item.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} className="pl-3">
          <AboutAutocomplete
            handleChangeItem={e => setSelectedType(e)}
            fetchOptions={fetchAboutOptions}
            placeholder={aboutPlaceholder}
            shouldClear
            width="100%"
            disabled={false}
            required
          />
        </Grid>
        <Grid item xs={12} className="pb-0">
          <TextField
            className={classes.textField}
            variant="outlined"
            label="Mensagem"
            inputProps={{ maxLength: 255 }}
            value={description}
            onChange={e => setDescription(e.target.value)}
            multiline
            InputLabelProps={{
              className: classes.label
            }}
            rows={8}
            required
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  formControl: {
    width: "100%"
  },
  textField: {
    width: "100%"
  },
  label: {
    color: `rgba(0,0,0,0.54)`
  },
  colorGray: {
    color: GRAY
  }
}));

export default CreateMessageModalStandard;
