import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Checkbox
} from "@material-ui/core";
import Loading from "../Loading";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../../theme";
import CheckedCheckbox from "../../../shared/img/CheckedCheckbox.svg";
import CheckedHeaderIcon from "../../../shared/img/CheckedHeaderIcon.svg";
import NeutralCheckboxIcon from "../../../shared/img/NeutralCheckboxIcon.svg";

const SelectableTable = props => {
  const {
    isLoading,
    headerInfos,
    bodyInfos,
    selectedItens,
    selectRemoveElement,
    selectRemoveAllElements,
    noDataComponent
  } = props;
  const classes = useStyles();

  const showTable = headerInfos && bodyInfos;
  const indexItemsRight =
    headerInfos && headerInfos.map((i, index) => (i.alignRight ? index : -1)).filter(e => e !== -1);
  const indexItemsCenter =
    headerInfos &&
    headerInfos.map((i, index) => (i.alignCenter ? index : -1)).filter(e => e !== -1);
  const getItemAlign = index => {
    if (indexItemsCenter.includes(index)) return "center";
    if (indexItemsRight.includes(index)) return "right";
    return "left";
  };

  return (
    <>
      {isLoading ? (
        <Loading loading={isLoading} />
      ) : (
        <>
          {showTable && (
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: `1%` }}>
                    <Checkbox
                      checked={
                        selectedItens &&
                        bodyInfos &&
                        bodyInfos.length > 0 &&
                        selectedItens.length === bodyInfos.length
                      }
                      indeterminate={
                        selectedItens &&
                        bodyInfos &&
                        selectedItens.length > 0 &&
                        selectedItens.length < bodyInfos.length
                      }
                      onChange={selectRemoveAllElements}
                      inputProps={{ "aria-label": "select all elements" }}
                      className={`p-0 ${classes.headerCheckBox}`}
                      checkedIcon={
                        <img
                          src={CheckedHeaderIcon}
                          alt="Checked icon"
                          className={classes.checkdIcon}
                        />
                      }
                      indeterminateIcon={
                        <img
                          src={NeutralCheckboxIcon}
                          alt="neutral icon"
                          className={classes.checkdIcon}
                        />
                      }
                    />
                  </TableCell>
                  {headerInfos &&
                    headerInfos.map((i, index) => (
                      <TableCell
                        key={i.name + index + "header"}
                        align={getItemAlign(index)}
                        style={i.width ? { width: `${i.width * 10}%` } : ""}
                        className={classes.tableHeader}
                      >
                        {i.name}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              {bodyInfos && bodyInfos.length > 0 && (
                <TableBody>
                  {bodyInfos.map((i, indexrow) => {
                    const checked = !!(selectedItens && selectedItens.find(e => e.id === i.id));
                    return (
                      <TableRow
                        key={indexrow}
                        className="pr-2 pl-2"
                        onClick={() => selectRemoveElement(i)}
                        role="checkbox"
                      >
                        <TableCell>
                          <Checkbox
                            checked={checked}
                            inputProps={{ "aria-label": "select all elements" }}
                            className={"p-0"}
                            checkedIcon={
                              <img
                                src={CheckedCheckbox}
                                alt="Checked icon"
                                className={classes.checkdIcon}
                              />
                            }
                          />
                        </TableCell>
                        {headerInfos.map((obj, index) => (
                          <TableCell
                            key={`${indexrow} ${obj.name} ${index}`}
                            align={getItemAlign(index)}
                            className={checked && classes.checkedColor}
                          >
                            {i[obj.objName]}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          )}
          {!bodyInfos ||
            (bodyInfos && bodyInfos.length === 0 && noDataComponent && noDataComponent())}
        </>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  table: {
    "&.MuiTable-root": {
      borderCollapse: "inherit"
    }
  },
  tableHeader: {
    color: PRIMARY_COLOR
  },
  checkedColor: {
    color: SECONDARY_COLOR
  },
  checkdIcon: {
    width: "22px",
    height: "24px",
    marginLeft: "1px",
    marginRight: "1px"
  },
  headerCheckBox: {
    color: PRIMARY_COLOR
  }
}));

export default SelectableTable;
