import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { Typography, Grid, makeStyles } from "@material-ui/core";
import { UploadCloud } from "react-feather";
import PropTypes from "prop-types";
import { SECONDARY_COLOR, PALE_BLUE_GRAY, GRAY } from "../../../theme";
import Loading from "../../../shared/components/Loading";

const DropzoneFiles = props => {
  const classes = useStyles();

  const {
    maxSizeQuantityFiles,
    maxQuantityFiles,
    descriptionText,
    onUploadSuccess,
    onUploadError,
    acceptedTypes,
    disabled
  } = props;

  const uploadedFiles = [];

  const [fileIsLoading, setFileIsLoading] = useState(false);

  const uploadSuccess = async files => {
    files.forEach(file => {
      if (uploadedFiles.length < maxQuantityFiles) {
        uploadedFiles.push(file);
        setFileIsLoading(true);
      }
    });
    await onUploadSuccess(uploadedFiles);
    setFileIsLoading(false);
  };

  const uploadError = files => {
    if (files.length > maxQuantityFiles) {
      onUploadError("O número de arquivos enviados é maior do que 1.");
    } else if (files[0].file.size > maxSizeQuantityFiles * 1000000) {
      onUploadError(
        "O arquivo importado é maior que o permitido (2MB) verifique e importe novamente."
      );
    } else {
      onUploadError(
        "O arquivo importado não está no formato correto, faça download do template e importe novamente."
      );
    }
  };

  return (
    <>
      <Dropzone
        maxSize={maxSizeQuantityFiles * 1000000}
        accept={acceptedTypes}
        multiple={false}
        onDropRejected={files => uploadError(files)}
        onDropAccepted={files => uploadSuccess(files)}
        disabled={disabled}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            className={`${classes.dropzone} ${disabled && classes.dropZoneDisabled}`}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              className={classes.container}
            >
              <Grid item className="pt-4">
                <UploadCloud color={disabled ? PALE_BLUE_GRAY : SECONDARY_COLOR} />
              </Grid>
              <Grid item>
                <Typography align="center" variant="caption" className={classes.descriptionText}>
                  {descriptionText}
                </Typography>
              </Grid>
              <Grid item className="pt-4 pb-4">
                <Typography
                  align="center"
                  variant="caption"
                  className={`${disabled && classes.importDisabled} bold`}
                >
                  {fileIsLoading ? <Loading loading /> : "IMPORTAR"}
                </Typography>
              </Grid>
            </Grid>
          </div>
        )}
      </Dropzone>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  dropzone: {
    borderStyle: "dashed",
    borderWidth: "1px",
    borderRadius: "10px",
    borderColor: PALE_BLUE_GRAY,
    cursor: "pointer",
    "&:hover": {
      borderStyle: "solid",
      borderColor: SECONDARY_COLOR,
      backgroundColor: `${SECONDARY_COLOR}0D`
    },
    "&:focus": {
      outline: "none"
    }
  },
  descriptionText: {
    color: GRAY
  },
  container: {
    minHeight: theme.spacing(12)
  },
  dropZoneDisabled: {
    cursor: "not-allowed",
    borderColor: PALE_BLUE_GRAY
  },
  importDisabled: {
    color: PALE_BLUE_GRAY
  }
}));

DropzoneFiles.propTypes = {
  /** Define accepted file types. Access the following link to learn how to pass each type -> https://react-dropzone.js.org/#!/Accepting%20specific%20file%20types */
  acceptedTypes: PropTypes.string,
  /** Set the maximum number of files that can be uploaded. */
  maxQuantityFiles: PropTypes.number,
  /** Define the maximum size that a file must have to be accepted. */
  maxSizeQuantityFiles: PropTypes.number,
  /** Function called when there is a problem uploading the file. */
  onUploadError: PropTypes.func,
  /** Function called when the file is successfully sent */
  onUploadSuccess: PropTypes.func,
  /** Function called when the maximum number of files is extrapolated */
  outOfMaxQuantityFiles: PropTypes.func,
  /** Define whether the dropzone will be disabled */
  disabled: PropTypes.bool,
  /** Text to describe how to use the component */
  descriptionText: PropTypes.string
};

DropzoneFiles.defaultProps = {
  maxQuantityFiles: 1,
  acceptedTypes: ".xlsx, .csv",
  outOfMaxQuantityFiles: null,
  maxSizeQuantityFiles: 5,
  onUploadError: null,
  onUploadSuccess: null,
  disabled: false,
  descriptionText: null
};

export default DropzoneFiles;
