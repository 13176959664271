import axios from "axios";
import { configureEnvironment } from "./config/env";
import AxiosWrapper from "./shared/utils/AxiosWrapper";
import { handleRequestError } from "./shared/utils/RequestUtil";
import store from "./store";
import { unauthenticateUser } from "./store/UserStore";

const header = { Pragma: "no-cache" };

const axiosInstance = axios.create({
  headers: header,
  baseURL: configureEnvironment().REACT_APP_API_URL
});

axiosInstance.interceptors.request.use(async config => {
  const cancelRequest = () => {
    store.dispatch(unauthenticateUser());
    sessionStorage.clear();
    return {
      ...config,
      cancelToken: new axios.CancelToken((cancel) => cancel())
    };
  }

  const {
    msalInstance,
    protectedResources
  } = store.getState().authentication;
  
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    return cancelRequest();
  }

  const request = {
    scopes: protectedResources.mainApi.scopes,
    account: activeAccount || accounts[0]
  };

  try {
    const authResult = await msalInstance.acquireTokenSilent(request);
    config.headers.Authorization = `Bearer ${authResult.accessToken}`;
  }
  catch {
    return cancelRequest();
  }

  return config;
});

export default new AxiosWrapper(axiosInstance, { defaultErrorHandler: handleRequestError });
