import { useState } from "react";
import PotentialImportManager from "../PotentialImportManager";

export const useModals = (userId, setReloadImportEvents) => {
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState(null);

  const handleOnUploadError = message => {
    setErrorModalOpen(!errorModalOpen);
    setErrorModalMessage(message);
  };

  const handleOnUploadSuccess = async files => {
    if (files) {
      const result = await PotentialImportManager.importPotentialSheet(files[0], userId);
      if (result != null && !result.requestError) {
        setReloadImportEvents(true);
        result.error === true
          ? handleOnUploadError(
              "A planilha de potencial foi importada porém existem erros. Corrija-os e importe novamente."
            )
          : setSuccessModalOpen(true);
      } else {
        handleOnUploadError(result && result.message);
      }
    }
  };

  const handleOpenCloseSuccessModal = () => setSuccessModalOpen(!successModalOpen);

  return {
    handleOpenCloseSuccessModal,
    handleOnUploadSuccess,
    handleOnUploadError,
    errorModalMessage,
    successModalOpen,
    errorModalOpen
  };
};
