import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as action from "../../../../features/alertMessage/redux/alertMessageActions";
import { Typography, Box, Grid, TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "../../Autocomplete";
import _ from "lodash";
import { Trash2 } from "react-feather";
import { GRAY, MID_BLUE_BACKGROUND } from "../../../../theme";
import { DataType } from "../../../utils/enum";
import TypeAutocompleteManager from "../../../../features/typeAutoComplete/TypeAutocompleteManager";
import Select from "../../Select";
import NewCustomerCompetitorManager from "../NewCustomerCompetitorManager";
import { removeAccent } from "../../../utils/Utils";

let loadOptions;

const asyncLoadOptions = _.debounce((input, callback) => {
  loadOptions(input, callback);
}, 1000);

const CellNewCustomerCompetitor = props => {
  const {
    checkExistingElement,
    handleDeleteItem,
    showNotification,
    nextYearSelect,
    contentAlign,
    isPotential,
    idBodyItem,
    inputTitle,
    updateData,
    isMobile,
    disabled,
    rowId,
    width,
    value,
    type
  } = props;

  const classes = useStyles({ width: width });

  const [selectedItem, setSelectedItem] = useState(null);
  const [searchText, setSearchText] = useState("");

  const [customerTypes, setCustomerTypes] = useState([]);
  const [selectedCustomerType, setSelectedCustomerType] = useState(null);

  const isCustomer = type === DataType["customer"].name;
  const isCompetitor = type === DataType["competitor"].name;

  useEffect(() => {
    if (value) {
      value.name && setSearchText(value.name);
      if (value.name && value.customerType) {
        setSelectedItem(value);
      }
    } else {
      setSearchText("");
      setSelectedItem(null);
    }
  }, [value]);

  useEffect(() => {
    const getData = async () => {
      setCustomerTypes([]);

      const data = await NewCustomerCompetitorManager.listCustomerTypes();

      data && setCustomerTypes(data);
    };
    if (isCustomer) getData();
  }, [isCustomer]);

  useEffect(() => {
    setSelectedCustomerType(selectedItem ? selectedItem.customerType : null);
  }, [selectedItem]);

  loadOptions = async (inputValue, callback) => {
    if (!inputValue) {
      callback([]);
      return;
    }

    let optionsList;

    if (isCompetitor)
      optionsList = await TypeAutocompleteManager.searchCompetitorByTerm(inputValue);
    else if (isCustomer)
      optionsList = await TypeAutocompleteManager.searchCustomerByTerm(inputValue);

    const reactSelectObject =
      optionsList == null
        ? []
        : optionsList.map((item, index) => {
            return {
              ...item,
              value: index,
              label: layoutItemOptionsList(item)
            };
          });

    callback(reactSelectObject);
  };

  const layoutItemOptionsList = item => (
    <Box className={classes.listLine}>
      <Typography>{item.name}</Typography>
    </Box>
  );

  const selectItemOnAutocomplete = item => {
    if (item) {
      const formatedItem = {
        id: item.id,
        name: item.name
      };

      if (item.customerTypeId && item.customerType)
        formatedItem.customerType = {
          id: item.customerTypeId,
          description: item.customerType
        };

      if (!checkExistingElement(formatedItem)) {
        setSelectedItem(formatedItem);
        setSearchText((formatedItem && formatedItem.name) || "");
        updateData(rowId, !isPotential && isMobile ? 0 : 1, formatedItem, nextYearSelect);
      } else {
        showNotification(`${formatedItem && formatedItem.name} já existe na tabela.`);
      }
    } else {
      setSelectedItem(null);
      setSearchText("");
      updateData(rowId, !isPotential && isMobile ? 0 : 1, null, nextYearSelect);
    }
  };

  const handleAutocompleteInputChange = (inputValue, event) => {
    if (event.action === "input-blur" && searchText) {
      const text = removeAccent(searchText.toUpperCase());

      const objectInput = {
        name: text,
        customerType: selectedCustomerType,
        rowId: rowId,
        id: selectedItem && selectedItem.id
      };

      if (!checkExistingElement(objectInput))
        updateData(rowId, !isPotential && isMobile ? 0 : 1, objectInput, nextYearSelect);
      else {
        showNotification(`${objectInput && objectInput.name} já existe na tabela.`);
        setSelectedItem(null);
        setSearchText("");
        updateData(rowId, !isPotential && isMobile ? 0 : 1, null, nextYearSelect);
      }
    }

    if (event.action !== "input-blur" && event.action !== "menu-close") {
      setSearchText(inputValue);

      if (inputValue === "") {
        setSelectedItem(null);
      }
    }
  };

  const handleSelectCustomerType = e => {
    setSelectedCustomerType(e);

    const objectInput = {
      name: searchText,
      customerType: e
    };

    updateData(rowId, !isPotential && isMobile ? 0 : 1, objectInput, nextYearSelect);
  };

  return (
    <TableCell align={contentAlign} aria-label={inputTitle} className={classes.differentBackground}>
      <Grid container key={idBodyItem}>
        <Grid item xs={2} sm={1}>
          <Trash2
            size={isMobile ? 15 : 17}
            color={GRAY}
            onClick={() => {
              updateData(rowId, !isPotential && isMobile ? 0 : 1, null, nextYearSelect);
              handleDeleteItem(idBodyItem);
            }}
            className={`mt-4 mr-1 hover-icon ${!isMobile ? "ml-5" : null}`}
          />
        </Grid>
        <Grid item xs={10} sm={6}>
          <Autocomplete
            id={idBodyItem}
            placeholder={isMobile ? "Digite..." : "Digite um nome"}
            clearable
            onChange={selectItemOnAutocomplete}
            loadOptions={asyncLoadOptions}
            onInputChange={handleAutocompleteInputChange}
            inputValue={searchText}
            value={selectedItem}
            lineInput
            hasLabel={false}
            className={classes.autocomplete}
            disableField={disabled}
            noSearchIcon
          />
        </Grid>
        {isCustomer && isMobile && <Grid item xs={2} />}
        {isCustomer && (
          <Grid item xs={10} sm={5} className={!isMobile ? "pl-2" : null}>
            <Select
              label={!selectedCustomerType ? "Tipo" : null}
              code={selectedCustomerType && selectedCustomerType.id}
              maxHeightMenu="150"
              options={customerTypes}
              handleChange={e => handleSelectCustomerType(e)}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.description}
              returnFullObject
              disabled={Boolean(selectedItem)}
              variant="standard"
              noIndicatorSeparator
            />
          </Grid>
        )}
      </Grid>
    </TableCell>
  );
};

const useStyles = makeStyles({
  listLine: {
    display: "flex",
    justifyContent: "space-between"
  },
  autocomplete: props => ({
    width: props.width || "100%"
  }),
  differentBackground: {
    backgroundColor: MID_BLUE_BACKGROUND
  }
});

const mapDispatchToProps = dispatch => {
  return {
    showNotification: message => dispatch(action.showNotification(message))
  };
};

export default connect(null, mapDispatchToProps)(CellNewCustomerCompetitor);
