import CompetitorService from "../../services/CompetitorService";
import CustomerService from "../../services/CustomerService";

class ApprovePreRegisteredManager {
  async getPreRegisteredCompetitor(preRegisteredId) {
    return await CompetitorService.getPreRegisteredCompetitor(preRegisteredId).then(
      response => response && response.data
    );
  }
  async getPreRegisteredCustomer(preRegisteredId) {
    return await CustomerService.getPreRegisteredCustomer(preRegisteredId).then(
      response => response && response.data
    );
  }
  async completePreRegisteredCompetitor(preRegisteredCompetitorId, userId, correctCompetitorId) {
    return await CompetitorService.completePreRegisteredCompetitor(
      preRegisteredCompetitorId,
      userId,
      correctCompetitorId
    ).then(response => response && response.data);
  }
  async completePreRegisteredCustomer(preRegisteredCustomerId, userId, correctCustomerId) {
    return await CustomerService.completePreRegisteredCustomer(
      preRegisteredCustomerId,
      userId,
      correctCustomerId
    ).then(response => response && response.data);
  }
  async SearchCompetitorByTerm(searchTerm) {
    return await CompetitorService.searchCompetitorByTerm(searchTerm).then(
      response => response && response.data
    );
  }
  async SearchCustomerByTerm(searchTerm) {
    return await CustomerService.searchCustomerByTerm(searchTerm).then(
      response => response && response.data
    );
  }
}

export default new ApprovePreRegisteredManager();
