import React from "react";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  IconButton
} from "@material-ui/core";
import { PALE_BLUE_GRAY, GRAY, PRIMARY_COLOR, SECONDARY_COLOR } from "../../../theme";
import { format, parseISO } from "date-fns";
import { User } from "react-feather";
import { preRegisteredTypes } from "../../../shared/utils/enum";
import { CheckCircle } from "react-feather";
import { Redirect } from "react-router-dom";
import { routes } from "../../../shared/utils/Constants";

const ListTable = props => {
  const { preRegisteredItems, setRedirect } = props;
  const classes = useStyles();

  const handleClickAction = (itemId, type) => {
    const routeType =
      type === preRegisteredTypes.customer.toUpperCase()
        ? preRegisteredTypes.customer
        : preRegisteredTypes.competitor;
    setRedirect(<Redirect to={`${routes.approvePreRegistered}${routeType}/${itemId}`} />);
  };

  return (
    <Grid item xs={12} className={classes.container}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.primaryColor}>Nome</TableCell>
            <TableCell className={classes.primaryColor}>Tipo</TableCell>
            <TableCell className={classes.primaryColor}>Gerências</TableCell>
            <TableCell className={classes.primaryColor}>Consultoria</TableCell>
            <TableCell className={classes.primaryColor}>Estado</TableCell>
            <TableCell className={classes.primaryColor}>Solicitante</TableCell>
            <TableCell className={classes.primaryColor}>Data de solicitação</TableCell>
            <TableCell className={classes.primaryColor}>Ações</TableCell>
          </TableRow>
        </TableHead>
        {preRegisteredItems && preRegisteredItems.length > 0 && (
          <TableBody>
            {preRegisteredItems.map((e, index) => (
              <TableRow key={index}>
                <TableCell>{e.itemName}</TableCell>
                <TableCell className={classes.secondaryColor}>
                  {e.typeName === preRegisteredTypes.customer.toUpperCase()
                    ? "Cliente"
                    : "Concorrente"}
                </TableCell>
                <TableCell>{e.managementInitials}</TableCell>
                <TableCell>{e.vendorInitials}</TableCell>
                <TableCell>{e.stateInitials}</TableCell>
                <TableCell>{e.requestingUserName}</TableCell>
                <TableCell>{format(parseISO(e.requestDate), "dd/MM/yyyy")}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleClickAction(e.id, e.typeName)} className="p-0">
                    <CheckCircle className="mr-2 ml-2" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {(!preRegisteredItems || (preRegisteredItems && preRegisteredItems.length === 0)) && (
        <Grid item xs={12}>
          <User size={40} color={PRIMARY_COLOR} className={`mt-8 ${classes.icon}`} />
          <Typography className={`h7`} align="center">
            Não existem novas solicitações.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    borderColor: PALE_BLUE_GRAY,
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "8px",
    borderCollapse: "inherit"
  },
  textColor: {
    color: GRAY
  },
  primaryColor: {
    color: PRIMARY_COLOR
  },
  secondaryColor: {
    color: SECONDARY_COLOR
  },
  icon: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto"
  }
}));

export default ListTable;
