import React, { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { Mail, Plus } from "react-feather";
import PageTitle from "../../../shared/components/PageTitle";
import { SECONDARY_COLOR } from "../../../theme";
import UnreadCounter from "../../../shared/components/UnreadCounter";
import FloatingButton from "../../../shared/components/FloatingButton";
import ListMessages from "./ListMessages";
import { screenSizes, profiles } from "../../../shared/utils/enum";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { routes } from "../../../shared/utils/Constants";

const Messages = props => {
  const {
    authenticatedUserId,
    windowWidth,
    selectedVendorId,
    userProfiles
  } = props;

  const isConsultant =
    userProfiles &&
    userProfiles.find(
      p =>
        p.initials === profiles.commercialConsultant.initials ||
        p.initials === profiles.hortiFrutiConsultant.initials
    );

  const classes = UseStyles();
  const [countUnreadMessages, setCountUnreadMessages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [shouldReloadData, setShouldReloadData] = useState(false);
  const [redirect, setRedirect] = useState(null);

  const isMobile = windowWidth < screenSizes.webScreenSize;

  return (
    <Grid container className={!isMobile ? "pl-8 pt-8 pr-8 pb-4" : "pl-4 pt-8 pr-4 pb-4"}>
      <Grid item xs={8} className="mb-2">
        <PageTitle
          title="Mensagens"
          icon={
            <Mail
              color={SECONDARY_COLOR}
              size={!isMobile ? 36 : 32}
              className={`${classes.iconMail} pr-1`}
            />
          }
          isMobile={isMobile}
        />
      </Grid>
      <Grid item xs={4} className={`pb-3 ${classes.containerCounter}`}>
        <UnreadCounter
          isLoading={loading}
          reloadFunction={() => {
            setShouldReloadData(true);
          }}
          unreadCount={countUnreadMessages}
          isNotification={false}
          isMobile={isMobile}
        />
      </Grid>

      <Grid item xs={12}>
        <ListMessages
          authenticatedUserId={authenticatedUserId}
          selectedVendorId={selectedVendorId}
          countUnreadMessages={countUnreadMessages}
          setCountUnreadMessages={setCountUnreadMessages}
          loading={loading}
          setLoading={setLoading}
          shouldReloadData={shouldReloadData}
          setShouldReloadData={setShouldReloadData}
          isMobile={isMobile}
          isConsultant={isConsultant}
        />
      </Grid>

      {isConsultant && 
        <FloatingButton
          handleClick={() => setRedirect(<Redirect to={routes.newDirectMessageConsultant} />)}
          icon={<Plus />}
          ariaLabel="addNewMessage"
          isWeb={!isMobile}
        />
      }

      {redirect}
    </Grid>
  );
};

const UseStyles = makeStyles(theme => ({
  iconMail: {
    verticalAlign: "bottom"
  },
  containerCounter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  }
}));

const mapStateToProps = state => {
  return {
    authenticatedUserId: state.user.id,
    windowWidth: state.window.width,
    selectedVendorId: state.selectedVendor.id,
    userProfiles: state.user.profiles
  };
};

export default connect(mapStateToProps, null)(Messages);
