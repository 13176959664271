import { useState, useEffect } from "react";
import SolidMarketManager from "../SolidMarketManager";
import { getCurrentAndNextsYearsTotal, updateBodyInfo } from "../TableDataHelper";
import { DataType } from "../../../shared/utils/enum";
import { startOfWeek, endOfWeek, format } from "date-fns";

export const useCompetitorSales = (
  selectedDate,
  selectedStateVendor,
  updateTotalVolume,
  isMobile,
  reloadScreen
) => {
  const [competitorSales, setCompetitorSales] = useState();
  const [competitorTotals, setCompetitorTotals] = useState();
  const [loadingCompetitorTable, setloadingCompetitorTable] = useState(false);

  useEffect(() => {
    if (competitorTotals) {
      updateTotalVolume(
        DataType["competitor"].id,
        competitorTotals.currentYearTotal,
        competitorTotals.firstNextYearTotal,
        competitorTotals.secondNextYearTotal,
        competitorTotals.thirdNextYearTotal
      );
    }
  }, [competitorTotals, updateTotalVolume]);

  useEffect(() => {
    const getData = async () => {
      setloadingCompetitorTable(true);
      setCompetitorSales();
      setCompetitorTotals({
        currentYearTotal: 0,
        firstNextYearTotal: 0,
        secondNextYearTotal: 0,
        thirdNextYearTotal: 0
      });

      const startDate = format(startOfWeek(selectedDate), "yyyy-MM-dd");
      const endDate = format(endOfWeek(selectedDate), "yyyy-MM-dd");

      const data = await SolidMarketManager.listCompetitorSellings(
        selectedStateVendor,
        startDate,
        endDate
      );

      if (data) {
        setCompetitorSales(data);
        updateTotals(data);
      }
      setloadingCompetitorTable(false);
    };
    if (selectedStateVendor) getData();
  }, [selectedDate, selectedStateVendor, reloadScreen]);

  const updateTotals = data => {
    const totals = getCurrentAndNextsYearsTotal(data);
    setCompetitorTotals(totals);
  };

  const updateCompetitorData = (rowId, columnId, newValue, nextYear) => {
    const newData = updateBodyInfo(competitorSales, rowId, columnId, newValue, isMobile, nextYear);

    if (newData) {
      setCompetitorSales(newData);
      updateTotals(newData);
    }
  };

  return {
    competitorSales,
    setCompetitorSales,
    competitorTotals,
    loadingCompetitorTable,
    updateCompetitorData
  };
};
