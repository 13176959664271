import SellingService from "../../services/SellingService";

class EntriesManagementManager {
  async getPotentialReport(date) {
    await SellingService.getSolidMarketEntriesReport(date).then(response => {
      if (!response || !response.data) {
        return;
      }

      const xlsxSolidMarketEntriesFile = new Blob([response.data], {
        type: response.headers["content-type"]
      });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(
          xlsxSolidMarketEntriesFile,
          "Exportação de mercado solido"
        );
      } else {
        const URLfile = URL.createObjectURL(xlsxSolidMarketEntriesFile);
        const link = document.createElement("a");
        link.setAttribute("download", "AderênciaAcompMercado.xlsx");
        link.href = URLfile;
        link.click();
      }
    });
  }
}

export default new EntriesManagementManager();
