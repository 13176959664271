import { useState } from "react";
import CompetitorCustomerCropManager from "../CompetitorCustomerCropManager";

const useUpdateCustomer = () => {
  const [loadingData, setLoadingData] = useState(false);

  const handleUpdateCustomer = async (customer, customerId) => {
    setLoadingData(true);

    const response = await CompetitorCustomerCropManager.updateCustomer(customer, customerId);

    setLoadingData(false);

    return response;
  };

  return {
    handleUpdateCustomer,
    loadingData
  };
};

export default useUpdateCustomer;
