import React, { useEffect, useState } from "react";
import { Grid, MobileStepper, makeStyles, Divider, Typography, Button } from "@material-ui/core";
import { SECONDARY_COLOR, PALE_BLUE_GRAY } from "../../../theme";
import PotentialTypeTabs from "./PotentialTypeTabs";
import TableCustomized from "../../../shared/components/TableCustomized";
import { generateBody, generateFooter, generateHeader } from "../TableDataHelper";
import { DataType } from "../../../shared/utils/enum";
import Loading from "../../../shared/components/Loading";
import PotentialManager from "../PotentialManager";
import { FormatDataToUpdate } from "../FormatDataToUpdate";
import { generateId } from "../../../shared/utils/Utils";
import FloatingButton from "../../../shared/components/FloatingButton";
import { Save } from "react-feather";
import AddNewCustomerCompetitorCard from "../../../shared/components/NewCustomerCompetitor/views/AddNewCustomerCompetitorCard";
import _ from "lodash";

const EntriesContainer = props => {
  const {
    handleSuccessModalOpenClose,
    handleErrorModalOpenClose,
    competitorYaraTotalVolume,
    customerYaraTotalVolume,
    loadingCompetitorTable,
    competitorTotalVolume,
    loadingCustomerTable,
    updateCompetitorData,
    competitorPotentials,
    cropYaraTotalVolume,
    customerTotalVolume,
    setSelectedTypeTabs,
    updateCustomerData,
    customerPotentials,
    setCompetitorSales,
    setEntryIsLoading,
    loadingCropTable,
    setCustomerSales,
    updateCropsData,
    cropTotalVolume,
    selectedTypeTab,
    competitorTotal,
    handleChangeTab,
    cropPotentials,
    entryIsLoading,
    selectedVendor,
    isEndOfScreen,
    customerTotal,
    stateVendorId,
    cropTotals,
    isMobile,
    userId
  } = props;

  const [disabledSendButton, setDisabledSendButton] = useState(true);

  const classes = useStyles();

  const generateCompetitorTable = () => {
    return (
      <TableCustomized
        headerInfos={generateHeader(DataType["competitor"].name, true, true, isMobile)}
        bodyInfos={generateBody(
          competitorPotentials,
          competitorTotal,
          isMobile,
          DataType["competitor"].name
        )}
        showFooter
        footerInfos={generateFooter(competitorTotal, isMobile, DataType["competitor"].name)}
        footerRadius
        isLoading={loadingCompetitorTable}
        updateData={updateCompetitorData}
        typeData={DataType.competitor.name}
        handleDeleteNewItem={handleDeleteNewCompetitor}
        isMobile={isMobile}
        isPotential
      />
    );
  };

  const generateCropTable = () => {
    return (
      <TableCustomized
        headerInfos={generateHeader(DataType["crop"].name, false, true, isMobile)}
        bodyInfos={generateBody(cropPotentials, cropTotals, isMobile, DataType["crop"].name)}
        showFooter
        footerInfos={generateFooter(cropTotals, isMobile, DataType["crop"].name)}
        footerRadius
        isLoading={loadingCropTable}
        updateData={updateCropsData}
        typeData={DataType.crop.name}
        isPotential
        isMobile={isMobile}
      />
    );
  };

  const generateCustomerTable = () => {
    return (
      <TableCustomized
        headerInfos={generateHeader(DataType["customer"].name, true, true, isMobile)}
        bodyInfos={generateBody(
          customerPotentials,
          customerTotal,
          isMobile,
          DataType["customer"].name
        )}
        showFooter
        footerInfos={generateFooter(customerTotal, isMobile, DataType["customer"].name)}
        footerRadius
        isLoading={loadingCustomerTable}
        updateData={updateCustomerData}
        typeData={DataType.customer.name}
        handleDeleteNewItem={handleDeleteNewCustomer}
        isMobile={isMobile}
        isPotential
      />
    );
  };

  const handleActiveTable = () => {
    switch (selectedTypeTab) {
      case DataType["competitor"].id:
        return generateCompetitorTable();
      case DataType["customer"].id:
        return generateCustomerTable();
      case DataType["crop"].id:
        return generateCropTable();
      default:
        return generateCompetitorTable();
    }
  };

  const finishEntryPotential = async () => {
    setEntryIsLoading(true);

    const result = await PotentialManager.updatePotentials(
      FormatDataToUpdate(
        competitorPotentials,
        customerPotentials,
        cropPotentials,
        stateVendorId,
        userId,
        selectedVendor
      )
    );

    if (result) handleSuccessModalOpenClose();
    else setEntryIsLoading(false);
  };

  const onClickFinishButton = () => {
    if (
      competitorTotalVolume !== customerTotalVolume ||
      competitorTotalVolume !== cropTotalVolume ||
      cropTotalVolume !== customerTotalVolume
    )
      handleErrorModalOpenClose("Os totais de concorrentes, clientes e cultura devem ser iguais.");
    else if (
      competitorYaraTotalVolume !== customerYaraTotalVolume ||
      competitorYaraTotalVolume !== cropYaraTotalVolume
    ) {
      handleErrorModalOpenClose(
        "Os totais de Volume Yara para cultura, clientes e concorrentes 'YARA' devem ser iguais para a consultoria/UF"
      );
    } else finishEntryPotential();
  };

  const handleAddNewCompetitor = () => {
    setSelectedTypeTabs(DataType["competitor"].id);

    const newCompetitor = {
      id: `new-${generateId()}`,
      name: "",
      currentYearSale: 0,
      solidSale: 0
    };

    let cloneCompetitors = [...competitorPotentials];
    cloneCompetitors.unshift(newCompetitor);

    setCompetitorSales(cloneCompetitors);
  };

  const handleAddNewCustomer = () => {
    setSelectedTypeTabs(DataType["customer"].id);

    const newCustomer = {
      id: `new-${generateId()}`,
      name: "",
      currentYearSale: 0,
      solidSale: 0,
      yaraVolume: 0
    };

    let cloneCustomers = [...customerPotentials];
    cloneCustomers.unshift(newCustomer);

    setCustomerSales(cloneCustomers);
  };

  const handleDeleteNewCustomer = idBodyItem => {
    let cloneCustomers = [...customerPotentials];
    cloneCustomers = cloneCustomers.filter(item => item.id !== idBodyItem);

    setCustomerSales(cloneCustomers);
  };

  const handleDeleteNewCompetitor = idBodyItem => {
    let cloneCompetitors = [...competitorPotentials];
    cloneCompetitors = cloneCompetitors.filter(item => item.id !== idBodyItem);

    setCompetitorSales(cloneCompetitors);
  };

  useEffect(() => {
    const checkNewEmptyLines = () => {
      let newEmptyLines = false;

      customerPotentials.forEach(cp => {
        if (
          (_.isString(cp.name) && cp.name.length === 0) ||
          (_.isObject(cp.name) && (!cp.name.name || !cp.name.customerType))
        )
          newEmptyLines = true;
      });

      competitorPotentials.forEach(cp => {
        if ((_.isString(cp.name) && cp.name.length === 0) || (_.isObject(cp.name) && !cp.name.name))
          newEmptyLines = true;
      });

      return newEmptyLines;
    };

    setDisabledSendButton(checkNewEmptyLines());
  }, [customerPotentials, competitorPotentials]);

  return (
    <Grid container item xs={12}>
      <Grid item xs={11} sm={8} className="pb-3">
        <Typography
          variant={!isMobile ? "h5" : "h6"}
          align={!isMobile ? "left" : "center"}
          className={classes.titleColor}
        >
          Lançamentos
        </Typography>
      </Grid>
      <Grid item xs={1} sm={4} className="pb-3">
        <AddNewCustomerCompetitorCard
          className="float-right"
          handleAddNewCustomer={handleAddNewCustomer}
          handleAddNewCompetitor={handleAddNewCompetitor}
        />
      </Grid>
      <Grid container item xs={12} className={classes.contentAligment}>
        <Grid item xs={12}>
          <PotentialTypeTabs
            handleChangeTab={handleChangeTab}
            customerTotalVolume={customerTotalVolume}
            competitorTotalVolume={competitorTotalVolume}
            cropTotalVolume={cropTotalVolume}
            selectedTypeTab={selectedTypeTab}
            isMobile={isMobile}
            customerYaraTotalVolume={customerYaraTotalVolume}
            cropYaraTotalVolume={cropYaraTotalVolume}
            competitorYaraTotalVolume={competitorYaraTotalVolume}
          />
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          {handleActiveTable()}
        </Grid>
      </Grid>

      <Grid item xs={12} className="pb-4">
        <MobileStepper
          variant="dots"
          steps={3}
          position="static"
          activeStep={selectedTypeTab}
          className={classes.stepper}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          className={!isMobile ? classes.buttonWebProperties : classes.button}
          onClick={() => onClickFinishButton()}
          disabled={entryIsLoading || disabledSendButton}
        >
          {entryIsLoading ? (
            <Loading size={20} loading={entryIsLoading} className={classes.loadingPosition} />
          ) : (
            "SALVAR"
          )}
        </Button>
      </Grid>
      {!isEndOfScreen && (
        <FloatingButton
          isWeb={!isMobile}
          handleClick={() => onClickFinishButton()}
          icon={<Save size={20} />}
          text={!isMobile && "SALVAR"}
          ariaLabel="sendVolume"
          loading={entryIsLoading}
          disabled={disabledSendButton}
        />
      )}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  stepper: {
    "& .MuiMobileStepper-dots": {
      margin: "auto",
      display: "flex"
    },
    "& .MuiMobileStepper-dotActive": {
      backgroundColor: SECONDARY_COLOR
    }
  },
  titleColor: {
    color: SECONDARY_COLOR
  },
  contentAligment: {
    margin: "auto",
    display: "flex",
    width: "100%",
    border: `1px solid ${PALE_BLUE_GRAY}`,
    borderRadius: theme.spacing(1)
  },
  divider: {
    height: theme.spacing(0.25),
    backgroundColor: PALE_BLUE_GRAY
  },
  button: {
    width: "180px",
    height: "56px",
    margin: "auto",
    display: "flex"
  },
  buttonWebProperties: {
    width: "180px",
    height: "56px",
    margin: "auto",
    display: "flex",
    marginRight: "0px"
  },
  loadingPosition: {
    margin: theme.spacing(2),
    position: "relative",
    color: SECONDARY_COLOR
  }
}));

export default EntriesContainer;
