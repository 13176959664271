import PreRegisteredService from "../../services/PreRegisteredService";

class ListPreRegisteredsManager {
  async ListPreRegisteredItems() {
    return await PreRegisteredService.listPreRegisteredItems().then(
      response => response && response.data
    );
  }
}

export default new ListPreRegisteredsManager();
