import {
  Button,
  makeStyles,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField
} from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { DataType } from "../../../shared/utils/enum";
import { removeAccent } from "../../../shared/utils/Utils";
import { LIGHT_GRAY, MID_BLUE } from "../../../theme";
import useCreateCompetitor from "../customHooks/useCreateCompetitor";
import useCreateCrop from "../customHooks/useCreateCrop";
import useCreateCustomer from "../customHooks/useCreateCustomer";
import useListCropType from "../customHooks/useListCropType";
import useListCustomerType from "../customHooks/useListCustomerType";

const AddLine = props => {
  const classes = useStyles();

  const {
    selectedTabHasType,
    selectedTypeTab,
    setSearchNameParameter,
    authenticatedUserId,
    setPage,
    loadData,
    showSuccessNotification
  } = props;

  const [beforeCall, setBeforeCall] = useState(null);
  const [listType, setListType] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [selectedType, setSelectedType] = useState(0);

  const { listCustomerTypes, handleListCustomerTypes } = useListCustomerType();
  const { listCropType, handleListCropType } = useListCropType();
  const { handleCreateCompetitor } = useCreateCompetitor();
  const { handleCreateCustomer } = useCreateCustomer();
  const { handleCreateCrop } = useCreateCrop();

  const handleAddItem = async () => {
    const entity = {
      Name: searchName,
      LastChangeUserId: authenticatedUserId
    };

    let createdId;

    if (selectedTypeTab === DataType["competitor"].id)
      createdId = await handleCreateCompetitor(entity);

    if (selectedTypeTab === DataType["customer"].id) {
      entity.CustomerTypeId = selectedType;
      createdId = await handleCreateCustomer(entity);
    }

    if (selectedTypeTab === DataType["crop"].id) {
      entity.CropTypeId = selectedType;
      createdId = await handleCreateCrop(entity);
    }

    if (createdId) {
      showSuccessNotification("Registro adicionado com sucesso.");
      loadData();
    }
  };

  const refSetSearchNameParameter = useRef(setSearchNameParameter);
  const refHandleListCustomerType = useRef(handleListCustomerTypes);
  const refHandleListCropType = useRef(handleListCropType);
  const refBeforeCall = useRef(beforeCall);
  const refSetPage = useRef(setPage);
  useEffect(() => {
    refSetSearchNameParameter.current = setSearchNameParameter;
    refHandleListCustomerType.current = handleListCustomerTypes;
    refHandleListCropType.current = handleListCropType;
    refBeforeCall.current = beforeCall;
    refSetPage.current = setPage;
  });

  useEffect(() => {
    setSearchName("");
    refSetSearchNameParameter.current("");
    setSelectedType(0);

    async function loadDataTypes() {
      if (selectedTypeTab === DataType["customer"].id) await refHandleListCustomerType.current();
      if (selectedTypeTab === DataType["crop"].id) await refHandleListCropType.current();
    }

    loadDataTypes();
  }, [selectedTypeTab]);

  useEffect(() => {
    if (selectedTypeTab === DataType["customer"].id) setListType(listCustomerTypes);
    if (selectedTypeTab === DataType["crop"].id) setListType(listCropType);
  }, [listCustomerTypes, listCropType, selectedTypeTab]);

  useEffect(() => {
    if (refBeforeCall.current) clearTimeout(refBeforeCall.current);

    const idTimeOut = window.setTimeout(() => {
      refSetPage.current(0);
      refSetSearchNameParameter.current(searchName);
    }, 1000);

    if (!searchName) setSelectedType(0);
    setBeforeCall(idTimeOut);
  }, [searchName]);

  return (
    <TableRow>
      <TableCell
        className={`${classes.lightGrayColor} ${
          selectedTabHasType ? classes.hasTypeTableCell : classes.hasntTypeTableCell
        }`}
      >
        <label className={`${classes.label} pr-4`}>Nome</label>
        <TextField
          className={classes.field}
          value={searchName}
          placeholder="Digite para pesquisar ou adicionar"
          onChange={e => setSearchName(removeAccent(e.target.value.toUpperCase()))}
        />
      </TableCell>

      {selectedTabHasType && (
        <TableCell style={{ height: "100%", width: `14%` }} className={classes.lightGrayColor}>
          <label className={`${classes.label} pr-4`}>Tipo</label>
          <Select
            disabled={!searchName}
            className={classes.field}
            value={selectedType}
            onChange={e => setSelectedType(e.target.value)}
          >
            {listType.map((type, index) => (
              <MenuItem key={`type ${index}`} value={type.id}>
                {type.description}
              </MenuItem>
            ))}
          </Select>
        </TableCell>
      )}

      <TableCell style={{ width: `23%` }} className={classes.lightGrayColor}></TableCell>

      <TableCell style={{ width: `15%` }} className={classes.lightGrayColor}></TableCell>

      <TableCell style={{ width: `12%` }} className={classes.lightGrayColor}></TableCell>

      <TableCell style={{ width: `12%` }} align="right">
        <Button
          disabled={!searchName || (selectedTabHasType && !selectedType)}
          variant="outlined"
          color="secondary"
          onClick={() => handleAddItem()}
          style={{ height: "36px" }}
        >
          ADICIONAR
        </Button>
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles(theme => ({
  lightGrayColor: {
    color: LIGHT_GRAY
  },
  field: {
    width: "calc(100% - 62px)"
  },
  label: {
    color: MID_BLUE,
    textTransform: "uppercase",
    float: "left",
    height: "100%",
    display: "flex",
    alignItems: "center"
  },
  hasTypeTableCell: { height: "100%", width: `24%` },
  hasntTypeTableCell: { height: "100%", width: `38%` }
}));

export default AddLine;
