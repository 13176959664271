import React from "react";
import { makeStyles, Typography, Stepper, Step, StepLabel, Grid } from "@material-ui/core";
import { WHITE, SECONDARY_COLOR, GRAY } from "../../../theme";

const CreateMessageModalHeader = props => {
  const { activeStep } = props;

  const classes = useStyles();

  return (
    <Grid container className="pt-1">
      <Grid item xs={4}>
        <Typography variant="h5">Nova Mensagem</Typography>
      </Grid>
      <Grid item xs={5} className={classes.containerStepper}>
        <Stepper activeStep={activeStep} className={`pt-1 ${classes.stepper}`}>
          <Step completed={false}>
            <StepLabel
              StepIconProps={{
                classes: {
                  root: activeStep > 0 ? classes.active : classes.defaultStep,
                  text: classes.textCompleted,
                  active: classes.active
                }
              }}
              classes={{
                label: classes.stepLabelCompleted
              }}
            >
              Tipo
            </StepLabel>
          </Step>
          <Step completed={false}>
            <StepLabel
              StepIconProps={{
                classes: {
                  root: classes.defaultStep,
                  text: activeStep > 0 ? classes.textCompleted : classes.stepIconText,
                  active: classes.active
                }
              }}
              classes={{
                label: activeStep > 0 ? classes.stepLabelCompleted : classes.stepLabel
              }}
            >
              Mensagem
            </StepLabel>
          </Step>
        </Stepper>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  stepper: {
    padding: "0px",
    paddingRight: "80px"
  },
  defaultStep: {
    color: WHITE,
    border: `1px solid ${GRAY}`,
    borderRadius: "60px",
    "&.MuiStepIcon-active": {
      color: WHITE
    }
  },
  active: {
    color: WHITE,
    border: `1px solid ${SECONDARY_COLOR}`,
    borderRadius: "60px"
  },
  textCompleted: {
    fill: SECONDARY_COLOR
  },
  stepIconText: {
    fill: GRAY
  },
  stepLabelCompleted: {
    color: SECONDARY_COLOR,
    "&.MuiStepLabel-active": {
      color: SECONDARY_COLOR
    }
  },
  stepLabel: {
    color: GRAY
  },
  containerStepper: {
    marginLeft: "-40px"
  }
}));

export default CreateMessageModalHeader;
