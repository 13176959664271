import axios from "../axiosInstance";

class StateVendorCompetitor {
  async listCompetitorSellings(stateVendorId, startDate, endDate) {
    return await axios.get(`competitor/competitorsellings/${stateVendorId}`, {
      params: { startDate, endDate }
    });
  }

  async listCompetitorPotentials(stateVendorId, year) {
    return await axios.get(`competitor/competitorPotential/${stateVendorId}`, {
      params: { year }
    });
  }

  async listCompetitorNextYearOrderbook(stateVendorId, year) {
    return await axios.get(`competitor/competitorNextYearOrderbook/${stateVendorId}`, {
      params: { year }
    });
  }

  async listCompetitorsByStateVendor(stateVendorId) {
    return await axios.get(`competitor/${stateVendorId}`);
  }

  async verifyCompetitorHasVolume(stateVendorCompetitorId) {
    return await axios.get(`competitor/verifyhasvolume/${stateVendorCompetitorId}`);
  }
}

export default new StateVendorCompetitor();
