import React, { useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { GRAY } from "../../../theme";
import Modal from "../../../shared/components/Modal";
import DeniedAccessIcon from "../../../shared/img/DeniedAccessIcon.svg";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { routes } from "../../../shared/utils/Constants";

const ExpiredSection = props => {
  const { isAuthenticated } = props;

  const [openedModal, setOpenedModal] = useState(false);
  const [redirect, setRedirect] = useState();

  useEffect(() => {
    !isAuthenticated && setOpenedModal(true);
  }, [isAuthenticated]);

  const classes = useStyles();

  const redirectToLogin = () => {
    setRedirect(<Redirect to={`${routes.logIn}`} />);
    setOpenedModal(false);
  }

  return (
    <>
      <Modal
        primaryButton={{
          action: () => redirectToLogin(),
          text: "FECHAR"
        }}
        openedModal={openedModal}
        title="Sessão expirada!"
        titleIcon={<img src={DeniedAccessIcon} alt="Denied Accesss" aria-hidden="true" />}
      >
        <Typography align="center" className={classes.colorSubtitle}>
          Sua sessão expirou!
        </Typography>
      </Modal>
      {redirect}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  colorSubtitle: {
    color: GRAY
  }
}));

const mapStateToProps = state => {
  return {
    isAuthenticated: state.user.isAuthenticated
  };
};

export default connect(mapStateToProps, null)(ExpiredSection);
