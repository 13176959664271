import { PublicClientApplication, BrowserAuthError } from "@azure/msal-browser";
import { msalConfigB2B, msalConfigB2C } from "../../authProvider";
import store from "../../store";
import { alertMessageActions } from "../../features/alertMessage/redux";
import { authenticationErrorMessage } from "./RequestUtil";

const msalInstanceB2B = new PublicClientApplication(msalConfigB2B);
const msalInstanceB2C = new PublicClientApplication(msalConfigB2C);

export const msalInstances = {
  B2B: msalInstanceB2B,
  B2C: msalInstanceB2C
};

export const handlePopupError = (error) => {
  sessionStorage.clear();
  
  if (error.errorCode !== BrowserAuthError.createUserCancelledError().errorCode) {
    store.dispatch(alertMessageActions.showNotification(authenticationErrorMessage));
  }
}