import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactSelect, { components } from "react-select";
import { FormControl, InputLabel } from "@material-ui/core";
import PropTypes from "prop-types";
import { PRIMARY_COLOR, SECONDARY_COLOR, FONT_FAMILY, GRAY, WHITE } from "../../theme";

const MultiValue = props => {
  const label = props.selectProps.getOptionLabel(props.data);
  return (
    <span style={props.style}>
      {props.index > 0 ? ", " + label : label}
    </span>
  );
};

const ValueContainer = ({ children, ...props }) => {
  let firstChild = children && children.length && children[0];
  
  if (Array.isArray(firstChild) && props.selectProps.isMulti) {
    firstChild = (
      <div style={{ position: "absolute", top: "6px" }}>
        {firstChild}
      </div>
    )
  }

  return (
    <components.ValueContainer {...props}>
      {firstChild}
      {children.filter((_, i) => i > 0)}
    </components.ValueContainer>
  );
}

const Placeholder = required => props => {
  return (
    <InputLabel required={required} style={{ top: "-6px", left: "-4px" }}>
      {props.children}
    </InputLabel>
  );
};

const Select = React.forwardRef((props, ref) => {
  const {
    noIndicatorSeparator,
    menuPortalTarget,
    customProperties,
    returnFullObject,
    noOptionsMessage,
    openMenuOnFocus,
    getOptionValue,
    getOptionLabel,
    maxHeightMenu,
    filterOption,
    isSearchable,
    handleChange,
    isClearable,
    autoFocus,
    disabled,
    isMobile,
    required,
    multiple,
    options,
    variant,
    label,
    code,
    id
  } = props;

  const classes = useStyles(props);

  const [formattedOptions, setFormattedOptions] = useState([]);

  useEffect(() => {
    if (options.length && typeof(options[0]) === "string") {
      setFormattedOptions(options.map(option => ({ value: option, label: option})));
    }
    else {
      setFormattedOptions(options);
    }
  }, [options]);

  const getSelectValues = () => {
    let values = [];

    if (code === null || code === undefined) return null;

    if (multiple) {
      code.forEach(item => {
        values.push(formattedOptions.filter(option => getOptionValue(option) === item)[0]);
      });
      return values;
    }

    return formattedOptions.find(o => getOptionValue(o) === code);
  };

  const value = getSelectValues();

  const handleSelectOption = option => {
    if (multiple) {
      handleChange(returnFullObject ? option : option.map(o => getOptionValue(o)));
    } else {
      handleChange(returnFullObject ? option : option && getOptionValue(option));
    }
  };

  const hasSelectedValue = multiple ? value && value.length > 0 : value;

  return (
    <FormControl id="form" variant="outlined" className={classes.input}>
      {hasSelectedValue && (
        <InputLabel
          style={{
            color: disabled ? "rgba(0, 0, 0, 0.38)" : PRIMARY_COLOR,
            backgroundColor: variant === "standard" ? "unset" : WHITE,
            backgroundImage: "none"
          }}
          className={classes.floatLabel}
          htmlFor="outlined-age-native-simple"
          required={required}
        >
          {label}
        </InputLabel>
      )}
      <ReactSelect
        {...customProperties}
        components={{
          ValueContainer,
          MultiValue,
          Placeholder: Placeholder(required),
          IndicatorSeparator: noIndicatorSeparator && null
        }}
        noOptionsMessage={() => noOptionsMessage}
        styles={customStyles(maxHeightMenu, variant, isMobile, multiple)}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        onChange={handleSelectOption}
        placeholder={label}
        filterOption={filterOption}
        isDisabled={disabled}
        isClearable={isClearable}
        className={classes.input}
        autoFocus={autoFocus}
        options={formattedOptions}
        openMenuOnFocus={openMenuOnFocus}
        tabSelectsValue
        value={value}
        id={id}
        isMulti={multiple}
        isSearchable={isSearchable}
        closeMenuOnSelect={!multiple}
        hideSelectedOptions={false}
        menuPortalTarget={menuPortalTarget || document.body}
        ref={ref}
      />
    </FormControl>
  );
});

const customStyles = (maxHeight, variant, isMobile, multiple) => ({
  menuList: styles => ({
    ...styles,
    maxHeight: maxHeight ? maxHeight + "px" : "500px",
    fontFamily: FONT_FAMILY,
    color: PRIMARY_COLOR,
    fontSize: "0.75rem",
    fontWeight: "500"
  }),
  menu: styles => ({ ...styles, zIndex: 1300 }),
  placeholder: styles => {
    return {
      ...styles,
      color: GRAY
    };
  },
  input: provided => ({
    ...provided,
    width: "100%",
    fontFamily: FONT_FAMILY,
    margin: "0px",
    marginRight: "0px",
    paddingTop: "0px",
    paddingBottom: "0px",
    fontSize: "1rem",
    input: {
      opacity: "1 !important"
    }
  }),
  menuPortal: styles => ({
    ...styles,
    zIndex: 9999
  }),
  singleValue: styles => ({
    ...styles,
    fontFamily: FONT_FAMILY,
    fontSize: "1rem",
    color: GRAY
  }),
  control: (styles, { isFocused, isDisabled }) => {
    return variant === "standard"
      ? {
          ...styles,
          borderColor: isFocused && !isDisabled ? SECONDARY_COLOR : "hsl(0, 0%, 70%)",
          borderWidth: "1px",
          borderRadius: "0px",
          backgroundColor: "unset",
          borderStyle: "solid",
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          boxShadow: "none",
          ":hover": {
            borderColor: SECONDARY_COLOR
          }
        }
      : {
          ...styles,
          ":hover": {
            borderColor: SECONDARY_COLOR
          },
          backgroundColor: WHITE,
          boxShadow: isFocused && !isDisabled ? `0 0 0 1px ${SECONDARY_COLOR}` : "none",
          borderColor: isFocused && !isDisabled ? SECONDARY_COLOR : "rgba(0, 0, 0, 0.23)",
          height: !isMobile && (multiple ? "64px" : "57px")
        };
  },
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? null : isSelected || isFocused ? "hsl(0,0%,95%)" : null,
      color: SECONDARY_COLOR,
      fontFamily: FONT_FAMILY,
      fontSize: "1rem",
      cursor: "pointer",
      ":active": {
        ...styles[":active"]
      }
    };
  },
  valueContainer: (base, { isDisabled }) => ({
    ...base,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "block",
    width: "100%",
    fontFamily: FONT_FAMILY,
    fontSize: "1rem",
    paddingTop: multiple ? "1.5rem" : "1rem",
    paddingBottom: "1rem",
    maxHeight: "38px",
    fontWeight: isDisabled ? "300" : "500",
    "& .MuiFormLabel-root": {
      color: isDisabled ? "rgba(0, 0, 0, 0.23)" : "rgba(0, 0, 0, 0.54)"
    }
  })
});

const useStyles = makeStyles({
  input: ({ isMobile }) => ({
    width: "100%",
    maxHeight: isMobile ? "38px" : "64px",
  }),
  disabled: {
    backgroundColor: "white",
    borderColor: "rgb(230, 230, 230)"
  },
  floatLabel: {
    backgroundColor: "white",
    transform: "scale(0.75)",
    padding: "0 8px 0 8px",
    position: "absolute",
    fontSize: "1rem",
    lineHeight: "1",
    height: "16px",
    top: "-6px",
    left: "6px"
  }
});

Select.propTypes = {
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  customProperties: PropTypes.object,
  returnFullObject: PropTypes.bool,
  noOptionsMessage: PropTypes.string,
  maxHeightMenu: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  label: PropTypes.string,
  autoFocus: PropTypes.bool,
  options: PropTypes.array,
  id: PropTypes.string,
  openMenuOnFocus: PropTypes.bool,
  filterOption: PropTypes.func,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func
};

Select.defaultProps = {
  noOptionsMessage: "Sem opções",
  returnFullObject: false,
  customProperties: {},
  required: false,
  maxHeightMenu: "300",
  disabled: false,
  isClearable: false,
  autoFocus: false,
  label: "",
  code: null,
  options: [],
  id: "",
  openMenuOnFocus: true,
  multiple: false,
  getOptionValue: option => option.value,
  getOptionLabel: option => option.label,
  filterOption: (option, text) => 
    option.label.toString().toUpperCase().includes(text.toUpperCase()),
  backgroundColor: "white",
  isSearchable: true,
  isMobile: false,
};

export default Select;
