import React from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  makeStyles
} from "@material-ui/core";
import Loading from "./Loading";
import EditableTableCell from "./EditableTableCell";
import { SECONDARY_COLOR, WHITE, GRAY, LIGHT_GRAY } from "../../theme";
import CellNewCustomerCompetitor from "./NewCustomerCompetitor/views/CellNewCustomerCompetitor";
import _ from "lodash";
import { ChevronDown } from "react-feather";

const TableCustomized = props => {
  const {
    handleDeleteNewItem,
    setNextYearSelect,
    nextYearSelect,
    footerRadius,
    footerInfos,
    headerInfos,
    isPotential,
    showFooter,
    updateData,
    isLoading,
    bodyInfos,
    typeData,
    isMobile
  } = props;
  const classes = useStyles({ isMobile: isMobile });

  const showTable = headerInfos && bodyInfos;
  const indexItemsRight =
    headerInfos && headerInfos.map((i, index) => (i.alignRight ? index : -1)).filter(e => e !== -1);
  const indexItemsCenter =
    headerInfos &&
    headerInfos.map((i, index) => (i.alignCenter ? index : -1)).filter(e => e !== -1);
  const editableColumn =
    headerInfos && headerInfos.map((i, index) => (i.editable ? index : -1)).filter(e => e !== -1);
  const differentBackgroundColumn =
    headerInfos &&
    headerInfos.map((i, index) => (i.differentBackground ? index : -1)).filter(e => e !== -1);
  const differentTypeColumn =
    headerInfos && headerInfos.map((i, index) => (i.inputMode ? index : -1)).filter(e => e !== -1);

  const firstNextYear = new Date().getFullYear() + 1;
  const secondNextYear = new Date().getFullYear() + 2;
  const thirdNextYear = new Date().getFullYear() + 3;

  const getItemAlign = index => {
    if (indexItemsCenter.includes(index)) return "center";
    if (indexItemsRight.includes(index)) return "right";
    return "left";
  };

  const checkExistingElement = item => {
    let itemExists = null;

    bodyInfos.forEach((i, index) => {
      if (item && index !== item.rowId) {
        if (
          (_.isString(i[1]) && _.upperCase(i[1]) === _.upperCase(item.name)) ||
          (_.isObject(i[1]) && _.upperCase(i[1].name) === _.upperCase(item.name))
        )
          itemExists = i[1];
      }
    });

    return !!itemExists;
  };

  const getCell = (col, indexCol, bodyItem, indexBody, idBodyItem) => {
    const isNewItem = _.isString(idBodyItem) && idBodyItem.includes("new");

    if (editableColumn.includes(indexCol)) {
      return (
        <EditableTableCell
          key={`${indexBody} ${col} ${indexCol}`}
          contentAlign="right"
          rowId={indexBody}
          columnId={indexCol}
          rowValue={col}
          updateData={updateData}
          inputTitle={bodyItem[0]}
          inputMode={
            differentTypeColumn.includes(indexCol) ? headerInfos[indexCol].inputMode : "text"
          }
          isNewItem={isNewItem}
          nextYearSelect={nextYearSelect}
        />
      );
    }

    if (isNewItem && indexCol === 0) {
      let valueCustomerCompetitor = null;

      bodyInfos.forEach(item => {
        if (item[0] === idBodyItem) valueCustomerCompetitor = item[1];
      });

      return (
        <CellNewCustomerCompetitor
          checkExistingElement={checkExistingElement}
          handleDeleteItem={handleDeleteNewItem}
          key={`${indexBody} ${indexCol}`}
          value={valueCustomerCompetitor}
          nextYearSelect={nextYearSelect}
          width={isMobile && "93px"}
          isPotential={isPotential}
          inputTitle={bodyItem[0]}
          idBodyItem={idBodyItem}
          updateData={updateData}
          isMobile={isMobile}
          contentAlign="left"
          rowId={indexBody}
          type={typeData}
        />
      );
    }

    return (
      <TableCell
        key={`${indexBody} ${col} ${indexCol}`}
        align={getItemAlign(indexCol)}
        className={`${
          differentBackgroundColumn.includes(indexCol) || isNewItem
            ? classes.differentBackground
            : ""
        } ${indexCol === 0 ? classes.firstColumnResponsiveness : ""}`}
      >
        {col}
      </TableCell>
    );
  };

  const getHeaderComponent = headerItem => {
    if (headerItem.isSelect) {
      return (
        <>
          <Typography variant="caption" className={classes.labelYearHeader}>
            {headerItem.labelAbove}
          </Typography>
          <br />
          <Select
            className={`${classes.selectNextYear}`}
            value={nextYearSelect}
            disableUnderline
            onChange={e => setNextYearSelect(e.target.value)}
            IconComponent={({ className, ...rest }) => (
              <ChevronDown {...rest} className={`${className}`} color={SECONDARY_COLOR} size={22} />
            )}
          >
            <MenuItem
              key={1}
              value={firstNextYear}
              className={`${classes.menuItemSelectNextYear} ${classes.dividerMenuItem}`}
            >
              {firstNextYear}
            </MenuItem>
            <MenuItem
              key={2}
              value={secondNextYear}
              className={`${classes.menuItemSelectNextYear} ${classes.dividerMenuItem}`}
            >
              {secondNextYear}
            </MenuItem>
            <MenuItem key={3} value={thirdNextYear} className={classes.menuItemSelectNextYear}>
              {thirdNextYear}
            </MenuItem>
          </Select>
        </>
      );
    } else if (headerItem.labelAbove) {
      return (
        <>
          <Typography variant="caption" className={classes.labelYearHeader}>
            {headerItem.labelAbove}
          </Typography>
          <br />
          {headerItem.name}
        </>
      );
    } else {
      return headerItem.name;
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading loading={isLoading} />
      ) : (
        <>
          {showTable ? (
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow className="pr-2 pl-2">
                  {headerInfos &&
                    headerInfos.map((headerItem, index) => {
                      return (
                        <TableCell
                          key={headerItem.name + index + "header"}
                          align={getItemAlign(index)}
                          style={headerItem.width ? { width: `${headerItem.width * 10}%` } : ""}
                          className={
                            headerItem.differentBackground ? classes.differentBackground : null
                          }
                        >
                          {getHeaderComponent(headerItem)}
                        </TableCell>
                      );
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {bodyInfos &&
                  bodyInfos.map((bodyItem, indexBody) => {
                    const cloneBodyItem = [...bodyItem];
                    const idBodyItem = cloneBodyItem[0];
                    cloneBodyItem.shift();

                    return (
                      <TableRow key={indexBody} className="pr-2 pl-2">
                        {cloneBodyItem.map((col, indexCol) =>
                          getCell(col, indexCol, cloneBodyItem, indexBody, idBodyItem)
                        )}
                      </TableRow>
                    );
                  })}
                {showFooter && footerInfos && (
                  <TableRow className={classes.footerBackground}>
                    {footerInfos.map((i, index) => (
                      <TableCell
                        key={`${index} ${i} footer`}
                        className={`${footerRadius && index === 0 && classes.footerLeftRadius} ${
                          footerRadius &&
                          index === footerInfos.length - 1 &&
                          classes.footerRightRadius
                        } ${classes.footerTextColor}`}
                        align={indexItemsRight.includes(index) ? "right" : "left"}
                      >
                        {i}
                      </TableCell>
                    ))}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          ) : (
            <div className="p-2">
              <Typography variant="caption">Dados invalidos</Typography>
            </div>
          )}
        </>
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  footerLeftRadius: {
    borderBottomLeftRadius: "8px"
  },
  footerRightRadius: {
    borderBottomRightRadius: "8px"
  },
  footerTextColor: {
    color: WHITE
  },
  footerBackground: {
    backgroundColor: SECONDARY_COLOR
  },
  table: {
    "&.MuiTable-root": {
      borderCollapse: "inherit"
    }
  },
  differentBackground: {
    backgroundColor: SECONDARY_COLOR + "0D"
  },
  firstColumnResponsiveness: {
    wordBreak: "break-word"
  },
  selectNextYear: {
    backgroundColor: WHITE,
    "& .MuiSelect-select:focus": {
      backgroundColor: WHITE
    },
    color: SECONDARY_COLOR,
    fontSize: "0.75rem"
  },
  menuItemSelectNextYear: {
    "&.Mui-selected": {
      backgroundColor: WHITE,
      color: SECONDARY_COLOR,
      borderLeft: `3px solid ${SECONDARY_COLOR}`
    },
    color: GRAY,
    borderLeft: `3px solid ${WHITE}`
  },
  dividerMenuItem: {
    borderBottom: `1px solid ${LIGHT_GRAY}`
  },
  labelYearHeader: props => ({
    fontSize: props.isMobile ? "0.58rem" : "0.65rem",
    color: SECONDARY_COLOR
  })
}));

export default TableCustomized;
