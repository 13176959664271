import { useState, useEffect } from "react";
import NextYearOrderbookManager from "../NextYearOrderbookManager";
import { getTotalVolume, updateBodyInfo } from "../TableDataHelper";
import { DataType } from "../../../shared/utils/enum";

export const useCompetitorNextYearOrderbook = (selectedStateVendor, updateTotalVolume) => {
  const [competitorNextYearOrderbook, setCompetitorNextYearOrderbook] = useState();
  const [competitorTotal, setCompetitorTotal] = useState();
  const [loadingCompetitorTable, setloadingCompetitorTable] = useState(false);

  useEffect(() => {
    if (competitorTotal != null) {
      updateTotalVolume(DataType["competitor"].id, competitorTotal);
    }
  }, [competitorTotal, updateTotalVolume]);

  useEffect(() => {
    const getData = async () => {
      setloadingCompetitorTable(true);
      setCompetitorNextYearOrderbook();
      setCompetitorTotal();
      const data = await NextYearOrderbookManager.listCompetitorNextYearOrderbook(
        selectedStateVendor,
        new Date().getFullYear()
      );

      if (data) {
        setCompetitorNextYearOrderbook(data);
        updateTotals(data);
      } else {
        updateTotals([]);
      }

      setloadingCompetitorTable(false);
    };
    if (selectedStateVendor) getData();
  }, [selectedStateVendor]);

  const updateTotals = data => {
    const totals = getTotalVolume(data);
    setCompetitorTotal(totals);
  };

  const updateCompetitorData = (rowId, columnId, newValue) => {
    const newData = updateBodyInfo(competitorNextYearOrderbook, rowId, columnId, newValue);

    if (newData) {
      setCompetitorNextYearOrderbook(newData);
      updateTotals(newData);
    }
  };

  return {
    competitorNextYearOrderbook,
    loadingCompetitorTable,
    updateCompetitorData,
    competitorTotal
  };
};
