import ptLocale from "date-fns/locale/pt-BR";
import buildLocalize from "date-fns/locale/_lib/buildLocalizeFn";

export const monthValues = {
  narrow: ["j", "f", "m", "a", "m", "j", "j", "a", "s", "o", "n", "d"],
  abbreviated: ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"],
  wide: [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
  ]
};
var dayValues = {
  short: ["D", "S", "T", "Q", "Q", "S", "S"],
  abbreviated: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
  wide: [
    "Domingo",
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "Sexta-feira",
    "Sábado"
  ]
};

const locale = {
  ...ptLocale,
  localize: {
    ...ptLocale.localize,
    month: buildLocalize({
      values: monthValues,
      default: "wide"
    }),
    day: buildLocalize({
      values: dayValues,
      defaultWidth: "wide"
    })
  }
};

export default locale;
