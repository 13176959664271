import React from "react";
import {
  Grid,
  makeStyles,
  TableCell,
  TableRow,
  TextField,
  Table,
  TableBody,
  TableHead,
  Typography,
  TableContainer
} from "@material-ui/core";
import { User } from "react-feather";
import Loading from "../../../shared/components/Loading";
import SelectableRow from "./SelectableRow";
import { MID_BLUE, LIGHT_GRAY, PALE_BLUE_GRAY, SECONDARY_COLOR } from "../../../theme";
import { removeAccent } from "../../../shared/utils/Utils";
import useSearchBox from "../customHooks/useSearchBox";

const SearchBox = props => {
  const { isTypeCompetitor, selectedItem, setSelectedItem } = props;
  const classes = useStyles();

  const {
    setSearchTextField,
    loading,
    selectNewItem,
    searchTextField,
    searchedItems
  } = useSearchBox(isTypeCompetitor, setSelectedItem);

  return (
    <Grid container item xs={12} className={classes.container}>
      <TableContainer className={classes.tableContainer}>
        <Table size="small" className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={`${classes.lightGrayColor}`} colSpan={3}>
                <label className={`${classes.label} pr-4 mt-1`}>Nome</label>
                <TextField
                  className={classes.field}
                  value={searchTextField}
                  placeholder="Pesquise e selecione um cadastro para associar"
                  onChange={e => setSearchTextField(removeAccent(e.target.value.toUpperCase()))}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              searchedItems &&
              searchedItems.length > 0 &&
              searchedItems.map((element, index) => (
                <SelectableRow
                  key={index}
                  data={element}
                  isTypeCompetitor={isTypeCompetitor}
                  selectedItem={selectedItem}
                  selectNewItem={selectNewItem}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && <Loading size={50} loading className={classes.loading} />}
      {!loading && searchedItems && searchedItems.length === 0 && (
        <Grid item xs={12}>
          <User size={40} color={LIGHT_GRAY} className={`mt-8 ${classes.icon}`} />
          <Typography className={`h7 ${classes.lightGrayColor}`} align="center">
            Procure por cadastros que
            <br /> possam estar escritos de <br /> forma parecida
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  label: {
    color: MID_BLUE,
    textTransform: "uppercase",
    float: "left",
    height: "100%",
    display: "flex",
    alignItems: "center"
  },
  lightGrayColor: {
    color: LIGHT_GRAY
  },
  field: {
    width: "calc(100% - 62px)"
  },
  table: {
    tableLayout: "fixed"
  },
  loading: {
    margin: "auto",
    marginTop: "32px",
    color: SECONDARY_COLOR,
    display: "flex"
  },
  icon: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto"
  },
  container: {
    borderColor: PALE_BLUE_GRAY,
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "8px",
    borderCollapse: "inherit"
  },
  tableContainer: {
    maxHeight: "64vh"
  }
}));
export default SearchBox;
