import { useState, useEffect } from "react";
import NextYearOrderbookManager from "../NextYearOrderbookManager";
import { getTotalVolume, updateBodyInfo } from "../TableDataHelper";
import { DataType } from "../../../shared/utils/enum";

export const useCustomerNextYearOrderbook = (selectedStateVendor, updateTotalVolume) => {
  const [customerNextYearOrderbook, setCustomerNextYearOrderbook] = useState();
  const [customerTotal, setCustomerTotal] = useState();
  const [loadingCustomerTable, setloadingCustomerTable] = useState(false);

  useEffect(() => {
    if (customerTotal != null) {
      updateTotalVolume(DataType["customer"].id, customerTotal);
    }
  }, [customerTotal, updateTotalVolume]);

  useEffect(() => {
    const getData = async () => {
      setloadingCustomerTable(true);
      setCustomerNextYearOrderbook();
      setCustomerTotal();

      const data = await NextYearOrderbookManager.listCustomerNextYearOrderbook(
        selectedStateVendor,
        new Date().getFullYear()
      );

      if (data) {
        setCustomerNextYearOrderbook(data);
        updateTotals(data);
      } else {
        updateTotals([]);
      }
      
      setloadingCustomerTable(false);
    };
    if (selectedStateVendor) getData();
  }, [selectedStateVendor]);

  const updateTotals = data => {
    const totals = getTotalVolume(data);
    setCustomerTotal(totals);
  };

  const updateCustomerData = (rowId, columnId, newValue) => {
    const newData = updateBodyInfo(customerNextYearOrderbook, rowId, columnId, newValue);

    if (newData) {
      setCustomerNextYearOrderbook(newData);
      updateTotals(newData);
    }
  };

  return {
    customerNextYearOrderbook,
    loadingCustomerTable,
    customerTotal,
    updateCustomerData
  };
};
