import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/VendorStore";
import { SwipeableDrawer, Grid, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { WHITE, PRIMARY_COLOR, SECONDARY_COLOR } from "../../../theme";
import ConsultantSidebarListItems from "./ConsultantSidebarListItems";
import BackofficeSidebarListItems from "./BackofficeSidebarListItems";
import { profiles } from "../../../shared/utils/enum";
import UserInfo from "./UserInfo";
import RepresentativeSidebarListItems from "./RepresentativeSidebarListItems";

const Sidebar = props => {
  const classes = useStyles({ isMobile: window.innerWidth <= 768 });

  const {
    openDrawer,
    setOpenDrawer,
    userName,
    listVendors,
    updateVendor,
    selectedVendor,
    userProfile,
    logout
  } = props;

  const isBackOffice =
    userProfile && userProfile.find(element => element.initials === profiles.backOffice.initials);

  const isConsultant =
    userProfile && 
      (userProfile.find(element => element.initials === profiles.commercialConsultant.initials) 
      || userProfile.find(element => element.initials === profiles.hortiFrutiConsultant.initials));

  const isRepresentative =
    userProfile && userProfile.find(element => element.initials === profiles.representative.initials);

  const toggleDrawer = open => event => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setOpenDrawer(open);
  };

  useEffect(() => {
    updateVendor(listVendors && listVendors[0]);
  }, [listVendors, updateVendor]);

  return (
    <SwipeableDrawer
      anchor="left"
      open={openDrawer}
      onOpen={toggleDrawer(!openDrawer)}
      onClose={toggleDrawer(!openDrawer)}
      className={classes.drawer}
      disableDiscovery
    >
      <Grid container>
        <Grid container item xs={12} className={classes.headerSidebarBackgroundColor}>
          <Grid item xs={12} className="pt-3">
            <UserInfo
              userName={userName}
              selectedVendor={selectedVendor}
              listVendors={listVendors}
              updateVendor={updateVendor}
              isBackOffice={isBackOffice}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          {isBackOffice && <BackofficeSidebarListItems setOpenDrawer={setOpenDrawer} logout={logout} />}
          {isConsultant && <ConsultantSidebarListItems setOpenDrawer={setOpenDrawer} logout={logout} />}
          {isRepresentative && <RepresentativeSidebarListItems setOpenDrawer={setOpenDrawer} logout={logout} />} 
        </Grid>
      </Grid>
    </SwipeableDrawer>
  );
};

const useStyles = makeStyles(() => ({
  headerSidebarBackgroundColor: {
    backgroundColor: PRIMARY_COLOR
  },
  drawer: props => ({
    "& .MuiDrawer-paperAnchorLeft": {
      backgroundColor: SECONDARY_COLOR,
      width: props.isMobile ? "75%" : "25%"
    }
  }),
  divider: {
    height: 2,
    backgroundColor: WHITE
  }
}));

const mapStateToProps = state => {
  return {
    userName: state.user.name,
    listVendors: state.user.listVendor,
    selectedVendor: state.selectedVendor,
    userProfile: state.user.profiles
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateVendor: selectedVendor => dispatch(actions.updateVendor(selectedVendor))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
