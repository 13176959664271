import { useState, useEffect } from "react";
import PotentialManager from "../PotentialManager";

export const useStateTabs = selectedVendor => {
  const [selectedStateVendor, setSelectedStateVendor] = useState();
  const [statesInfo, setStatesInfo] = useState([]);
  const [selectedStateTab, setSelectedStateTab] = useState(0);
  const [numSteppers, setNumSteppers] = useState(1);
  const [stateTabsAreReady, setStateTabsAreReady] = useState(false);

  useEffect(() => {
    if (statesInfo && statesInfo.length > 0) {
      setSelectedStateVendor(statesInfo[selectedStateTab].stateVendorId);
      setStateTabsAreReady(true);
    }
  }, [selectedStateTab, setSelectedStateVendor, statesInfo]);

  useEffect(() => {
    const handleTotals = (totals, states) => {
      let statesInfoCopy = states;

      states.forEach((s, index) => {
        const indexData = totals.findIndex(d => d.stateVendorId === s.stateVendorId);
        if (indexData >= 0) {
          const currentStateTotals = totals[indexData].totals;
          if (currentStateTotals === null) {
            statesInfoCopy[index] = { ...s, totals: null };
          } else {
            statesInfoCopy[index] = { ...s, totals: currentStateTotals };
          }
        }
      });

      setStatesInfo(statesInfoCopy);
    };

    const GetVendorStates = async () => {
      if (selectedVendor) {
        const data = await PotentialManager.getStatesFromVendorId(selectedVendor);
        if (data) {
          setSelectedStateTab(0);
          setNumSteppers(data.length);

          const year = new Date().getFullYear();
          const states = data.map(s => s.stateVendorId).join(",");

          if (year && states) {
            const totals = await PotentialManager.listStatesPotentialTotals(year, states);
            handleTotals(totals, data);
          }
        }
      }
    };

    GetVendorStates();
  }, [selectedVendor]);

  return {
    selectedStateVendor,
    numSteppers,
    selectedStateTab,
    setSelectedStateTab,
    statesInfo,
    stateTabsAreReady
  };
};
