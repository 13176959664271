import { format } from "date-fns";
import ImportEventsService from "../../services/ImportEventsService";
import PotentialReportService from "../../services/PotentialReportService";
import PotentialService from "../../services/PotentialService";

class PotentialImportManager {
  async listLastImportEvents(maxNumberOfEvents) {
    const response = await ImportEventsService.listLastImportEvents(maxNumberOfEvents).then(
      res => res && res.data
    );

    return this.formatImportEvents(response);
  }
  async getPotentialReport(importEventId) {
    await PotentialReportService.getPotentialReport(importEventId).then(response => {
      if (!response || !response.data) {
        return;
      }
      const xlsxPotentialFile = new Blob([response.data], {
        type: response.headers["content-type"]
      });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(xlsxPotentialFile, "Relatorio Potencial");
      } else {
        const URLfile = URL.createObjectURL(xlsxPotentialFile);
        const link = document.createElement("a");
        link.setAttribute("download", "RelatorioPotencial.xlsx");
        link.href = URLfile;
        link.click();
      }
    });
  }

  async importPotentialSheet(file, userId) {
    return await PotentialService.importPotentialSheet(file, userId).then(response => {
      if (response && response.data != null) {
        if (response.data.requestError) return response.data;

        return { error: response.data };
      }
      return;
    });
  }

  formatImportEvents(data) {
    let result = [];

    if (data && data.length > 0) {
      result = data.map(item => ({
        id: item.id,
        importDate: [format(new Date(item.date), "dd/MM/yyyy - HH:mm")],
        dataError: !item.dataError,
        userName: item.userName,
        status: item.dataError
          ? "Planilha não importada, faça download da planilha e verifique os erros"
          : "Planilha importada"
      }));
    }

    return result;
  }
}

export default new PotentialImportManager();
