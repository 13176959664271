import CompetitorCustomerCropManager from "../CompetitorCustomerCropManager";

const useCreateCustomer = () => {
  const handleCreateCustomer = async customer => {
    const response = await CompetitorCustomerCropManager.createCustomer(customer);

    return response;
  };

  return {
    handleCreateCustomer
  };
};

export default useCreateCustomer;
