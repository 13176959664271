import axios from "../axiosInstance";

class StateVendorCustomer {
  async listCustomerSellings(stateVendorId, startDate, endDate) {
    return await axios.get(`customer/customersellings/${stateVendorId}`, {
      params: { startDate, endDate }
    });
  }

  async listCustomerPotentials(stateVendorId, year) {
    return await axios.get(`customer/customerpotential/${stateVendorId}`, {
      params: { year }
    });
  }

  async listCustomerNextYearOrderbook(stateVendorId, year) {
    return await axios.get(`customer/customerNextYearOrderbook/${stateVendorId}`, {
      params: { year }
    });
  }

  async listCustomersByStateVendor(stateVendorId) {
    return await axios.get(`customer/${stateVendorId}`);
  }

  async verifyCustomerHasVolume(stateVendorCustomerId) {
    return await axios.get(`customer/verifyhasvolume/${stateVendorCustomerId}`);
  }
}

export default new StateVendorCustomer();
