import clsx from "clsx";
import React, { useState, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  startOfWeek,
  endOfWeek,
  isValid,
  format,
  isWithinInterval,
  isSameDay,
  isAfter
} from "date-fns";
import {
  IconButton,
  InputAdornment,
  Typography,
  withStyles,
  Grid,
  makeStyles
} from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
import locale from "../../../shared/utils/Locale";
import ptBr from "date-fns/locale/pt-BR";
import { Calendar } from "react-feather";
import { SECONDARY_COLOR, PRIMARY_COLOR } from "../../../theme";
import SolidMarketManager from "../SolidMarketManager";
import Loading from "../../../shared/components/Loading";
import { dayWeek } from "../../../shared/utils/enum";

const WeekPicker = props => {
  const { weekNumber, setWeekNumber, selectedDate, setSelectedDate, isMobile } = props;

  const pageClasses = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const formatedDate = format(selectedDate, "yyyy-MM-dd");
      const result = await SolidMarketManager.getWeekNumber(formatedDate);
      if (result) {
        setWeekNumber(result);
      } else {
        setWeekNumber(null);
      }
      setIsLoading(false);
    };

    if (selectedDate) {
      getData();
      formatWeekSelectLabel(selectedDate);
    }
  }, [selectedDate, setWeekNumber]);

  const formatWeekSelectLabel = (date, invalidLabel) => {
    let dateClone = date;

    return dateClone && isValid(dateClone)
      ? `${format(startOfWeek(dateClone), "dd MMM", { locale: ptBr })} - ${format(
          endOfWeek(dateClone),
          "dd MMM",
          { locale: ptBr }
        )}`
      : invalidLabel;
  };

  const handleWeekChange = date => {
    setSelectedDate(date);
  };

  const firstWeekend = endOfWeek(new Date(new Date().getFullYear(), 0, 1));

  const renderWrappedWeekDay = (date, selectedDateParam, dayInCurrentMonth) => {
    const { classes } = props;
    let dateClone = date;
    let selectedDateClone = selectedDateParam;

    const start = startOfWeek(selectedDateClone);
    const end = endOfWeek(selectedDateClone);
    const currentEndWeek = endOfWeek(new Date());

    const dayIsBetween = isWithinInterval(dateClone, { start, end });

    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);
    const isFutureWeek = isAfter(dateClone, currentEndWeek);

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth || isFutureWeek,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(dateClone, "d")} </span>
        </IconButton>
      </div>
    );
  };

  return (
    <Grid item container className="pt-4" xs={!isMobile ? 2 : 12}>
      <Grid item xs={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
          <DatePicker
            cancelLabel="VOLTAR"
            className={
              !isMobile
                ? `${pageClasses.inputPropertiesWeb}`
                : `${pageClasses.inputPropertiesMobile}`
            }
            value={selectedDate}
            onChange={handleWeekChange}
            labelFunc={formatWeekSelectLabel}
            renderDay={renderWrappedWeekDay}
            maxDate={endOfWeek(new Date())}
            minDate={
              new Date(new Date().getFullYear(), 0, 1).getDay() <= dayWeek.wednesday
                ? new Date(new Date().getFullYear(), 0, 1)
                : new Date(firstWeekend.setDate(firstWeekend.getDate() + 1))
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className="ml-0">
                  <IconButton className="p-2 mr-4">
                    <Calendar size={24} color={SECONDARY_COLOR} />
                  </IconButton>
                </InputAdornment>
              ),
              disableUnderline: true,
              className: `${pageClasses.inputAligment} ${pageClasses.inputSize}`
            }}
            inputProps={{ className: `${pageClasses.inputText} bold pb-0` }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <Loading loading={isLoading} size={12} />
        ) : (
          weekNumber && (
            <Typography
              variant={!isMobile ? "body2" : "caption"}
              align="center"
              className={pageClasses.labelAligment}
            >
              Semana {weekNumber}
            </Typography>
          )
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  inputAligment: {
    display: "flex",
    margin: "auto"
  },
  inputPropertiesWeb: {
    display: "flex",
    margin: "auto",
    "& .MuiInputBase-input": {
      fontSize: "larger"
    }
  },
  inputPropertiesMobile: {
    display: "flex",
    margin: "auto",
    "& .MuiInputBase-input": {
      fontSize: "large"
    }
  },
  inputSize: {
    width: "225px"
  },
  inputText: {
    textAlign: "right",
    color: PRIMARY_COLOR,
    cursor: "pointer"
  },
  labelAligment: {
    display: "block",
    margin: "auto"
  }
});

const styles = createStyles(theme => ({
  dayWrapper: {
    position: "relative"
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit"
  },
  customDayHighlight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "2px",
    right: "2px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "50%"
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled
  },
  highlightNonCurrentMonthDay: {
    color: "#676767"
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%"
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%"
  }
}));

export default withStyles(styles)(WeekPicker);
