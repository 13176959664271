export const FormatDataToCreate = (name, email, phone, profile, ADUserId, associations) => {
  return {
    name,
    email,
    phone,
    profile,
    listVendor: createVendorList(associations),
    listManagement: createManagementList(associations),
    AdUserId: ADUserId
  };
};

const createVendorList = associations => {
  const vendors = [];

  associations.forEach(a => {
    a.forEach(v => {
      vendors.push({ id: v.vendorId, initials: v.vendor });
    });
  });

  return vendors;
};

const createManagementList = associations => {
  const managements = [];

  associations.forEach(a => {
    const currentManagement = a[0];
    managements.push({
      id: currentManagement.managementId,
      initials: currentManagement.management
    });
  });

  return managements;
};
