// Types
const LIST_RECENT_MESSAGES = "message/LIST_RECENT_MESSAGES";

// Reducer
export const INITIAL_STATE = [];

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LIST_RECENT_MESSAGES: {
      return {
        ...action.payload
      };
    }
    default: {
      return state;
    }
  }
}

// Action creators

export const listRecentMessages = messageData => {
  return {
    type: LIST_RECENT_MESSAGES,
    payload: messageData
  };
};
