import axios from "../axiosInstance";

class CompetitorService {
  async searchCompetitorByTerm(term) {
    return await axios.get(`competitor/search`, {
      params: { term }
    });
  }

  async listCompetitor(searchName, rowsPerPage, page) {
    return await axios.get(
      `competitor?${
        searchName ? `searchName=${searchName}&` : ""
      }rowsPerPage=${rowsPerPage}&pageNumber=${page}`
    );
  }

  async createCompetitor(competitor) {
    return await axios.post("competitor", competitor);
  }

  async updateCompetitor(competitor, competitorId) {
    return await axios.put(`competitor/${competitorId}`, competitor);
  }

  async getPreRegisteredCompetitor(preRegisteredId) {
    return await axios.get(`competitor/preregistered/${preRegisteredId}`);
  }

  async completePreRegisteredCompetitor(preRegisteredCompetitorId, userId, correctCompetitorId) {
    return await axios.put(`competitor/approvepreregistered/${preRegisteredCompetitorId}`, null, {
      params: { userId, correctCompetitorId }
    });
  }
}

export default new CompetitorService();
