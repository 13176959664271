import axios from "../axiosInstance";

class NextYearOrderbookService {
  async listStatesNextYearOrderbookTotals(year, stateVendorIds) {
    return await axios.get("nextyearorderbook/statetotals", {
      params: { year, stateVendorIds }
    });
  }
  async updateNextYearOrderbook(data) {
    return await axios.post("nextyearorderbook/updatenextyearorderbook", data);
  }
}

export default new NextYearOrderbookService();
