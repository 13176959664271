// Types
const UPDATE_VENDOR = "user/UPDATE_VENDOR";

// Reducer
export const INITIAL_STATE = {
  id: null,
  initials: null,
  description: null
};

export default function reducer(state = INITIAL_STATE, action) {
  if (action.type === UPDATE_VENDOR)
    return {
      ...action.payload
    };
  return state;
}

// Action creators

export const updateVendor = vendor => {
  return {
    type: UPDATE_VENDOR,
    payload: vendor
  };
};
