import React, { useState } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { SECONDARY_COLOR, GRAY } from "../../../theme";
import { BarChart, UploadCloud } from "react-feather";
import EntriesContainer from "./EntriesContainer";
import StateTabs from "./StateTabs";
import PageTitle from "../../../shared/components/PageTitle";
import Modal from "../../../shared/components/Modal";
import ErrorImportPotential from "../../../shared/img/ErrorImportPotential.svg";
import { usePotentialTypeTabs } from "../customHooks/usePotentialTypeTabs";
import { useStateTabs } from "../customHooks/useStateTabs";
import { useCompetitorPotential } from "../customHooks/useCompetitorPotential";
import { useCropPotential } from "../customHooks/useCropPotential";
import { useCustomerPotential } from "../customHooks/useCustomerPotential";
import { useModals } from "../customHooks/useModals";
import { connect } from "react-redux";
import { screenSizes } from "../../../shared/utils/enum";
import GeneralLoading from "../../../shared/components/GeneralLoading";
import { usePageScroll } from "../../../shared/utils/usePageScroll";

const Potential = props => {
  const classes = useStyles();

  const { userId, windowWidth, selectedVendor } = props;

  const [reloadScreen, setReloadScreen] = useState(false);
  const [entryIsLoading, setEntryIsLoading] = useState(false);

  const { isEndOfScreen } = usePageScroll();

  const {
    selectedStateVendor,
    numSteppers,
    selectedStateTab,
    setSelectedStateTab,
    statesInfo,
    stateTabsAreReady
  } = useStateTabs(selectedVendor);

  const {
    selectedTypeTab,
    setSelectedTypeTabs,
    handleChangeTab,
    updateTotalVolume,
    competitorTotalVolume,
    customerTotalVolume,
    cropTotalVolume,
    customerYaraTotalVolume,
    competitorYaraTotalVolume,
    cropYaraTotalVolume
  } = usePotentialTypeTabs();

  const {
    handleSuccessModalOpenClose,
    handleErrorModalOpenClose,
    successModalOpen,
    errorModalOpen,
    messageErrorTotals
  } = useModals();

  const {
    competitorSales,
    setCompetitorSales,
    loadingCompetitorTable,
    updateCompetitorData,
    competitorTotal
  } = useCompetitorPotential(selectedStateVendor, updateTotalVolume, reloadScreen);

  const { cropSales, loadingCropTable, updateCropData, cropTotal } = useCropPotential(
    selectedStateVendor,
    updateTotalVolume,
    reloadScreen
  );

  const {
    customerSales,
    setCustomerSales,
    loadingCustomerTable,
    updateCustomerData,
    customerTotal
  } = useCustomerPotential(selectedStateVendor, updateTotalVolume, reloadScreen);

  const isMobile = windowWidth < screenSizes.webScreenSize;

  const closeSuccessModal = () => {
    handleSuccessModalOpenClose();
    setReloadScreen(!reloadScreen);
    setEntryIsLoading(false);
  };

  return stateTabsAreReady && cropSales && competitorSales && customerSales ? (
    <Grid container className={!isMobile ? "pl-8 pt-8 pr-8 pb-4" : "pl-4 pt-8 pr-4 pb-4"}>
      <PageTitle
        title="Potencial"
        subtitle="Volume em mil toneladas"
        className={classes.title}
        icon={
          <BarChart
            color={SECONDARY_COLOR}
            size={!isMobile ? 36 : 32}
            className={`${classes.iconPotential} pr-1`}
          />
        }
        isMobile={isMobile}
      />
      <Grid item container xs={!isMobile ? 2 : 12}>
        <Grid item xs={12}>
          <Typography
            variant={!isMobile ? "h5" : "h6"}
            align={!isMobile ? "right" : "center"}
            className={classes.title}
          >
            01 Jan - 31 Dez
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant={!isMobile ? "body2" : "caption"}
            align={!isMobile ? "right" : "center"}
            className={
              !isMobile ? `${classes.labelAligmentLargeScreen}` : `${classes.labelAligment}`
            }
          >
            {new Date().getFullYear()}
          </Typography>
        </Grid>
      </Grid>

      <StateTabs
        isMobile={isMobile}
        numSteppers={numSteppers}
        selectedStateTab={selectedStateTab}
        setSelectedStateTab={setSelectedStateTab}
        statesInfo={statesInfo}
      />

      <EntriesContainer
        selectedTypeTab={selectedTypeTab}
        handleChangeTab={handleChangeTab}
        loadingCompetitorTable={loadingCompetitorTable}
        updateCompetitorData={updateCompetitorData}
        competitorPotentials={competitorSales}
        competitorTotalVolume={competitorTotalVolume}
        competitorTotal={competitorTotal}
        customerTotalVolume={customerTotalVolume}
        loadingCropTable={loadingCropTable}
        updateCropsData={updateCropData}
        cropPotentials={cropSales}
        cropTotalVolume={cropTotalVolume}
        cropTotals={cropTotal}
        loadingCustomerTable={loadingCustomerTable}
        updateCustomerData={updateCustomerData}
        customerPotentials={customerSales}
        customerTotal={customerTotal}
        stateVendorId={selectedStateVendor}
        handleSuccessModalOpenClose={handleSuccessModalOpenClose}
        handleErrorModalOpenClose={handleErrorModalOpenClose}
        userId={userId}
        isMobile={isMobile}
        setSelectedTypeTabs={setSelectedTypeTabs}
        setCustomerSales={setCustomerSales}
        setCompetitorSales={setCompetitorSales}
        selectedVendor={selectedVendor}
        setEntryIsLoading={setEntryIsLoading}
        entryIsLoading={entryIsLoading}
        isEndOfScreen={isEndOfScreen}
        customerYaraTotalVolume={customerYaraTotalVolume}
        competitorYaraTotalVolume={competitorYaraTotalVolume}
        cropYaraTotalVolume={cropYaraTotalVolume}
      />
      <Modal
        primaryButton={{
          action: () => closeSuccessModal(),
          text: "FECHAR"
        }}
        openedModal={successModalOpen}
        closeModal={() => closeSuccessModal()}
        title="Potencial atualizado!"
        titleIcon={
          <UploadCloud
            color={SECONDARY_COLOR}
            size={56}
            className={`${classes.iconPotential} pr-1`}
          />
        }
        closeIcon
      >
        <Typography align="center" className={classes.colorSubtitle}>
          O potencial de mercado foi salvo e atualizado com sucesso.
        </Typography>
      </Modal>

      <Modal
        primaryButton={{
          action: () => handleErrorModalOpenClose(),
          text: "FECHAR"
        }}
        openedModal={errorModalOpen}
        closeModal={() => handleErrorModalOpenClose()}
        title="Não foi possivel salvar!"
        titleIcon={<img src={ErrorImportPotential} alt="error send volume" />}
        closeIcon
      >
        <Typography align="center" className={classes.colorSubtitle}>
          {messageErrorTotals}
        </Typography>
      </Modal>
    </Grid>
  ) : (
    <GeneralLoading />
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    paddingTop: "16px"
  },
  iconPotential: {
    verticalAlign: "bottom"
  },
  labelAligment: {
    display: "block",
    margin: "auto"
  },
  labelAligmentLargeScreen: {
    display: "block",
    margin: "auto",
    marginRight: theme.spacing(8.5)
  },
  colorSubtitle: {
    color: GRAY
  }
}));

const mapStateToProps = state => {
  return {
    userId: state.user.id,
    windowWidth: state.window.width,
    selectedVendor: state.selectedVendor.id
  };
};

export default connect(mapStateToProps, null)(Potential);
