import WeekService from "../../services/WeekService";
import StateService from "../../services/StateService";
import StateVendorCompetitorService from "../../services/StateVendorCompetitorService";
import StateVendorCropService from "../../services/StateVendorCropService";
import StateVendorCustomerService from "../../services/StateVendorCustomerService";
import SellingService from "../../services/SellingService";

class SolidMarketManager {
  async getWeekNumber(date) {
    return await WeekService.getWeekNumber(date).then(response => response && response.data);
  }

  async getStatesFromVendorId(vendorId) {
    return await StateService.getStatesFromVendorIds(vendorId, true).then(
      answer => answer && answer.data
    );
  }

  async listCompetitorSellings(stateVendorId, startDate, endDate) {
    return await StateVendorCompetitorService.listCompetitorSellings(
      stateVendorId,
      startDate,
      endDate
    ).then(response => response && response.data);
  }

  async listCropSellings(stateVendorId, startDate, endDate) {
    return await StateVendorCropService.listCropSellings(stateVendorId, startDate, endDate).then(
      response => response && response.data
    );
  }

  async listCustomerSellings(stateVendorId, startDate, endDate) {
    return await StateVendorCustomerService.listCustomerSellings(
      stateVendorId,
      startDate,
      endDate
    ).then(response => response && response.data);
  }

  async listStateTotalsSelling(stateVendorIds, startDate, endDate) {
    return await SellingService.listStateTotalsSelling(
      stateVendorIds.join(","),
      startDate,
      endDate
    ).then(response => response && response.data);
  }

  async updateSellings(data) {
    return await SellingService.updateSellings(data).then(response => response && response.data);
  }
}

export default new SolidMarketManager();
