import { envConfig as developer } from "./developer";
import { envConfig as test } from "./test";
import { envConfig as production } from "./production";
import { envConfig as rollout } from "./rollout";

export function configureEnvironment() {
  const hostname = window && window.location && window.location.hostname;

  if (hostname.startsWith("appmarkettracker2.azurewebsites.net")) {
    return test;
  } else if (hostname.startsWith("url rollout")) {
    return rollout;
  } else if (
    hostname.startsWith("mkttracker-frontend.azurewebsites.net") ||
    hostname.startsWith("markettracker.yarabrasil.com.br")
  ) {
    return production;
  } else {
    return developer;
  }
}
