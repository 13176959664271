import React, { useCallback, useEffect, useState } from 'react';
import { Typography } from "@material-ui/core";
import { format, startOfWeek, endOfWeek, isValid } from "date-fns";
import ptBr from "date-fns/locale/pt-BR";
import WeekLabelManager from '../WeekLabelManager';

const WeekLabel = props => {
  const {
    weekNumber,
    setWeekNumber,
    selectedDate,
    isMobile
  } = props;

  const [weekLabel, setWeekLabel] = useState();

  const formatWeekSelectLabel = useCallback((date, invalidLabel) => {
    let dateClone = date;

    return dateClone && isValid(dateClone)
      ?
      setWeekLabel(
        `${format(
          startOfWeek(dateClone),
          "dd",
          { locale: ptBr }
        )} 
        a
        ${format(
          endOfWeek(dateClone),
          "dd",
          { locale: ptBr }
        )}
        ${format(
          startOfWeek(dateClone),
          "MMM yyyy",
          { locale: ptBr }
        )}`
      )
      : setWeekLabel("Data Inválida");
  }, []);

  useEffect(() => {
    const getData = async (date) => {
      const formatedDate = format(date, "yyyy-MM-dd");
      const result = await WeekLabelManager.getWeekNumber(formatedDate);
      if (result) {
        setWeekNumber(result);
      } else {
        setWeekNumber(null);
      }
    };

    let date = selectedDate == null ? new Date() : selectedDate;

    getData(date);
    formatWeekSelectLabel(date);
  }, [selectedDate, formatWeekSelectLabel, setWeekNumber]);

  return (
    <Typography>
      Semana {weekNumber} {isMobile ? <br></br> : null} ({weekLabel})
    </Typography>
  );
}

export default WeekLabel;