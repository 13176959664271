import React, { useState } from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import Select from "../../../shared/components/Select";
import DateRangePicker from "../../../shared/components/DateRangePicker";
import { GRAY } from "../../../theme";
import { readingStatus } from "../../../shared/utils/enum";
import ManagementVendorSelects from "../../../shared/components/ManagementVendorSelects";

const PageFilter = props => {
  const {
    selectedManagement,
    setSelectedManagement,
    selectedVendor,
    setSelectedVendor,
    selectedReadingStatus,
    setSelectedReadingStatus,
    selectedRangeDate,
    setSelectedRangeDate
  } = props;

  const classes = UseStyles();

  const [shoudReloadManagements, setShoudReloadManagements] = useState(true);

  return (
    <>
      <Grid item xs={12} className="pb-2">
        <Typography variant="h5" align="left">
          Filtrar
        </Typography>
      </Grid>
      <Grid item xs={12} className="pb-2">
        <Typography align="left" className={classes.subtitlePage} variant="body1">
          Selecione a gerência, consultoria e estado.
        </Typography>
      </Grid>
      <Grid container item className="pb-4 pt-1" spacing={2}>
        <Grid item sm={6} xs={12}>
          <ManagementVendorSelects
            shouldHaveAllVendor
            gridSpacing={2}
            selectedManagement={selectedManagement}
            setSelectedManagement={setSelectedManagement}
            selectedVendor={selectedVendor}
            setSelectedVendor={setSelectedVendor}
            shoudReloadManagements={shoudReloadManagements}
            setShoudReloadManagements={setShoudReloadManagements}
            active
          />
        </Grid>
        <Grid item sm={3} xs={12} className="pt-2">
          <DateRangePicker
            startDate={selectedRangeDate.startDate}
            endDate={selectedRangeDate.endDate}
            setRangeDate={setSelectedRangeDate}
            label="Período de envio"
            allowFutureDate={false}
            maxDayRange={90}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <Select
            label="Status de leitura"
            maxHeightMenu="150"
            code={selectedReadingStatus}
            options={readingStatus}
            handleChange={e => setSelectedReadingStatus(e)}
            getOptionValue={option => option.value}
            getOptionLabel={option => option.label}
          />
        </Grid>
      </Grid>
    </>
  );
};

const UseStyles = makeStyles(theme => ({
  subtitlePage: {
    color: GRAY
  }
}));

export default PageFilter;
