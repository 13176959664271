import CompetitorService from "../../services/CompetitorService";
import CustomerService from "../../services/CustomerService";
import CropService from "../../services/CropService";
import CustomerTypeService from "../../services/CustomerTypeService";
import CropTypeService from "../../services/CropTypeService";

class CompetitorCustomerCropManager {
  async listCompetitor(searchName, rowsPerPage, page) {
    return await CompetitorService.listCompetitor(searchName, rowsPerPage, page).then(
      response => response && response.data
    );
  }

  async createCompetitor(competitor) {
    return await CompetitorService.createCompetitor(competitor).then(
      response => response && response.data
    );
  }

  async updateCompetitor(competitor, competitorId) {
    return await CompetitorService.updateCompetitor(competitor, competitorId).then(
      response => response && response.data
    );
  }

  async listCustomer(searchName, rowsPerPage, page) {
    return await CustomerService.listCustomer(searchName, rowsPerPage, page).then(
      response => response && response.data
    );
  }

  async createCustomer(customer) {
    return await CustomerService.createCustomer(customer).then(
      response => response && response.data
    );
  }

  async updateCustomer(customer, customerId) {
    return await CustomerService.updateCustomer(customer, customerId).then(
      response => response && response.data
    );
  }

  async listCrop(searchName, rowsPerPage, page) {
    return await CropService.listCrop(searchName, rowsPerPage, page).then(
      response => response && response.data
    );
  }

  async createCrop(crop) {
    return await CropService.createCrop(crop).then(response => response && response.data);
  }

  async updateCrop(crop, cropId) {
    return await CropService.updateCrop(crop, cropId).then(response => response && response.data);
  }

  async listCropType() {
    return await CropTypeService.listCropType().then(response => response && response.data);
  }

  async listCustomerTypes() {
    return await CustomerTypeService.listCustomerTypes().then(response => response && response.data);
  }
}

export default new CompetitorCustomerCropManager();
