export const generateInternalUserTableHeaders = () => {
  return [
    { name: "Consultor/Gerente", width: 4.5, objName: "name" },
    { name: "Telefone", width: 2.3, objName: "phone" },
    { name: "E-mail", width: 3.1, objName: "email" }
  ];
};

export const generateRepresentativeUserTableHeaders = () => {
  return [
    { name: "Representante", width: 4.5, objName: "name" },
    { name: "Telefone", width: 2.3, objName: "phone" },
    { name: "E-mail", width: 3.1, objName: "email" }
  ];
};

export const formatManagements = vendors => {
  if (!vendors || !vendors.length) return "-";

  const managements = [...new Set(vendors.map(vendor => vendor.management?.initials))];

  let managementsLabel = managements[0];
  
  if (managements.length > 1) {
    managementsLabel += " + " + (managements.length - 1);
  }

  return managementsLabel;
}

export const formatVendors = vendors => {
  if (!vendors || !vendors.length) return "-";

  let vendorsLabel = vendors[0].initials;

  if (vendors.length > 1) {
    vendorsLabel += " + " + (vendors.length - 1);
  }

  return vendorsLabel;
}