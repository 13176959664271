import { useState, useEffect } from "react";
import SolidMarketManager from "../SolidMarketManager";
import { getCurrentAndNextsYearsTotal, updateBodyInfo } from "../TableDataHelper";
import { DataType } from "../../../shared/utils/enum";
import { startOfWeek, endOfWeek, format } from "date-fns";

export const useCropSales = (
  selectedDate,
  selectedStateVendor,
  updateTotalVolume,
  isMobile,
  reloadScreen
) => {
  const [cropSales, setCropSales] = useState();
  const [cropTotals, setCropTotals] = useState();
  const [loadingCropTable, setLoadingCropTable] = useState(false);

  useEffect(() => {
    if (cropTotals) {
      updateTotalVolume(
        DataType["crop"].id,
        cropTotals.currentYearTotal,
        cropTotals.firstNextYearTotal,
        cropTotals.secondNextYearTotal,
        cropTotals.thirdNextYearTotal
      );
    }
  }, [cropTotals, updateTotalVolume]);

  useEffect(() => {
    const getData = async () => {
      setLoadingCropTable(true);
      setCropSales();
      setCropTotals({
        currentYearTotal: 0,
        firstNextYearTotal: 0,
        secondNextYearTotal: 0,
        thirdNextYearTotal: 0
      });

      const startDate = format(startOfWeek(selectedDate), "yyyy-MM-dd");
      const endDate = format(endOfWeek(selectedDate), "yyyy-MM-dd");

      const data = await SolidMarketManager.listCropSellings(
        selectedStateVendor,
        startDate,
        endDate
      );

      if (data) {
        setCropSales(data);
        updateTotals(data);
      }

      setLoadingCropTable(false);
    };

    selectedStateVendor && getData();
  }, [selectedDate, selectedStateVendor, reloadScreen]);

  const updateTotals = data => {
    const totals = getCurrentAndNextsYearsTotal(data);
    setCropTotals(totals);
  };

  const updateCropsData = (rowId, columnId, newValue, nextYear) => {
    const newData = updateBodyInfo(cropSales, rowId, columnId, newValue, isMobile, nextYear);

    if (newData) {
      setCropSales(newData);
      updateTotals(newData);
    }
  };

  return {
    cropSales,
    cropTotals,
    loadingCropTable,
    updateCropsData
  };
};
