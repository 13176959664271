import React from "react";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import { connect } from "react-redux";
import AlertMessage from "./features/alertMessage";
import Clarity from "./integrations/clarity";
import Routes from "./Routes";
import styles from "./theme";
import "./App.css";
import { MsalProvider } from "@azure/msal-react";
import { ActionCreators as AuthActions } from "./store/AuthStore";
import { IDENTITY_PROVIDER_STORAGE_KEY } from "./shared/utils/Constants";

const projectTheme = createMuiTheme(styles);

function App({
  authenticated,
  profiles,
  msalInstance,
  setIdentityProvider,
}) {
  useStyles();

  setIdentityProvider(sessionStorage.getItem(IDENTITY_PROVIDER_STORAGE_KEY));

  return (
    <MsalProvider instance={msalInstance}>
      <Clarity />
      <ThemeProvider theme={projectTheme}>
        <CssBaseline />
        <Routes authenticated={authenticated} userProfiles={profiles} />
        <AlertMessage />
      </ThemeProvider>
    </MsalProvider>
  );
}

const useStyles = makeStyles(() => ({
  "@global": styles.global
}));

const mapStateToProps = state => {
  return {
    authenticated: state.user.isAuthenticated,
    profiles: state.user.profiles,
    msalInstance: state.authentication.msalInstance
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setIdentityProvider: (identityProvider) => dispatch(AuthActions.setIdentityProvider(identityProvider)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
