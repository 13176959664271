import React, { useState, useEffect, useCallback } from "react";
import { Grid, MobileStepper, makeStyles, Divider } from "@material-ui/core";
import { SECONDARY_COLOR, PALE_BLUE_GRAY } from "../../../theme";
import { connect } from "react-redux";
import { screenSizes } from "../../../shared/utils/enum";
import PageTitle from "../../../shared/components/PageTitle";
import SellingLimitTab from "./SellingLimitTab";
import ForecastAndEvolutionTab from "./ForecastAndEvolutionTab";
import SellingBalanceManager from "../SellingBalanceManager";
import { ShoppingBag } from "react-feather";
import Tabs from "../../../shared/components/Tabs";
import { createSellingBalanceTabsEnum } from "../sellingBalanceTabsEnum";
import DollarExchange from "../../dollarExchange";
import SellingBalanceLastUpdate from "../../sellingBalanceLastUpdate";

const START_DATE_INITIAL_VALUE = new Date(new Date().setMonth(0));
const END_DATE_INITIAL_VALUE = new Date(new Date().setMonth(11));

const SellingBalance = props => {
  const {
    windowWidth,
    selectedVendorInitials
  } = props;

  const isMobile = windowWidth < screenSizes.webScreenSize;
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState(0);
  const [sellingLimits, setSellingLimits] = useState({});
  const [forecastAndEvolutions, setForecastAndEvolutions] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [selectedStartDate, setSelectedStartDate] = useState(START_DATE_INITIAL_VALUE);
  const [selectedEndDate, setSelectedEndDate] = useState(END_DATE_INITIAL_VALUE);

  const fetchSellingLimits = useCallback(async (vendorInitials, startDate, endDate) => {
    const sellingLimitData = await SellingBalanceManager.getSellingLimits(vendorInitials, startDate, endDate);

    if (sellingLimitData) {
      setSellingLimits(sellingLimitData);
    }

    setIsLoadingData(false);
  }, []);

  const fetchForecastAndEvolutions = useCallback(async (vendorInitials, startDate, endDate) => {
    const forecastAndEvolutionsData = await SellingBalanceManager.getForecastAndEvolutions(
      vendorInitials,
      startDate,
      endDate
    );

    if (forecastAndEvolutionsData) {
      setForecastAndEvolutions(forecastAndEvolutionsData);
    }

    setIsLoadingData(false);
  }, []);

  useEffect(() => {
    if (selectedVendorInitials) {      
      if (selectedTab === 0) {
        fetchSellingLimits(selectedVendorInitials, selectedStartDate, selectedEndDate);
      }
      else if (selectedTab === 1) {
        fetchForecastAndEvolutions(selectedVendorInitials, selectedStartDate, selectedEndDate);
      }
    }
  }, [
    selectedTab,
    selectedVendorInitials,
    fetchSellingLimits,
    fetchForecastAndEvolutions,
    selectedStartDate,
    selectedEndDate
  ]);

  const handleChangeDates = (startDate, endDate) => {
    setIsLoadingData(true);
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  }

  const handleChangeTab = (_, newTab) => {
    if (newTab === selectedTab) return;
    
    setIsLoadingData(true);
    setSelectedTab(newTab);
    setSelectedStartDate(START_DATE_INITIAL_VALUE);
    setSelectedEndDate(END_DATE_INITIAL_VALUE);
  }

  const renderSelectedTab = () => {
    if (selectedTab === 1) {
      return (
        <ForecastAndEvolutionTab
          isLoading={isLoadingData}
          forecastAndEvolutionsData={forecastAndEvolutions}
          isMobile={isMobile}
          onChangeDates={handleChangeDates}
          startDate={selectedStartDate}
          endDate={selectedEndDate}
        />
      );
    }
    
    return (
      <SellingLimitTab
        isLoading={isLoadingData}
        sellingLimitsData={sellingLimits}
        isMobile={isMobile}
        onChangeDates={handleChangeDates}
        startDate={selectedStartDate}
        endDate={selectedEndDate}
      />
    );
  }

  return (
    <Grid container justify="center" className={!isMobile ? "pl-8 pt-8 pr-8 pb-4" : "pl-4 pt-8 pr-4 pb-4"}>
      <Grid container justify="center" item xs={12} className="mb-4">
        <Grid item xs={isMobile ? 12 : 6}>
          <PageTitle
            title="Saldo a Vender"
            subtitle="Volume em toneladas"
            isMobile={isMobile}
            icon={
              <ShoppingBag
                color={SECONDARY_COLOR}
                size={!isMobile ? 36 : 32}
                className={`${classes.iconSalesBalance} pr-1`}
              />
            }
          />
        </Grid>
      </Grid>
      <Grid container item xs={isMobile ? 12 : 6}>
        <Grid item xs={12} className="mb-2">
          <SellingBalanceLastUpdate isMobile={isMobile} />
        </Grid>
        <Grid item xs={12} className="mb-2">
          <DollarExchange isMobile={isMobile} />
        </Grid>
        <Grid container item xs={12} className={classes.contentAligment}>
          <Grid item xs={12}>
            <Tabs
              selectedTab={selectedTab}
              handleChangeTab={handleChangeTab}
              isMobile={isMobile}
              tabsEnum={createSellingBalanceTabsEnum({ selectedTab, isMobile })}
            />
            <Divider className={classes.divider} />
          </Grid>
          <Grid item xs={12}>
            {renderSelectedTab()}
          </Grid>
        </Grid>
        <Grid item xs={12} className="pb-4">
          <MobileStepper
            variant="dots"
            steps={2}
            position="static"
            activeStep={selectedTab}
            className={classes.stepper}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  iconSalesBalance: {
    verticalAlign: "bottom"
  },
  stepper: {
    "& .MuiMobileStepper-dots": {
      margin: "auto",
      display: "flex"
    },
    "& .MuiMobileStepper-dotActive": {
      backgroundColor: SECONDARY_COLOR
    }
  },
  contentAligment: {
    margin: "auto",
    display: "flex",
    width: "100%",
    border: `1px solid ${PALE_BLUE_GRAY}`,
    borderRadius: theme.spacing(1)
  },
  divider: {
    height: theme.spacing(0.25),
    backgroundColor: PALE_BLUE_GRAY
  },
  dollarExchangeContainer: {
    marginBottom: theme.spacing(1),
    width: "100%"
  }
}));

const mapStateToProps = state => {
  return {
    windowWidth: state.window.width,
    selectedVendorInitials: state.selectedVendor.initials,
  };
};

export default connect(mapStateToProps, null)(SellingBalance);
