import React, { useEffect, useState } from 'react';
import { Grid, Typography, makeStyles } from "@material-ui/core";
import CustomTable from '../../../shared/components/CustomTable/CustomTable';
import Loading from "../../../shared/components/Loading";
import CompletionPercentageAndBalance from './CompletionPercentageAndBalance';
import FamilyGroupButton from './FamilyGroupButton';
import { SECONDARY_COLOR, PALE_BLUE_GRAY } from '../../../theme';
import MonthRangePicker from './MonthRangePicker';
import CustomMessage from "../../../shared/components/CustomMessage";
import { AlertCircle } from "react-feather";

const ForecastAndEvolutionTab = props => {
  const {
    isLoading,
    forecastAndEvolutionsData,
    isMobile,
    onChangeDates,
    startDate,
    endDate
  } = props;

  const classes = useStyles();

  const [forecastAndEvolutions, setForecastAndEvolutions] = useState([]);

  useEffect(() => {
    if (forecastAndEvolutionsData) {
      setForecastAndEvolutions(forecastAndEvolutionsData.map(item => {
        return {
          familyGroup: <FamilyGroupButton familyGroup={item.familyGroup} isMobile={isMobile} />,
          completionPercentageAndBalance: <CompletionPercentageAndBalance {...item} />,
        }
      }));
    }
  }, [forecastAndEvolutionsData, isMobile]);

  const tableHeaders = [
    {
      field: "familyGroup",
      name: ""
    },
    {
      field: "completionPercentageAndBalance",
      name: <Typography className={classes.headers}>% realizado/ Saldo</Typography>
    },
  ]

  return (
    <>
      {isLoading ? (
        <Loading size={32} loading={isLoading} />
      ) : (
        <Grid container justify="center">
          <Grid container item xs={12} alignItems="center" spacing={2} className="pt-4 pb-4 pl-2 pr-2">
            <MonthRangePicker
              onChangeDates={onChangeDates}
              startDate={startDate}
              endDate={endDate}
              isMobile={isMobile}
            />
          </Grid>
          <Grid item xs={12}>
            {forecastAndEvolutions.length ? (
              <CustomTable
                headers={tableHeaders}
                data={forecastAndEvolutions}
                options={{}}
                shouldOverflow={false}
              />
            ) : (
              <CustomMessage
                icon={
                  <AlertCircle
                    color={SECONDARY_COLOR}
                    size={!isMobile ? 26 : 24}
                  />
                }
                description="Não existem dados para os filtros selecionados."
                isMobile={isMobile}
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}

const useStyles = makeStyles(theme => ({
  headers: {
    color: SECONDARY_COLOR,
    fontSize: "0.875rem",
  },
  divider: {
    height: theme.spacing(0.25),
    backgroundColor: PALE_BLUE_GRAY,
  },
}));

export default ForecastAndEvolutionTab;