import React from "react";
import { Grid, makeStyles, Button, Typography } from "@material-ui/core";
import { GRAY, PRIMARY_COLOR } from "../../../theme";

const FinishButton = props => {
  const { selectedItem, isNewRegister, clickButton } = props;
  const classes = useStyles();

  const getButtonHelperText = () => {
    if (isNewRegister)
      return (
        <>
          A solicitação será aprovada como um
          <br /> novo cadastro.
        </>
      );
    if (selectedItem)
      return (
        <>
          Associar a <span className={classes.primaryColor}>{selectedItem.name}</span>
        </>
      );

    return (
      <>
        Selecione um cadastro ao lado para <br /> associar o volume cadastrado.
      </>
    );
  };

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          className={`${classes.button} mr-4`}
          onClick={() => clickButton()}
          disabled={!isNewRegister && !selectedItem}
        >
          ASSOCIAR CADASTRO
        </Button>

        <Typography variant="body1" className={classes.buttonHelperText}>
          {getButtonHelperText()}
        </Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  button: {
    width: "200px",
    height: "56px"
  },
  buttonHelperText: {
    color: GRAY,
    display: "inline-block",
    verticalAlign: "middle"
  },
  primaryColor: {
    color: PRIMARY_COLOR
  }
}));

export default FinishButton;
