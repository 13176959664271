// Constantes
export const PRIMARY_COLOR = "#00205B";
export const SECONDARY_COLOR = "#2777b8";
export const GRAY = "#666666";
export const LIGHT_GRAY = "#E0E0E0";
export const LIGHT_GRAY2 = "#F4F4F4";
export const LIGHT_GRAY3 = "#D8D0C7";
export const MID_BLUE_LIGHT = "#64A6EB";
export const MID_BLUE = "#2777B8";
export const MID_BLUE_BACKGROUND = "#2777b80D";
export const BRIGHT_BLUE = "#63B6E6";
export const PALE_BLUE_GRAY = "#D9E1E2";
export const PALE_GREEN = "#E2E477";
export const MID_GREEN = "#C2CC23";
export const DARK_GREEN = "#507A07";
export const YELLOW = "#FFCF01";
export const ORANGE = "#FF8228";
export const RED = "#D83E38";
export const WHITE = "#FFFFFF";
export const SPACING = 8;
export const FONT_FAMILY = "YaraMax";

export function hexToRgba(hex, opacity) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  const rgba = result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
    a: opacity,
  } : null;

  return result ? `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})` : null;
}

// Mixins

/* Funcionalidade para o em da unidade de media de tela
  const round2 = (n) => Math.round(n * 100) / 100;
  const em = (n) => round2(n) + 'em'; */

const generateNClasses = (name, number, calc) => {
  let classes = {};

  for (let i = 0; i <= number; i++) {
    let className = name.replace("{i}", i);
    classes[className] = calc(i);
  }

  return classes;
};

const spacing = num => (num ? num * SPACING + "px" : "0");

export default {
  spacing: SPACING,

  palette: {
    primary: {
      light: PRIMARY_COLOR,
      main: PRIMARY_COLOR,
      dark: PRIMARY_COLOR
    },
    secondary: {
      light: SECONDARY_COLOR,
      main: SECONDARY_COLOR
    },
    action: {
      disabled: {}
    },
    background: {
      default: "#ffffff"
    }
  },

  typography: {
    fontFamily: FONT_FAMILY,

    h1: {
      fontWeight: "300",
      fontSize: "6rem",
      color: PRIMARY_COLOR
    },
    h2: {
      fontWeight: "300",
      fontSize: "3.75rem",
      color: PRIMARY_COLOR
    },
    h3: {
      fontWeight: "400",
      fontSize: "3rem",
      color: PRIMARY_COLOR
    },
    h4: {
      fontWeight: "400",
      fontSize: "2.125rem",
      color: PRIMARY_COLOR
    },
    h5: {
      fontWeight: "400",
      fontSize: "1.5rem",
      color: PRIMARY_COLOR
    },
    h6: {
      fontWeight: "600",
      fontSize: "1.25rem",
      color: PRIMARY_COLOR
    },
    subtitle1: {
      fontWeight: "400",
      fontSize: "1rem",
      color: PRIMARY_COLOR
    },
    subtitle2: {
      fontWeight: "600",
      fontSize: "0.875rem",
      color: PRIMARY_COLOR
    },

    body1: {
      fontWeight: "400",
      fontSize: "1rem",
      color: PRIMARY_COLOR
    },
    body2: {
      fontWeight: "400",
      fontSize: "0.875rem",
      color: PRIMARY_COLOR
    },
    caption: {
      fontWeight: "400",
      fontSize: "0.75rem",
      color: PRIMARY_COLOR
    },
    overline: {
      fontWeight: "400",
      fontSize: "0.625rem",
      color: PRIMARY_COLOR
    }
  },

  overrides: {
    MuiTableCell: {
      root: {
        "&.spacing-1": {
          padding: `${spacing(2)} ${spacing(1)} ${spacing(2)} ${spacing(1)}`
        }
      },
      sizeSmall: {
        padding: `${spacing(1)} ${spacing(1)} ${spacing(1)} ${spacing(1)}`
      },
      head: {
        color: SECONDARY_COLOR,
        fontSize: "0.75rem",
        lineHeight: "1.2rem"
      },
      body: {
        color: GRAY,
        fontSize: "0.875rem"
      }
    },
    MuiButton: {
      root: {
        textTransform: "none",
        fontWeight: "600",
        borderRadius: "8px",
        "&.Mui-disabled": {
          backgroundColor: LIGHT_GRAY2,
          color: LIGHT_GRAY
        }
      },
      text: {
        "&.Mui-disabled": {
          backgroundColor: LIGHT_GRAY2,
          color: LIGHT_GRAY
        }
      },
      outlined: {
        "&.Mui-disabled": {
          border: "none"
        }
      },
      contained: {
        "&.Mui-disabled": {
          border: "none",
          backgroundColor: LIGHT_GRAY2,
          color: LIGHT_GRAY
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        "&.Mui-disabled": {
          "&:hover": {
            "& $notchedOutline": {
              borderColor: "rgba(0,0,0,0.23)"
            }
          }
        },
        "&:hover": {
          "& $notchedOutline": {
            borderColor: SECONDARY_COLOR
          },
          "&.Mui-error": {
            "& $notchedOutline": {
              borderColor: RED
            }
          }
        },
        "&.Mui-focused": {
          "& $notchedOutline": {
            borderColor: SECONDARY_COLOR
          },
          "&.Mui-error": {
            "& $notchedOutline": {
              borderColor: RED
            }
          }
        }
      }
    },
    MuiInputBase: {
      root: {
        color: GRAY,
        fontWeight: "500",
        fontSize: "1rem",
        borderColor: LIGHT_GRAY,

        "&.Mui-disabled": {
          backgroundColor: WHITE,
          color: LIGHT_GRAY
        }
      }
    },
    MuiInput: {
      underline: {
        "&:hover:not(.Mui-disabled):before,&:after": {
          borderColor: SECONDARY_COLOR
        },
        "&.Mui-focused": {
          borderColor: SECONDARY_COLOR
        }
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: "1rem",
        fontWeight: "500",
        "&.Mui-error": {
          color: RED
        }
      }
    },
    MuiFormHelperText: {
      root: {
        fontWeight: "400",
        fontSize: "0.75rem",
        color: GRAY,
        "&.Mui-focused": {
          color: SECONDARY_COLOR
        },
        "&.Mui-error": {
          color: RED
        }
      }
    },
    MuiMobileStepper: {
      dot: {
        backgroundColor: LIGHT_GRAY
      }
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        "&.Mui-disabled": {
          color: LIGHT_GRAY
        }
      }
    },
    MuiBreadcrumbs: {
      separator: {
        color: WHITE
      }
    }
  },

  global: {
    ".text-overflow-ellipsis": {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      display: "block"
    },
    ".hover-icon": {
      "&:hover": {
        stroke: SECONDARY_COLOR,
        cursor: "pointer"
      }
    },
    ".pointer": {
      cursor: "pointer"
    },
    ".icone-pequeno": { height: "16px", width: "16px" },
    ".icone-medio": { height: "22px", width: "22px" },
    ".icone-grande": { height: "48px", width: "48px" },
    ".italic": {
      fontStyle: "italic"
    },
    ".bold": {
      fontWeight: 600
    },
    ".light": {
      fontWeight: 300
    },
    ".float-right": {
      display: "block",
      float: "right"
    },
    ".h7": {
      fontWeight: "400",
      fontSize: "1.125rem"
    },
    ".body3": {
      fontWeight: "700",
      fontSize: "0.875rem"
    },
    ...generateNClasses(".m-{i}", 8, i => ({ margin: spacing(i * 0.5) })),
    ...generateNClasses(".mb-{i}", 8, i => ({
      marginBottom: spacing(i * 0.5)
    })),
    ...generateNClasses(".mt-{i}", 8, i => ({ marginTop: spacing(i * 0.5) })),
    ...generateNClasses(".mr-{i}", 8, i => ({
      marginRight: spacing(i * 0.5)
    })),
    ...generateNClasses(".ml-{i}", 8, i => ({
      marginLeft: spacing(i * 0.5)
    })),
    ".aviso-indicador": {
      display: "inline-block",
      borderRadius: "50%",
      width: "8px",
      height: "8px",
      verticalAlign: "inherit"
    },
    ...generateNClasses(".p-{i}", 8, i => ({ padding: spacing(i * 0.5) })),
    ...generateNClasses(".pb-{i}", 8, i => ({
      paddingBottom: spacing(i * 0.5)
    })),
    ...generateNClasses(".pt-{i}", 8, i => ({
      paddingTop: spacing(i * 0.5)
    })),
    ...generateNClasses(".pr-{i}", 8, i => ({
      paddingRight: spacing(i * 0.5)
    })),
    ...generateNClasses(".pl-{i}", 8, i => ({
      paddingLeft: spacing(i * 0.5)
    }))
  }
};
