import { useState, useEffect } from "react";
import { validateADUserId, validateEmail, validatePhone } from "../../../shared/utils/Utils";
import { profiles as profilesEnum } from "../../../shared/utils/enum";
import UserManager from "../UserManager";
import { FormatDataToCreate } from "../FormatDataToCreate";

const useEditUser = (
  name,
  email,
  phone,
  selectedProfile,
  selectedManagement,
  selectedVendor,
  associations,
  isEditFlow,
  userId,
  handleSuccessModalOpenClose,
  handleErrorModalOpenClose,
  ADUserId
) => {
  const [associateButtonDisabled, setAssociateButtonDisabled] = useState(true);
  const [addButtonDisabled, setAddButtonDisabled] = useState(true);
  const [saveUserButtonLoading, setSaveUserButtonLoading] = useState(false);
  const [openAssociateToolTip, setOpenAssociateToolTip] = useState(false);

  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    const buttonDisabled =
      !selectedProfile ||
      !name ||
      !email ||
      !validateEmail(email) ||
      !validateADUserId(ADUserId) ||
      !ADUserId ||
      !phone ||
      (phone && validatePhone(phone));

    const isAssociateButtonDisabled =
      !selectedProfile ||
      (selectedProfile &&
        (selectedProfile.initials === profilesEnum.commercialConsultant.initials ||
          selectedProfile.initials === profilesEnum.hortiFrutiConsultant.initials) &&
        (!selectedVendor || !selectedManagement)) ||
      (selectedProfile &&
        selectedProfile.initials === profilesEnum.manager.initials &&
        !selectedManagement);

    const backOfficeSelected =
      selectedProfile && selectedProfile.initials === profilesEnum.backOffice.initials;

    const addButtonAssociationsValidation = backOfficeSelected || associations.length > 0;

    setAssociateButtonDisabled(isAssociateButtonDisabled || backOfficeSelected);
    setAddButtonDisabled(buttonDisabled || !addButtonAssociationsValidation);
  }, [
    selectedProfile,
    selectedVendor,
    selectedManagement,
    name,
    email,
    phone,
    associations,
    ADUserId
  ]);

  const editUser = async () => {
    setSaveUserButtonLoading(true);

    const user = FormatDataToCreate(name, email, phone, selectedProfile, ADUserId, associations);

    const result = isEditFlow
      ? await UserManager.updateUser(user, userId)
      : await UserManager.createUser(user);

    if (result !== undefined) {
      if (result) handleSuccessModalOpenClose();
      else handleErrorModalOpenClose();
    }

    setSaveUserButtonLoading(false);
  };

  const handleOpenToolTip = () => setOpenAssociateToolTip(associateButtonDisabled);

  const handleCloseToolTip = () => setOpenAssociateToolTip(false);

  return {
    associateButtonDisabled,
    addButtonDisabled,
    redirect,
    setRedirect,
    saveUserButtonLoading,
    editUser,
    handleOpenToolTip,
    handleCloseToolTip,
    openAssociateToolTip
  };
};

export default useEditUser;
