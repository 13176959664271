import MessageManager from "../MessageManager";
import { intervals } from "../../../utils/enum";
import { useEffect, useState, useCallback } from "react";

const useListRecentMessages = (userId, vendorId, listRecentMessages) => {
  const [refresh, setRefresh] = useState(false);
  const [vendorChanged, setVendorChanged] = useState(false);
  const [timer, setTimer] = useState();
  const [loadingRefreshButton, setLoadingRefreshButton] = useState(false);

  const listRecentMessagesByUserIdAndVendorId = useCallback(async () => {
    setLoadingRefreshButton(true);
    if (userId && vendorId) {
      const data = await MessageManager.listRecentMessagesByUserIdAndVendorId(userId, vendorId);

      data && listRecentMessages(data);
    }
    setLoadingRefreshButton(false);
  }, [userId, vendorId, listRecentMessages]);

  useEffect(() => {
    if (refresh) {
      listRecentMessagesByUserIdAndVendorId();
      setRefresh(false);
    }
  }, [refresh, listRecentMessagesByUserIdAndVendorId]);

  useEffect(() => {
    if (vendorId) {
      setVendorChanged(true);
      setRefresh(true);
    }
  }, [vendorId]);

  if (!refresh && vendorChanged) {
    setVendorChanged(false);
    clearInterval(timer);
    setTimer(setInterval(listRecentMessagesByUserIdAndVendorId, intervals.refreshNewMessages));
  }

  return {
    setRefresh,
    loadingRefreshButton
  };
};

export default useListRecentMessages;
