import { useState } from "react";
import MessageManager from "../MessageManager";

const useInsertMessageReadDate = (messageId, userId, readDate) => {
  const [openMessage, setOpenMessage] = useState(false);

  const insertMessageReadDate = async () =>
    await MessageManager.insertMessageReadDate(messageId, userId);

  if (openMessage && !readDate) insertMessageReadDate();

  return {
    openMessage,
    setOpenMessage,
    insertMessageReadDate
  };
};

export default useInsertMessageReadDate;
