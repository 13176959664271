import { combineReducers } from "redux";
import { alertMessageReducer } from "../features/alertMessage/redux";
import userStore from "./UserStore";
import vendorStore from "./VendorStore";
import authStore from "./AuthStore";
import windowSizeStore from "./WindowSizeStore";
import messageStore from "./MessageStore"

const reducers = combineReducers({
  notification: alertMessageReducer,
  user: userStore,
  selectedVendor: vendorStore,
  authentication: authStore,
  window: windowSizeStore,
  messages: messageStore
});

export default reducers;
