import axios from "../axiosInstance";

class PotentialExportService {
  async getExportPotentialFile() {
    return await axios.get("potential/exportpotential", {
      responseType: "blob"
    });
  }
}

export default new PotentialExportService();
