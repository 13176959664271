import { useEffect, useState } from "react";
import AssociationsManager from "../AssociationsManager";

const useEditVendor = (
  vendorId,
  setInitials,
  setDescription,
  setManagements,
  setSelectedManagement,
  setStatus,
  setStatesList,
  setStates
) => {
  const [shouldGetData, setShouldGetData] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    const getManagements = async () => {
      const newManagements = await AssociationsManager.listManagements();
      newManagements && setManagements(newManagements);
    };

    const getVendorData = async () => {
      const vendor = await AssociationsManager.getVendorByVendorId(vendorId);
      if (vendor) {
        setInitials(vendor.initials);
        setDescription(vendor.description);
        setSelectedManagement(vendor.management);
        setStatus(vendor.active ? "active" : "inative");
      }
    };

    const getStatesList = async () => {
      const newStatesList = await AssociationsManager.listStates();
      newStatesList && setStatesList(newStatesList);
    };

    const getVendorStates = async () => {
      const vendorStates = await AssociationsManager.listVendorStates(vendorId);
      const newStates = [];
      if (vendorStates) {
        for (let e of vendorStates) {
          const competitors = await AssociationsManager.listStateCompetitors(e.stateVendorId);
          const crops = await AssociationsManager.listStateCrops(e.stateVendorId);
          const customers = await AssociationsManager.listStateCustomers(e.stateVendorId);

          const newState = {
            ...e,
            competitors: competitors || [],
            crops: crops || [],
            customers: customers || []
          };
          newStates.push(newState);
        }
      }
      setStates(newStates);
    };

    const getPageData = async () => {
      await getManagements();
      await getVendorData();
      await getStatesList();
      await getVendorStates();
      setPageLoading(false);
    };

    if (shouldGetData && vendorId) {
      setPageLoading(true);
      getPageData();
      setShouldGetData(false);
    }
  }, [
    vendorId,
    setInitials,
    setDescription,
    setManagements,
    setSelectedManagement,
    setStatus,
    shouldGetData,
    setStatesList,
    setStates
  ]);

  return { pageLoading, redirect, setRedirect };
};

export default useEditVendor;
