import DateFnsUtils from "@date-io/date-fns";
import locale from "../../../shared/utils/Locale";
import React from 'react';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createMuiTheme, ThemeProvider, Grid } from "@material-ui/core";
import theme from "../../../theme";

const LAST_DAY_OF_NEXT_YEAR = new Date(new Date().getFullYear() + 1, 11, 31);

const MonthRangePicker = props => {
  const {
    onChangeDates,
    startDate,
    endDate
  } = props;

  const handleChangeStartDate = (value) => {
    onChangeDates(value, endDate);
  }

  const handleChangeEndDate = (value) => {
    onChangeDates(startDate, value);
  }

  const calculateMaxDate = () => {
    if (startDate.getFullYear() === new Date().getFullYear() + 1) {
      return LAST_DAY_OF_NEXT_YEAR;
    }

    return new Date(startDate.getFullYear() + 1, startDate.getMonth(), 0);
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
      <ThemeProvider theme={materialTheme}>
        <Grid container alignItems="flex-start">
          <Grid item xs={6} className="pr-2">
            <DatePicker
              cancelLabel="VOLTAR"
              value={startDate}
              onChange={handleChangeStartDate}
              minDate={new Date(new Date().getFullYear(), 0, 1)}
              maxDate={LAST_DAY_OF_NEXT_YEAR}
              label="Mês Inicial"
              views={["year", "month"]}
              inputVariant="outlined"
              minDateMessage="Selecione uma data válida"
              maxDateMessage="Selecione uma data válida"
            />
          </Grid>
          <Grid item xs={6} className="pl-2">
            <DatePicker
              cancelLabel="VOLTAR"
              value={endDate}
              onChange={handleChangeEndDate}
              minDate={startDate}
              maxDate={calculateMaxDate()}
              label="Mês Final"
              views={["year", "month"]}
              inputVariant="outlined"
              minDateMessage="Selecione uma data válida"
              maxDateMessage="Selecione uma data válida"
            />
          </Grid>
        </Grid>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

const materialTheme = createMuiTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiPickersYearSelection: {
      container: {
        height: "auto",
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        minHeight: "0px",
      }
    },
  },
});

export default MonthRangePicker;