export const formatDataToCreateDirectMessage = (userSenderId, vendorId, directMessage) => {
  return {
    userSender: {
      id: userSenderId
    },
    vendors: [
      {
        id: vendorId
      },
    ],
    directMessage: directMessage
  };
};

export const formatDirectMessage = (reasonId, description, about) => {
  let directMessage = {
    reason: {
      id: reasonId
    },
    description: description
  };

  switch (about.entityType) {
    case "Cliente":
      directMessage.customer = {
        id: about.id
      };
      break;
    case "Concorrente":
      directMessage.competitor = {
        id: about.id
      };
      break;
    case "Cultura":
      directMessage.crop = {
        id: about.id
      };
      break;
    default:
      return null;
  }

  return directMessage;
};
