import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Divider } from "@material-ui/core";
import { GRAY, WHITE, MID_BLUE, MID_BLUE_BACKGROUND } from "../../../../theme";
import { ChevronRight, ChevronDown } from "react-feather";
import { format } from "date-fns";
import useInsertMessageReadDate from "../customHooks/useInsertMessageReadDate";
import { messageType } from "../../../utils/enum";
import { dialogNotificationBox } from "../../../utils/Constants";
import ItemAdjustmentMessage from "./ItemAdjustmentMessage";
import ItemCommunicationMessage from "./ItemCommunicationMessage";
import ItemStandardMessage from "./ItemStandardMessage";

const ListItemMessage = props => {
  const {
    type,
    sendDate,
    readDate,
    message,
    index,
    indexOpened,
    setIndexOpened,
    messageId,
    userId,
    setRefresh
  } = props;

  const { openMessage, setOpenMessage, insertMessageReadDate } = useInsertMessageReadDate(
    messageId,
    userId,
    readDate
  );

  const classes = useStyles({
    readDate: readDate || openMessage,
    minItemHeight: dialogNotificationBox.minItemHeight,
    widthNotificationBar: dialogNotificationBox.widthNotificationBar
  });

  const openItemMessagem = () => {
    setOpenMessage(!openMessage);
    setIndexOpened(index);
    !readDate && insertMessageReadDate() && setRefresh(true);
  };

  const closeItemMessage = () => {
    setOpenMessage(!openMessage);
    setIndexOpened();
  };

  if (openMessage && index !== indexOpened) setOpenMessage(!openMessage);

  const getDescriptionClosedCard = () => {
    if (message[1] && type) {
      switch (type.id) {
        case messageType.adjustment.id:
          if (message[1].adjustmentMessage) return message[1].adjustmentMessage.reasonDescription;
          break;
        case messageType.communication.id:
          return "Novo cliente e/ou concorrente";
        case messageType.sentDirect.id:
          const directReason =
            message[1].directMessage &&
            message[1].directMessage.reason &&
            message[1].directMessage.reason.description;

          let cropCustomerCompetitor = null;

          if (message[1].directMessage) {
            if (message[1].directMessage.crop)
              cropCustomerCompetitor = `${message[1].directMessage.crop.name} Cultura`;

            if (message[1].directMessage.customer)
              cropCustomerCompetitor = `${message[1].directMessage.customer.name} Cliente`;

            if (message[1].directMessage.competitor)
              cropCustomerCompetitor = `${message[1].directMessage.competitor.name} Concorrente`;
          }

          return `${directReason} - ${cropCustomerCompetitor}`;
        case messageType.sellingBalance.id:
          const sellingBalanceMessageReason =
            message[1].sellingBalanceMessage &&
            message[1].sellingBalanceMessage.reason &&
            message[1].sellingBalanceMessage.reason.description;

          let siteProductGroupRawMaterial = null;

          if (message[1].sellingBalanceMessage) {
            if (message[1].sellingBalanceMessage.site)
              siteProductGroupRawMaterial = `${message[1].sellingBalanceMessage.site} Planta`;

            if (message[1].sellingBalanceMessage.productGroup)
              siteProductGroupRawMaterial = `${message[1].sellingBalanceMessage.productGroup} Produto`;

            if (message[1].sellingBalanceMessage.rawMaterial)
              siteProductGroupRawMaterial = `${message[1].sellingBalanceMessage.rawMaterial} Matéria Prima`;
          }

          return `${sellingBalanceMessageReason} - ${siteProductGroupRawMaterial}`;
        default:
          return null;
      }
    }
  };

  const getComponentMessage = () => {
    if (message[1].adjustmentMessage) {
      return (
        <React.Fragment key={message[1].adjustmentMessage.id}>
          <ItemAdjustmentMessage
            state={message[1].adjustmentMessage.state.initials}
            crop={message[1].adjustmentMessage.crop.name}
            volume={message[1].adjustmentMessage.volume}
            isIncreasing={message[1].adjustmentMessage.isIncreasing}
            description={message[1].adjustmentMessage.descriptionMessage}
          />
          <Divider className={classes.divider} />
        </React.Fragment>
      );
    } else if (message[1].communicationMessage) {
      return (
        <React.Fragment key={message[1].communicationMessage.id}>
          <ItemCommunicationMessage description={message[1].communicationMessage.description} />
          <Divider className={classes.divider} />
        </React.Fragment>
      );
    } else if (message[1].directMessage) {
      return (
        <React.Fragment key={message[1].directMessage.id}>
          <ItemStandardMessage description={message[1].directMessage.description} />
          <Divider className={classes.divider} />
        </React.Fragment>
      );
    } else if (message[1].sellingBalanceMessage) {
      return (
        <React.Fragment key={message[1].sellingBalanceMessage.id}>
          <ItemStandardMessage description={message[1].sellingBalanceMessage.description} />
          <Divider className={classes.divider} />
        </React.Fragment>
      );
    }
  };

  return (
    <Grid container item xs={12} className={`${classes.itemMessage} pr-4 pl-4 pt-2 pb-2`}>
      <Grid container item xs={10}>
        <Grid container item xs={12}>
          <Typography className={`${classes.messageType} pr-2`}>
            {message && message[1].messageType.description}
          </Typography>
          <Typography variant="overline" className={classes.details}>
            {format(new Date(sendDate), "dd/MM/yyyy")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" className={classes.details}>
            {getDescriptionClosedCard()}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={2} className={classes.contentPosition}>
        {openMessage ? (
          <ChevronDown
            onClick={() => closeItemMessage()}
            className={`${classes.detailsIcon} hover-icon`}
          />
        ) : (
          <ChevronRight
            onClick={() => openItemMessagem()}
            className={`${classes.detailsIcon} hover-icon`}
          />
        )}
      </Grid>
      {openMessage && getComponentMessage()}
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  itemMessage: props => ({
    backgroundColor: props.readDate ? WHITE : MID_BLUE_BACKGROUND,
    minHeight: `${props.minItemHeight}px`,
    borderLeft: `4px solid ${props.readDate ? WHITE : MID_BLUE}`
  }),
  messageType: {
    color: MID_BLUE
  },
  details: {
    color: GRAY
  },
  detailsIcon: {
    marginLeft: "auto",
    display: "flex",
    color: MID_BLUE
  },
  contentPosition: {
    alignSelf: "center"
  },
  truncateField: {
    display: "block",
    width: "calc(100%)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}));

export default ListItemMessage;
