import React from 'react';
import { makeStyles } from "@material-ui/core";
import { SECONDARY_COLOR, BRIGHT_BLUE } from "../../../theme";

const TabIconFactory = IconComponent => {
  const classes = useStyles();

  return (props => {
    const {
      selectedTab,
      tabIndex,
      isMobile,
    } = props;
    
    return (
      <IconComponent
        className={
          isMobile
          ? `mt-3 ${selectedTab === tabIndex && classes.selectedColor} ${classes.tabIconMobileSize}`
          : `mt-2 mb-2 ${selectedTab === tabIndex && classes.selectedColor} ${classes.tabIcon}`
        }
      />
    );
  });
}

const useStyles = makeStyles(theme => ({
  tabIcon: {
    height: theme.spacing(4.5),
    width: theme.spacing(7.5),
    color: BRIGHT_BLUE,
    marginLeft: "auto"
  },
  tabIconMobileSize: {
    height: theme.spacing(2.5),
    width: theme.spacing(5.5),
    color: BRIGHT_BLUE,
    marginLeft: "auto",
    marginRight: "auto"
  },
  selectedColor: {
    color: SECONDARY_COLOR
  },
}));

export default TabIconFactory;