import { useState } from "react";
import { FormatDataToEditVendor } from "../FormatDataToEditVendor";
import AssociationsManager from "../AssociationsManager";

const useEditVendorSave = (initials, description, status, selectedManagement, states, vendorId) => {
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [loadingSaveButton, setLoadingSaveButton] = useState(false);

  const handleSaveVendorEdits = async () => {
    setLoadingSaveButton(true);

    const finalData = FormatDataToEditVendor(
      initials,
      description,
      status,
      selectedManagement,
      states
    );

    const saveSuccess = await AssociationsManager.saveEditVendor(finalData, vendorId);

    if (saveSuccess) setSuccessModalOpen(!successModalOpen);
    setLoadingSaveButton(false);
  };

  return {
    successModalOpen,
    handleSaveVendorEdits,
    loadingSaveButton
  };
};

export default useEditVendorSave;
