import { useState } from "react";
import CompetitorCustomerCropManager from "../CompetitorCustomerCropManager";

const useListCustomerType = () => {
  const [listCustomerTypes, setListCustomerTypes] = useState([]);

  const handleListCustomerTypes = async () => {
    const result = await CompetitorCustomerCropManager.listCustomerTypes();
    if (result) setListCustomerTypes(result);
  };

  return {
    listCustomerTypes,
    handleListCustomerTypes
  };
};

export default useListCustomerType;
