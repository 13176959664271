import React from "react";
import {
  Grid,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles
} from "@material-ui/core";
import { PALE_BLUE_GRAY, GRAY, PRIMARY_COLOR } from "../../../theme";
import { format, parseISO } from "date-fns";

const Request = props => {
  const { preRegisteredData, isTypeCompetitor } = props;
  const classes = useStyles();

  return (
    <Grid container item xs={12}>
      <Typography variant="h5" className="pb-4">
        Solicitação
      </Typography>
      <Grid item xs={12}>
        <Box className={classes.container}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.primaryColor}>Nome</TableCell>
                {!isTypeCompetitor && <TableCell className={classes.primaryColor}>Tipo</TableCell>}
                <TableCell className={classes.primaryColor}>Gerências</TableCell>
                <TableCell className={classes.primaryColor}>Consultoria</TableCell>
                <TableCell className={classes.primaryColor}>Estado</TableCell>
              </TableRow>
            </TableHead>
            {preRegisteredData && (
              <TableBody>
                <TableRow>
                  <TableCell>{preRegisteredData.itemName}</TableCell>
                  {!isTypeCompetitor && <TableCell>{preRegisteredData.typeName}</TableCell>}
                  <TableCell>{preRegisteredData.managementInitials}</TableCell>
                  <TableCell>{preRegisteredData.vendorInitials}</TableCell>
                  <TableCell>{preRegisteredData.stateInitials}</TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </Box>
        {preRegisteredData && (
          <Typography variant="body1" className={`pt-2 ${classes.textColor}`} align="right">
            Solicitado por{" "}
            <span className={classes.primaryColor}>{preRegisteredData.requestingUserName}</span> em{" "}
            <span className={classes.primaryColor}>
              {format(parseISO(preRegisteredData.requestDate), "dd/MM/yyyy")}
            </span>
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    borderColor: PALE_BLUE_GRAY,
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "8px",
    borderCollapse: "inherit"
  },
  textColor: {
    color: GRAY
  },
  primaryColor: {
    color: PRIMARY_COLOR
  }
}));

export default Request;
