import { numberWithSeparators } from "../../shared/utils/Utils";

export const getTotalVolume = data => {
  if (!data) return;

  let totalVolume = 0;

  data.forEach(d => {
    totalVolume = parseFloat((d.volume + totalVolume).toFixed(2));
  });
  return totalVolume;
};

export const generateBody = (data, totalVolume) => {
  if (!data || totalVolume == null) return;

  const lines = data.map(d => [d.id, d.name, parseFloat(d.volume).toFixed(2)]);

  return lines;
};

export const generateFooter = totalVolume => {
  if (totalVolume == null) return;
  return ["TOTAL", numberWithSeparators(totalVolume)];
};

export const generateHeader = (name, isEditable) => {
  return [
    { name: name, width: 5 },
    {
      name: new Date().getFullYear().toString(),
      alignRight: true,
      editable: isEditable,
      width: 5,
      inputMode: "decimal"
    }
  ];
};

export const updateBodyInfo = (data, rowId, colId, newFloatValue) => {
  if (data[rowId]) {
    let dataCopy = data;

    if (colId === 2) {
      dataCopy[rowId] = { ...dataCopy[rowId], volume: parseFloat(newFloatValue.toFixed(2)) };
    }
    return dataCopy;
  }
  return null;
};
