import axios from "../axiosInstance";
import qs from "qs";

class StateService {
  async getStatesFromVendorIds(vendorIds, shouldReturnStates) {
    if (typeof(vendorIds) === "number") vendorIds = [vendorIds];

    return axios.get(`state/byVendorIds`, {
      params: {
        shouldReturnStates,
        vendorIds
      },
      paramsSerializer: (params) => qs.stringify(params)
    });
  }
  async listStates() {
    return axios.get(`state`);
  }
}

export default new StateService();
