import React, { useEffect, useState } from "react";
import { Grid, MobileStepper, makeStyles, Divider, Typography, Button } from "@material-ui/core";
import { SECONDARY_COLOR, PALE_BLUE_GRAY } from "../../../theme";
import SaleTypeTabs from "./SaleTypeTabs";
import TableCustomized from "../../../shared/components/TableCustomized";
import { generateBody, generateFooter, generateHeader } from "../TableDataHelper";
import { DataType } from "../../../shared/utils/enum";
import { generateId } from "../../../shared/utils/Utils";
import Loading from "../../../shared/components/Loading";
import FloatingButton from "../../../shared/components/FloatingButton";
import { Send } from "react-feather";
import AddNewCustomerCompetitorCard from "../../../shared/components/NewCustomerCompetitor/views/AddNewCustomerCompetitorCard";
import _ from "lodash";

const EntriesContainer = props => {
  const {
    handleOverVolumeModalOpenClose,
    handleErrorModalOpenClose,
    loadingCompetitorTable,
    competitorTotalVolume,
    updateCompetitorData,
    loadingCustomerTable,
    setSelectedTypeTabs,
    updateCustomerData,
    setCompetitorSales,
    finishEntryVolume,
    competitorTotals,
    loadingCropTable,
    setCustomerSales,
    updateCropsData,
    competitorSales,
    selectedTypeTab,
    handleChangeTab,
    customerTotals,
    entryIsLoading,
    customerSales,
    isEndOfScreen,
    cropTotals,
    cropSales,
    isMobile
  } = props;

  const [disabledSendButton, setDisabledSendButton] = useState(true);
  const [nextYearSelect, setNextYearSelect] = useState(new Date().getFullYear() + 1);

  const classes = useStyles();

  const generateCompetitorTable = () => {
    return (
      <TableCustomized
        headerInfos={generateHeader(DataType["competitor"].name, isMobile)}
        bodyInfos={generateBody(competitorSales, competitorTotals, isMobile, nextYearSelect)}
        showFooter
        footerInfos={generateFooter(competitorTotals, isMobile, nextYearSelect)}
        footerRadius
        isLoading={loadingCompetitorTable}
        updateData={updateCompetitorData}
        typeData={DataType.competitor.name}
        handleDeleteNewItem={handleDeleteNewCompetitor}
        isMobile={isMobile}
        nextYearSelect={nextYearSelect}
        setNextYearSelect={setNextYearSelect}
      />
    );
  };

  const generateCropTable = () => {
    return (
      <TableCustomized
        headerInfos={generateHeader(DataType["crop"].name, isMobile)}
        bodyInfos={generateBody(cropSales, cropTotals, isMobile, nextYearSelect)}
        showFooter
        footerInfos={generateFooter(cropTotals, isMobile, nextYearSelect)}
        footerRadius
        isLoading={loadingCropTable}
        updateData={updateCropsData}
        typeData={DataType.crop.name}
        isMobile={isMobile}
        nextYearSelect={nextYearSelect}
        setNextYearSelect={setNextYearSelect}
      />
    );
  };

  const generateCustomerTable = () => {
    return (
      <TableCustomized
        headerInfos={generateHeader(DataType["customer"].name, isMobile)}
        bodyInfos={generateBody(customerSales, customerTotals, isMobile, nextYearSelect)}
        showFooter
        footerInfos={generateFooter(customerTotals, isMobile, nextYearSelect)}
        footerRadius
        isLoading={loadingCustomerTable}
        updateData={updateCustomerData}
        typeData={DataType.customer.name}
        handleDeleteNewItem={handleDeleteNewCustomer}
        isMobile={isMobile}
        nextYearSelect={nextYearSelect}
        setNextYearSelect={setNextYearSelect}
      />
    );
  };

  const handleActiveTable = () => {
    switch (selectedTypeTab) {
      case DataType["competitor"].id:
        return generateCompetitorTable();
      case DataType["customer"].id:
        return generateCustomerTable();
      case DataType["crop"].id:
        return generateCropTable();
      default:
        return generateCompetitorTable();
    }
  };

  const roundValues = () => {
    competitorTotals.currentYearTotal =
      competitorTotals.currentYearTotal && parseFloat(competitorTotals.currentYearTotal.toFixed(2));
    competitorTotals.firstNextYearTotal =
      competitorTotals.firstNextYearTotal &&
      parseFloat(competitorTotals.firstNextYearTotal.toFixed(2));
    competitorTotals.secondNextYearTotal =
      competitorTotals.secondNextYearTotal &&
      parseFloat(competitorTotals.secondNextYearTotal.toFixed(2));
    competitorTotals.thirdNextYearTotal =
      competitorTotals.thirdNextYearTotal &&
      parseFloat(competitorTotals.thirdNextYearTotal.toFixed(2));
    cropTotals.currentYearTotal =
      cropTotals.currentYearTotal && parseFloat(cropTotals.currentYearTotal.toFixed(2));
    cropTotals.firstNextYearTotal =
      cropTotals.firstNextYearTotal && parseFloat(cropTotals.firstNextYearTotal.toFixed(2));
    cropTotals.secondNextYearTotal =
      cropTotals.secondNextYearTotal && parseFloat(cropTotals.secondNextYearTotal.toFixed(2));
    cropTotals.thirdNextYearTotal =
      cropTotals.thirdNextYearTotal && parseFloat(cropTotals.thirdNextYearTotal.toFixed(2));
    customerTotals.currentYearTotal =
      customerTotals.currentYearTotal && parseFloat(customerTotals.currentYearTotal.toFixed(2));
    customerTotals.firstNextYearTotal =
      customerTotals.firstNextYearTotal && parseFloat(customerTotals.firstNextYearTotal.toFixed(2));
    customerTotals.secondNextYearTotal =
      customerTotals.secondNextYearTotal &&
      parseFloat(customerTotals.secondNextYearTotal.toFixed(2));
    customerTotals.thirdNextYearTotal =
      customerTotals.thirdNextYearTotal && parseFloat(customerTotals.thirdNextYearTotal.toFixed(2));
  };

  const verifyCurrentYearTotal = () =>
    competitorTotals.currentYearTotal === customerTotals.currentYearTotal &&
    competitorTotals.currentYearTotal === cropTotals.currentYearTotal &&
    cropTotals.currentYearTotal === customerTotals.currentYearTotal;

  const verifyFirstNextYearTotal = () =>
    competitorTotals.firstNextYearTotal === customerTotals.firstNextYearTotal &&
    competitorTotals.firstNextYearTotal === cropTotals.firstNextYearTotal &&
    cropTotals.firstNextYearTotal === customerTotals.firstNextYearTotal;

  const verifySecondNextYearTotal = () =>
    competitorTotals.secondNextYearTotal === customerTotals.secondNextYearTotal &&
    competitorTotals.secondNextYearTotal === cropTotals.secondNextYearTotal &&
    cropTotals.secondNextYearTotal === customerTotals.secondNextYearTotal;

  const verifyThirdNextYearTotal = () =>
    competitorTotals.thirdNextYearTotal === customerTotals.thirdNextYearTotal &&
    competitorTotals.thirdNextYearTotal === cropTotals.thirdNextYearTotal &&
    cropTotals.thirdNextYearTotal === customerTotals.thirdNextYearTotal;

  const onClickFinishButton = () => {
    roundValues();
    const currentYearTotalValid = verifyCurrentYearTotal();
    const firstNextYearTotalValid = verifyFirstNextYearTotal();
    const secondNextYearTotalValid = verifySecondNextYearTotal();
    const thirdNextYearTotalValid = verifyThirdNextYearTotal();

    if (
      currentYearTotalValid &&
      firstNextYearTotalValid &&
      secondNextYearTotalValid &&
      thirdNextYearTotalValid
    ) {
      competitorTotalVolume < 50 ? finishEntryVolume() : handleOverVolumeModalOpenClose();
    } else {
      handleErrorModalOpenClose(
        currentYearTotalValid,
        firstNextYearTotalValid,
        secondNextYearTotalValid,
        thirdNextYearTotalValid
      );
    }
  };

  const handleAddNewCompetitor = () => {
    setSelectedTypeTabs(DataType["competitor"].id);

    const newCompetitor = {
      id: `new-${generateId()}`,
      name: "",
      currentYearSale: 0,
      firstNextYearSale: 0,
      secondNextYearSale: 0,
      thirdNextYearSale: 0,
      potentialSelling: 0,
      yearSolidSale: 0
    };

    let cloneCompetitors = [...competitorSales];
    cloneCompetitors.unshift(newCompetitor);

    setCompetitorSales(cloneCompetitors);
  };

  const handleAddNewCustomer = () => {
    setSelectedTypeTabs(DataType["customer"].id);

    const newCustomer = {
      id: `new-${generateId()}`,
      name: "",
      currentYearSale: 0,
      firstNextYearSale: 0,
      secondNextYearSale: 0,
      thirdNextYearSale: 0,
      potentialSelling: 0,
      yearSolidSale: 0,
      customerTypeId: 0
    };

    let cloneCustomers = [...customerSales];
    cloneCustomers.unshift(newCustomer);

    setCustomerSales(cloneCustomers);
  };

  const handleDeleteNewCustomer = idBodyItem => {
    let cloneCustomers = [...customerSales];
    cloneCustomers = cloneCustomers.filter(item => item.id !== idBodyItem);

    setCustomerSales(cloneCustomers);
  };

  const handleDeleteNewCompetitor = idBodyItem => {
    let cloneCompetitors = [...competitorSales];
    cloneCompetitors = cloneCompetitors.filter(item => item.id !== idBodyItem);

    setCompetitorSales(cloneCompetitors);
  };

  useEffect(() => {
    const checkNewEmptyLines = () => {
      let newEmptyLines = false;

      customerSales.forEach(cp => {
        if (
          (_.isString(cp.name) && cp.name.length === 0) ||
          (_.isObject(cp.name) && (!cp.name.name || !cp.name.customerType))
        )
          newEmptyLines = true;
      });

      competitorSales.forEach(cp => {
        if ((_.isString(cp.name) && cp.name.length === 0) || (_.isObject(cp.name) && !cp.name.name))
          newEmptyLines = true;
      });

      return newEmptyLines;
    };

    setDisabledSendButton(checkNewEmptyLines());
  }, [customerSales, competitorSales]);

  return (
    <Grid container item xs={12}>
      <Grid item xs={11} sm={8} className="pb-3">
        <Typography
          variant={!isMobile ? "h5" : "h6"}
          align={!isMobile ? "left" : "center"}
          className={classes.titleColor}
        >
          Lançamentos
        </Typography>
      </Grid>
      <Grid item xs={1} sm={4} className="pb-3">
        <AddNewCustomerCompetitorCard
          className="float-right"
          handleAddNewCustomer={handleAddNewCustomer}
          handleAddNewCompetitor={handleAddNewCompetitor}
        />
      </Grid>
      <Grid container item xs={12} className={classes.contentAligment}>
        <Grid item xs={12}>
          <SaleTypeTabs
            handleChangeTab={handleChangeTab}
            selectedTypeTab={selectedTypeTab}
            competitorTotals={competitorTotals}
            customerTotals={customerTotals}
            cropTotals={cropTotals}
            isMobile={isMobile}
          />
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          {handleActiveTable()}
        </Grid>
      </Grid>

      <Grid item xs={12} className="pb-4">
        <MobileStepper
          variant="dots"
          steps={3}
          position="static"
          activeStep={selectedTypeTab}
          className={classes.stepper}
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          className={!isMobile ? classes.buttonWebProperties : classes.button}
          onClick={() => onClickFinishButton()}
          disabled={entryIsLoading || disabledSendButton}
        >
          {entryIsLoading ? (
            <Loading size={20} loading={entryIsLoading} className={classes.loadingPosition} />
          ) : (
            "ENVIAR"
          )}
        </Button>
      </Grid>
      {!isEndOfScreen && (
        <FloatingButton
          isWeb={!isMobile}
          handleClick={() => onClickFinishButton()}
          icon={<Send size={20} />}
          text={!isMobile && "ENVIAR"}
          ariaLabel="sendVolume"
          loading={entryIsLoading}
          disabled={disabledSendButton}
        />
      )}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  stepper: {
    "& .MuiMobileStepper-dots": {
      margin: "auto",
      display: "flex"
    },
    "& .MuiMobileStepper-dotActive": {
      backgroundColor: SECONDARY_COLOR
    }
  },
  titleColor: {
    color: SECONDARY_COLOR
  },
  contentAligment: {
    margin: "auto",
    display: "flex",
    width: "100%",
    border: `1px solid ${PALE_BLUE_GRAY}`,
    borderRadius: theme.spacing(1)
  },
  divider: {
    height: theme.spacing(0.25),
    backgroundColor: PALE_BLUE_GRAY
  },
  button: {
    width: "180px",
    height: "56px",
    margin: "auto",
    display: "flex"
  },
  buttonWebProperties: {
    width: "180px",
    height: "56px",
    margin: "auto",
    display: "flex",
    marginRight: "0px"
  },
  loadingPosition: {
    margin: theme.spacing(2),
    position: "relative",
    color: SECONDARY_COLOR
  }
}));

export default EntriesContainer;
