import React from 'react';
import TabIconFactory from '../../shared/components/Tabs/TabIconFactory';
import { AlertCircle, BarChart2 } from 'react-feather';

export const createSellingBalanceTabsEnum = props => {
  const BarChartIcon = TabIconFactory(BarChart2);
  const AlertCircleIcon = TabIconFactory(AlertCircle);

  return {
    sellingLimits: {
      id: 0,
      name: "Limites de venda",
      icon: <AlertCircleIcon {...props} tabIndex={0} />,
    },
    forecastAndEvolution: {
      id: 1,
      name: "Forecast e evolução",
      icon: <BarChartIcon {...props} tabIndex={1} />,
    },
  };
};