import { useState, useEffect } from "react";
import BackofficeMessageManager from "../BackofficeMessageManager";

const useCreateMessageModalDirect = () => {
  const [directReasons, setDirectReasons] = useState([]);

  const fetchDirectAboutOptions = async (selectedVendorIds, term) => {
    return BackofficeMessageManager.listCropCompetitorCustomerByTermAndVendorIds(selectedVendorIds, term);
  }

  useEffect(() => {
    const getReasons = async () => {
      const data = await BackofficeMessageManager.listDirectMessageReasons();
      if (data) setDirectReasons(data);
    };
    getReasons();
  }, []);

  return {
    directReasons,
    fetchDirectAboutOptions
  };
};

export default useCreateMessageModalDirect;
