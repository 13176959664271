import React, { useState, useCallback, useEffect, useRef } from "react";
import { ChevronRight, ChevronDown } from "react-feather";
import { Divider, Grid, Typography, makeStyles } from "@material-ui/core";
import { GRAY, MID_BLUE, MID_BLUE_BACKGROUND, PALE_BLUE_GRAY, WHITE } from "../../../theme";
import {
  ItemAdjustmentMessage,
  ItemCommunicationMessage,
  ItemStandardMessage
} from "../../../shared/components/MessageNotification";
import { parseISO, format } from "date-fns";
import useInsertMessageReadDate from "../../../shared/components/MessageNotification/customHooks/useInsertMessageReadDate";
import { messageTypeSending } from "../../../shared/utils/enum";

const ItemMessage = props => {
  const { message, authenticatedUserId, handleReadMessage, selectedTab } = props;
  const [alreadyRead, setAlreadyRead] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);

  const classes = useStyles({
    isRead: message.recipientMessage[0].readDate || selectedTab === messageTypeSending.sent.id
  });

  const { insertMessageReadDate } = useInsertMessageReadDate(
    message.id,
    authenticatedUserId,
    message.recipientMessage[0].readDate
  );

  const refHandleReadMessage = useRef(handleReadMessage);
  const refMessage = useRef(message);
  const refInsertMessageReadDate = useRef(insertMessageReadDate);
  const refSelectedTab = useRef(selectedTab);

  useEffect(() => {
    refHandleReadMessage.current = handleReadMessage;
    refMessage.current = message;
    refInsertMessageReadDate.current = insertMessageReadDate;
    refSelectedTab.current = selectedTab;
  });

  useEffect(() => {
    if (alreadyRead) refHandleReadMessage.current(refMessage.current.id);
  }, [alreadyRead]);

  const openItemMessagem = useCallback(() => {
    setOpenMessage(true);
    if (
      !refMessage.current.recipientMessage[0].readDate &&
      refSelectedTab.current !== messageTypeSending.sent.id
    ) {
      refInsertMessageReadDate.current();
      setAlreadyRead(true);
    }
  }, []);

  const closeItemMessage = useCallback(() => {
    setOpenMessage(false);
  }, []);

  const getItemMessage = () => {
    if (message.adjustmentMessage) {
      return (
        <React.Fragment key={message.adjustmentMessage.id}>
          <ItemAdjustmentMessage
            state={message.adjustmentMessage.state.initials}
            crop={message.adjustmentMessage.crop.name}
            volume={message.adjustmentMessage.volume}
            isIncreasing={message.adjustmentMessage.isIncreasing}
            description={message.adjustmentMessage.descriptionMessage}
          />
          <Divider className={classes.divider} />
        </React.Fragment>
      );
    } else if (message.communicationMessage) {
      return (
        <React.Fragment key={message.communicationMessage.id}>
          <ItemCommunicationMessage description={message.communicationMessage.description} />
          <Divider className={classes.divider} />
        </React.Fragment>
      );
    } else if (message.directMessage) {
      return (
        <React.Fragment key={message.directMessage.id}>
          <ItemStandardMessage description={message.directMessage.description} />
          <Divider className={classes.divider} />
        </React.Fragment>
      );
    } else if (message.sellingBalanceMessage) {
      return (
        <React.Fragment key={message.sellingBalanceMessage.id}>
          <ItemStandardMessage description={message.sellingBalanceMessage.description} />
          <Divider className={classes.divider} />
        </React.Fragment>
      );
    }
  };

  const getDescriptionClosedCard = () => {
    if (message.adjustmentMessage) {
      return message.adjustmentMessage.reasonDescription;
    } else if (message.communicationMessage) {
      return "Novo cliente e/ou concorrente";
    } else if (message.directMessage) {
      const reason =
        message.directMessage &&
        message.directMessage.reason &&
        message.directMessage.reason.description;

      let cropCustomerCompetitor = null;

      if (message.directMessage.crop)
        cropCustomerCompetitor = `${message.directMessage.crop.name} Cultura`;

      if (message.directMessage.customer)
        cropCustomerCompetitor = `${message.directMessage.customer.name} Cliente`;

      if (message.directMessage.competitor)
        cropCustomerCompetitor = `${message.directMessage.competitor.name} Concorrente`;

      return `${reason} - ${cropCustomerCompetitor}`;
    } else if (message.sellingBalanceMessage) {
      const reason =
        message.sellingBalanceMessage &&
        message.sellingBalanceMessage.reason &&
        message.sellingBalanceMessage.reason.description;

      let siteProductGroupRawMaterial = null;

      if (message.sellingBalanceMessage.site)
        siteProductGroupRawMaterial = `${message.sellingBalanceMessage.site} Planta`;

      if (message.sellingBalanceMessage.productGroup)
        siteProductGroupRawMaterial = `${message.sellingBalanceMessage.productGroup} Produto`;

      if (message.sellingBalanceMessage.rawMaterial)
        siteProductGroupRawMaterial = `${message.sellingBalanceMessage.rawMaterial} Matéria Prima`;

      return `${reason} - ${siteProductGroupRawMaterial}`;
    }
  };

  return (
    <Grid
      container
      item
      xs={12}
      className={`${classes.itemMessage} pr-4 pl-4 pt-2 pb-2`}
      onClick={!openMessage ? openItemMessagem : closeItemMessage}
    >
      <Grid container item xs={10} className={classes.conatinerMessage}>
        <Typography className={`${classes.messageType} pr-2`}>
          {message.messageType.description}
        </Typography>
        <Typography variant="overline" className={classes.details}>
          {format(parseISO(message.sendDate), "dd/MM/yyyy")}
        </Typography>
        <Typography variant="caption" className={classes.details}>
          {getDescriptionClosedCard()}
        </Typography>
      </Grid>
      <Grid item xs={2} className={classes.contentPosition}>
        {openMessage ? (
          <ChevronDown className={`${classes.detailsIcon} hover-icon`} />
        ) : (
          <ChevronRight className={`${classes.detailsIcon} hover-icon`} />
        )}
      </Grid>
      {openMessage && getItemMessage()}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  conatinerMessage: {
    display: "flex",
    flexDirection: "column"
  },
  itemMessage: props => ({
    backgroundColor: props.isRead ? WHITE : MID_BLUE_BACKGROUND,
    borderLeft: `2Px solid ${props.isRead ? WHITE : MID_BLUE}`,
    borderBottom: `1px solid ${PALE_BLUE_GRAY}`
  }),
  messageType: {
    color: MID_BLUE,
    lineHeight: 1.6
  },
  details: {
    color: GRAY,
    lineHeight: 1.6
  },
  detailsIcon: {
    marginLeft: "auto",
    display: "flex",
    color: MID_BLUE
  },
  contentPosition: {
    alignSelf: "center"
  }
}));

export default ItemMessage;
