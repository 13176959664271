import { useState, useEffect } from "react";
import { profiles } from "../../../shared/utils/enum";
import BackofficeMessageManager from "../BackofficeMessageManager";

const useCreateMessageModalTypeStep = messageTypes => {
  const [selectedType, setSelectedType] = useState();
  const [selectedManagements, setSelectedManagements] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [vendorUsers, setVendorUsers] = useState([]);
  const [internalVendorUsers, setInternalVendorUsers] = useState([]);
  const [representativeVendorUsers, setRepresentativeVendorUsers] = useState([]);
  const [loadingUserTable, setLoadingUserTable] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedInternalUsers, setSelectedInternalUsers] = useState([]);
  const [selectedRepresentativeUsers, setSelectedRepresentativeUsers] = useState([]);
  const [includeRepresentativeUsersSelected, setIncludeRepresentativeUsersSelected] = useState(false)

  useEffect(() => {
    const getVendorUsers = async () => {
      setLoadingUserTable(true);
      const data = await BackofficeMessageManager.listUsersByVendorIds(selectedVendors.map(vendor => vendor.id));
      if (data) {
        setVendorUsers(data);
        setSelectedUsers([]);
      }
      setLoadingUserTable(false);
    };

    if (selectedVendors && selectedVendors.length) {
      getVendorUsers();
    }
    else {
      setVendorUsers([]);
    }
  }, [selectedVendors]);

  useEffect(() => {
    setVendorUsers([]);
    setSelectedUsers([]);
    setSelectedVendors([]);
  }, [selectedManagements]);
  
  const isRepresentative = user => {
    return user.profiles?.find(profile => profile.name === profiles.representative.name)
  }
  
  useEffect(() => {
    setSelectedInternalUsers(selectedUsers.filter(user => !isRepresentative(user)));
    setSelectedRepresentativeUsers(selectedUsers.filter(user => isRepresentative(user)));
  }, [selectedUsers]);

  useEffect(() => {
    setInternalVendorUsers(vendorUsers.filter(user => !isRepresentative(user)));
    setRepresentativeVendorUsers(vendorUsers.filter(user => isRepresentative(user)));
  }, [vendorUsers]);
  
  const handleSelectType = description => {
    const newType = messageTypes.find(t => t.description === description);
    setSelectedType(newType);
  };

  const handleSelectUser = user => {
    const cloneSelectedUsers = [...selectedUsers];

    if (cloneSelectedUsers.find(u => u.id === user.id))
      setSelectedUsers(cloneSelectedUsers.filter(u => u.id !== user.id));
    else {
      cloneSelectedUsers.push(user);
      setSelectedUsers([...cloneSelectedUsers]);
    }
  };

  const handleAddRemoveAllInternalUsers = () => {
    const initialValue = selectedUsers;

    if (internalVendorUsers.every(su => selectedUsers?.find(u => u.id === su.id)))
      setSelectedUsers(initialValue.filter(u => isRepresentative(u)));
    else 
      setSelectedUsers([...new Set([...initialValue, ...internalVendorUsers])]);
  };

  const handleAddRemoveAllRepresentativeUsers = () => {
    const initialValue = selectedUsers;

    if (representativeVendorUsers.every(su => selectedUsers?.find(u => u.id === su.id))) 
      setSelectedUsers(initialValue.filter(u => !isRepresentative(u)));
    else 
      setSelectedUsers([...new Set([...initialValue, ...representativeVendorUsers])]);
  };

  const removeAllRepresentativeUsersSelections = () => {
    const initialValue = selectedUsers;
    setSelectedUsers(initialValue.filter(u => !isRepresentative(u)));
  };

  const handleIncludeRepresentativeUserChange = () => {
    const newValue = !includeRepresentativeUsersSelected;
    
    if (!newValue) 
      removeAllRepresentativeUsersSelections();

    setIncludeRepresentativeUsersSelected(newValue);
  }

  return {
    selectedType,
    handleSelectType,
    selectedVendors,
    setSelectedVendors,
    selectedManagements,
    setSelectedManagements,
    setSelectedType,
    internalVendorUsers,
    representativeVendorUsers,
    loadingUserTable,
    selectedUsers,
    selectedInternalUsers,
    selectedRepresentativeUsers,
    includeRepresentativeUsersSelected,
    handleSelectUser,
    handleAddRemoveAllInternalUsers,
    handleAddRemoveAllRepresentativeUsers,
    handleIncludeRepresentativeUserChange,
    setVendorUsers,
    setRepresentativeVendorUsers,
    setInternalVendorUsers,
    setSelectedUsers,
    setSelectedInternalUsers,
    setSelectedRepresentativeUsers,
    setIncludeRepresentativeUsersSelected
  };
};

export default useCreateMessageModalTypeStep;
