const formatDataToCreateMessage = (
  userSenderId,
  vendors,
  recipientUsers
) => {
  return {
    userSender: {
      id: userSenderId
    },
    vendors: vendors.map(vendor => ({
      id: vendor.id
    })),
    recipientMessage: recipientUsers
  };
};

export const formatDataToCreateAdjustmentMessage = (
  userSenderId,
  vendors,
  recipientUsers,
  adjustmentData
) => {
  return {
    ...formatDataToCreateMessage(userSenderId, vendors, recipientUsers),
    adjustmentMessage: adjustmentData
  };
};

export const formatRecipientUsers = selectedUsers =>
  selectedUsers.map(u => ({ user: { id: u.id } }));

export const formatAdjustmentData = (
  description,
  adjustmentReason,
  volume,
  isIncreasing,
  stateId,
  cropId
) => {
  return {
    descriptionMessage: description || null,
    adjustmentMessageReason: adjustmentReason,
    volume: parseFloat(volume.replace(",", ".")),
    isIncreasing: isIncreasing === "increasing",
    state: { id: stateId },
    crop: { id: cropId }
  };
};

export const formatDataToCreateDirectMessage = (
  userSenderId,
  vendors,
  directMessage,
  recipientUsers
) => {
  return {
    ...formatDataToCreateMessage(userSenderId, vendors, recipientUsers),
    directMessage: directMessage
  };
};

export const formatDataToCreateSellingBalanceMessage = (
  userSenderId,
  vendors,
  sellingBalanceMessage,
  recipientUsers
) => {
  return {
    ...formatDataToCreateMessage(userSenderId, vendors, recipientUsers),
    sellingBalanceMessage: sellingBalanceMessage
  };
};

export const formatStandardMessage = (reasonId, description, about) => {
  let standardMessage = {
    reason: {
      id: reasonId
    },
    description: description
  };

  switch (about.entityType) {
    case "Cliente":
      standardMessage.customer = {
        id: about.id
      };
      break;
    case "Concorrente":
      standardMessage.competitor = {
        id: about.id
      };
      break;
    case "Cultura":
      standardMessage.crop = {
        id: about.id
      };
      break;
    case "Produto":
      standardMessage.productGroup = about.name;
      break;
    case "Matéria Prima":
      standardMessage.rawMaterial = about.name;
      break;
    case "Planta":
      standardMessage.site = about.name;
      break;
    default:
      return null;
  }

  return standardMessage;
};
