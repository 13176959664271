import {
  Divider,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TablePagination,
  Typography
} from "@material-ui/core";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import TablePaginationActions from "../../../shared/components/CustomTable/TablePaginationActions";
import Loading from "../../../shared/components/Loading";
import NoResults from "../../../shared/img/NoResults.svg";
import { DataType } from "../../../shared/utils/enum";
import { GRAY, MID_BLUE, PALE_BLUE_GRAY } from "../../../theme";
import * as action from "../../alertMessage/redux/alertMessageActions";
import useListCompetitor from "../customHooks/useListCompetitor";
import useListCrop from "../customHooks/useListCrop";
import useListCustomer from "../customHooks/useListCustomer";
import AddLine from "./AddLine";
import EditableLine from "./EditableLine";
import EditTypeTabs from "./EditTypeTabs";

const CustomTable = props => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pageElements, setPageElements] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [selectedTypeTab, setSelectedTypeTab] = useState(0);
  const [selectedTabHasType, setSelectedTabHasType] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [searchNameParameter, setSearchNameParameter] = useState("");

  const { authenticatedUserId, showSuccessNotification } = props;

  const {
    listCompetitor: {
      pageElements: pageElementsCompetitor,
      totalElements: totalElementsCompetitor
    },
    loadingData: loadingCompetitor,
    handleListCompetitor
  } = useListCompetitor();

  const {
    listCustomer: { pageElements: pageElementsCustomer, totalElements: totalElementsCustomer },
    loadingData: loadingCustomer,
    handleListCustomer
  } = useListCustomer();

  const {
    listCrop: { pageElements: pageElementsCrop, totalElements: totalElementsCrop },
    loadingData: loadingCrop,
    handleListCrop
  } = useListCrop();

  const loadData = useCallback(
    (loadBySelectedTab = false) => {
      setTotalElements(0);
      setPageElements([]);

      const searchName = loadBySelectedTab ? "" : searchNameParameter;

      if (selectedTypeTab === DataType["competitor"].id)
        handleListCompetitor(searchName.trim(), rowsPerPage, page);

      if (selectedTypeTab === DataType["customer"].id)
        handleListCustomer(searchName.trim(), rowsPerPage, page);

      if (selectedTypeTab === DataType["crop"].id)
        handleListCrop(searchName.trim(), rowsPerPage, page);
    },
    [
      searchNameParameter,
      rowsPerPage,
      page,
      selectedTypeTab,
      handleListCompetitor,
      handleListCustomer,
      handleListCrop
    ]
  );

  const refLoadData = useRef(loadData);
  useEffect(() => {
    refLoadData.current = loadData;
  });

  const handleSelectTypeTab = (event, tab) => {
    setSelectedTypeTab(tab);
    setPage(0);
  };

  const handleChangeRowsPerPage = newRowsPerPage => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleChangePage = newPage => {
    setPage(newPage);
  };

  useEffect(() => {
    if (selectedTypeTab === DataType["competitor"].id) {
      setPageElements(pageElementsCompetitor);
      setTotalElements(totalElementsCompetitor);
    }
    if (selectedTypeTab === DataType["customer"].id) {
      setPageElements(pageElementsCustomer);
      setTotalElements(totalElementsCustomer);
    }
    if (selectedTypeTab === DataType["crop"].id) {
      setPageElements(pageElementsCrop);
      setTotalElements(totalElementsCrop);
    }
  }, [
    pageElementsCompetitor,
    totalElementsCompetitor,
    pageElementsCustomer,
    totalElementsCustomer,
    pageElementsCrop,
    totalElementsCrop,
    selectedTypeTab
  ]);

  useEffect(() => {
    setLoading(loadingCompetitor || loadingCustomer || loadingCrop);
  }, [loadingCompetitor, loadingCustomer, loadingCrop]);

  useEffect(() => {
    setSelectedTabHasType(
      selectedTypeTab === DataType["customer"].id || selectedTypeTab === DataType["crop"].id
    );
  }, [selectedTypeTab]);

  useEffect(() => {
    refLoadData.current();
  }, [searchNameParameter, page, rowsPerPage]);

  useEffect(() => {
    refLoadData.current(true);
  }, [selectedTypeTab]);

  return (
    <Grid item xs={12}>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <EditTypeTabs
            selectedTypeTab={selectedTypeTab}
            handleChangeTab={handleSelectTypeTab}
            disabled={loading}
          />
          <Divider className={classes.divider} />
        </Grid>
        <Grid container item xs={12}>
          <Table size="small" item xs={12} className={classes.table}>
            <TableBody>
              <AddLine
                authenticatedUserId={authenticatedUserId}
                setPage={setPage}
                loadData={loadData}
                setSearchNameParameter={setSearchNameParameter}
                selectedTypeTab={selectedTypeTab}
                searchNameParameter={searchNameParameter}
                selectedTabHasType={selectedTabHasType}
                showSuccessNotification={showSuccessNotification}
              />

              {pageElements &&
                pageElements.map((d, index) => (
                  <EditableLine
                    key={`row ${index}`}
                    loadData={loadData}
                    authenticatedUserId={authenticatedUserId}
                    selectedTypeTab={selectedTypeTab}
                    index={index}
                    entity={d}
                    selectedTabHasType={selectedTabHasType}
                    showSuccessNotification={showSuccessNotification}
                  />
                ))}
            </TableBody>
          </Table>

          {loading && <Loading loading />}

          {!loading && totalElements === 0 && (
            <Grid container>
              <Grid item xs={12} className="pt-6">
                <img
                  src={NoResults}
                  alt="Nenhum resultado encontrado"
                  className={classes.noResults}
                />
              </Grid>
              <Grid item xs={12} className="pb-6">
                <Typography className={`h7 ${classes.midBlueColor}`} align="center">
                  Não foram encontrados
                  <br />
                  resultados para esta pesquisa.
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {!loading && totalElements > 0 && (
        <TablePagination
          align="right"
          rowsPerPageOptions={[5, 10, 20, 30]}
          component="div"
          count={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={(e, newPage) => handleChangePage(newPage)}
          onChangeRowsPerPage={e => handleChangeRowsPerPage(e.target.value)}
          ActionsComponent={TablePaginationActions}
          labelRowsPerPage="Itens por página:"
          classes={{ caption: classes.grayColor }}
        />
      )}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  table: {
    tableLayout: "fixed"
  },
  container: {
    borderColor: PALE_BLUE_GRAY,
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "8px",
    borderCollapse: "inherit"
  },
  divider: {
    height: 1,
    backgroundColor: PALE_BLUE_GRAY
  },
  midBlueColor: {
    color: MID_BLUE
  },
  noResults: {
    width: 50,
    height: 50,
    display: "flex",
    margin: "auto"
  },
  grayColor: {
    color: GRAY
  }
}));

const mapStateToProps = state => {
  return {
    authenticatedUserId: state.user.id
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showSuccessNotification: message => dispatch(action.showSuccessNotification(message))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomTable);
