import { useState, useEffect, useCallback } from "react";
import BackofficeMessageManager from "../BackofficeMessageManager";
import {
  readingStatus,
  managementInitials,
  vendorInitials,
  messageType
} from "../../../shared/utils/enum";
import moment from "moment";

const useMessagesFilter = (selectedTab, userId) => {
  const [selectedManagement, setSelectedManagement] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedReadingStatus, setSelectedReadingStatus] = useState(readingStatus[0].value);
  const [messages, setMessages] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [reloadMessages, setReloadMessages] = useState(true);
  const [selectedRangeDate, setSelectedRangeDate] = useState({
    startDate: moment().add(-15, "days"),
    endDate: moment()
  });
  const [loadingExport, setloadingExport] = useState(false);

  const getMessages = useCallback(
    async parameters => {
      setLoadingData(true);

      let result = null;

      switch (selectedTab) {
        case messageType.adjustment.tabValue:
          result = await BackofficeMessageManager.listAdjustmentMessages(parameters);
          break;
        case messageType.sellingBalance.tabValue:
            result = await BackofficeMessageManager.listSellingBalanceMessages(parameters);
            break;
        case messageType.sentDirect.tabValue:
          result = await BackofficeMessageManager.listDirectMessagesSentByBackoffice(parameters);
          break;
        case messageType.receivedDirect.tabValue:
          result = await BackofficeMessageManager.listDirectMessagesSentByConsultant(
            userId,
            parameters
          );
          break;
        default:
          return null;
      }

      if (result) setMessages(result);

      setLoadingData(false);
      setReloadMessages(false);
    },
    [selectedTab, userId]
  );

  const getFilters = () => {
    const parameters = [];

    if (selectedManagement.initials !== managementInitials.all)
      parameters.push({ label: "managementId", value: selectedManagement.id });

    if (selectedVendor.initials !== vendorInitials.all)
      parameters.push({ label: "vendorId", value: selectedVendor.id });

    if (selectedReadingStatus !== readingStatus[0].value)
      parameters.push({ label: "read", value: selectedReadingStatus });

    if (selectedRangeDate) {
      parameters.push({ label: "startDate", value: selectedRangeDate.startDate });
      parameters.push({ label: "endDate", value: selectedRangeDate.endDate });
    }

    return parameters;
  };

  const handleSubmitFilter = async () => {
    const parameters = getFilters();

    getMessages(parameters);
  };

  const handleExportMessageReport = async () => {
    const parameters = getFilters();

    parameters.push({ label: "userId", value: userId });

    setloadingExport(true);

    await BackofficeMessageManager.listReportMessages(parameters);

    setloadingExport(false);
  };

  useEffect(() => {
    const getMessage = async () => {
      const parameters = [];
      parameters.push({ label: "startDate", value: selectedRangeDate.startDate });
      parameters.push({ label: "endDate", value: selectedRangeDate.endDate });

      getMessages(parameters);
    };

    if (reloadMessages && selectedRangeDate) getMessage();
  }, [reloadMessages, selectedRangeDate, getMessages]);

  useEffect(() => {
    setReloadMessages(true);
  }, [selectedTab]);

  const insertMessageReadDate = messageId => {
    const cloneMessages = [...messages];
    cloneMessages.forEach(m => {
      if (m.id === messageId) m.recipientMessage[0].readDate = new Date().toISOString();
    });
    setMessages(cloneMessages);
  };

  return {
    selectedVendor,
    setSelectedVendor,
    selectedManagement,
    setSelectedManagement,
    selectedReadingStatus,
    setSelectedReadingStatus,
    messages,
    handleSubmitFilter,
    handleExportMessageReport,
    loadingData,
    setReloadMessages,
    selectedRangeDate,
    setSelectedRangeDate,
    setMessages,
    insertMessageReadDate,
    loadingExport
  };
};

export default useMessagesFilter;
