import React, { useState } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { PALE_BLUE_GRAY, SECONDARY_COLOR, GRAY } from "../../../theme";
import { FileText, AlertTriangle } from "react-feather";
import WeekPicker from "./WeekPicker";
import EntriesContainer from "./EntriesContainer";
import InvalidInsertionMessage from "./InvalidInsertionMessage";
import StateTabs from "./StateTabs";
import Modal from "../../../shared/components/Modal";
import ConfirmationModal from "../../../shared/components/ConfirmationModal";
import PageTitle from "../../../shared/components/PageTitle";
import SuccessSendVolumeIcon from "../../../shared/img/SuccessSendVolumeIcon.svg";
import ErrorSendVolumeIcon from "../../../shared/img/ErrorSendVolumeIcon.svg";
import { useSaleTypeTabs } from "../customHooks/useSaleTypeTabs";
import { useWeeks } from "../customHooks/useWeeks";
import { useCompetitorSales } from "../customHooks/useCompetitorSales";
import { useCropSales } from "../customHooks/useCropSales";
import { useCustomerSales } from "../customHooks/useCustomerSales";
import { useStateTabs } from "../customHooks/useStateTabs";
import { useModals } from "../customHooks/useModals";
import { startOfWeek } from "date-fns";
import { connect } from "react-redux";
import { screenSizes } from "../../../shared/utils/enum";
import { numberWithSeparators } from "../../../shared/utils/Utils";
import SolidMarketManager from "../SolidMarketManager";
import { FormatDataToUpdate } from "../FormatDataToUpdate";
import extenso from "extenso";
import GeneralLoading from "../../../shared/components/GeneralLoading";
import { usePageScroll } from "../../../shared/utils/usePageScroll";

const SolidMarket = props => {
  const classes = useStyles();

  const { windowWidth, selectedVendor, userId } = props;

  const [entryIsLoading, setEntryIsLoading] = useState(false);
  const [reloadScreen, setReloadScreen] = useState(false);

  const { isEndOfScreen } = usePageScroll();

  const isMobile = windowWidth < screenSizes.webScreenSize;

  const { weekNumber, selectedDate, setWeekNumber, setSelectedDate } = useWeeks();

  const {
    setUpdateStateTotalsSelling,
    selectedStateVendor,
    statesInfo,
    numSteppers,
    selectedStateTab,
    setSelectedStateTab,
    totalPending,
    pageShouldRerender,
    stateTabsAreReady,
    updatedTotalsAreReady,
    setUpdatedTotalsAreReady
  } = useStateTabs(selectedDate, selectedVendor);

  const {
    selectedTypeTab,
    setSelectedTypeTabs,
    handleChangeTab,
    customerTotalVolume,
    competitorTotalVolume,
    cropTotalVolume,
    updateTotalVolume
  } = useSaleTypeTabs();

  const {
    competitorSales,
    setCompetitorSales,
    competitorTotals,
    loadingCompetitorTable,
    updateCompetitorData
  } = useCompetitorSales(
    selectedDate,
    selectedStateVendor,
    updateTotalVolume,
    isMobile,
    reloadScreen
  );

  const {
    handleSuccessModalOpenClose,
    handleErrorModalOpenClose,
    handleOverVolumeModalOpenClose,
    successModalOpen,
    errorModalOpen,
    overVolumeModalOpen,
    yearWithWrongVolume
  } = useModals(setUpdateStateTotalsSelling);

  const {
    customerSales,
    setCustomerSales,
    customerTotals,
    updateCustomerData,
    loadingCustomerTable
  } = useCustomerSales(
    selectedDate,
    selectedStateVendor,
    updateTotalVolume,
    isMobile,
    reloadScreen
  );

  const { cropSales, cropTotals, loadingCropTable, updateCropsData } = useCropSales(
    selectedDate,
    selectedStateVendor,
    updateTotalVolume,
    isMobile,
    reloadScreen
  );

  const insertionDayIsInvalid =
    new Date().getDay() < 2 &&
    startOfWeek(new Date()).getTime() === startOfWeek(selectedDate).getTime();

  const backToLastWeek = () => {
    setWeekNumber(weekNumber - 1);
    setSelectedDate(selectedDate.setDate(selectedDate.getDate() - 7));
    setUpdatedTotalsAreReady(false);
  };

  const finishEntryVolume = async () => {
    setEntryIsLoading(true);

    const result = await SolidMarketManager.updateSellings(
      FormatDataToUpdate(
        cropSales,
        competitorSales,
        customerSales,
        selectedDate,
        userId,
        selectedVendor,
        selectedStateVendor
      )
    );

    if (result) handleSuccessModalOpenClose();
    else setEntryIsLoading(false);
  };

  const closeSuccessModal = () => {
    handleSuccessModalOpenClose();
    setReloadScreen(!reloadScreen);
    setEntryIsLoading(false);
  };

  return stateTabsAreReady &&
    cropSales &&
    competitorSales &&
    customerSales &&
    updatedTotalsAreReady ? (
    <Grid container className={!isMobile ? "pl-8 pt-8 pr-8 pb-4" : "pl-4 pt-8 pr-4 pb-4"}>
      <PageTitle
        title="Mercado Sólido"
        subtitle="Volume em mil toneladas"
        icon={
          <FileText
            color={SECONDARY_COLOR}
            size={!isMobile ? 36 : 32}
            className={`${classes.iconSolidMarket} pr-1`}
          />
        }
        isMobile={isMobile}
      />
      <WeekPicker
        setWeekNumber={setWeekNumber}
        weekNumber={weekNumber}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        isMobile={isMobile}
      />
      {insertionDayIsInvalid ? (
        <InvalidInsertionMessage backToLastWeek={backToLastWeek} />
      ) : (
        <>
          <StateTabs
            isMobile={isMobile}
            statesInfo={statesInfo}
            numSteppers={numSteppers}
            selectedStateTab={selectedStateTab}
            setSelectedStateTab={setSelectedStateTab}
            totalPending={totalPending}
            pageShouldRerender={pageShouldRerender}
          />

          <EntriesContainer
            selectedTypeTab={selectedTypeTab}
            setSelectedTypeTabs={setSelectedTypeTabs}
            handleChangeTab={handleChangeTab}
            competitorSales={competitorSales}
            setCompetitorSales={setCompetitorSales}
            competitorTotals={competitorTotals}
            customerSales={customerSales}
            setCustomerSales={setCustomerSales}
            customerTotals={customerTotals}
            cropSales={cropSales}
            cropTotals={cropTotals}
            loadingCompetitorTable={loadingCompetitorTable}
            loadingCustomerTable={loadingCustomerTable}
            loadingCropTable={loadingCropTable}
            handleErrorModalOpenClose={handleErrorModalOpenClose}
            handleOverVolumeModalOpenClose={handleOverVolumeModalOpenClose}
            updateCompetitorData={updateCompetitorData}
            updateCustomerData={updateCustomerData}
            updateCropsData={updateCropsData}
            competitorTotalVolume={competitorTotalVolume}
            cropTotalVolume={cropTotalVolume}
            customerTotalVolume={customerTotalVolume}
            isMobile={isMobile}
            entryIsLoading={entryIsLoading}
            finishEntryVolume={finishEntryVolume}
            isEndOfScreen={isEndOfScreen}
          />
        </>
      )}
      <Modal
        primaryButton={{
          action: () => closeSuccessModal(),
          text: "FECHAR"
        }}
        openedModal={successModalOpen}
        closeModal={() => closeSuccessModal()}
        title="Mercado sólido enviado!"
        titleIcon={<img src={SuccessSendVolumeIcon} alt="Success send volume" aria-hidden="true" />}
        closeIcon
      >
        <Typography align="center" className={classes.colorSubtitle}>
          O volume de mercado sólido foi enviado com sucesso.
        </Typography>
      </Modal>
      <Modal
        primaryButton={{
          action: () => handleErrorModalOpenClose(),
          text: "FECHAR"
        }}
        openedModal={errorModalOpen}
        closeModal={handleErrorModalOpenClose}
        title="Não foi possivel enviar!"
        titleIcon={<img src={ErrorSendVolumeIcon} alt="error send volume" aria-hidden="true" />}
        closeIcon
      >
        <Typography align="center" className={classes.colorSubtitle}>
          {`Os volumes totais referentes ao(s) ano(s) de ${yearWithWrongVolume} 
          devem ser iguais para concorrentes, clientes e culturas.`}
        </Typography>
      </Modal>
      <ConfirmationModal
        openedModal={overVolumeModalOpen}
        title="Volume elevado"
        subtitle={
          <>
            Tem certeza que o volume a ser enviado é de <br />
            <span className={`${classes.alertMessage} body3`}>
              {numberWithSeparators(competitorTotalVolume)} (
              {extenso(numberWithSeparators(competitorTotalVolume), {
                number: { decimal: "informal" }
              })}
              ) mil toneladas?
            </span>
          </>
        }
        secondaryButtonText="NÃO"
        secondaryAction={handleOverVolumeModalOpenClose}
        primaryButtonText="SIM"
        primaryAction={() => {
          finishEntryVolume();
          handleOverVolumeModalOpenClose();
        }}
        handleOpenClose={handleOverVolumeModalOpenClose}
        titleIcon={<AlertTriangle size={48} color={SECONDARY_COLOR} />}
      />
    </Grid>
  ) : (
    <GeneralLoading />
  );
};

const useStyles = makeStyles(theme => ({
  iconSolidMarket: {
    verticalAlign: "bottom"
  },
  contentAligment: {
    width: "100%",
    border: `1px solid ${PALE_BLUE_GRAY}`,
    borderRadius: theme.spacing(1)
  },
  alertMessage: {
    color: SECONDARY_COLOR
  },
  Button: {
    width: "196px",
    height: "56px",
    margin: "auto",
    display: "flex"
  },
  colorSubtitle: {
    color: GRAY
  }
}));

const mapStateToProps = state => {
  return {
    windowWidth: state.window.width,
    selectedVendor: state.selectedVendor.id,
    userId: state.user.id
  };
};

export default connect(mapStateToProps, null)(SolidMarket);
