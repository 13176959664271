import { useState, useEffect } from "react";
import NextYearOrderbookManager from "../NextYearOrderbookManager";
import { getTotalVolume, updateBodyInfo } from "../TableDataHelper";
import { DataType } from "../../../shared/utils/enum";

export const useCropNextYearOrderbook = (selectedStateVendor, updateTotalVolume) => {
  const [cropNextYearOrderbook, setCropNextYearOrderbook] = useState();
  const [cropTotal, setCropTotal] = useState();
  const [loadingCropTable, setloadingCropTable] = useState(false);

  useEffect(() => {
    if (cropTotal != null) {
      updateTotalVolume(DataType["crop"].id, cropTotal);
    }
  }, [cropTotal, updateTotalVolume]);

  useEffect(() => {
    const getData = async () => {
      setloadingCropTable(true);
      setCropNextYearOrderbook();
      setCropTotal();
      const data = await NextYearOrderbookManager.listCropNextYearOrderbook(
        selectedStateVendor,
        new Date().getFullYear()
      );

      if (data) {
        setCropNextYearOrderbook(data);
        updateTotals(data);
      } else {
        updateTotals([]);
      }

      setloadingCropTable(false);
    };
    if (selectedStateVendor) getData();
  }, [selectedStateVendor]);

  const updateTotals = data => {
    const totals = getTotalVolume(data);
    setCropTotal(totals);
  };

  const updateCropData = (rowId, columnId, newValue) => {
    const newData = updateBodyInfo(cropNextYearOrderbook, rowId, columnId, newValue);

    if (newData) {
      setCropNextYearOrderbook(newData);
      updateTotals(newData);
    }
  };

  return {
    cropNextYearOrderbook,
    loadingCropTable,
    cropTotal,
    updateCropData
  };
};
