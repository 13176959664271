import CustomerTypeService from "../../../services/CustomerTypeService";

class NewCustomerCompetitorManager {
  async listCustomerTypes() {
    return await CustomerTypeService.listCustomerTypes().then(
      response => response && response.data
    );
  }
}

export default new NewCustomerCompetitorManager();
