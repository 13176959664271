import { useState, useEffect } from "react";
import SolidMarketManager from "../SolidMarketManager";
import { getCurrentAndNextsYearsTotal, updateBodyInfo } from "../TableDataHelper";
import { DataType } from "../../../shared/utils/enum";
import { startOfWeek, endOfWeek, format } from "date-fns";

export const useCustomerSales = (
  selectedDate,
  stateVendorId,
  updateTotalVolume,
  isMobile,
  reloadScreen
) => {
  const [customerSales, setCustomerSales] = useState();
  const [customerTotals, setCustomerTotals] = useState();
  const [loadingCustomerTable, setLoadingCustomerTable] = useState(false);

  useEffect(() => {
    if (customerTotals) {
      updateTotalVolume(
        DataType["customer"].id,
        customerTotals.currentYearTotal,
        customerTotals.firstNextYearTotal,
        customerTotals.secondNextYearTotal,
        customerTotals.thirdNextYearTotal
      );
    }
  }, [customerTotals, updateTotalVolume]);

  useEffect(() => {
    const getData = async () => {
      setLoadingCustomerTable(true);
      setCustomerSales();
      setCustomerTotals({
        currentYearTotal: 0,
        firstNextYearTotal: 0,
        secondNextYearTotal: 0,
        thirdNextYearTotal: 0
      });

      const startDate = format(startOfWeek(selectedDate), "yyyy-MM-dd");
      const endDate = format(endOfWeek(selectedDate), "yyyy-MM-dd");

      const data = await SolidMarketManager.listCustomerSellings(stateVendorId, startDate, endDate);

      if (data) {
        setCustomerSales(data);
        updateTotals(data);
      }

      setLoadingCustomerTable(false);
    };

    stateVendorId && getData();
  }, [selectedDate, stateVendorId, reloadScreen]);

  const updateTotals = data => {
    const totals = getCurrentAndNextsYearsTotal(data);
    setCustomerTotals(totals);
  };

  const updateCustomerData = (rowId, columnId, newValue, nextYear) => {
    const newData = updateBodyInfo(customerSales, rowId, columnId, newValue, isMobile, nextYear);

    if (newData) {
      setCustomerSales(newData);
      updateTotals(newData);
    }
  };

  return {
    customerSales,
    setCustomerSales,
    customerTotals,
    loadingCustomerTable,
    updateCustomerData
  };
};
