import React, { useState } from "react";
import {
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  makeStyles, 
  Checkbox
} from "@material-ui/core";
import { GRAY, PRIMARY_COLOR, PALE_BLUE_GRAY } from "../../../theme";
import ManagementVendorSelects from "../../../shared/components/ManagementVendorSelects";
import SelectableTable from "../../../shared/components/CustomTable/SelectableTable";
import { generateInternalUserTableHeaders, generateRepresentativeUserTableHeaders } from "../FormatTableData";
import NoItem from "../../../shared/components/NoItem";
import CheckedCheckbox from "../../../shared/img/CheckedCheckbox.svg";
import { messageType } from "../../../shared/utils/enum";
import { Briefcase } from "react-feather";

const CreateMessageModalTypeStep = props => {
  const {
    messageTypes,
    selectedType,
    setSelectedType,
    selectedVendors,
    setSelectedVendors,
    selectedManagements,
    setSelectedManagements,
    internalVendorUsers,
    representativeVendorUsers,
    loadingUserTable,
    selectedInternalUsers,
    selectedRepresentativeUsers,
    includeRepresentativeUsersSelected,
    handleSelectUser,
    handleAddRemoveAllInternalUsers,
    handleAddRemoveAllRepresentativeUsers,
    handleIncludeRepresentativeUserChange
  } = props;

  const classes = useStyles();
  
  const [shoudReloadManagements, setShoudReloadManagements] = useState(true);

  return (
    <Grid container className="pt-4">
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.colorGray}>
          Qual o tipo da mensagem?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="type"
            name="type"
            value={selectedType && selectedType.description}
            onChange={e => setSelectedType(e.target.value)}
          >
            {messageTypes &&
              messageTypes.map(
                m =>
                  m.description !== messageType.communication.description && (
                    <FormControlLabel
                      key={m.id}
                      value={m.description}
                      control={<Radio className={classes.radio} />}
                      label={<Typography className={classes.colorGray}>{m.description}</Typography>}
                      labelPlacement="end"
                      color="primary"
                    />
                  )
              )}
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} className="pt-2">
        <Typography variant="body1" className={classes.colorGray}>
          Para quem e onde enviaremos?
        </Typography>
      </Grid>
      <Grid container item xs={8} className="pt-3">
        <ManagementVendorSelects
          selectedManagement={selectedManagements}
          setSelectedManagement={setSelectedManagements}
          selectedVendor={selectedVendors}
          setSelectedVendor={setSelectedVendors}
          gridSize={4}
          shoudReloadManagements={shoudReloadManagements}
          setShoudReloadManagements={setShoudReloadManagements}
          active
          multipleManagements
          multipleVendors
        />
      </Grid>
      <Grid item xs={12} className={`${classes.tableContainer} mt-4`}>
        <SelectableTable
          isLoading={loadingUserTable}
          headerInfos={generateInternalUserTableHeaders()}
          bodyInfos={internalVendorUsers}
          selectedItens={selectedInternalUsers}
          selectRemoveElement={handleSelectUser}
          selectRemoveAllElements={handleAddRemoveAllInternalUsers}
          noDataComponent={() => (
            <NoItem
              icon={
                <Briefcase
                  size={32}
                  color={PRIMARY_COLOR}
                  className={classes.iconTableWithoutData}
                />
              }
              text="Selecione uma consultoria"
              textColor={PRIMARY_COLOR}
            />
          )}
        />
      </Grid>
      { 
        selectedType?.id === messageType.sellingBalance.id && (
          <Grid item container xs={12}>
            <Grid item container xs={12} className="pt-3" flexDirection="column" alignItems="center">
              <Checkbox 
                className={classes.radio} 
                onClick={handleIncludeRepresentativeUserChange}
                checked={includeRepresentativeUsersSelected}
                checkedIcon={
                  <img
                    src={CheckedCheckbox}
                    alt="Checked icon"
                    className={classes.checkdIcon}
                  />
                }
              />
              <Typography variant="body1" className={classes.colorGray}>
                Incluir Representantes
              </Typography>
            </Grid>
            {
              includeRepresentativeUsersSelected && (
                <Grid item xs={12} className={`${classes.tableContainer} mt-4`}>
                  <SelectableTable
                    isLoading={loadingUserTable}
                    headerInfos={generateRepresentativeUserTableHeaders()}
                    bodyInfos={representativeVendorUsers}
                    selectedItens={selectedRepresentativeUsers}
                    selectRemoveElement={handleSelectUser}
                    selectRemoveAllElements={handleAddRemoveAllRepresentativeUsers}
                    noDataComponent={() => (
                      <NoItem
                        icon={
                          <Briefcase
                            size={32}
                            color={PRIMARY_COLOR}
                            className={classes.iconTableWithoutData}
                          />
                        }
                        text="Selecione uma consultoria"
                        textColor={PRIMARY_COLOR}
                      />
                    )}
                  />
                </Grid>
              )
            }
          </Grid>
        )
      }

    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  colorGray: {
    color: GRAY
  },
  radio: {
    "&.Mui-checked": {
      color: PRIMARY_COLOR
    }
  },
  tableContainer: {
    height: "126px",
    border: `1px solid ${PALE_BLUE_GRAY}`,
    borderRadius: "8px",
    overflowY: "auto",
    overflowX: "hidden"
  },
  iconTableWithoutData: {
    display: "flex",
    margin: "auto"
  },
  checkdIcon: {
    width: "22px",
    height: "24px",
    marginLeft: "1px",
    marginRight: "1px"
  },
}));

export default CreateMessageModalTypeStep;
