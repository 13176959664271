import React from "react";
import { connect } from "react-redux";
import { Grid, Typography, makeStyles, Button } from "@material-ui/core";
import { Briefcase } from "react-feather";
import * as action from "../../alertMessage/redux/alertMessageActions";
import { SECONDARY_COLOR, GRAY } from "../../../theme";
import useEditVendor from "../customHooks/useEditVendor";
import useEditVendorData from "../customHooks/useEditVendorData";
import useEditVendorStates from "../customHooks/useEditVendorStates";
import useEditVendorTable from "../customHooks/useEditVendorTable";
import useEditVendorSave from "../customHooks/useEditVendorSave";
import EditVendorData from "./EditVendorData";
import EditVendorStateTabs from "./EditVendorStateTabs";
import EditVendorTable from "./EditVendorTable";
import AddStateModal from "./AddStateModal";
import Loading from "../../../shared/components/Loading";
import ConfirmationModal from "../../../shared/components/ConfirmationModal";
import Modal from "../../../shared/components/Modal";
import { X, Trash2 } from "react-feather";
import SuccessSaveVendor from "../../../shared/img/SuccessSaveVendor.svg";
import { Redirect } from "react-router-dom";
import { routes } from "../../../shared/utils/Constants";
import PageTitle from "../../../shared/components/PageTitle";

const EditVendor = props => {
  const {
    match: { params: routeParams },
    showNotification
  } = props;

  const { vendorId } = routeParams;

  const classes = useStyles();

  const {
    initials,
    setInitials,
    description,
    setDescription,
    managements,
    setManagements,
    selectedManagement,
    setSelectedManagement,
    status,
    setStatus
  } = useEditVendorData();

  const {
    states,
    setStates,
    statesList,
    setStatesList,
    handleAddState,
    handleOpenCloseAddStateModal,
    addStateModalOpen,
    setAddSelectedState,
    addSelectedState,
    selectedState,
    handleChangeSelectedStateTab,
    removeStateModalOpen,
    handleOpenCloseRemoveStateModal,
    handleConfirmRemoveStateModal
  } = useEditVendorStates(showNotification);

  const { pageLoading, redirect, setRedirect } = useEditVendor(
    vendorId,
    setInitials,
    setDescription,
    setManagements,
    setSelectedManagement,
    setStatus,
    setStatesList,
    setStates
  );

  const {
    selectedTypeTab,
    handleChangeTab,
    handleOpenCloseConfirmDeleteItemModal,
    confirmDeleteTableItemModal,
    getSelectedTypeName,
    handleConfirmDeleteItem,
    setSelectedItemToBeAdded,
    selectedItemToBeAdded,
    handleAddItem,
    shouldClearAutocomplete,
    loadingHasVolume,
    itemHasVolume
  } = useEditVendorTable(states, setStates, selectedState, showNotification);

  const { successModalOpen, handleSaveVendorEdits, loadingSaveButton } = useEditVendorSave(
    initials,
    description,
    status,
    selectedManagement,
    states,
    vendorId
  );

  const redirectToAssociationsManagement = () =>
    setRedirect(<Redirect to={routes.associationsManagement} />);

  return (
    <Grid container className={`pl-8 pt-5 pr-8 pb-4`}>
      {pageLoading ? (
        <Loading size={50} loading className={classes.loadingPosition} />
      ) : (
        <>
          <Grid container item xs={12} spacing={2} className="pb-3">
            <Grid item xs={12}>
              <PageTitle
                title="Consultoria"
                icon={
                  <Briefcase
                    color={SECONDARY_COLOR}
                    size={56}
                    className={`pr-4 pt-3 ${classes.icon}`}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Dados</Typography>
            </Grid>
          </Grid>
          <Grid container className="pb-2">
            <EditVendorData
              initials={initials}
              setInitials={setInitials}
              description={description}
              setDescription={setDescription}
              managements={managements}
              selectedManagement={selectedManagement}
              setSelectedManagement={setSelectedManagement}
              status={status}
              setStatus={setStatus}
            />
          </Grid>
          <Grid container>
            <EditVendorStateTabs
              states={states}
              openAddModal={handleOpenCloseAddStateModal}
              handleChangeSelectedStateTab={handleChangeSelectedStateTab}
              selectedState={selectedState}
              handleOpenCloseRemoveStateModal={handleOpenCloseRemoveStateModal}
            />
          </Grid>
          <Grid container>
            <EditVendorTable
              selectedTypeTab={selectedTypeTab}
              handleChangeTab={handleChangeTab}
              selectedState={states && states[selectedState]}
              openDeleteItemModal={handleOpenCloseConfirmDeleteItemModal}
              setSelectedItemToBeAdded={setSelectedItemToBeAdded}
              selectedItemToBeAdded={selectedItemToBeAdded}
              handleAddItem={handleAddItem}
              shouldClearAutocomplete={shouldClearAutocomplete}
            />
          </Grid>
          <Grid container item xs={12} className="pt-4">
            <Button
              variant="text"
              color="primary"
              className={`${classes.button} ${classes.returnButton} mr-6`}
              onClick={() => redirectToAssociationsManagement()}
            >
              VOLTAR
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={`${classes.button}`}
              onClick={() => handleSaveVendorEdits()}
              disabled={!initials || !selectedManagement || loadingSaveButton}
            >
              {!loadingSaveButton ? (
                "SALVAR"
              ) : (
                <Loading
                  size={20}
                  loading={loadingSaveButton}
                  className={classes.loadingSaveButton}
                />
              )}
            </Button>
          </Grid>

          <AddStateModal
            handleOpenCloseAddStateModal={handleOpenCloseAddStateModal}
            handleAddState={handleAddState}
            addStateModalOpen={addStateModalOpen}
            statesList={statesList}
            addSelectedState={addSelectedState}
            setAddSelectedState={setAddSelectedState}
          />
          <ConfirmationModal
            openedModal={removeStateModalOpen}
            title="Excluir estado"
            subtitle="Tem certeza que deseja excluir o estado e desvincular todos os clientes, concorrentes e culturas da consultoria?"
            secondaryButtonText="NÃO"
            secondaryAction={handleOpenCloseRemoveStateModal}
            primaryButtonText="SIM"
            primaryAction={handleConfirmRemoveStateModal}
            handleOpenClose={handleOpenCloseRemoveStateModal}
            titleIcon={<X color={SECONDARY_COLOR} size={50} />}
          />
          <ConfirmationModal
            openedModal={confirmDeleteTableItemModal}
            title={`Desvincular ${getSelectedTypeName().displayName} ${
              itemHasVolume ? " com volume" : ""
            }`}
            subtitle={
              itemHasVolume ? (
                <>
                  O(a) {getSelectedTypeName().displayName} possui{" "}
                  <span className={`${classes.colorHasVolume}`}>volume lançado</span> para esse ano.
                  <br />
                  Tem certeza que deseja desvincular-lo(a) da consultoria?
                </>
              ) : (
                <>
                  Tem certeza que deseja desvincular o(a) {getSelectedTypeName().displayName} da
                  consultoria?
                </>
              )
            }
            secondaryButtonText="NÃO"
            secondaryAction={handleOpenCloseConfirmDeleteItemModal}
            primaryButtonText="SIM"
            primaryAction={handleConfirmDeleteItem}
            handleOpenClose={handleOpenCloseConfirmDeleteItemModal}
            titleIcon={<Trash2 color={SECONDARY_COLOR} size={50} />}
            loading={loadingHasVolume}
          />
          <Modal
            primaryButton={{
              action: () => redirectToAssociationsManagement(),
              text: "FECHAR"
            }}
            openedModal={successModalOpen}
            closeModal={redirectToAssociationsManagement}
            title="Consultoria salva!"
            titleIcon={<img src={SuccessSaveVendor} alt="Success edit vendor" />}
            closeIcon
          >
            <Typography align="center" className={classes.subtitle}>
              As alterações na consultoria foram salvas com sucesso.
            </Typography>
          </Modal>
        </>
      )}
      {redirect}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  icon: {
    verticalAlign: "bottom"
  },
  loadingPosition: {
    margin: "auto",
    marginTop: "32px",
    color: SECONDARY_COLOR
  },
  button: {
    width: "180px",
    height: "56px"
  },
  returnButton: {
    marginLeft: "auto"
  },
  subtitle: {
    color: GRAY
  },
  loadingSaveButton: {
    margin: theme.spacing(2),
    position: "relative",
    color: SECONDARY_COLOR
  },
  colorHasVolume: {
    color: SECONDARY_COLOR
  }
}));

const mapDispatchToProps = dispatch => {
  return {
    showNotification: message => dispatch(action.showNotification(message))
  };
};

export default connect(null, mapDispatchToProps)(EditVendor);
