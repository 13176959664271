import { numberWithSeparators, getEvolution } from "../../shared/utils/Utils";
import { DataType } from "../../shared/utils/enum";
import { competitorYaraName } from "../../shared/utils/Constants";

export const getCurrentTotal = (data, isCompetitor) => {
  if (!data) return;

  let currentYearTotal = 0;
  let currentYaraTotal = 0;

  data.forEach(d => {
    currentYearTotal = parseFloat((d.currentYearSale + currentYearTotal).toFixed(2));
    currentYaraTotal = parseFloat((d.yaraVolume + currentYaraTotal).toFixed(2));
  });
  if (isCompetitor) {
    const yaraData = data.find(e => e.name === competitorYaraName);
    currentYaraTotal = yaraData ? yaraData.currentYearSale : 0;
  }

  return { currentYearTotal, currentYaraTotal };
};

const verifyIsCompetitor = dataType => dataType === DataType["competitor"].name;

const getYaraVolumeBody = (dataType, volume) =>
  !verifyIsCompetitor(dataType) ? [parseFloat(volume).toFixed(2)] : [];

const getMarketShare = (dataType, item, totals) =>
  !verifyIsCompetitor(dataType)
    ? (item.yaraVolume / item.currentYearSale) * 100
    : (item.currentYearSale / totals.currentYearTotal) * 100;

export const generateBody = (data, totals, isMobile, dataType) => {
  if (!data || totals == null) return;

  const lines = data.map(d => {
    let quota = getMarketShare(dataType, d, totals).toFixed(2);

    let evolution = getEvolution(d.solidSale, d.currentYearSale);

    quota = isNaN(quota) || !isFinite(evolution) ? "0,00%" : quota + "%";
    evolution = !isFinite(evolution) ? "-" : evolution + "%";
    if (!isMobile)
      return [
        d.id,
        d.name,
        quota.replace(".", ","),
        parseFloat(d.currentYearSale).toFixed(2),
        ...getYaraVolumeBody(dataType, d.yaraVolume),
        evolution
      ];
    return [
      d.id,
      d.name,
      quota.replace(".", ","),
      parseFloat(d.currentYearSale).toFixed(2),
      ...getYaraVolumeBody(dataType, d.yaraVolume)
    ];
  });

  return lines;
};

const getYaraVolumeFooter = (dataType, currentYaraTotal) =>
  !verifyIsCompetitor(dataType) ? [numberWithSeparators(currentYaraTotal)] : [];

export const generateFooter = (totals, isMobile, dataType) => {
  if (totals == null) return;
  if (!isMobile)
    return [
      "TOTAL",
      "",
      numberWithSeparators(totals.currentYearTotal),
      ...getYaraVolumeFooter(dataType, totals.currentYaraTotal),
      ""
    ];
  return [
    "TOTAL",
    "",
    numberWithSeparators(totals.currentYearTotal),
    ...getYaraVolumeFooter(dataType, totals.currentYaraTotal)
  ];
};

const getYaraVolumeHeader = (dataType, isEditable) =>
  !verifyIsCompetitor(dataType)
    ? [
        {
          name: `Vol. Yara`,
          alignRight: true,
          editable: isEditable,
          width: 2,
          inputMode: "decimal"
        }
      ]
    : [];

export const generateHeader = (name, isEditable, yaraVolumeEditable, isMobile) => {
  if (!isMobile)
    return [
      { name: name, width: !verifyIsCompetitor(name) ? 4 : 6 },
      { name: "Market Share", alignRight: true, width: 1 },
      {
        name: `Vol. ${new Date().getFullYear().toString()}`,
        alignRight: true,
        editable: isEditable,
        width: 2,
        inputMode: "decimal"
      },
      ...getYaraVolumeHeader(name, yaraVolumeEditable),
      { name: "Evolução", alignRight: true, width: 1, differentBackground: true }
    ];
  return [
    { name: name, width: 4 },
    { name: "Market Share", alignRight: true, width: 2 },
    {
      name: `Vol. ${new Date().getFullYear().toString()}`,
      alignRight: true,
      editable: isEditable,
      width: 2,
      inputMode: "decimal"
    },
    ...getYaraVolumeHeader(name, yaraVolumeEditable)
  ];
};

export const updateBodyInfo = (data, rowId, colId, newValue) => {
  if (data[rowId]) {
    let dataCopy = [...data];

    if (colId === 1) {
      if (newValue) {
        dataCopy[rowId] = {
          ...dataCopy[rowId],
          name: newValue
        };
      } else {
        dataCopy[rowId] = {
          ...dataCopy[rowId],
          name: "",
          currentYearSale: 0
        };
      }
    } else if (colId === 3) {
      dataCopy[rowId] = {
        ...dataCopy[rowId],
        currentYearSale: parseFloat(newValue.toFixed(2))
      };
    } else if (colId === 4) {
      dataCopy[rowId] = {
        ...dataCopy[rowId],
        yaraVolume: parseFloat(newValue.toFixed(2))
      };
    }
    return dataCopy;
  }
  return null;
};
