import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import Modal from "../../../shared/components/Modal";
import * as action from "../../alertMessage/redux/alertMessageActions";
import useCreateMessageModalStepper from "../customHooks/useCreateMessageModalStepper";
import useCreateMessageModalAdjustment from "../customHooks/useCreateMessageModalAdjustment";
import useCreateMessageModalDirect from "../customHooks/useCreateMessageModalDirect";
import CreateMessageModalHeader from "./CreateMessageModalHeader";
import CreateMessageModalTypeStep from "./CreateMessageModalTypeStep";
import useCreateMessageModalTypeStep from "../customHooks/useCreateMessageModalTypeStep";
import CreateMessageModalAdjustment from "./CreateMessageModalAdjustment";
import {
  formatDataToCreateAdjustmentMessage,
  formatRecipientUsers,
  formatAdjustmentData,
  formatDataToCreateDirectMessage,
  formatStandardMessage,
  formatDataToCreateSellingBalanceMessage
} from "../FormatDataToCreateMessage";
import { messageType as MessageTypeEnum } from "../../../shared/utils/enum";
import BackofficeMessageManager from "../BackofficeMessageManager";
import CreateMessageModalStandard from "./CreateMessageModalStandard";
import useCreateMessageModalStandard from "../customHooks/useCreateMessageModalStandard";
import useCreateMessageModalSellingBalance from "../customHooks/useCreateMessageModalSellingBalance";

const CreateMessageModal = props => {
  const {
    openedModal,
    closeModal,
    messageTypes,
    reloadMessages,
    userSenderId,
    showNotification
  } = props;

  const classes = useStyles();

  const [loadingFinishButton, setLoadingFinishButton] = useState(false);

  const {
    selectedType,
    handleSelectType,
    selectedVendors,
    setSelectedVendors,
    selectedManagements,
    setSelectedManagements,
    setSelectedType,
    internalVendorUsers,
    representativeVendorUsers,
    loadingUserTable,
    selectedUsers,
    selectedInternalUsers,
    selectedRepresentativeUsers,
    includeRepresentativeUsersSelected,
    handleSelectUser,
    handleAddRemoveAllInternalUsers,
    handleAddRemoveAllRepresentativeUsers,
    handleIncludeRepresentativeUserChange,
    setVendorUsers,
    setRepresentativeVendorUsers,
    setInternalVendorUsers,
    setSelectedUsers,
    setSelectedInternalUsers,
    setSelectedRepresentativeUsers,
    setIncludeRepresentativeUsersSelected
  } = useCreateMessageModalTypeStep(messageTypes);

  const {
    activeStep,
    setActiveStep,
    handleNextStep,
    handleBackStep,
    isFirstStep
  } = useCreateMessageModalStepper();

  const {
    adjustmentSelectedReason,
    adjustmentSelectedCrop,
    adjustmentSelectedState,
    adjustmentStates,
    adjustmentReasons,
    adjustmentCrops,
    adjustmentIsActiveCropsSelect,
    adjustmentIsIncreasing,
    adjustmentVolume,
    adjustmentDescription,
    setAdjustmentIsIncreasing,
    setAdjustmentVolume,
    setAdjustmentSelectedReason,
    setAdjustmentDescription,
    setAdjustmentSelectedState,
    setAdjustmentSelectedCrop,
    adjustmentFinishButtonDisabled
  } = useCreateMessageModalAdjustment(selectedVendors);

  const {
    directReasons,
    fetchDirectAboutOptions
  } = useCreateMessageModalDirect();

  const {
    sellingBalanceReasons,
    fetchSellingBalanceAboutOptions
  } = useCreateMessageModalSellingBalance();

  const {
    standardFinishButtonDisabled,
    standardSelectedReason,
    setStandardSelectedReason,
    standardSelectedType,
    setStandardSelectedtype,
    standardDescription,
    setStandardDescription
  } = useCreateMessageModalStandard(selectedVendors);

  useEffect(() => {
    setActiveStep(0);
    setSelectedManagements([]);
    setSelectedVendors([]);
    setSelectedType();
    setVendorUsers([]);
    setInternalVendorUsers([]);
    setRepresentativeVendorUsers([]);
    setSelectedUsers([]);
    setSelectedInternalUsers([]);
    setSelectedRepresentativeUsers([]);
    setAdjustmentIsIncreasing(null);
    setAdjustmentVolume("");
    setAdjustmentDescription("");
    setAdjustmentSelectedReason();
    setAdjustmentSelectedState();
    setAdjustmentSelectedCrop();
    setLoadingFinishButton(false);
    setStandardSelectedReason();
    setStandardSelectedtype();
    setStandardDescription("");
    setIncludeRepresentativeUsersSelected(false);
  }, [
    openedModal,
    setActiveStep,
    setSelectedManagements,
    setSelectedVendors,
    setSelectedType,
    setVendorUsers,
    setInternalVendorUsers,
    setRepresentativeVendorUsers,
    setSelectedUsers,
    setSelectedInternalUsers,
    setSelectedRepresentativeUsers,
    setAdjustmentIsIncreasing,
    setAdjustmentVolume,
    setAdjustmentDescription,
    setAdjustmentSelectedReason,
    setAdjustmentSelectedState,
    setAdjustmentSelectedCrop,
    setStandardSelectedReason,
    setStandardSelectedtype,
    setStandardDescription,
    setIncludeRepresentativeUsersSelected
  ]);

  const handleCloseModal = () => closeModal();

  const getSecondStepComponent = () => {
    if (selectedType.id === MessageTypeEnum.adjustment.id)
      return (
        <CreateMessageModalAdjustment
          selectedReason={adjustmentSelectedReason}
          selectedCrop={adjustmentSelectedCrop}
          selectedState={adjustmentSelectedState}
          states={adjustmentStates}
          reasons={adjustmentReasons}
          crops={adjustmentCrops}
          isActiveCropsSelect={adjustmentIsActiveCropsSelect}
          isIncreasing={adjustmentIsIncreasing}
          volume={adjustmentVolume}
          description={adjustmentDescription}
          setIsIncreasing={setAdjustmentIsIncreasing}
          setVolume={setAdjustmentVolume}
          setSelectedReason={setAdjustmentSelectedReason}
          setDescription={setAdjustmentDescription}
          setSelectedState={setAdjustmentSelectedState}
          setSelectedCrop={setAdjustmentSelectedCrop}
        />
      );
    else if (selectedType.id === MessageTypeEnum.sentDirect.id)
      return (
        <CreateMessageModalStandard
          selectedReason={standardSelectedReason}
          selectedType={standardSelectedType}
          reasons={directReasons}
          description={standardDescription}
          setSelectedReason={setStandardSelectedReason}
          setDescription={setStandardDescription}
          setSelectedType={setStandardSelectedtype}
          fetchAboutOptions={(term) => fetchDirectAboutOptions(selectedVendors.map(vendor => vendor.id), term)}
          aboutPlaceholder={"Concorrente, cliente ou cultura"}
          title={"Qual o motivo e sobre qual concorrente, cliente ou cultura seria a mensagem?"}
        />
      );
    else if (selectedType.id === MessageTypeEnum.sellingBalance.id)
      return (
        <CreateMessageModalStandard
          selectedReason={standardSelectedReason}
          selectedType={standardSelectedType}
          reasons={sellingBalanceReasons}
          description={standardDescription}
          setSelectedReason={setStandardSelectedReason}
          setDescription={setStandardDescription}
          setSelectedType={setStandardSelectedtype}
          fetchAboutOptions={(term) => fetchSellingBalanceAboutOptions(selectedVendors.map(vendor => vendor.id), term)}
          aboutPlaceholder={"Produto, matéria-prima ou planta"}
          title={"Qual o motivo e sobre qual produto, matéria-prima ou planta seria a mensagem?"}
        />
      );
  };

  const getSecondStepButtonDisableRule = () => {
    if (selectedType.id === MessageTypeEnum.adjustment.id) return adjustmentFinishButtonDisabled;
    else if (selectedType.id === MessageTypeEnum.sentDirect.id ||
      selectedType.id === MessageTypeEnum.sellingBalance.id
    ) return standardFinishButtonDisabled;
  };

  const handleFinishModal = async () => {
    setLoadingFinishButton(true);
    const users = formatRecipientUsers(selectedUsers);

    let message = null;

    if (selectedType.id === MessageTypeEnum.adjustment.id) {
      const adjustmentData = formatAdjustmentData(
        adjustmentDescription,
        adjustmentSelectedReason,
        adjustmentVolume,
        adjustmentIsIncreasing,
        adjustmentSelectedState.id,
        adjustmentSelectedCrop.id
      );

      message = formatDataToCreateAdjustmentMessage(
        userSenderId,
        selectedVendors,
        users,
        adjustmentData
      );
    } else if (selectedType.id === MessageTypeEnum.sentDirect.id) {
      const directMessage = formatStandardMessage(
        standardSelectedReason,
        standardDescription,
        standardSelectedType
      );

      message = formatDataToCreateDirectMessage(
        userSenderId,
        selectedVendors,
        directMessage,
        users
      );
    } else if (selectedType.id === MessageTypeEnum.sellingBalance.id) {
      const sellingBalanceMessage = formatStandardMessage(
        standardSelectedReason,
        standardDescription,
        standardSelectedType
      );

      message = formatDataToCreateSellingBalanceMessage(
        userSenderId,
        selectedVendors,
        sellingBalanceMessage,
        users
      );
    }

    const data = await BackofficeMessageManager.createMessage(message, selectedType.id);
    
    if (data) {
      reloadMessages(true);
      handleCloseModal();
      showNotification("Mensagem enviada com sucesso!");
    }
    setLoadingFinishButton(false);
  };

  return (
    <Modal
      secondaryButton={{
        action: () => (isFirstStep ? handleCloseModal() : handleBackStep()),
        text: isFirstStep ? "FECHAR" : "VOLTAR",
        variant: "outlined",
        className: classes.modalButtons
      }}
      primaryButton={{
        text: isFirstStep ? "PROXIMO" : "ENVIAR",
        variant: "contained",
        color: "primary",
        action: () => (isFirstStep ? handleNextStep() : handleFinishModal()),
        className: classes.modalButtons,
        disabled: isFirstStep
          ? !selectedType || !selectedUsers || (selectedUsers && selectedUsers.length === 0)
          : getSecondStepButtonDisableRule() || loadingFinishButton
      }}
      openedModal={openedModal}
      closeModal={() => handleCloseModal()}
      closeIcon
      id="newMessageModal"
      className={classes.modal}
      paperClassName={classes.paperModal}
    >
      <CreateMessageModalHeader activeStep={activeStep} />
      {isFirstStep ? (
        <CreateMessageModalTypeStep
          messageTypes={messageTypes}
          selectedType={selectedType}
          setSelectedType={handleSelectType}
          selectedVendors={selectedVendors}
          setSelectedVendors={setSelectedVendors}
          selectedManagements={selectedManagements}
          setSelectedManagements={setSelectedManagements}
          internalVendorUsers={internalVendorUsers}
          representativeVendorUsers={representativeVendorUsers}
          loadingUserTable={loadingUserTable}
          selectedInternalUsers={selectedInternalUsers}
          selectedRepresentativeUsers={selectedRepresentativeUsers}
          includeRepresentativeUsersSelected={includeRepresentativeUsersSelected}
          handleSelectUser={handleSelectUser}
          handleAddRemoveAllInternalUsers={handleAddRemoveAllInternalUsers}
          handleAddRemoveAllRepresentativeUsers={handleAddRemoveAllRepresentativeUsers}
          handleIncludeRepresentativeUserChange={handleIncludeRepresentativeUserChange}
        />
      ) : (
        getSecondStepComponent()
      )}
    </Modal>
  );
};

const useStyles = makeStyles(theme => ({
  modalButtons: {
    width: "180px",
    height: "56px"
  },
  modal: {
    maxHeight: "100%"
  },
  paperModal: {
    maxWidth: "800px",
    minHeight: "481px",
  }
}));

const mapStateToProps = state => {
  return {
    userSenderId: state.user.id
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showNotification: message => dispatch(action.showNotification(message))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateMessageModal);
