import CompetitorIcon from "../img/Competitor.svg";
import CropsIcon from "../img/Crops.svg";
import CustomerIcon from "../img/Customer.svg";
import SmallCropIcon from "../img/SmallCrop.svg";
import SmallCompetitorIcon from "../img/SmallCompetitor.svg";
import SmallCustomerIcon from "../img/SmallCustomer.svg";

export const environment = {
  production: { code: "production" },
  homologation: { code: "homologation" },
  rollout: { code: "rollout" },
  development: { code: "development" },
  local: { code: "local" }
};

export const DataType = {
  competitor: {
    id: 0,
    name: "Concorrentes",
    icon: CompetitorIcon,
    smallIcon: SmallCompetitorIcon
  },
  customer: {
    id: 1,
    name: "Clientes",
    icon: CustomerIcon,
    smallIcon: SmallCustomerIcon
  },
  crop: {
    id: 2,
    name: "Culturas",
    icon: CropsIcon,
    smallIcon: SmallCropIcon
  }
};

export const profiles = {
  backOffice: {
    name: "BackOffice",
    initials: "BO"
  },
  commercialConsultant: {
    name: "Consultor Comercial",
    initials: "CTC"
  },
  hortiFrutiConsultant: {
    name: "Consultor Hortifruti",
    initials: "CTH"
  },
  manager: {
    name: "Gerente",
    initials: "GC"
  },
  representative: {
    name: "Representante",
    initials: "RP",
  }
};

export const accessType = {
  backOffice: "BackOffice",
  consultant: "Consultor",
  representative: "Representante"
};

export const dayWeek = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6
};

export const managementInitials = {
  all: "Todas"
};

export const vendorInitials = {
  all: "Todas"
};

export const productGroupOptions = {
  all: "Todos"
};

export const siteInitialsOptions = {
  all: "Todas"
};

export const sellingBalanceSearchPeriods = {
  weekly: "Weekly",
  monthly: "Monthly",
}

export const screenSizes = {
  webScreenSize: 960
};

export const messageType = {
  adjustment: {
    id: 1,
    description: "Ajuste",
    fieldName: "adjustmentMessage",
    tabValue: 0
  },
  communication: {
    id: 2,
    description: "Comunicado",
    fieldName: "communicationMessage",
    tabValue: -1
  },
  sentDirect: {
    id: 3,
    description: "Diretas enviadas",
    fieldName: "directMessage",
    tabValue: 1
  },
  receivedDirect: {
    id: -1,
    description: "Diretas recebidas",
    fieldName: "directMessage",
    tabValue: 2
  },
  sellingBalance: {
    id: 4,
    description: "Saldo a Vender",
    fieldName: "sellingBalanceMessage",
    tabValue: 3
  }
};

export const messageTypeSending = {
  received: {
    id: 1,
    description: "Recebidas"
  },
  sent: {
    id: 2,
    description: "Enviadas"
  }
};

export const getMessageTypeById = id => {
  for (let prop in messageType) {
    if (messageType[prop].id === id) return messageType[prop];
  }
};

export const intervals = {
  refreshNewMessages: 600000
};

export const readingStatus = [
  {
    value: "all",
    label: "Todas"
  },
  {
    value: "read",
    label: "Lidas"
  },
  {
    value: "unRead",
    label: "Não lidas"
  }
];

export const activeStatus = [
  { id: 1, name: "Todas" },
  { id: 2, name: "Ativo", active: true },
  { id: 3, name: "Inativo", active: false }
];

export const preRegisteredTypes = {
  competitor: "competitor",
  customer: "customer"
};

export const IdentityProviders = {
  B2B: "B2B",
  B2C: "B2C"
};