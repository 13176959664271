import MessageService from "../../services/MessageService";

class ConsultantMessageManager {
  async listMessagesByUserIdAndVendorIdPaged(userId, vendorId, page) {
    return await MessageService.listMessagesByUserIdAndVendorIdPaged(userId, vendorId, page).then(
      response => response && response.data
    );
  }

  async listVendorSentMessages(vendorId, page) {
    return await MessageService.listVendorSentMessages(vendorId, page).then(
      response => response && response.data
    );
  }
}

export default new ConsultantMessageManager();
