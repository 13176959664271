import React from "react";
import {
  Dialog,
  Grid,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
  Box,
  Button
} from "@material-ui/core";
import { X } from "react-feather";
import { PRIMARY_COLOR, SECONDARY_COLOR, WHITE } from "../../theme";

const Modal = props => {
  const classes = useStyles(props);
  const {
    disableClickOutsideModal,
    disableCloseOnEsc,
    secondaryButton,
    paperClassName,
    primaryButton,
    titleVariant,
    openedModal,
    closeModal,
    titleAlign,
    closeIcon,
    titleIcon,
    className,
    errorType,
    preTitle,
    maxWidth,
    title
  } = props;

  const isThereTwoButtons = Boolean(primaryButton && secondaryButton);
  const buttonSizes = isThereTwoButtons ? 6 : 12;

  return (
    <Dialog
      open={openedModal}
      onClose={closeModal}
      scroll="body"
      maxWidth={maxWidth}
      fullWidth
      className={`${classes.modal} ${className}`}
      classes={{
        paper: `${classes.paperModal} ${errorType && classes.errorModalBorder} ${paperClassName}`
      }}
      disableBackdropClick={disableClickOutsideModal}
      disableEscapeKeyDown={disableCloseOnEsc}
    >
      {closeIcon && (
        <X
          color={SECONDARY_COLOR}
          className={`hover-icon ${classes.closeIcon} mr-4 mt-4`}
          onClick={closeModal}
        />
      )}

      {preTitle && <Box className={`${classes.preTitleContainer} pt-8`}>{preTitle}</Box>}

      {title && (
        <DialogTitle
          disableTypography
          onClose={closeModal}
          className={`${classes.titleContainer} pb-2`}
        >
          {titleIcon && <Box className="pb-2">{titleIcon}</Box>}
          <Typography
            className={`${errorType ? classes.errorTitle : classes.title}`}
            align={titleAlign || "center"}
            variant={titleVariant || "h4"}
          >
            {title}
          </Typography>
        </DialogTitle>
      )}
      <DialogContent>{props.children}</DialogContent>

      <DialogActions className="p-6 pt-6">
        <Grid container spacing={3}>
          {secondaryButton && (
            <Grid item align={isThereTwoButtons ? "left" : "center"} xs={buttonSizes}>
              <Button
                onClick={secondaryButton.action}
                href={secondaryButton.href}
                target={secondaryButton.openNewTab ? "_blank" : "_self"}
                className={secondaryButton.className || classes.button}
                color={secondaryButton.color || "secondary"}
                variant={secondaryButton.variant || "text"}
                disabled={secondaryButton.disabled}
              >
                {secondaryButton.text}
              </Button>
            </Grid>
          )}
          {primaryButton && (
            <Grid item align={isThereTwoButtons ? "right" : "center"} xs={buttonSizes}>
              <Button
                onClick={primaryButton.action}
                href={primaryButton.href}
                target={primaryButton.openNewTab ? "_blank" : "_self"}
                className={primaryButton.className || classes.button}
                color={primaryButton.color || "secondary"}
                variant={primaryButton.variant || "outlined"}
                disabled={primaryButton.disabled}
              >
                {primaryButton.text}
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  button: {
    width: "100%",
    height: "56px"
  },
  modal: {
    overflow: "hidden",
    width: "100%",
    maxHeight: "calc(100% - 70px)"
  },
  paperModal: {
    backgroundColor: WHITE,
    borderRadius: "8px"
  },
  errorModalBorder: {
    border: `2px solid ${SECONDARY_COLOR}`
  },
  preTitleContainer: {
    textAlign: "center"
  },
  title: {
    color: PRIMARY_COLOR
  },
  errorTitle: {
    color: SECONDARY_COLOR
  },
  closeIcon: {
    position: "absolute",
    right: 0
  },
  titleContainer: {
    textAlign: "center",
    paddingTop: "40px"
  }
});

export default Modal;
