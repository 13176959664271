import StateService from "../../services/StateService";
import StateVendorCompetitorService from "../../services/StateVendorCompetitorService";
import StateVendorCropService from "../../services/StateVendorCropService";
import StateVendorCustomerService from "../../services/StateVendorCustomerService";
import PotentialService from "../../services/PotentialService";

class PotentialManager {
  async getStatesFromVendorId(vendorId) {
    return await StateService.getStatesFromVendorIds(vendorId, true).then(
      answer => answer && answer.data
    );
  }
  async listCompetitorPotentials(stateVendorId, year) {
    return await StateVendorCompetitorService.listCompetitorPotentials(stateVendorId, year).then(
      answer => answer && answer.data
    );
  }
  async listCropPotentials(stateVendorId, year) {
    return await StateVendorCropService.listCropPotentials(stateVendorId, year).then(
      answer => answer && answer.data
    );
  }
  async listCustomerPotentials(stateVendorId, year) {
    return await StateVendorCustomerService.listCustomerPotentials(stateVendorId, year).then(
      answer => answer && answer.data
    );
  }
  async updatePotentials(data) {
    return await PotentialService.updatePotentials(data).then(answer => answer && answer.data);
  }
  async listStatesPotentialTotals(year, stateVendorIds) {
    return await PotentialService.listStatesPotentialTotals(year, stateVendorIds).then(
      answer => answer && answer.data
    );
  }
}

export default new PotentialManager();
