import { useState, useEffect } from "react";
import PotentialManager from "../PotentialManager";
import { getCurrentTotal, updateBodyInfo } from "../TableDataHelper";
import { DataType } from "../../../shared/utils/enum";

export const useCompetitorPotential = (selectedStateVendor, updateTotalVolume, reloadScreen) => {
  const [competitorSales, setCompetitorSales] = useState();
  const [competitorTotal, setCompetitorTotal] = useState();
  const [loadingCompetitorTable, setloadingCompetitorTable] = useState(false);

  useEffect(() => {
    if (competitorTotal != null) {
      updateTotalVolume(
        DataType["competitor"].id,
        competitorTotal.currentYearTotal,
        competitorTotal.currentYaraTotal
      );
    }
  }, [competitorTotal, updateTotalVolume]);

  useEffect(() => {
    const getData = async () => {
      setloadingCompetitorTable(true);
      setCompetitorSales();
      setCompetitorTotal({
        currentYearTotal: 0,
        currentYaraTotal: 0
      });
      const data = await PotentialManager.listCompetitorPotentials(
        selectedStateVendor,
        new Date().getFullYear()
      );

      if (data) {
        setCompetitorSales(data);
        updateTotals(data);
      }
      setloadingCompetitorTable(false);
    };
    if (selectedStateVendor) getData();
  }, [selectedStateVendor, reloadScreen]);

  const updateTotals = data => {
    const totals = getCurrentTotal(data, true);
    setCompetitorTotal(totals);
  };

  const updateCompetitorData = (rowId, columnId, newValue) => {
    const newData = updateBodyInfo(competitorSales, rowId, columnId, newValue);

    if (newData) {
      setCompetitorSales(newData);
      updateTotals(newData);
    }
  };

  return {
    competitorSales,
    setCompetitorSales,
    loadingCompetitorTable,
    competitorTotal,
    updateCompetitorData
  };
};
