import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@material-ui/core";
import Select from "../../Select";
import { managementInitials, vendorInitials } from "../../../utils/enum";
import ManagementVendorSelectsManager from "../ManagementVendorSelectsManager";

const OPTION_ALL_MANAGEMENTS = {
  initials: managementInitials.all,
  id: 0
};
const OPTION_ALL_VENDORS = {
  initials: vendorInitials.all,
  id: 0
};

const ManagementVendorSelects = props => {
  const {
    selectedManagement,
    setSelectedManagement,
    selectedVendor,
    setSelectedVendor,
    gridSpacing,
    shouldHaveAllVendor,
    shoudReloadManagements,
    setShoudReloadManagements,
    active,
    multipleManagements,
    multipleVendors,
  } = props;

  const [managements, setManagements] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [loadingVendor, setLoadingVendor] = useState(false);
  const [loadingManagement, setLoadingManagement] = useState(false);
  const [lastSelectedManagement, setLastSelectedManagement] = useState(selectedManagement);

  const refSelectedVendor = useRef(selectedManagement);

  useEffect(() => {
    refSelectedVendor.current = selectedManagement;
  });

  useEffect(() => {
    if (!shoudReloadManagements) return;

    const getManagements = async () => {
      const data = await ManagementVendorSelectsManager.listManagements();

      data && data.splice(0, 0, OPTION_ALL_MANAGEMENTS);

      setManagements(data || []);

      if (!refSelectedVendor.current || !refSelectedVendor.current.length) {
        if (multipleManagements) {
          setSelectedManagement([OPTION_ALL_MANAGEMENTS]);
        }
        else {
          setSelectedManagement(OPTION_ALL_MANAGEMENTS);
        }
      }

      setLoadingManagement(false);
    };

    setLoadingManagement(true);
    getManagements();
    setShoudReloadManagements(false);
  }, [setSelectedManagement, setShoudReloadManagements, shoudReloadManagements, multipleManagements]);

  useEffect(() => {
    if (multipleManagements) {
      if (!selectedManagement || !selectedManagement.length) {
        shouldHaveAllVendor ? setSelectedVendor([OPTION_ALL_VENDORS]) : setSelectedVendor([]);
      }
    }
    else {
      if (
        selectedManagement &&
        lastSelectedManagement &&
        selectedManagement.id !== lastSelectedManagement.id
      )
        shouldHaveAllVendor ? setSelectedVendor(OPTION_ALL_VENDORS) : setSelectedVendor();
    }
  }, [selectedManagement, setSelectedVendor, shouldHaveAllVendor, lastSelectedManagement, multipleManagements]);

  useEffect(() => {
    const getVendorsByManagementId = async managementIds => {
      const data = await ManagementVendorSelectsManager.listVendorsByManagementIds(
        managementIds,
        active
      );

      shouldHaveAllVendor && data && data.splice(0, 0, OPTION_ALL_VENDORS);

      setVendors(data || []);

      if (shouldHaveAllVendor) {
        if (multipleVendors) {
          setSelectedVendor([OPTION_ALL_VENDORS]);
        }
        else {
          setSelectedVendor(OPTION_ALL_VENDORS);
        }
      }

      setLoadingVendor(false);
    };

    const getVendors = async () => {
      const data = await ManagementVendorSelectsManager.listVendorsByManagementIds(null, active);
      const optionAll = {
        initials: vendorInitials.all,
        id: 0
      };

      shouldHaveAllVendor && data && data.splice(0, 0, optionAll);

      setVendors(data || []);

      if (shouldHaveAllVendor) {
        if (multipleVendors) {
          setSelectedVendor([optionAll]);
        }
        else {
          setSelectedVendor(optionAll);
        }
      }

      setLoadingVendor(false);
    };

    setLoadingVendor(true);

    if (multipleVendors && selectedManagement && selectedManagement.length) {
      getVendorsByManagementId(selectedManagement.map(management => management.id));
    }
    else if (selectedManagement && selectedManagement.id) {
      getVendorsByManagementId([selectedManagement.id]);
    }
    else {
      getVendors();
    }
  }, [selectedManagement, setSelectedVendor, shouldHaveAllVendor, active, multipleVendors]);

  const handleChangeManagements = newSelectedManagements => {
    if (multipleManagements && newSelectedManagements) {
      const lastSelectedMultipleManagement = newSelectedManagements.length && newSelectedManagements.at(-1);
      
      if (lastSelectedMultipleManagement.id === OPTION_ALL_MANAGEMENTS.id) {
        newSelectedManagements = [OPTION_ALL_MANAGEMENTS];
      }
      else {
        newSelectedManagements = newSelectedManagements.filter(management => management.id > OPTION_ALL_MANAGEMENTS.id);
  
        if (newSelectedManagements.length === 0) {
          newSelectedManagements = [OPTION_ALL_MANAGEMENTS];
        }
      }
    }

    setSelectedManagement(newSelectedManagements);
    setLastSelectedManagement(selectedManagement);
  }

  return (
    <Grid container spacing={gridSpacing || 3}>
      <Grid item xs={6}>
        <Select
          label="Gerências"
          code={multipleManagements
            ? selectedManagement && selectedManagement.map(management => management.id)
            : selectedManagement && selectedManagement.id
          }
          maxHeightMenu="150"
          options={managements}
          handleChange={handleChangeManagements}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.initials}
          returnFullObject
          disabled={loadingManagement}
          multiple={multipleManagements}
        />
      </Grid>
      <Grid item xs={6}>
        <Select
          label="Consultorias"
          code={multipleVendors
            ? selectedVendor && selectedVendor.map(vendor => vendor.id)
            : selectedVendor && selectedVendor.id
          }
          maxHeightMenu="150"
          options={vendors}
          handleChange={e => setSelectedVendor(e)}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.initials}
          returnFullObject
          disabled={loadingVendor}
          multiple={multipleVendors}
        />
      </Grid>
    </Grid>
  );
};

export default ManagementVendorSelects;
