import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import React from 'react';
import { AlertTriangle, CheckCircle } from 'react-feather';
import { DARK_GREEN, RED } from '../../../theme';

const ProductGroupTableCell = props => {
  const {
    productGroup,
    siteInitials,
    rawMaterials,
    isMobile,
  } = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant={isMobile ? "subtitle2" : "h6"}>{productGroup}</Typography>
      </Grid>
      <List disablePadding dense>
        {rawMaterials.map(item => 
          <ListItem
            disableGutters
            key={`${siteInitials}, ${productGroup}, ${item.rawMaterial}`}
          >
            <ListItemText
              primary={item.rawMaterial}
              primaryTypographyProps={{
                variant: "body2",
              }}
            />
            <ListItemIcon>
              {item.hasRestriction
                ? <AlertTriangle size={16} color={RED} className="ml-2" />
                : <CheckCircle size={16} color={DARK_GREEN} className="ml-2" />
              }
            </ListItemIcon>
          </ListItem>
        )}
      </List>
    </Grid>
  );
}

export default ProductGroupTableCell;