import React, { useState } from "react";
import { Grid, MobileStepper, makeStyles, Divider, Typography, Button } from "@material-ui/core";
import { SECONDARY_COLOR, PALE_BLUE_GRAY } from "../../../theme";
import NextYearOrderbookTypeTabs from "./NextYearOrderbookTypeTabs";
import TableCustomized from "../../../shared/components/TableCustomized";
import { generateBody, generateFooter, generateHeader } from "../TableDataHelper";
import { DataType } from "../../../shared/utils/enum";
import Loading from "../../../shared/components/Loading";
import FloatingButton from "../../../shared/components/FloatingButton";
import { Save } from "react-feather";
import { FormatDataToUpdate } from "../FormatDataToUpdate";
import NextYearOrderbookManager from "../NextYearOrderbookManager";

const EntriesContainer = props => {
  const {
    competitorNextYearOrderbook,
    handleSuccessModalOpenClose,
    handleErrorModalOpenClose,
    customerNextYearOrderbook,
    loadingCompetitorTable,
    competitorTotalVolume,
    loadingCustomerTable,
    updateCompetitorData,
    cropNextYearOrderbook,
    customerTotalVolume,
    updateCustomerData,
    loadingCropTable,
    updateCropsData,
    competitorTotal,
    cropTotalVolume,
    selectedTypeTab,
    handleChangeTab,
    customerTotal,
    isEndOfScreen,
    cropTotal,
    isMobile
  } = props;

  const classes = useStyles();

  const [entryIsLoading, setEntryIsLoading] = useState(false);

  const finishEntryVolume = async () => {
    setEntryIsLoading(true);

    const result = await NextYearOrderbookManager.updateNextYearOrderbook(
      FormatDataToUpdate(
        cropNextYearOrderbook,
        competitorNextYearOrderbook,
        customerNextYearOrderbook
      )
    );

    if (result) handleSuccessModalOpenClose();

    setEntryIsLoading(false);
  };

  const onClickFinishButton = () => {
    customerTotalVolume.toFixed(2) !== cropTotalVolume.toFixed(2) ||
    cropTotalVolume.toFixed(2) !== competitorTotalVolume.toFixed(2)
      ? handleErrorModalOpenClose()
      : finishEntryVolume();
  };

  const generateCompetitorTable = () => {
    return (
      <TableCustomized
        headerInfos={generateHeader(DataType["competitor"].name, true)}
        bodyInfos={generateBody(competitorNextYearOrderbook, competitorTotal)}
        showFooter
        footerInfos={generateFooter(competitorTotal)}
        footerRadius
        isLoading={loadingCompetitorTable}
        updateData={updateCompetitorData}
        typeData={DataType.competitor.name}
        isMobile={isMobile}
      />
    );
  };

  const generateCropTable = () => {
    return (
      <TableCustomized
        headerInfos={generateHeader(DataType["crop"].name, true)}
        bodyInfos={generateBody(cropNextYearOrderbook, cropTotal)}
        showFooter
        footerInfos={generateFooter(cropTotal)}
        footerRadius
        isLoading={loadingCropTable}
        updateData={updateCropsData}
        typeData={DataType.crop.name}
        isMobile={isMobile}
      />
    );
  };

  const generateCustomerTable = () => {
    return (
      <TableCustomized
        headerInfos={generateHeader(DataType["customer"].name, true)}
        bodyInfos={generateBody(customerNextYearOrderbook, customerTotal)}
        showFooter
        footerInfos={generateFooter(customerTotal)}
        footerRadius
        isLoading={loadingCustomerTable}
        updateData={updateCustomerData}
        typeData={DataType.customer.name}
        isMobile={isMobile}
      />
    );
  };

  const handleActiveTable = () => {
    switch (selectedTypeTab) {
      case DataType["competitor"].id:
        return generateCompetitorTable();
      case DataType["customer"].id:
        return generateCustomerTable();
      case DataType["crop"].id:
        return generateCropTable();
      default:
        return generateCompetitorTable();
    }
  };

  return (
    <Grid container item xs={12}>
      <Grid item xs={12} className="pb-3">
        <Typography
          variant={!isMobile ? "h5" : "h6"}
          align={!isMobile ? "left" : "center"}
          className={classes.titleColor}
        >
          Volumes
        </Typography>
      </Grid>
      <Grid container item xs={12} className={classes.contentAligment}>
        <Grid item xs={12}>
          <NextYearOrderbookTypeTabs
            handleChangeTab={handleChangeTab}
            customerTotalVolume={customerTotalVolume}
            competitorTotalVolume={competitorTotalVolume}
            cropTotalVolume={cropTotalVolume}
            selectedTypeTab={selectedTypeTab}
            isMobile={isMobile}
          />
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          {handleActiveTable()}
        </Grid>
      </Grid>

      <Grid item xs={12} className="pb-4">
        <MobileStepper
          variant="dots"
          steps={3}
          position="static"
          activeStep={selectedTypeTab}
          className={classes.stepper}
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          className={!isMobile ? classes.buttonWebProperties : classes.button}
          onClick={() => onClickFinishButton()}
          disabled={entryIsLoading}
        >
          {entryIsLoading ? (
            <Loading size={20} loading={entryIsLoading} className={classes.loadingPosition} />
          ) : (
            "SALVAR"
          )}
        </Button>
      </Grid>

      {!isEndOfScreen && (
        <FloatingButton
          isWeb={!isMobile}
          handleClick={() => onClickFinishButton()}
          icon={<Save size={20} />}
          text={!isMobile && "SALVAR"}
          ariaLabel="sendVolume"
          loading={entryIsLoading}
          disabled={entryIsLoading}
        />
      )}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  stepper: {
    "& .MuiMobileStepper-dots": {
      margin: "auto",
      display: "flex"
    },
    "& .MuiMobileStepper-dotActive": {
      backgroundColor: SECONDARY_COLOR
    }
  },
  titleColor: {
    color: SECONDARY_COLOR
  },
  contentAligment: {
    margin: "auto",
    display: "flex",
    width: "100%",
    border: `1px solid ${PALE_BLUE_GRAY}`,
    borderRadius: theme.spacing(1)
  },
  divider: {
    height: theme.spacing(0.25),
    backgroundColor: PALE_BLUE_GRAY
  },
  button: {
    width: "180px",
    height: "56px",
    margin: "auto",
    display: "flex"
  },
  buttonWebProperties: {
    width: "180px",
    height: "56px",
    margin: "auto",
    display: "flex",
    marginRight: "0px"
  },
  loadingPosition: {
    margin: theme.spacing(2),
    position: "relative",
    color: SECONDARY_COLOR
  }
}));

export default EntriesContainer;
