import React from "react";
import {
  Grid,
  Typography,
  makeStyles,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel
} from "@material-ui/core";
import Select from "../../../shared/components/Select";
import { GRAY } from "../../../theme";

const EditVendorData = props => {
  const classes = useStyles();
  const {
    initials,
    setInitials,
    description,
    setDescription,
    managements,
    selectedManagement,
    setSelectedManagement,
    status,
    setStatus
  } = props;

  return (
    <Grid container item xs={12} spacing={3} className="pb-2">
      <Grid item xs={2}>
        <TextField
          className={classes.textField}
          variant="outlined"
          label="Sigla"
          inputProps={{ maxLength: 255 }}
          value={initials}
          onChange={e => setInitials(e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          className={classes.textField}
          variant="outlined"
          label="Descrição"
          inputProps={{ maxLength: 255 }}
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <Select
          label="Gerência"
          options={managements}
          code={selectedManagement && selectedManagement.id}
          handleChange={e => setSelectedManagement(e)}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.initials}
          maxHeightMenu="150"
          returnFullObject
        />
      </Grid>
      <Grid item xs={2}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Status</FormLabel>
          <RadioGroup
            row
            aria-label="status"
            name="status1"
            value={status}
            onChange={e => setStatus(e.target.value)}
          >
            <FormControlLabel
              value="active"
              control={<Radio className={classes.radio} />}
              label={<Typography className={classes.radioLabel}>Ativo</Typography>}
              labelPlacement="end"
            />
            <FormControlLabel
              value="inative"
              control={<Radio className={classes.radio} />}
              label={<Typography className={classes.radioLabel}>Inativo</Typography>}
              labelPlacement="end"
              className="mr-0 pl-6"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  textField: {
    width: "100%",
    height: "100%"
  },
  radio: {
    "&.Mui-checked": {
      color: GRAY
    }
  },
  radioLabel: {
    color: GRAY
  }
}));

export default EditVendorData;
