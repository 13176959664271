import React, { useState } from "react";
import { TableCell, makeStyles, TextField } from "@material-ui/core";
import { acceptOnlyNumbers, formatWithTwoDecimalDigits } from "../../shared/utils/Utils";
import { MID_BLUE_BACKGROUND } from "../../theme";

const EditableTableCell = props => {
  const classes = useStyles();

  const {
    rowId,
    columnId,
    rowValue,
    updateData,
    contentAlign,
    inputTitle,
    inputMode,
    isNewItem,
    nextYearSelect
  } = props;

  const [textfieldValue, setTextfieldValue] = useState(formatWithTwoDecimalDigits(rowValue));

  const handleFinishEdition = () => {
    const newValue = parseFloat(textfieldValue.toString().replace(",", "."));
    const formatedValue = newValue.toFixed(2);

    if (isNaN(newValue)) {
      setTextfieldValue(formatWithTwoDecimalDigits(0));
      updateData(rowId, columnId + 1, 0, nextYearSelect);
    } else {
      setTextfieldValue(formatWithTwoDecimalDigits(formatedValue));

      if (formatedValue !== rowValue) {
        updateData(rowId, columnId + 1, newValue, nextYearSelect);
      }
    }
  };

  return (
    <TableCell
      align={contentAlign}
      aria-label={inputTitle}
      className={isNewItem ? classes.differentBackground : null}
    >
      <TextField
        className={classes.textField}
        variant="standard"
        value={textfieldValue}
        onChange={e => setTextfieldValue(acceptOnlyNumbers(e.target.value))}
        onKeyPress={ev => {
          if (ev.key === "Enter") {
            ev.target.blur();
            ev.preventDefault();
          }
        }}
        inputProps={{ className: classes.textfieldContentAlign, inputMode }}
        onBlur={() => handleFinishEdition()}
        onFocus={e => parseFloat(e.target.value.replace(",", ".")) === 0 && e.target.select()}
        InputProps={{ className: classes.textFieldSize }}
      />
    </TableCell>
  );
};

const useStyles = makeStyles({
  textField: {
    width: "65px"
  },
  textfieldContentAlign: {
    "&.MuiInputBase-input": {
      textAlign: "right"
    }
  },
  textFieldSize: {
    height: "20px"
  },
  differentBackground: {
    backgroundColor: MID_BLUE_BACKGROUND
  }
});

export default EditableTableCell;
