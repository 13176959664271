import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  makeStyles,
  Tooltip
} from "@material-ui/core";
import {
  PRIMARY_COLOR,
  PALE_BLUE_GRAY,
  GRAY,
  DARK_GREEN,
  MID_BLUE_BACKGROUND,
  SECONDARY_COLOR
} from "../../../theme";
import Loading from "../../../shared/components/Loading";
import CustomTableNotReadMessage from "./CustomTableNotReadMessage";
import TablePaginationActions from "../../../shared/components/CustomTable/TablePaginationActions";
import MessageManager from "../../../shared/components/MessageNotification/MessageManager";
import { range } from "lodash";
import { connect } from "react-redux";
import { format, parseISO } from "date-fns";
import { Check, Clock, ExternalLink } from "react-feather";
import { formatManagements, formatVendors } from "../FormatTableData";

const CustomTableMessageStandard = props => {
  const {
    messages = [],
    loading = false,
    isMessagesSentByConsultant,
    setOpenDrawerMessagesStandard,
    setSelectedMessage,
    authenticatedUserId,
    insertLocalMessageReadDate,
    messagesFieldName
  } = props;
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [optionsRowsPerPage, setOptionRowPerPage] = useState([]);

  useEffect(() => {
    let items = [];

    const skip = page * rowsPerPage;

    for (let i = skip; i < skip + rowsPerPage; i++) {
      if (messages[i]) items.push(messages[i]);
    }

    setData(items);
  }, [messages, page, rowsPerPage]);

  useEffect(() => {
    const defaultRowsPerPage = 5;
    const options = range(
      defaultRowsPerPage,
      messages.length + defaultRowsPerPage,
      defaultRowsPerPage
    );
    setOptionRowPerPage(options);
    setPage(0);
  }, [messages]);

  const insertMessageReadDate = async (messageId, userId) => {
    insertLocalMessageReadDate(messageId);
    await MessageManager.insertMessageReadDate(messageId, userId);
  };

  const handleClickAction = message => {
    setSelectedMessage(message);
    setOpenDrawerMessagesStandard(true);

    const shouldInsertReadDate = 
      isMessagesSentByConsultant &&
      message.recipientMessage[0] &&
      !message.recipientMessage[0].readDate;

    if (shouldInsertReadDate) insertMessageReadDate(message.id, authenticatedUserId);
  };

  return (
    <Grid item xs={12}>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow className="pr-2 pl-2">
            <TableCell className={`${classes.tableHeaderColor}`} align="left" width="8%">
              Gerências
            </TableCell>
            <TableCell className={`${classes.tableHeaderColor}`} align="left" width="8%">
              Consultorias
            </TableCell>
            <TableCell className={`${classes.tableHeaderColor}`} align="left" width="16%">
              Gerente/Consultor
            </TableCell>
            <TableCell className={`${classes.tableHeaderColor}`} align="left" width="14%">
              Telefone
            </TableCell>
            <TableCell className={`${classes.tableHeaderColor}`} align="left" width="9%">
              Data do envio
            </TableCell>
            <TableCell className={`${classes.tableHeaderColor}`} align="left" width="15%">
              Motivo
            </TableCell>
            <TableCell className={`${classes.tableHeaderColor}`} align="left" width="10%">
              Status de leitura
            </TableCell>
            <TableCell className={`${classes.tableHeaderColor}`} align="left" width="15%">
              Data da leitura
            </TableCell>
            <TableCell className={`${classes.tableHeaderColor}`} align="center" width="5%">
              Ações
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading &&
            data &&
            data.map((message, indexMessage) => {
              const isMessageRead = !!message.recipientMessage[0].readDate;

              return (
                <TableRow
                  className={`pr-2 pl-2 ${
                    isMessagesSentByConsultant && !isMessageRead ? classes.unreadRow : null
                  }`}
                  key={indexMessage}
                  hover
                >
                  <TableCell
                    align="left"
                    className={
                      isMessagesSentByConsultant && !isMessageRead
                        ? classes.borderLeftUnreadMessage
                        : null
                    }
                  >
                    {formatManagements(message.vendors)}
                  </TableCell>
                  <TableCell>{formatVendors(message.vendors)}</TableCell>
                  <TableCell>
                    <Tooltip title={message.recipientMessage[0].user.name} placement="top-start">
                      <span className={classes.truncateField}>
                        {message.recipientMessage[0].user.name}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{message.recipientMessage[0].user.phone}</TableCell>
                  <TableCell>{format(parseISO(message.sendDate), "dd/MM/yyyy")}</TableCell>
                  <TableCell>{message[messagesFieldName].reason.description}</TableCell>
                  <TableCell>
                    {isMessageRead ? (
                      <span className={`${classes.containerIcon} ${classes.read}`}>
                        <Check size={16} className={`mr-2`} />
                        Lida
                      </span>
                    ) : (
                      <span className={classes.containerIcon}>
                        <Clock size={16} className={`mr-2`} />
                        Não lida
                      </span>
                    )}
                  </TableCell>
                  <TableCell>
                    {isMessageRead &&
                      format(parseISO(message.recipientMessage[0].readDate), "dd/MM/yyyy")}
                  </TableCell>
                  <TableCell align="center">
                    <ExternalLink
                      size={24}
                      color={GRAY}
                      className={"pointer"}
                      onClick={() => handleClickAction(message)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>

      {loading && (
        <Grid item xs={12} className={`${classes.noMessagesBorder} ${classes.noMessagesBorderTop}`}>
          <Loading loading />
        </Grid>
      )}

      {!loading && data.length <= 0 && <CustomTableNotReadMessage />}

      <TablePagination
        rowsPerPageOptions={optionsRowsPerPage}
        component="div"
        count={messages.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(e, newPage) => setPage(newPage)}
        onChangeRowsPerPage={e => {
          setRowsPerPage(e.target.value);
          setPage(0);
        }}
        ActionsComponent={TablePaginationActions}
        labelRowsPerPage="Itens por página:"
        classes={{ caption: classes.caption }}
      />
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  table: {
    tableLayout: "fixed",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      width: "100%",
      overflowX: "auto"
    },
    "&.MuiTable-root": {
      borderCollapse: "inherit",
      borderWidth: "1px",
      borderColor: PALE_BLUE_GRAY,
      borderStyle: "solid",
      borderRadius: theme.spacing(1)
    }
  },
  tableHeaderColor: {
    color: PRIMARY_COLOR
  },
  caption: {
    color: GRAY
  },
  containerIcon: {
    display: "flex",
    alignItems: "center"
  },
  read: {
    color: DARK_GREEN
  },
  truncateField: {
    display: "block",
    width: "calc(100% - 20px)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  unreadRow: {
    backgroundColor: `${MID_BLUE_BACKGROUND}`
  },
  borderLeftUnreadMessage: {
    borderLeft: `3px solid ${SECONDARY_COLOR}`
  }
}));

const mapStateToProps = state => {
  return {
    authenticatedUserId: state.user.id
  };
};

export default connect(mapStateToProps, null)(CustomTableMessageStandard);
