import React, { useEffect, useState } from 'react';
import { Button, Grid, List, ListItem, makeStyles } from "@material-ui/core";
import CompletionAndBalanceItem from './CompletionAndBalanceItem';
import { ChevronDown, ChevronUp } from 'react-feather';

const CompletionAndBalanceList = props => {
  const {
    completionAndBalancesData,
    productGroup,
    siteInitials,
    searchPeriod,
    isMobile,
  } = props;

  const classes = useStyles();

  const [completionAndBalances, setCompletionAndBalances] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (isExpanded) {
      setCompletionAndBalances(completionAndBalancesData);
    }
    else {
      setCompletionAndBalances(completionAndBalancesData.slice(0, 3));
    }
  }, [completionAndBalancesData, isExpanded]);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <List disablePadding dense>
          {completionAndBalances.map(item =>
            <ListItem
              disableGutters
              divider
              key={`${siteInitials}, ${productGroup}, ${item.year}, ${item.period}`}
            >
              <CompletionAndBalanceItem
                isMobile={isMobile}
                searchPeriod={searchPeriod}
                year={item.year}
                period={item.period}
                completionPercentage={item.completionPercentage}
                balance={item.balance}
                forecast={item.forecast}
                submitted={item.submitted}
              />
            </ListItem>
          )}
        </List>
      </Grid>
      <Grid container item xs={12} justify="center">
        <Button
          variant="text"
          endIcon={isExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? "ver menos" : "ver mais"}
        </Button>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  container: {
    minWidth: "115px",
  },
}));

export default CompletionAndBalanceList;