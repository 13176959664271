import React from "react";
import { Grid, Typography, makeStyles, Button, Tabs, Tab, IconButton } from "@material-ui/core";
import { X } from "react-feather";
import { PRIMARY_COLOR, GRAY } from "../../../theme";

const EditVendorStateTabs = props => {
  const {
    states,
    openAddModal,
    selectedState,
    handleChangeSelectedStateTab,
    handleOpenCloseRemoveStateModal
  } = props;

  const classes = useStyles();

  const getLabelTab = (s, index) => {
    return (
      <Grid container item xs={12}>
        <Typography
          variant="h6"
          className={`${classes.tabLabelText} ${
            selectedState === index ? classes.selectedTab : classes.grayColor
          }`}
        >
          {s.initials}
        </Typography>
        <IconButton
          onClick={() => handleOpenCloseRemoveStateModal(s.id)}
          className={`p-0 ${classes.tabLabelIcon}`}
        >
          <X color={selectedState === index ? PRIMARY_COLOR : GRAY} size={16} />
        </IconButton>
      </Grid>
    );
  };

  return (
    <Grid container item xs={12} className="pb-2">
      <Grid item xs={1} className={classes.gridAlign}>
        <Typography variant="h5">Estados</Typography>
      </Grid>
      <Grid item xs={9} className={classes.gridAlign}>
        {states && states.length === 0 ? (
          <Typography variant="caption" className={classes.grayColor}>
            Não existem estados adicionados.
          </Typography>
        ) : (
          <Tabs
            value={selectedState}
            indicatorColor="secondary"
            textColor="secondary"
            onChange={handleChangeSelectedStateTab}
          >
            {states &&
              states.map((s, index) => (
                <Tab key={s.id} label={getLabelTab(s, index)} className={classes.tab} />
              ))}
          </Tabs>
        )}
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="text"
          color="primary"
          className={`${classes.button}`}
          onClick={openAddModal}
        >
          + ADICIONAR
        </Button>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  button: {
    width: "180px",
    height: "56px",
    display: "flex",
    marginLeft: "auto"
  },
  selectedTabLabel: {
    color: PRIMARY_COLOR
  },
  grayColor: {
    color: GRAY
  },
  tabLabelIcon: {
    height: "16px"
  },
  tabLabelText: {
    margin: "auto"
  },
  tab: {
    minWidth: "110px",
    maxWidth: "110px"
  },
  gridAlign: {
    marginTop: "auto",
    marginBottom: "auto"
  }
}));

export default EditVendorStateTabs;
