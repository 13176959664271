import axios from "../axiosInstance";

class ManagementService {
  async listManagements() {
    return await axios.get("management");
  }

  async createManagement(data) {
    return await axios.post("management", data);
  }
}

export default new ManagementService();
