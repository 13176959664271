import React from "react";
import { Grid } from "@material-ui/core";
import Select from "../../../shared/components/Select";
import DateRangePicker from "../../../shared/components/DateRangePicker";

const PageFilter = props => {
  const { disableDatePicker, selectedRangeDate, setSelectedRangeDate } = props;
  return (
    <>
      <Grid container xs={12} item className="pb-3">
        <Grid item sm={3} xs={12} className={`pt-2 pb-2 pr-4`}>
          <DateRangePicker
            disabled={disableDatePicker}
            startDate={selectedRangeDate.startDate}
            endDate={selectedRangeDate.endDate}
            setRangeDate={setSelectedRangeDate}
            label="Período"
            allowFutureDate={false}
            maxDayRange={184}
          />
        </Grid>
        <Grid item sm={3} xs={12} className={`pt-2 pb-2 pr-4 pl-4`}>
          <Select label="Gerência" placeholder="Todas" disabled />
        </Grid>
        <Grid item sm={4} xs={12} className={`pt-2 pb-2 pr-4 pl-4`}>
          <Select label="Consultoria" placeholder="Todas" disabled />
        </Grid>
        <Grid item sm={2} xs={12} className={`pt-2 pb-2 pr-4 pl-4`}>
          <Select label="Estado" placeholder="Todos" disabled />
        </Grid>
      </Grid>
    </>
  );
};

export default PageFilter;
