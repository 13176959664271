import { useState } from "react";
import CompetitorCustomerCropManager from "../CompetitorCustomerCropManager";

const useListCropType = () => {
  const [listCropType, setListCropType] = useState([]);

  const handleListCropType = async () => {
    const result = await CompetitorCustomerCropManager.listCropType();
    if (result) setListCropType(result);
  };

  return {
    listCropType,
    handleListCropType
  };
};

export default useListCropType;
