import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import React from "react";
import { DARK_GREEN } from "../../../theme";

import { alertMessageActions } from "../redux";

const AlertMessage = props => {
  const handleClose = () => {
    props.hide();
  };
  const classes = useStyles();
  const classe = props.type === "error" ? classes.error : classes.success;

  return (
    <Snackbar
      className="sucess"
      classes={{ root: classe }}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={props.showNotification}
      ContentProps={{
        "aria-describedby": "message-id",
        classes: { root: classe }
      }}
      message={<span id="message-id">{props.message}</span>}
      autoHideDuration={7000}
      onClose={handleClose}
      ClickAwayListenerProps={{
        mouseEvent: false
      }}
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      ]}
    />
  );
};

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: DARK_GREEN,
    borderRadius: "4px"
  },
  error: {
    backgroundColor: "rgb(49, 49, 49);",
    borderRadius: "4px"
  }
}));

const mapStateToProps = state => {
  return {
    message: state.notification.message,
    type: state.notification.type,
    showNotification: state.notification.showNotification
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hide: () => dispatch(alertMessageActions.hideNotification())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertMessage);
