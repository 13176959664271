import VendorService from "../../../services/VendorService";
import ManagementService from "../../../services/ManagementService";

class ManagementVendorSelectsManager {
  async listVendors() {
    return VendorService.listVendors().then(response => response && response.data);
  }
  async listManagements() {
    return ManagementService.listManagements().then(response => response && response.data);
  }
  async listVendorsByManagementIds(managementIds, active) {
    if (managementIds) managementIds = managementIds.filter(id => id > 0);

    return VendorService.listVendorsByManagementIds(managementIds, active).then(
      response => response && response.data
    );
  }
}

export default new ManagementVendorSelectsManager();
