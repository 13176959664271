import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SECONDARY_COLOR } from "../../theme";

const Loading = props => {
  const classes = useStyles();
  const { loading, className, size } = props;

  return (
    loading && (
      <CircularProgress
        id="loading"
        size={size ? size : 40}
        className={className ? className : classes.progress}
      />
    )
  );
};

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2),
    position: "relative",
    left: "calc(50% - 40px)",
    color: SECONDARY_COLOR
  }
}));

export default Loading;
