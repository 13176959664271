import { LogLevel } from "@azure/msal-browser";
import { configureEnvironment } from "./config/env";
import { routes } from "./shared/utils/Constants";

const loggerCallback = (level, message, containsPii) => {
  if (containsPii) {
    return;
  }
  switch (level) {
    case LogLevel.Error:
      console.error(message);
      return;
    case LogLevel.Info:
      console.info(message);
      return;
    case LogLevel.Verbose:
      console.debug(message);
      return;
    case LogLevel.Warning:
      console.warn(message);
      return;
    default:
      return;
  }
}

const formatRedirectUri = () => {
  return (configureEnvironment().PUBLIC_URL + routes.logIn).replace(/([^:])\/\//g, "$1/");
}

export const msalConfigB2B = {
  auth: {
    clientId: "1bcb2677-3d5a-4d2d-8c24-e0a13f5a8f3d",
    authority: "https://login.microsoftonline.com/ef8a53ea-1a1c-4189-b792-c832dcaea568",
    redirectUri: formatRedirectUri(),
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "memoryStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: loggerCallback
    }
  }
};

export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_SignupandSignin",
  },
  authorities: {
    signUpSignIn: {
      authority: "https://YaraBrazilB2C.b2clogin.com/YaraBrazilB2C.onmicrosoft.com/B2C_1_SignupandSignin",
    },
  },
  authorityDomain: "YaraBrazilB2C.b2clogin.com"
}

export const msalConfigB2C = {
  auth: {
    clientId: "4df42278-fed0-4f66-a6d2-c9d5e0bbf6b9",
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: formatRedirectUri(),
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "memoryStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: loggerCallback
    }
  }
};

const protectedResourcesB2B = {
  mainApi: {
    endpoint: configureEnvironment().REACT_APP_API_URL,
    scopes: ["e1f59da9-2956-4772-ab08-7325de0a55bd/Acesso"],
  },
}

const protectedResourcesB2C = {
  mainApi: {
    endpoint: configureEnvironment().REACT_APP_API_URL,
    scopes: ["https://YaraBrazilB2C.onmicrosoft.com/e07446c9-cd47-482f-99d3-f5fb538e589c/demo.read"],
  },
}

export const protectedResourcesOptions = {
  B2B: protectedResourcesB2B,
  B2C: protectedResourcesB2C
}