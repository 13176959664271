import { NOTIFY, HIDE_NOTIFICATION, NOTIFY_SUCCESS } from "./alertMessageActions";

const INITIAL_STATE = {
  message: "",
  type: "error",
  showNotification: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NOTIFY:
      return {
        ...state,
        message: action.payload.message,
        type: "error",
        showNotification: true
      };

    case NOTIFY_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        type: "sucess",
        showNotification: true
      };

    case HIDE_NOTIFICATION:
      return {
        ...state,
        message: "",
        showNotification: false
      };

    default:
      return state;
  }
};
