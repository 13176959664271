import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

const CustomMessage = props => {
  const { icon, description, isMobile } = props;
  const classes = useStyles();

  return (
    <Grid container item className={classes.container}>
        {icon}
        <Typography variant={!isMobile ? "body1" : "body2"} align="center" className={classes.description}>
          {description}
        </Typography>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  description: {
    marginTop: theme.spacing(1),
    maxWidth: "50%",
  },
}));

export default CustomMessage;
