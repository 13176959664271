import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import DeniedAccessIcon from "../img/DeniedAccessIcon.svg";

const PageNotFound = props => {
  const classes = useStyles();

  return (
    <Grid container className="pl-4 pt-8 pr-4 pb-4">
      <Grid item xs={12} className={`${classes.spacingImage} pb-8`}>
        <img src={DeniedAccessIcon} alt="Denied Access" className={classes.image} />
      </Grid>
      <Grid item xs={12} className="pb-5">
        <Typography variant="h3" align="center">
          Página inexistente!
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" align="center" color="secondary">
          Desculpe, a página solicitada não existe no sistema.
        </Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  image: {
    display: "flex",
    margin: "auto",
    width: "160px"
  },
  spacingImage: {
    paddingTop: theme.spacing(8)
  }
}));

export default PageNotFound;
