import { useState, useEffect } from "react";
import { profiles as profilesEnum } from "../../../shared/utils/enum";

const useTable = (selectedProfile, vendors, selectedManagement, selectedVendor) => {
  const [associations, setAssociations] = useState([]);
  const [openedConfirmationModal, setOpenedConfirmationModal] = useState(false);
  const [dataToBeRemoved, setDataToBeRemoved] = useState();

  let flagAdded = false;
  let notRepeated = true;

  useEffect(() => {
    setAssociations([]);
  }, [selectedProfile]);

  const handleOpenCloseConfirmationModal = () => {
    setOpenedConfirmationModal(!openedConfirmationModal);
    setDataToBeRemoved();
  };

  const openConfirmationModal = (managementInitials, vendorInitials) => {
    handleOpenCloseConfirmationModal();
    setDataToBeRemoved({ managementInitials, vendorInitials });
  };

  const groupManagements = (association, associationsArray) => {
    let temporaryArray = associationsArray;
    temporaryArray &&
      temporaryArray.forEach(
        (managementArray, managementIndex) =>
          temporaryArray[managementIndex] &&
          temporaryArray[managementIndex].forEach((associationObject, associationIndex) => {
            if (
              associationObject.management === association.management &&
              associationObject.vendor === association.vendor
            )
              notRepeated = false;
          })
      );

    temporaryArray &&
      temporaryArray.forEach(
        (managementArray, managementIndex) =>
          temporaryArray[managementIndex] &&
          temporaryArray[managementIndex].forEach((associationObject, associationIndex) => {
            if (
              associationObject.management === association.management &&
              associationObject.vendor !== association.vendor &&
              !flagAdded &&
              notRepeated
            ) {
              associationsArray[managementIndex].push(association);
              flagAdded = true;
            }
          })
      );

    return associationsArray;
  };

  const generateAssociation = () => {
    if (selectedProfile.initials === profilesEnum.manager.initials) {
      let arrayResult = [];
      const cloneVendors = vendors;
      let associationsHelper = [...associations];
      cloneVendors.forEach((vendor, index) => {
        const association = {
          managementId: selectedManagement.id,
          management: selectedManagement.initials,
          vendor: vendor.initials,
          vendorId: vendor.id,
          states: vendor.states
        };
        const cloneAssociations = associationsHelper;
        arrayResult = groupManagements(association, cloneAssociations);

        if (!flagAdded && notRepeated) {
          let result = [];
          result.push(association);
          arrayResult.push(result);
        }

        flagAdded = false;
        notRepeated = true;
        setAssociations(arrayResult);
        associationsHelper = arrayResult;
      });
      setAssociations(associationsHelper);
    } else {
      let arrayResult = [];

      const association = {
        managementId: selectedManagement.id,
        management: selectedManagement.initials,
        vendor: selectedVendor.initials,
        vendorId: selectedVendor.id,
        states: selectedVendor.states
      };

      const cloneAssociations = [...associations];
      arrayResult = groupManagements(association, cloneAssociations);

      if (!flagAdded && notRepeated) {
        let result = [];
        result.push(association);
        arrayResult.push(result);
      }

      flagAdded = false;
      notRepeated = true;
      setAssociations(arrayResult);
    }
  };

  const removeAssociation = () => {
    const { managementInitials, vendorInitials } = dataToBeRemoved;
    let cloneAssociations = [...associations];

    if (vendorInitials) {
      cloneAssociations.forEach((m, i) => {
        const vendorsTemp = cloneAssociations[i];
        const removedVendor = vendorsTemp.filter(v => v.vendor !== vendorInitials);
        if (removedVendor.length === 0) cloneAssociations.splice(i, 1);
        else cloneAssociations[i] = removedVendor;
      });
    } else {
      cloneAssociations = cloneAssociations.filter(
        m => m && m[0] && m[0]["management"] !== managementInitials
      );
    }
    setAssociations(cloneAssociations);
    handleOpenCloseConfirmationModal();
  };

  return {
    associations,
    setAssociations,
    generateAssociation,
    openedConfirmationModal,
    handleOpenCloseConfirmationModal,
    openConfirmationModal,
    removeAssociation
  };
};

export default useTable;
