import CompetitorCustomerCropManager from "../CompetitorCustomerCropManager";

const useCreateCompetitor = () => {
  const handleCreateCompetitor = async competitor => {
    const response = await CompetitorCustomerCropManager.createCompetitor(competitor);

    return response;
  };

  return {
    handleCreateCompetitor
  };
};

export default useCreateCompetitor;
