import FamilyGroupService from "../../services/FamilyGroupService";
import ProductGroupService from "../../services/ProductGroupService";
import SellingBalanceService from "../../services/SellingBalanceService";
import SiteService from "../../services/SiteService";
import { productGroupOptions, siteInitialsOptions } from "../../shared/utils/enum";

class SellingBalanceSearchManager {
  async getFamilyGroupOptions() {
    const answer = await FamilyGroupService.getFamilyGroupOptions();

    return answer && answer.data;
  }

  async getProductGroupOptions(familyGroup) {
    const answer = await ProductGroupService.getProductGroupOptions(familyGroup);

    return answer && answer.data;
  }

  async getSiteInitialsOptions() {
    const answer = await SiteService.getSiteInitials();

    return answer && answer.data;
  }

  async getForecastGroups(vendorInitials, filters, searchPeriod) {
    const {
      familyGroup,
      productGroup,
      siteInitials,
    } = filters;

    if (!familyGroup) return;

    const productGroupOption = productGroup === productGroupOptions.all ? null : productGroup;
    const siteInitialsOption = siteInitials === siteInitialsOptions.all ? null : siteInitials;

    const answer = await SellingBalanceService.getForecastGroups(
      vendorInitials,
      familyGroup,
      productGroupOption,
      siteInitialsOption,
      searchPeriod
    );

    return answer && answer.data;
  }
}

export default new SellingBalanceSearchManager();
