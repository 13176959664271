import React from "react";
import {
  Grid,
  makeStyles,
  Divider,
  Table,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Typography,
  Button
} from "@material-ui/core";
import { PALE_BLUE_GRAY, GRAY, LIGHT_GRAY, SECONDARY_COLOR } from "../../../theme";
import NoAssociationsCreatedIcon from "../../../shared/img/NoAssociationsCreatedIcon.svg";
import DisabledNoAssociationsIcon from "../../../shared/img/DisabledNoAssociationsIcon.svg";
import EditVendorTypeTabs from "./EditVendorTypeTabs";
import { DataType } from "../../../shared/utils/enum";
import { Trash2 } from "react-feather";
import TypeAutocomplete from "../../typeAutoComplete";

const EditVendorTable = props => {
  const {
    selectedTypeTab,
    handleChangeTab,
    selectedState,
    openDeleteItemModal,
    setSelectedItemToBeAdded,
    selectedItemToBeAdded,
    handleAddItem,
    shouldClearAutocomplete
  } = props;

  const classes = useStyles();

  const selectedTabHasType =
    selectedTypeTab === DataType["customer"].id || selectedTypeTab === DataType["crop"].id;

  const disabled = !selectedState;

  const getSelectedData = () => {
    if (!selectedState) return [];
    switch (selectedTypeTab) {
      case DataType["customer"].id:
        return selectedState["customers"];
      case DataType["crop"].id:
        return selectedState["crops"];
      case DataType["competitor"].id:
        return selectedState["competitors"];
      default:
        return [];
    }
  };

  return (
    <Grid container item xs={12} className={classes.container}>
      <Grid item xs={12}>
        <EditVendorTypeTabs
          selectedTypeTab={selectedTypeTab}
          handleChangeTab={handleChangeTab}
          disabled={disabled}
        />
        <Divider className={classes.divider} />
      </Grid>
      <Grid container item xs={12}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell
                style={{ width: `2%` }}
                className={!disabled ? classes.tableHead : classes.lightGrayColor}
              >
                NOME
              </TableCell>
              <TableCell style={{ width: `48%` }}>
                <TypeAutocomplete
                  typeId={selectedTypeTab}
                  handleChangeItem={item => setSelectedItemToBeAdded(item)}
                  shouldClear={shouldClearAutocomplete}
                  width="98%"
                  disabled={disabled}
                />
              </TableCell>
              <TableCell
                style={{ width: `2%` }}
                className={!disabled ? classes.tableHead : classes.lightGrayColor}
              >
                {selectedTabHasType && "TIPO"}
              </TableCell>
              <TableCell style={{ width: `38%` }}>
                {selectedTabHasType &&
                  selectedItemToBeAdded &&
                  (selectedItemToBeAdded.customerType || selectedItemToBeAdded.cropType)}
              </TableCell>
              <TableCell style={{ width: `10%` }} colSpan={2}>
                <Button
                  disabled={!selectedItemToBeAdded}
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleAddItem()}
                >
                  ASSOCIAR
                </Button>
              </TableCell>
            </TableRow>

            {selectedState &&
              getSelectedData().length > 0 &&
              getSelectedData().map((d, index) => (
                <TableRow key={`row ${index}`}>
                  <TableCell className={classes.lightGrayColor}>NOME</TableCell>
                  <TableCell>{d.name.toUpperCase()}</TableCell>
                  <TableCell className={classes.lightGrayColor}>
                    {selectedTabHasType && "TIPO"}
                  </TableCell>
                  <TableCell>
                    {selectedTabHasType && (d.customerType || d.cropType).toUpperCase()}
                  </TableCell>
                  <TableCell className={classes.lightGrayColor} align="right">
                    AÇÕES
                  </TableCell>
                  <TableCell>
                    <IconButton
                      className="p-0"
                      onClick={() =>
                        openDeleteItemModal(
                          d.id,
                          d.stateVendorCompetitorId ||
                            d.stateVendorCustomerId ||
                            d.stateVendorCropId
                        )
                      }
                    >
                      <Trash2 size={24} color={GRAY} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {(!selectedState || (selectedState && getSelectedData().length === 0)) && (
          <Grid container>
            <Grid item xs={12} className="pt-6">
              <img
                src={disabled ? DisabledNoAssociationsIcon : NoAssociationsCreatedIcon}
                alt="Nenhuma associação feita"
                className={classes.noAssociationIcon}
              />
            </Grid>
            <Grid item xs={12} className="pb-6">
              <Typography className={`h7 ${disabled && classes.lightGrayColor}`} align="center">
                Ainda não existem concorrentes,
                <br /> clientes e culturas cadastradas
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    borderColor: PALE_BLUE_GRAY,
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "8px",
    borderCollapse: "inherit",
    marginTop: "-12px"
  },
  divider: {
    height: 1,
    backgroundColor: PALE_BLUE_GRAY
  },
  lightGrayColor: {
    color: LIGHT_GRAY
  },
  tableHead: {
    color: SECONDARY_COLOR
  },
  noAssociationIcon: {
    width: 50,
    height: 50,
    display: "flex",
    margin: "auto"
  }
}));
export default EditVendorTable;
