import React, { useState } from "react";
import { Button, Typography, TextField, Grid, makeStyles } from "@material-ui/core";
import Modal from "../../../shared/components/Modal";
import { GRAY } from "../../../theme";

const Demo = () => {
  const classes = useStyles();
  const [openedModal, setOpenedModal] = useState(false);

  return (
    <Grid container spacing={2} className="p-3">
      <Modal
        openedModal={openedModal}
        closeModal={() => setOpenedModal(false)}
        title="This is a title example!"
        closeIcon
        secondaryButton={{
          text: "Secondary Button Example",
          action: () => setOpenedModal(false)
        }}
        primaryButton={{
          text: "Primary Button Example",
          action: () => setOpenedModal(false)
        }}
        maxWidth="xs"
      >
        <Typography align="center" style={{ color: GRAY }}>
          Modal content example!
        </Typography>
      </Modal>
      <Grid item xs={12}>
        <Typography>Buttons</Typography>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={3}>
          <Button variant="contained" color="primary" className={classes.componentSize}>
            Button
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button variant="outlined" color="secondary" className={classes.componentSize}>
            Button
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button variant="contained" color="primary" className={classes.componentSize} disabled>
            Button
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button variant="text" color="primary" className={classes.componentSize}>
            Button
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography>TextFields</Typography>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={3}>
          <TextField
            className={classes.componentSize}
            variant="outlined"
            label="Label"
            value={""}
            helperText="Helper"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            className={classes.componentSize}
            variant="outlined"
            label="Label"
            value={"With texts"}
            helperText="Helper"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            className={classes.componentSize}
            variant="outlined"
            label="Label"
            value={"ERROR"}
            helperText="ERROR"
            error
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            className={classes.componentSize}
            variant="outlined"
            label="Label"
            value={""}
            helperText="Disabled"
            disabled
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={3}>
          <TextField
            className={classes.componentSize}
            variant="standard"
            label="Label"
            value={""}
            helperText="Helper"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            className={classes.componentSize}
            variant="standard"
            label="Label"
            value={"With texts"}
            helperText="Helper"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            className={classes.componentSize}
            variant="standard"
            label="Label"
            value={"ERROR"}
            helperText="ERROR"
            error
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            className={classes.componentSize}
            variant="standard"
            label="Label"
            value={""}
            helperText="Disabled"
            disabled
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography>Modal</Typography>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={3}>
          <Button
            variant="contained"
            color="primary"
            className={classes.componentSize}
            onClick={() => setOpenedModal(true)}
          >
            Open modal
          </Button>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1">H1/YaraMax/Light/96px</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2">H2/YaraMax/Light/60px</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3">H3/YaraMax/Regular/48px</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">H4/YaraMax/Regular/34px</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">H5/YaraMax/Regular/24px</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">H6/YaraMax/SemiBold/20px</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">body1/YaraMax/Regular/16px</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">body2/YaraMax/Regular/14px</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">subtitle1/YaraMax/Regular/16px</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">subtitle2/YaraMax/SemiBold/14px</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">caption/YaraMax/Regular/12px</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline">overline/YaraMax/Regular/10px</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  componentSize: {
    width: "100%",
    height: "100%"
  }
});

export default Demo;
