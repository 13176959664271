import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import { GRAY } from "../../../../theme";

const ItemStandardMessage = props => {
  const { description } = props;

  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Typography variant="caption" className={classes.details}>
        {description || "-"}
      </Typography>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  details: {
    color: GRAY
  }
}));

export default ItemStandardMessage;
