import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import { makeStyles } from "@material-ui/core/styles";
import { components } from "react-select";
import { InputLabel } from "@material-ui/core";
import PropTypes from "prop-types";
import { Search, X } from "react-feather";
import { SECONDARY_COLOR, RED, FONT_FAMILY, GRAY, LIGHT_GRAY } from "../../theme";

const AutoComplete = props => {
  const [error, setError] = useState(false);
  const [focus, setFocus] = useState(false);

  const onblur = () => {
    props.onBlur && props.onBlur();
    if (props.required && !(props.value || props.inputValue)) {
      setError(true);
    } else {
      setError(false);
    }
    setFocus(false);
  };

  useEffect(() => {
    if (props.required && (props.value || props.inputValue)) {
      setError(false);
    }
  }, [props.required, props.value, props.inputValue]);

  const onFocus = () => {
    setFocus(true);
    setError(false);
  };

  const classes = useStyles();

  const treatedPlaceHolder = props.required ? `${props.placeholder} *` : props.placeholder;
  const Option = properties => {
    return (
      <div className={classes.divOption}>
        <components.Option {...properties} />
        {props.addonOption && props.addonOption(properties.data, properties.isFocused)}
      </div>
    );
  };

  const DropdownIndicator = properties => {
    if (properties.hasValue || props.noSearchIcon) return null;

    return (
      <components.DropdownIndicator {...properties}>
        <Search color={properties.isFocused ? SECONDARY_COLOR : "hsl(0,0%,80%)"} />
      </components.DropdownIndicator>
    );
  };

  const ClearIndicator = properties => {
    return (
      <components.ClearIndicator {...properties} className={classes.cleanIcon}>
        <X size={15} color={properties.isFocused ? SECONDARY_COLOR : "#82888a"} />
      </components.ClearIndicator>
    );
  };

  return (
    <div style={{ position: "relative" }} className={props.className}>
      {props.hasLabel && (focus || props.value || props.inputValue) && (
        <InputLabel
          style={{
            color: props.disableField
              ? LIGHT_GRAY
              : error || props.invalidField
              ? RED
              : focus
              ? SECONDARY_COLOR
              : "rgba(0, 0, 0, 0.54)"
          }}
          className={classes.floatingLabel}
          htmlFor="outlined-age-native-simple"
        >
          {props.label ? props.label : treatedPlaceHolder}
        </InputLabel>
      )}
      <AsyncSelect
        menuPortalTarget={document.body}
        id={props.id}
        autoFocus={props.autoFocus}
        placeholder={treatedPlaceHolder}
        noOptionsMessage={props.notFindMessage}
        loadingMessage={props.loadingMessage}
        openMenuOnClick={false}
        closeMenuOnSelect={true}
        blurInputOnSelect={false}
        menuPlacement={props.openingOptions}
        defaultOptions={props.defaultOptions}
        isClearable={props.clearable}
        loadOptions={props.loadOptions}
        getOptionValue={props.getOptionValue}
        getOptionLabel={props.getOptionLabel}
        styles={style(error, props.invalidField, props.lineInput, props.outlined)}
        onChange={props.onChange}
        theme={props.theme}
        value={props.value}
        components={{ Option, DropdownIndicator, ClearIndicator }}
        tabSelectsValue={false}
        onInputChange={props.onInputChange}
        onBlur={onblur}
        inputValue={props.inputValue}
        cacheOptions={props.cacheOptions}
        onFocus={onFocus}
        isDisabled={props.disableField}
      />
    </div>
  );
};

const placeholderColor = (error, isFocused, isDisabled) => {
  if (isDisabled) return LIGHT_GRAY;
  if (error && !isFocused) return RED;

  if (isFocused) return "rgba(0, 0, 0, 0)";

  return "rgba(0, 0, 0, 0.54)";
};

const style = (error, invalidField, lineInput, outlined) => ({
  menu: styles => ({
    ...styles,
    zIndex: 1300,
    maxHeight: "180px"
  }),
  menuList: styles => ({
    ...styles,
    maxHeight: "180px",
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px",
      cursor: "pointer"
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(0, 0, 0, 0.2)",
      borderRadius: "10px"
    }
  }),
  menuPortal: styles => ({
    ...styles,
    zIndex: 1300
  }),
  indicatorSeparator: styles => ({ ...styles, display: "none" }),
  input: provided => ({
    ...provided,
    paddingTop: "4px",
    paddingBottom: "4px",
    width: "100%",
    fontFamily: FONT_FAMILY,
    fontSize: "1rem",
    input: {
      opacity: "1 !important"
    },
    cursor: "text",
    color: GRAY
  }),
  control: (styles, { isFocused, isDisabled }) => {
    const fieldWithError = invalidField || (error && !isFocused);
    let removeBorder;
    if (lineInput && !outlined)
      removeBorder = {
        borderTop: "none",
        borderRight: "none",
        borderLeft: "none",
        borderRadius: "0px",
        boxShadow: "none"
      };
    return {
      ...styles,
      ":hover": {
        borderColor: fieldWithError ? RED : SECONDARY_COLOR
      },
      height: outlined && "56px",
      backgroundColor: "transparent",
      boxShadow: isFocused && !invalidField ? `0 0 0 1px ${SECONDARY_COLOR}` : "none",
      borderColor: isDisabled
        ? LIGHT_GRAY
        : fieldWithError
        ? RED
        : isFocused
        ? SECONDARY_COLOR
        : outlined
        ? "rgba(0, 0, 0, 0.23)"
        : "hsl(0, 0%, 70%)",
      ...removeBorder,
      ".css-1uccc91-singleValue": {
        width: "100%",
        paddingRight: "8px"
      }
    };
  },
  placeholder: (styles, { isFocused, isDisabled }) => ({
    ...styles,
    fontFamily: FONT_FAMILY,
    fontSize: "1rem",
    color: placeholderColor(error, isFocused, isDisabled)
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: null,
      cursor: "pointer",
      color: "#61688B",
      ":active": {
        ...styles[":active"],
        backgroundColor: "transparent"
      }
    };
  }
});

const useStyles = makeStyles({
  cleanIcon: {
    paddingRight: "7px !important",
    cursor: "pointer",
    paddingTop: "0px",
    paddingBottom: "0px"
  },
  floatingLabel: {
    color: "rgba(0, 0, 0, 0.54)",
    padding: "0 8px 0 8px",
    zIndex: "1300",
    position: "absolute",
    fontSize: "1rem",
    transform: "scale(0.75) translate(8px, -8px)",
    lineHeight: "1",
    backgroundColor: "#FFFFFF"
  },
  divOption: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  helperText: {
    margin: "8px 12px 0"
  }
});

AutoComplete.propTypes = {
  id: PropTypes.string,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  notFindMessage: PropTypes.func,
  loadingMessage: PropTypes.func,
  openingOptions: PropTypes.string,
  defaultOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  clearable: PropTypes.bool,
  loadOptions: PropTypes.func,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  onChange: PropTypes.func,
  theme: PropTypes.object,
  components: PropTypes.object,
  onInputChange: PropTypes.func,
  onBlur: PropTypes.func,
  inputValue: PropTypes.string,
  addonOption: PropTypes.func,
  disableField: PropTypes.bool,
  hasLabel: PropTypes.bool,
  lineInput: PropTypes.bool,
  outlined: PropTypes.bool
};

AutoComplete.defaultProps = {
  id: "",
  autoFocus: false,
  notFindMessage: () => "Não foram encontrados registros",
  loadingMessage: () => "Carregando...",
  openingOptions: "auto",
  clearable: false,
  FormHelperTextProps: { error: true },
  helperText: false,
  disableField: false,
  hasLabel: true,
  lineInput: false,
  outlined: false
};

export default AutoComplete;
