import { useState, useEffect } from "react";
import BackofficeMessageManager from "../BackofficeMessageManager";

const useCreateMessageModal = () => {
  const [createMessageModalOpen, setCreateMessageModalOpen] = useState(false);
  const [messageTypes, setMessageTypes] = useState([]);

  useEffect(() => {
    const getTypes = async () => {
      const result = await BackofficeMessageManager.listMessageType();

      if (result) setMessageTypes(result);
    };

    getTypes();
  }, []);

  const handleOpenCloseCreateMessageModal = () =>
    setCreateMessageModalOpen(!createMessageModalOpen);

  return {
    createMessageModalOpen,
    handleOpenCloseCreateMessageModal,
    messageTypes
  };
};

export default useCreateMessageModal;
