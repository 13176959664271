import { useState, useEffect } from "react";
import PotentialManager from "../PotentialManager";
import { getCurrentTotal, updateBodyInfo } from "../TableDataHelper";
import { DataType } from "../../../shared/utils/enum";

export const useCustomerPotential = (selectedStateVendor, updateTotalVolume, reloadScreen) => {
  const [customerSales, setCustomerSales] = useState();
  const [customerTotal, setCustomerTotal] = useState();
  const [loadingCustomerTable, setloadingCustomerTable] = useState(false);

  useEffect(() => {
    if (customerTotal != null) {
      updateTotalVolume(
        DataType["customer"].id,
        customerTotal.currentYearTotal,
        customerTotal.currentYaraTotal
      );
    }
  }, [customerTotal, updateTotalVolume]);

  useEffect(() => {
    const getData = async () => {
      setloadingCustomerTable(true);
      setCustomerSales();
      setCustomerTotal({
        currentYearTotal: 0,
        currentYaraTotal: 0
      });

      const data = await PotentialManager.listCustomerPotentials(
        selectedStateVendor,
        new Date().getFullYear()
      );

      if (data) {
        setCustomerSales(data);
        updateTotals(data);
      }
      setloadingCustomerTable(false);
    };
    if (selectedStateVendor) getData();
  }, [selectedStateVendor, reloadScreen]);

  const updateTotals = data => {
    const totals = getCurrentTotal(data);
    setCustomerTotal(totals);
  };

  const updateCustomerData = (rowId, columnId, newValue) => {
    const newData = updateBodyInfo(customerSales, rowId, columnId, newValue);

    if (newData) {
      setCustomerSales(newData);
      updateTotals(newData);
    }
  };

  return {
    customerSales,
    setCustomerSales,
    loadingCustomerTable,
    customerTotal,
    updateCustomerData
  };
};
