import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

const NoItem = props => {
  const { icon, text, textColor } = props;
  const classes = useStyles({ textColor: textColor });
  return (
    <Grid container>
      <Grid item xs={12} className="pt-2">
        {icon}
      </Grid>
      <Grid item xs={12} className="pt-2">
        <Typography className={`h7 ${classes.typography}`} align="center">
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};
const useStyles = makeStyles(theme => ({
  typography: props => ({
    color: props.textColor
  })
}));
export default NoItem;
