import axios from "../axiosInstance";

class CropService {
  async searchCropByTerm(term) {
    return await axios.get(`crop/search`, {
      params: { term }
    });
  }

  async listCrop(searchName, rowsPerPage, page) {
    return await axios.get(
      `crop?${
        searchName ? `searchName=${searchName}&` : ""
      }rowsPerPage=${rowsPerPage}&pageNumber=${page}`
    );
  }

  async createCrop(crop) {
    return await axios.post("crop", crop);
  }

  async updateCrop(crop, cropId) {
    return await axios.put(`crop/${cropId}`, crop);
  }
}

export default new CropService();
