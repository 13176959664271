import SellingsReportService from "../../services/SellingsReportService";

class SellingsReportManager {
  async getSellingsReport(initialDate, finalDate) {
    await SellingsReportService.getSellingsReportFile(initialDate, finalDate).then(response => {
      if (!response || !response.data) {
        return;
      }

      const csvSellingReportFile = new Blob([response.data], {
        type: response.headers["content-type"]
      });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(csvSellingReportFile, "Relatório de Vendas");
      } else {
        const URLfile = URL.createObjectURL(csvSellingReportFile);
        const link = document.createElement("a");
        link.setAttribute("download", "RelatorioVendas.csv");
        link.href = URLfile;
        link.click();
      }
    });
  }
}

export default new SellingsReportManager();
