import SellingBalanceService from "../../services/SellingBalanceService";

class DollarExchangeMananger {
  async getDollarExchange() {
    const answer = await SellingBalanceService.getDollarExchange();

    return answer && answer.data;
  }
}

export default new DollarExchangeMananger();