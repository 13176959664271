import { useState, useEffect } from "react";

const useMessageNotifications = messages => {
  const [showNotificationCard, setShowNofiticationCard] = useState(false);
  const [numberOfNewMessages, setNumberOfNewMessages] = useState(0);
  const [hasRecentMessages, setHasRecentMessages] = useState(false);
  const [hasNewMessage, setHasNewMessage] = useState(false);

  useEffect(() => {
    let countNumberOfNewMessages = 0;

    messages &&
      Object.entries(messages).forEach((message, index) => {
        if (message[1] && !message[1].recipientMessage[0].readDate && message[1].id > 0) {
          countNumberOfNewMessages++;
        }
      });

    if (messages[0]) setHasRecentMessages(true);
    else setHasRecentMessages(false);
    setHasNewMessage(countNumberOfNewMessages > 0);
    setNumberOfNewMessages(countNumberOfNewMessages);
  }, [messages]);

  return {
    showNotificationCard,
    setShowNofiticationCard,
    numberOfNewMessages,
    hasRecentMessages,
    hasNewMessage
  };
};

export default useMessageNotifications;
