import { useState, useEffect } from "react";
import UserManager from "../UserManager";

const useUserManagement = () => {
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [dataIsReady, setDataIsReady] = useState(false);

  useEffect(() => {
    const listUsers = async () => {
      const data = await UserManager.listUsers();

      setUsers(data);
      setTotal(data.length);
      setDataIsReady(true);
    };

    listUsers();
  }, []);

  return {
    users,
    total,
    dataIsReady
  };
};

export default useUserManagement;
