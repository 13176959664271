import React, { useState } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import SECONDARY_COLOR from "../../../theme";
import BookIcon from "../../../shared/img/BookIcon.svg";
import PageFilter from "./PageFilter";
import FilterButtons from "../../../shared/components/FilterButtons";
import { GRAY } from "../../../theme";
import SellingsReportManager from "../SellingsReportManager";
import PageTitle from "../../../shared/components/PageTitle";
import moment from "moment";

const SellingsReport = props => {
  const classes = useStyles();

  const [loadingExport, setLoadingExport] = useState(false);

  const [selectedRangeDate, setSelectedRangeDate] = useState({
    startDate: moment().add(-184, "days"),
    endDate: moment()
  });

  const getEntriesReport = async () => {
    setLoadingExport(true);
    await SellingsReportManager.getSellingsReport(
      new Date(selectedRangeDate.startDate),
      new Date(selectedRangeDate.endDate)
    );
    setLoadingExport(false);
  };

  return (
    <Grid container className={`pl-8 pt-8 pr-8 pb-4`}>
      <PageTitle
        title="Relatório de vendas"
        icon={
          <img
            src={BookIcon}
            alt="Relatório de vendas"
            className={`${classes.iconTextSettings} pr-3 pt-1`}
          />
        }
      />
      <Grid item xs={12}>
        <Typography variant="h5" align="left" className="pb-2">
          Filtros
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1" align="left" className={`pb-2 ${classes.subtitlePage}`}>
          Selecione a gerência, consultoria e estado.
        </Typography>
      </Grid>
      <PageFilter
        disableDatePicker={false}
        selectedRangeDate={selectedRangeDate}
        setSelectedRangeDate={setSelectedRangeDate}
      />
      <FilterButtons
        secondaryDisabled
        secondaryText="FILTRAR"
        primaryText="EXPORTAR"
        primaryAction={getEntriesReport}
        primaryLoading={loadingExport}
        primaryDisabled={loadingExport}
      />
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  iconTextSettings: {
    verticalAlign: "bottom",
    color: SECONDARY_COLOR,
    height: "48px",
    width: "48px"
  },
  subtitlePage: {
    color: GRAY
  }
}));

export default SellingsReport;
