import { useState, useEffect } from "react";
import AssociationsManager from "../AssociationsManager";
import { managementInitials, vendorInitials } from "../../../shared/utils/enum";

const useAssociations = (selectedVendor, selectedManagement, selectedStatus) => {
  const [associations, setAssociations] = useState([]);
  const [shouldGetVendorData, setShouldGetVendorData] = useState(true);
  const [dataIsReady, setDataIsReady] = useState(false);

  useEffect(() => {
    let flagAdded = false;
    let notRepeated = true;

    const groupManagements = (association, associationsArray) => {
      let temporaryArray = associationsArray;
      temporaryArray &&
        temporaryArray.forEach(
          (managementArray, managementIndex) =>
            temporaryArray[managementIndex] &&
            temporaryArray[managementIndex].forEach((associationObject, associationIndex) => {
              if (
                associationObject.management === association.management &&
                associationObject.vendor === association.vendor
              )
                notRepeated = false;
            })
        );

      temporaryArray &&
        temporaryArray.forEach(
          (managementArray, managementIndex) =>
            temporaryArray[managementIndex] &&
            temporaryArray[managementIndex].forEach((associationObject, associationIndex) => {
              if (
                associationObject.management === association.management &&
                associationObject.vendor !== association.vendor &&
                !flagAdded &&
                notRepeated
              ) {
                associationsArray[managementIndex].push(association);
                flagAdded = true;
              }
            })
        );

      return associationsArray;
    };

    const generateList = vendors => {
      vendors.sort((a, b) => (a.management.initials > b.management.initials ? 1 : -1));
      let arrayResult = [];
      let associationsHelper = [...associations];
      vendors &&
        vendors.forEach((vendor, index) => {
          const association = {
            active: vendor.active,
            managementId: vendor.management.id,
            management: vendor.management.initials,
            vendor: vendor.initials,
            vendorId: vendor.id,
            states: vendor.states
          };
          const cloneAssociations = associationsHelper;
          arrayResult = groupManagements(association, cloneAssociations);

          if (!flagAdded && notRepeated) {
            let result = [];
            result.push(association);
            arrayResult.push(result);
          }

          flagAdded = false;
          notRepeated = true;
          associationsHelper = arrayResult;
        });

      arrayResult.forEach(
        (managementArray, managementIndex) =>
          arrayResult[managementIndex] &&
          arrayResult[managementIndex].sort((a, b) => (a.vendor > b.vendor ? 1 : -1))
      );

      setAssociations(arrayResult);
      setShouldGetVendorData(false);
    };

    const listVendors = async () => {
      let vendors = [];

      const onlyVendorSelected = selectedVendor && selectedVendor.initials !== vendorInitials.all;
      const onlyManagementSelected =
        ((selectedVendor && selectedVendor.initials === vendorInitials.all) || !selectedVendor) &&
        selectedManagement &&
        selectedManagement.initials !== managementInitials.all;

      if (onlyVendorSelected) {
        const vendor = await AssociationsManager.getVendorByVendorId(selectedVendor.id);
        vendors.push(vendor);
      } else if (onlyManagementSelected) {
        vendors = await AssociationsManager.listVendorsByManagementIdsOrActive(
          [selectedManagement.id],
          selectedStatus.active
        );
      } else {
        vendors = await AssociationsManager.listVendorsByManagementIdsOrActive(
          null,
          selectedStatus.active
        );
      }

      generateList(vendors);
      setDataIsReady(true);
    };

    shouldGetVendorData && listVendors();
  }, [shouldGetVendorData, associations, selectedManagement, selectedVendor, selectedStatus]);

  return {
    associations,
    setAssociations,
    shouldGetVendorData,
    setShouldGetVendorData,
    dataIsReady
  };
};

export default useAssociations;
