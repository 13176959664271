import React from "react";
import { Grid } from "@material-ui/core";
import Select from "../../../shared/components/Select";

const PageFilter = () => {
  return (
    <>
      <Grid container xs={12} item className="pb-3">
        <Grid item sm xs={12} className={`pt-2 pb-2 pr-4`}>
          <Select label="Semana" placeholder="Últimas 4 semanas" disabled />
        </Grid>
        <Grid item sm xs={12} className={`pt-2 pb-2 pr-4 pl-4`}>
          <Select label="Gerência" placeholder="Todas" disabled />
        </Grid>
        <Grid item sm xs={12} className={`pt-2 pb-2 pr-4 pl-4`}>
          <Select label="Consultoria" placeholder="Todas" disabled />
        </Grid>
        <Grid item sm xs={12} className={`pt-2 pb-2 pr-4 pl-4`}>
          <Select label="Estado" placeholder="Todos" disabled />
        </Grid>
        <Grid item sm xs={12} className={`pt-2 pb-2 pr-2 pl-4`}>
          <Select label="Situação" placeholder="Todas" disabled />
        </Grid>
      </Grid>
    </>
  );
};

export default PageFilter;
