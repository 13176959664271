import { useState } from "react";
export const useModals = setUpdateStateTotalsSelling => {
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const handleSuccessModalOpenClose = () => {
    if (successModalOpen) setUpdateStateTotalsSelling(true);
    setSuccessModalOpen(!successModalOpen);
  };
  const handleErrorModalOpenClose = () => {
    setErrorModalOpen(!errorModalOpen);
  };

  return {
    handleSuccessModalOpenClose,
    handleErrorModalOpenClose,
    successModalOpen,
    errorModalOpen
  };
};
