import React from "react";
import { connect } from "react-redux";
import { SwipeableDrawer, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SECONDARY_COLOR, WHITE, GRAY } from "../../../theme";
import { screenSizes } from "../../../shared/utils/enum";
import { Briefcase, User, Mail, X } from "react-feather";
import Loading from "../../../shared/components/Loading";

const DrawerMessagesStandard = props => {
  const { 
    openDrawer, 
    setOpenDrawer, 
    windowWidth, 
    message, 
    title, 
    messagesFieldName 
  } = props;

  const isMobile = windowWidth < screenSizes.webScreenSize;

  const classes = useStyles({ isMobile: isMobile });

  const toggleDrawer = open => event => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setOpenDrawer(open);
  };

  const getInfoAbout = () => {
    if (message && message.directMessage) {
      if (message.directMessage.customer) 
        return `${message.directMessage.customer.name} - Cliente`;
      else if (message.directMessage.competitor)
        return `${message.directMessage.competitor.name} - Concorrente`;
      else if (message.directMessage.crop) 
        return `${message.directMessage.crop.name} - Cultura`;
    }
    else if (message && message.sellingBalanceMessage) {
      if (message.sellingBalanceMessage.productGroup) 
        return `${message.sellingBalanceMessage.productGroup} - Produto`;
      else if (message.sellingBalanceMessage.rawMaterial)
        return `${message.sellingBalanceMessage.rawMaterial} - Matéria Prima`;
      else if (message.sellingBalanceMessage.site) 
        return `${message.sellingBalanceMessage.site} - Planta`;
    }
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={openDrawer}
      onOpen={toggleDrawer(!openDrawer)}
      onClose={toggleDrawer(!openDrawer)}
      className={classes.drawer}
      disableDiscovery
    >
      {message ? (
        <Grid container className="pt-5 pl-5 pr-5">
          <Grid item xs={11} className="pt-5 pb-2">
            <Typography variant="h4">Mensagem</Typography>
          </Grid>
          <Grid item xs={1} className="pt-2">
            <X
              color={SECONDARY_COLOR}
              size={25}
              className={"pointer"}
              onClick={() => setOpenDrawer(false)}
            />
          </Grid>

          <Grid item xs={12} className="pt-2">
            <Typography variant="h5" className={classes.title}>
              {title}
            </Typography>
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={1} className="pt-5">
              <Briefcase color={SECONDARY_COLOR} size={22} />
            </Grid>
            <Grid container item xs={5} className="pl-1 pt-5">
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.title}>
                  Gerências
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {message.vendors && message.vendors.map(vendor =>
                  <Typography key={vendor.management?.initials} variant="body2" className={`pt-1 ${classes.contentText}`}>
                    {(vendor.management && vendor.management.initials) || "-"}
                  </Typography>)
                }
              </Grid>
            </Grid>
            <Grid container item xs={6} className="pt-5">
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.title}>
                  Consultorias
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {message.vendors && message.vendors.map(vendor =>
                  <Typography key={vendor.initials} variant="body2" className={`pt-1 ${classes.contentText}`}>
                    {vendor.initials || "-"}
                  </Typography>)
                }
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={1} className="pt-5">
              <User color={SECONDARY_COLOR} size={22} />
            </Grid>
            <Grid container item xs={11} className="pl-1 pt-5">
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.title}>
                  Consultor
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" className={`pt-1 ${classes.contentText}`}>
                  {(message.recipientMessage[0] &&
                    message.recipientMessage[0].user &&
                    message.recipientMessage[0].user.name) ||
                    "-"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" className={`pt-1 ${classes.contentText}`}>
                  {(message.recipientMessage[0] &&
                    message.recipientMessage[0].user &&
                    message.recipientMessage[0].user.phone) ||
                    "-"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" className={`pt-1 ${classes.contentText}`}>
                  {(message.recipientMessage[0] &&
                    message.recipientMessage[0].user &&
                    message.recipientMessage[0].user.email) ||
                    "-"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={1} className="pt-5">
              <Mail color={SECONDARY_COLOR} size={22} />
            </Grid>
            <Grid container item xs={11} className="pl-1 pt-5">
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.title}>
                  Mensagem
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" className={`pt-1 ${classes.contentText}`}>
                  <span className={classes.labelTitle}>Motivo:</span>{" "}
                  {(message[messagesFieldName] &&
                    message[messagesFieldName].reason &&
                    message[messagesFieldName].reason.description) ||
                    "-"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" className={`pt-1 ${classes.contentText}`}>
                  <span className={classes.labelTitle}>Sobre:</span> {getInfoAbout()}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" className={`pt-1 ${classes.contentText}`}>
                  {(message[messagesFieldName] && message[messagesFieldName].description) || "-"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Loading loading />
      )}
    </SwipeableDrawer>
  );
};

const useStyles = makeStyles(() => ({
  drawer: props => ({
    "& .MuiDrawer-paperAnchorRight": {
      backgroundColor: WHITE,
      width: props.isMobile ? "75%" : "25%"
    }
  }),
  title: {
    color: SECONDARY_COLOR
  },
  labelTitle: {
    color: SECONDARY_COLOR,
    fontSize: "12px"
  },
  contentText: {
    color: GRAY
  }
}));

const mapStateToProps = state => {
  return {
    windowWidth: state.window.width
  };
};

export default connect(mapStateToProps, null)(DrawerMessagesStandard);
