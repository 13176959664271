import { useState, useEffect } from "react";
import UserManager from "../UserManager";

const useEditUserSelects = () => {
  const [selectedProfile, setSelectedProfile] = useState();
  const [selectedManagement, setSelectedManagement] = useState();
  const [selectedVendor, setSelectedVendor] = useState();

  const [profiles, setProfiles] = useState([]);
  const [managements, setManagements] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [associations, setAssociations] = useState([]);

  useEffect(() => {
    const getProfiles = async () => {
      const data = await UserManager.listProfiles();

      setProfiles(data || []);
    };

    const getManagements = async () => {
      const data = await UserManager.listManagements();

      setManagements(data || []);
    };

    getManagements();
    getProfiles();
  }, []);

  useEffect(() => {
    const getVendors = async managementIds => {
      const data = await UserManager.listVendorsByManagementIds(managementIds);

      setVendors(data || []);
    };

    if (selectedManagement) getVendors([selectedManagement.id]);

    setSelectedVendor(undefined);
  }, [selectedManagement]);

  useEffect(() => {
    setVendors([]);
    setSelectedVendor(undefined);
    setSelectedManagement(undefined);
    setAssociations([]);
  }, [selectedProfile]);

  return {
    selectedProfile,
    setSelectedProfile,
    selectedManagement,
    setSelectedManagement,
    selectedVendor,
    setSelectedVendor,
    profiles,
    associations,
    setAssociations,
    managements,
    vendors
  };
};

export default useEditUserSelects;
