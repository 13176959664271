import { useState } from "react";
import { DataType } from "../../../shared/utils/enum";

export const usePotentialTypeTabs = () => {
  const [customerTotalVolume, setCustomerTotalVolume] = useState(0);
  const [customerYaraTotalVolume, setCustomerYaraTotalVolume] = useState(0);
  const [competitorTotalVolume, setCompetitorTotalVolume] = useState(0);
  const [competitorYaraTotalVolume, setCompetitorYaraTotalVolume] = useState(0);
  const [cropTotalVolume, setCropTotalVolume] = useState(0);
  const [cropYaraTotalVolume, setCropYaraTotalVolume] = useState(0);

  const [selectedTypeTab, setSelectedTypeTabs] = useState(DataType["competitor"].id);

  const handleChangeTab = (event, newValue) => setSelectedTypeTabs(newValue);

  const updateTotalVolume = (index, newValue, newYaraVolume) => {
    switch (index) {
      case DataType["competitor"].id:
        setCompetitorTotalVolume(newValue);
        setCompetitorYaraTotalVolume(newYaraVolume);
        return;
      case DataType["customer"].id:
        setCustomerTotalVolume(newValue);
        setCustomerYaraTotalVolume(newYaraVolume);
        return;
      case DataType["crop"].id:
        setCropTotalVolume(newValue);
        setCropYaraTotalVolume(newYaraVolume);
        return;
      default:
        return;
    }
  };

  return {
    selectedTypeTab,
    setSelectedTypeTabs,
    handleChangeTab,
    customerTotalVolume,
    competitorTotalVolume,
    cropTotalVolume,
    updateTotalVolume,
    customerYaraTotalVolume,
    competitorYaraTotalVolume,
    cropYaraTotalVolume
  };
};
