import { useState } from "react";
import { FormatDataToCreateVendor } from "../FormatDataToCreateVendor";
import AssociationsManager from "../AssociationsManager";

const useAddVendorManagementModal = (
  handleRedrectToEditVendor,
  setSelectedManagement,
  setShoudReloadManagements,
  handleListItems,
  showSuccessNotification
) => {
  const [openedAddVendorManagementModal, setOpenedAddVendorManagementModal] = useState(false);
  const [loadingModalAddButton, setLoadingModalAddButton] = useState(false);

  const handleAddVendor = async (initials, description, management) => {
    setLoadingModalAddButton(true);
    const data = FormatDataToCreateVendor(initials, description, management);

    const result = await AssociationsManager.createVendor(data);
    if (result) {
      setOpenedAddVendorManagementModal(false);
      handleRedrectToEditVendor(result);
    }
    setLoadingModalAddButton(false);
  };

  const handleAddManagement = async initials => {
    setLoadingModalAddButton(true);

    const result = await AssociationsManager.createManagement({ initials });
    if (result) {
      showSuccessNotification("Gerência adicionada com sucesso.");
      setOpenedAddVendorManagementModal(false);
      setShoudReloadManagements(true);
      setSelectedManagement(result);
      handleListItems();
    }
    setLoadingModalAddButton(false);
  };

  return {
    openedAddVendorManagementModal,
    setOpenedAddVendorManagementModal,
    handleAddVendor,
    handleAddManagement,
    loadingModalAddButton
  };
};

export default useAddVendorManagementModal;
