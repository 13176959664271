import React, { useState } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { GRAY, PRIMARY_COLOR, SECONDARY_COLOR } from "../../theme";
import { Calendar } from "react-feather";
import {
  DateRangePicker as DateRangePickerComponent,
  isInclusivelyBeforeDay,
  isInclusivelyAfterDay
} from "react-dates";
import { START_DATE, END_DATE, ICON_AFTER_POSITION } from "react-dates/constants";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import omit from "lodash/omit";
import moment from "moment";
import "moment/locale/pt-br";

const DateRangePicker = props => {
  const {
    allowFutureDate,
    showClearDates,
    setRangeDate,
    maxDayRange,
    startDate,
    endDate,
    label
  } = props;

  const classes = useStyles();

  const [focusedInput, setFocusedInput] = useState(null);

  const onDatesChange = dates => {
    const isStartDateChanged =
      dates.startDate && (!startDate || !startDate.isSame(dates.startDate, "day"));

    if (
      maxDayRange &&
      dates.endDate &&
      isStartDateChanged &&
      isInclusivelyAfterDay(dates.endDate, dates.startDate.clone().add(maxDayRange, "days"))
    ) {
      dates.endDate = null;
    }

    setRangeDate({
      startDate: dates.startDate,
      endDate: dates.endDate
    });
  };

  const onClose = dates => {
    const invalidRange =
      (dates.startDate === null && dates.endDate !== null) ||
      (dates.startDate !== null && dates.endDate === null);

    if (invalidRange) {
      setRangeDate({
        startDate: moment().add(-15, "days"),
        endDate: moment()
      });
    }
  };

  const isOutsideRange = day => {
    if (allowFutureDate === false && isInclusivelyAfterDay(day, moment().add(1, "days")))
      return true;

    if (!maxDayRange) return false;

    if (
      focusedInput === END_DATE &&
      startDate &&
      isInclusivelyAfterDay(day, startDate.clone().add(maxDayRange, "days"))
    )
      return true;

    if (
      focusedInput === START_DATE &&
      endDate &&
      !startDate &&
      isInclusivelyBeforeDay(day, endDate.clone().add(-maxDayRange, "days"))
    )
      return true;
  };

  return (
    <Box className={classes.picker}>
      <label className={classes.label}>{label}</label>
      <DateRangePickerComponent
        {...omit(
          props,
          "maxDayRange",
          "allowFutureDate",
          "showClearDates",
          "setRangeDate",
          "startDate",
          "endDate",
          "label"
        )}
        showClearDates={showClearDates}
        readOnly={true}
        hideKeyboardShortcutsPanel
        startDatePlaceholderText="Início"
        endDatePlaceholderText="Fim"
        onDatesChange={onDatesChange}
        onFocusChange={input => setFocusedInput(input)}
        focusedInput={focusedInput}
        startDate={startDate}
        minimumNights={0}
        endDate={endDate}
        isOutsideRange={isOutsideRange}
        displayFormat="DD/MM/YYYY"
        customInputIcon={<Calendar color={GRAY} />}
        inputIconPosition={ICON_AFTER_POSITION}
        startDateId="startDateId"
        endDateId="endDateId"
        onClose={onClose}
        initialVisibleMonth={() => {
          if (!endDate && !startDate) {
            return moment();
          }
          return focusedInput === END_DATE ? endDate : startDate;
        }}
      />
    </Box>
  );
};

const useStyles = makeStyles({
  label: {
    top: "-8px",
    left: "-4px",
    lineHeight: "1",
    color: PRIMARY_COLOR,
    padding: "0 11px 0 6px",
    zIndex: "999",
    position: "absolute",
    fontSize: "1rem",
    transform: "scale(0.75)",
    backgroundColor: "white"
  },
  picker: {
    position: "relative",

    "& .CalendarDay__selected_span": {
      background: SECONDARY_COLOR,
      color: "white",
      border: "white 1px solid"
    },

    "& .CalendarDay__selected_span:hover": {
      background: PRIMARY_COLOR
    },

    "& .CalendarDay__selected": {
      background: PRIMARY_COLOR,
      color: "white",
      border: "white 1px solid"
    },

    "& .CalendarDay__selected:hover": {
      background: SECONDARY_COLOR,
      color: "white"
    },

    "& .CalendarDay__hovered_span:hover, .CalendarDay__hovered_span": {
      background: PRIMARY_COLOR
    },

    "& .DateInput__small": {
      width: "48%"
    },

    "& .DateInput__small input": {
      padding: "18.5px 14px",
      paddingRight: "0px",
      border: "none",
      fontFamily: "YaraMax, sans-serif"
    },

    "& .DateRangePickerInput": {
      display: "flex"
    },

    "& .DateRangePickerInput_arrow": {
      width: "1%",
      padding: "16px 8px"
    },

    "& .DateRangePickerInput__withBorder": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderRadius: "4px",
      "-webkit-border-radius": "4px",
      width: "100%"
    },

    "& .DateInput_fang, .DateRangePicker_picker": {
      marginTop: "10px"
    },
    "& .DateInput_fang": {
      zIndex: "4"
    },
    "& .DateRangePicker": {
      width: "100%",
      zIndex: "3"
    },
    "& .DateInput_input": {
      padding: "14.5px 12px",
      color: GRAY,
      fontFamily: "YaraMax",
      fontSize: "1rem"
    },

    "& .DateInput_input__focused": {
      borderBottom: "none"
    },

    "@media screen and (max-width: 599px)": {
      "& .DateRangePicker_picker": {
        left: "-90px !important",
        zIndex: "1200"
      }
    }
  }
});

export default DateRangePicker;
