import React from 'react';
import { Grid, LinearProgress, makeStyles, Typography } from "@material-ui/core";
import { GRAY, RED, WHITE } from '../../../theme';

const CompletionPercentageAndBalance = props => {
  const {
    completionPercentage,
    balance,
    isFooter,
  } = props;

  const classes = useStyles({ balance, isFooter });

  return (
    <Grid container>
      <Grid container item xs={12} justify="flex-end" >
        {completionPercentage == null ? "-" : completionPercentage + "%"}
      </Grid>
      <Grid item xs={12}>
        <LinearProgress variant="determinate" value={completionPercentage == null ? 0 : completionPercentage > 100 ? 100 : completionPercentage} />
      </Grid>
      <Grid container item xs={12} justify="flex-end" >
        <Typography className={classes.balance}>{balance}</Typography>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  balance: ({ balance, isFooter }) => {
    return {
      color: isFooter
        ? WHITE
        : balance < 0
          ? RED
          : GRAY,
      fontWeight: "bold"
    }
  }
}));

export default CompletionPercentageAndBalance;