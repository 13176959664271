import { useState, useEffect, useRef, useCallback } from "react";
import ApprovePreRegisteredManager from "../ApprovePreRegisteredManager";

const useSearchBox = (isTypeCompetitor, setSelectedItem) => {
  const [beforeCall, setBeforeCall] = useState(null);
  const [searchTextField, setSearchTextField] = useState("");
  const [searchParameter, setSearchParameter] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchedItems, setSearchedItems] = useState([]);

  const selectNewItem = item => {
    const cloneSearchedItems = searchedItems.map(e => {
      return { ...e, checked: item && e.id === item.id };
    });

    setSelectedItem(item);
    setSearchedItems(cloneSearchedItems);
  };

  const loadData = useCallback(async () => {
    if (searchParameter === "") {
      setSearchedItems([]);
      return;
    }
    setLoading(true);
    const data = isTypeCompetitor
      ? await ApprovePreRegisteredManager.SearchCompetitorByTerm(searchParameter)
      : await ApprovePreRegisteredManager.SearchCustomerByTerm(searchParameter);
    data && setSearchedItems(data);
    setLoading(false);
  }, [searchParameter, isTypeCompetitor]);

  const refLoadData = useRef(loadData);
  const refBeforeCall = useRef(beforeCall);
  useEffect(() => {
    refBeforeCall.current = beforeCall;
    refLoadData.current = loadData;
  });

  useEffect(() => {
    if (refBeforeCall.current) clearTimeout(refBeforeCall.current);

    const idTimeOut = window.setTimeout(() => {
      setSearchParameter(searchTextField);
    }, 1000);

    setBeforeCall(idTimeOut);
  }, [searchTextField]);

  useEffect(() => {
    refLoadData.current();
  }, [searchParameter]);

  return {
    setSearchTextField,
    searchTextField,
    loading,
    selectNewItem,
    searchedItems
  };
};

export default useSearchBox;
