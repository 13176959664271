import DirectMessageReasonService from "../../services/DirectMessageReasonService";
import MessageService from "../../services/MessageService";
import VendorService from "../../services/VendorService";

class NewDirectMessageConsultantManager {
  async listDirectMessageReasons() {
    return DirectMessageReasonService.listDirectMessageReasons().then(
      response => response && response.data
    );
  }

  async createMessageSentByConsultant(message, messageTypeId) {
    return MessageService.createMessageSentByConsultant(message, messageTypeId).then(
      response => response && response.data
    );
  }

  async listCropCompetitorCustomerByTermAndVendorIds(vendorIds, term) {
    return VendorService.listCropCompetitorCustomerByTermAndVendorIds(vendorIds, term).then(
      response => response && response.data
    );
  }
}

export default new NewDirectMessageConsultantManager();
