import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Dialog, Divider } from "@material-ui/core";
import { PRIMARY_COLOR } from "../../../../theme";
import NoNotificationIcon from "../../../img/BellNoNotificationIcon.svg";
import { getMessageTypeById } from "../../../utils/enum";
import { dialogNotificationBox } from "../../../utils/Constants";
import ListItemMessage from "./ListItemMessage";
import UnreadCounter from "../../UnreadCounter";

const MessageCard = props => {
  const {
    showCard,
    setShowCard,
    numberOfMessages,
    hasRecentMessages,
    setRefresh,
    windowWidth,
    isMobile,
    loadingRefreshButton,
    messages,
    userId
  } = props;

  const relativePosition = isMobile
    ? (windowWidth - dialogNotificationBox.width) / 2 - 12
    : windowWidth - (dialogNotificationBox.width + 20);

  const classes = useStyles({
    relativePosition: relativePosition,
    dialogWidth: dialogNotificationBox.width,
    dividerWidth: dialogNotificationBox.dividerWidth,
    maxHeight: dialogNotificationBox.maxHeight,
    maxWidth: dialogNotificationBox.maxWidth
  });

  const [indexOpened, setIndexOpened] = useState();

  return (
    <Dialog
      onClose={() => setShowCard(!showCard)}
      open={showCard}
      className={classes.root}
      classes={{
        paper: `${classes.cardPosition}`
      }}
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
    >
      <Grid container item xs={12} className="mt-3">
        <Grid container item xs={12}>
          <Grid item xs={7} className="pl-4">
            <Typography className={classes.title}>Notificações</Typography>
          </Grid>
          <Grid container item xs={5} className={`pt-1 pr-4 ${classes.counterContainer}`}>
            <UnreadCounter
              isLoading={loadingRefreshButton}
              reloadFunction={() => setRefresh(true)}
              unreadCount={numberOfMessages}
            />
          </Grid>
          <Grid item xs={12} className="pt-3">
            <Divider className={classes.divider} />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          {!hasRecentMessages ? (
            <Grid container item xs={12} className="pt-5 pb-5">
              <img className={classes.img} src={NoNotificationIcon} alt="No Notification Icon" />
              <Grid item xs={12} className={classes.paddingText} align="center">
                <Typography variant="caption">
                  Não existem mensagens enviadas nos últimos 21 dias.
                </Typography>
              </Grid>
            </Grid>
          ) : (
            messages &&
            Object.entries(messages).map((message, index) => (
              <React.Fragment key={message[1].id}>
                <ListItemMessage
                  type={getMessageTypeById(message[1].messageType.id)}
                  sendDate={message[1].sendDate}
                  readDate={message[1].recipientMessage[0].readDate}
                  message={message}
                  index={index}
                  indexOpened={indexOpened}
                  setIndexOpened={setIndexOpened}
                  messageId={message[1].id}
                  userId={userId}
                  setRefresh={setRefresh}
                />
                <Divider className={classes.divider} />
              </React.Fragment>
            ))
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  root: props => ({
    width: `${props.dialogWidth}px`,
    maxHeight: `${props.maxHeight}px`
  }),
  cardPosition: props => ({
    position: "absolute",
    left: `${props.relativePosition}px`,
    top: "60px",
    maxWidth: `${props.maxWidth}px`
  }),
  counterContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  title: {
    fontSize: "18px",
    color: PRIMARY_COLOR
  },
  img: {
    width: 30,
    height: 35,
    margin: "auto",
    display: "flex"
  },
  paddingText: {
    paddingRight: theme.spacing(5.5),
    paddingLeft: theme.spacing(5.5)
  },
  divider: props => ({
    width: props.dividerWidth
  })
}));

export default MessageCard;
