import axios from "../axiosInstance";
import qs from "qs";

class UserService {
  async getUserData() {
    try {
      return axios.get(`user/`, {
        handleError: false
      });
    } catch (error) {
      const message = error.response && error.response.data && error.response.data.message;
      return { data: { requestError: true, message: message } };
    }
  }

  async listUsers() {
    return axios.get("user/users");
  }

  async getUserFromId(userId) {
    return axios.get(`user/user/${userId}`);
  }

  async createUser(user) {
    return axios.post("user/createUser", user);
  }

  async updateUser(user, userId) {
    return axios.put(`user/updateUser/${userId}`, user);
  }

  async listUsersByVendorIds(vendorIds) {
    return axios.get(`user/userByVendor`, {
      params: {
        vendorIds
      },
      paramsSerializer: (params) => qs.stringify(params)
    });
  }
}

export default new UserService();
