import React, { useState, useEffect } from "react";
import Modal from "../../../shared/components/Modal";
import Select from "../../../shared/components/Select";
import {
  FormControlLabel,
  FormControl,
  Typography,
  RadioGroup,
  TextField,
  Radio,
  Grid,
  Box,
  makeStyles
} from "@material-ui/core";
import Loading from "../../../shared/components/Loading";
import { GRAY, PRIMARY_COLOR, SECONDARY_COLOR, LIGHT_GRAY } from "../../../theme";
import AssociationsManager from "../AssociationsManager";

const AddVendorManagementModal = props => {
  const {
    openedModal,
    closeModal,
    handleAddVendor,
    handleAddManagement,
    loadingModalAddButton
  } = props;

  const classes = useStyles();

  const [typeModal, setTypeModal] = useState("management");
  const [initials, setInitials] = useState("");
  const [description, setDescription] = useState("");
  const [managements, setManagements] = useState([]);
  const [selectedManagement, setSelectedManagement] = useState(null);

  useEffect(() => {
    setInitials("");
    setDescription("");
    setSelectedManagement(null);
  }, [typeModal, openedModal]);

  useEffect(() => {
    const getManagements = async () => {
      const newManagements = await AssociationsManager.listManagements();
      newManagements && setManagements(newManagements);
    };

    getManagements();
  }, []);

  const isManagement = typeModal === "management";
  const addButtonEnabled =
    (isManagement && initials) || (!isManagement && initials && selectedManagement);

  const handleCloseModal = () => {
    setTypeModal("management");
    closeModal();
  };

  return (
    <Modal
      secondaryButton={{
        action: () => handleCloseModal(),
        text: "FECHAR",
        variant: "outlined"
      }}
      primaryButton={{
        action: isManagement
          ? () => handleAddManagement(initials)
          : () => handleAddVendor(initials, description, selectedManagement),
        text: loadingModalAddButton ? (
          <Loading
            size={20}
            loading={loadingModalAddButton}
            className={classes.loadingSaveButton}
          />
        ) : (
          "ADICIONAR"
        ),
        variant: "contained",
        color: "primary",
        disabled: !addButtonEnabled || loadingModalAddButton
      }}
      openedModal={openedModal}
      closeModal={() => handleCloseModal()}
      title="Adicionar Gerência ou Consultoria"
      titleAlign="left"
      closeIcon
      id="addStateModal"
    >
      <Box className={classes.modalContent}>
        <Typography align="left" variant="body1" className={`${classes.colorGray}`}>
          Selecione o tipo da criação
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="typeModal"
            name="typeModal"
            value={typeModal}
            onChange={e => setTypeModal(e.target.value)}
          >
            <FormControlLabel
              value="management"
              control={<Radio className={classes.radio} />}
              label={<Typography className={classes.colorGray}>Gerência</Typography>}
              labelPlacement="end"
              className={classes.formControlLabel}
            />
            <FormControlLabel
              value="vendor"
              control={<Radio className={classes.radio} />}
              label={<Typography className={classes.colorGray}>Consultoria</Typography>}
              labelPlacement="end"
              className="mr-0 pl-6"
            />
          </RadioGroup>
        </FormControl>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              className={classes.textfield}
              variant="outlined"
              label="Sigla"
              value={initials}
              onChange={e => setInitials(e.target.value)}
              disabled={!typeModal}
              inputProps={{ maxLength: 255 }}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              label="Gerência"
              options={managements}
              code={selectedManagement && selectedManagement.id}
              handleChange={e => setSelectedManagement(e)}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.initials}
              maxHeightMenu="150"
              returnFullObject
              disabled={isManagement || !typeModal}
              menuPortalTarget={document.getElementById("addVendorManagementModal")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.textfield}
              variant="outlined"
              label="Descrição"
              disabled={isManagement || !typeModal}
              value={description}
              onChange={e => setDescription(e.target.value)}
              inputProps={{ maxLength: 255 }}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

const useStyles = makeStyles(theme => ({
  colorGray: {
    color: GRAY
  },
  radio: {
    "&.Mui-checked": {
      color: PRIMARY_COLOR
    },
    "&.Mui-disabled": {
      color: LIGHT_GRAY
    }
  },
  colorLightGray: {
    color: LIGHT_GRAY
  },
  textfield: {
    width: "100%",
    height: "100%"
  },
  modalContent: {
    height: "210px"
  },
  loadingSaveButton: {
    margin: theme.spacing(2),
    position: "relative",
    color: SECONDARY_COLOR
  }
}));

export default AddVendorManagementModal;
