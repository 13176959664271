import React from "react";
import { connect } from "react-redux";
import { Grid, makeStyles, Button, Typography } from "@material-ui/core";
import Logo from "../../../shared/img/Logo.svg";
import { IDENTITY_PROVIDER_STORAGE_KEY } from "../../../shared/utils/Constants";
import { IdentityProviders } from "../../../shared/utils/enum";
import { handlePopupError, msalInstances } from "../../../shared/utils/AuthUtil";
import { ActionCreators as AuthActions } from "../../../store/AuthStore";
import DefaultRoute from "../../../shared/components/DefaultRoute";

const Login = props => {
  const classes = useStyles();
  const {
    isAuthenticated,
    msalInstance,
    setIdentityProvider,
  } = props;

  msalInstance.handleRedirectPromise();

  const handleLogin = async (identityProviderName) => {
    const instance = msalInstances[identityProviderName];

    sessionStorage.setItem(IDENTITY_PROVIDER_STORAGE_KEY, identityProviderName);
    setIdentityProvider(identityProviderName);

    try {
      await instance.loginPopup();
    }
    catch (error) {
      handlePopupError(error);
    }
  }

  if (isAuthenticated) {
    return (
      <DefaultRoute />
    );
  } else {
    return (
      <Grid container className={`${classes.content}`}>
        <Grid container item xs={12}>
          <Grid item xs={12} className="pl-6 pr-6">
            <img src={Logo} alt="Logo Yara" align="center" className={classes.logo} />
          </Grid>
          <Grid item xs={12} className="pl-6 pr-6 pb-6">
            <Typography variant="h3" align="center">
              Market
              <br />
              Tracker
            </Typography>
          </Grid>
          <Grid item xs={12} className="pl-6 pr-6 pb-8">
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => handleLogin(IdentityProviders.B2B)}
            >
              ENTRAR COM E-MAIL YARA
            </Button>
          </Grid>
          <Grid item xs={12} className="pl-6 pr-6 pb-8">
            <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              onClick={() => handleLogin(IdentityProviders.B2C)}
            >
              ENTRAR COM OUTRO E-MAIL
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
};

const useStyles = makeStyles({
  content: {
    paddingTop: "72px"
  },
  button: {
    width: "320px",
    height: "56px",
    margin: "auto",
    display: "flex"
  },
  logo: {
    margin: "auto",
    display: "flex"
  }
});

const mapStateToProps = state => {
  return {
    isAuthenticated: state.user.isAuthenticated,
    msalInstance: state.authentication.msalInstance
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setIdentityProvider: (identityProvider) => dispatch(AuthActions.setIdentityProvider(identityProvider)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
