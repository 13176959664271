export const PathRoutesMapper = (route, isEdit = false) => {
  const routes = {
    solidMarket: "Mercado Sólido",
    potential: "Potencial",
    sellingBalance: "Limites e Forecast",
    sellingBalanceSearch: "Pesquisa",
    nextYearOrderbook: "Carteira Virada",
    potentialImport: "Importação de Potencial",
    potentialManager: "Gestão de Potencial",
    userManagement: "Gestão de Usuário",
    entriesManagement: "Gestão de Lançamentos",
    editUser: isEdit ? "Edição de Usuário" : "Novo usuário",
    sellingsReport: "Relatório de Vendas",
    associationsManagement: "Gerências e Consultorias",
    editVendor: "Edição de Consultorias",
    backofficeMessage: "Mensagens",
    consultantMessage: "Mensagens",
    competitorCustomerCrop: "Concorrentes, Clientes e Culturas",
    newMessage: "Nova Mensagem",
    preRegistered: "Pre Registros",
    ApprovePreRegistered: "Aprovação"
  };

  return routes[route];
};
