import { useState, useEffect } from "react";
import BackofficeMessageManager from "../BackofficeMessageManager";

const useCreateMessageModalSellingBalance = () => {
  const [sellingBalanceReasons, setSellingBalanceReasons] = useState([]);

  const fetchSellingBalanceAboutOptions = async (vendorIds, term) => {
    return BackofficeMessageManager.getSellingBalanceEntities(vendorIds, term);
  }

  useEffect(() => {
    const getReasons = async () => {
      const data = await BackofficeMessageManager.listSellingBalanceMessageReasons();
      if (data) setSellingBalanceReasons(data);
    };
    getReasons();
  }, []);

  return {
    sellingBalanceReasons,
    fetchSellingBalanceAboutOptions
  };
};

export default useCreateMessageModalSellingBalance;
