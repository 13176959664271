import { useState, useEffect } from "react";
export const useModals = setUpdateStateTotalsSelling => {
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [overVolumeModalOpen, setOverVolumeModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [yearWithWrongVolume, setYearWithWrongVolume] = useState([]);

  const handleSuccessModalOpenClose = () => {
    if (successModalOpen) setUpdateStateTotalsSelling(true);
    setSuccessModalOpen(!successModalOpen);
  };

  const handleErrorModalOpenClose = (
    currentYearTotalValid,
    firstNextYearTotalValid,
    secondNextYearTotalValid,
    thirdNextYearTotalValid
  ) => {
    const copyYearWithWrongVolume = [...yearWithWrongVolume];

    if (currentYearTotalValid === false) copyYearWithWrongVolume.push(new Date().getFullYear());

    if (firstNextYearTotalValid === false)
      copyYearWithWrongVolume.push(new Date().getFullYear() + 1);

    if (secondNextYearTotalValid === false)
      copyYearWithWrongVolume.push(new Date().getFullYear() + 2);

    if (thirdNextYearTotalValid === false)
      copyYearWithWrongVolume.push(new Date().getFullYear() + 3);

    setErrorModalOpen(!errorModalOpen);
    setYearWithWrongVolume(copyYearWithWrongVolume);
  };

  const handleOverVolumeModalOpenClose = () => {
    setOverVolumeModalOpen(!overVolumeModalOpen);
  };

  useEffect(() => {
    if (!errorModalOpen) setYearWithWrongVolume([]);
  }, [errorModalOpen]);

  return {
    handleSuccessModalOpenClose,
    handleErrorModalOpenClose,
    handleOverVolumeModalOpenClose,
    successModalOpen,
    errorModalOpen,
    overVolumeModalOpen,
    yearWithWrongVolume
  };
};
