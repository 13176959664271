import { useState } from "react";
export const useModals = () => {
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [messageErrorTotals, setMessageErrorTotals] = useState();

  const handleSuccessModalOpenClose = () => {
    setSuccessModalOpen(!successModalOpen);
  };

  const handleErrorModalOpenClose = SumOfVolumesErrorMessage => {
    setErrorModalOpen(!errorModalOpen);
    setMessageErrorTotals(SumOfVolumesErrorMessage);
  };

  return {
    handleSuccessModalOpenClose,
    handleErrorModalOpenClose,
    successModalOpen,
    errorModalOpen,
    messageErrorTotals
  };
};
