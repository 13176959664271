import { useState, useEffect } from "react";
import SolidMarketManager from "../SolidMarketManager";
import { startOfWeek, endOfWeek, format } from "date-fns";

export const useStateTabs = (selectedDate, selectedVendor) => {
  const [updateStateTotalsSelling, setUpdateStateTotalsSelling] = useState(false);
  const [selectedStateVendor, setSelectedStateVendor] = useState();
  const [statesInfo, setStatesInfo] = useState(null);
  const [numSteppers, setNumSteppers] = useState(1);
  const [selectedStateTab, setSelectedStateTab] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [pageShouldRerender, setPageShouldReRender] = useState(false);
  const [stateTabsAreReady, setStateTabsAreReady] = useState(false);
  const [updatedTotalsAreReady, setUpdatedTotalsAreReady] = useState(true);

  useEffect(() => {
    setUpdateStateTotalsSelling(true);
    setUpdatedTotalsAreReady(false);
  }, [selectedDate]);

  useEffect(() => {
    if (statesInfo) {
      setSelectedStateVendor(statesInfo[selectedStateTab].stateVendorId);
      setStateTabsAreReady(true);
    }
  }, [selectedStateTab, setSelectedStateVendor, statesInfo]);

  useEffect(() => {
    const GetVendorStates = async () => {
      const data = await SolidMarketManager.getStatesFromVendorId(selectedVendor);
      if (data) {
        setSelectedStateTab(0);
        setNumSteppers(data.length);
        setStatesInfo(data);
        setUpdateStateTotalsSelling(true);
      }
    };
    selectedVendor && GetVendorStates();
  }, [selectedVendor, setUpdateStateTotalsSelling]);

  useEffect(() => {
    const handleTotals = data => {
      let newTotalPending = 0;
      let statesInfoCopy = statesInfo;

      statesInfo.forEach((s, index) => {
        const indexData = data.findIndex(d => d.stateVendorId === s.stateVendorId);
        if (indexData === -1) {
          newTotalPending++;
        } else {
          const currentStateTotals = data[indexData].totals;
          if (currentStateTotals === null) {
            newTotalPending++;
            statesInfoCopy[index] = { ...s, totals: null };
          } else {
            statesInfoCopy[index] = { ...s, totals: currentStateTotals };
          }
        }
      });
      setStatesInfo(statesInfoCopy);
      setTotalPending(newTotalPending);
      setPageShouldReRender(true);
      setPageShouldReRender(false);
      setUpdatedTotalsAreReady(true);
    };

    const getStateTotals = async () => {
      if (!statesInfo) {
        setUpdateStateTotalsSelling(false);
        return;
      }

      const stateVendorIds = statesInfo.map(s => s.stateVendorId);

      const data = await SolidMarketManager.listStateTotalsSelling(
        stateVendorIds,
        format(startOfWeek(selectedDate), "yyyy-MM-dd"),
        format(endOfWeek(selectedDate), "yyyy-MM-dd")
      );

      setUpdateStateTotalsSelling(false);

      data && handleTotals(data);
    };

    updateStateTotalsSelling && getStateTotals();
  }, [statesInfo, updateStateTotalsSelling, setUpdateStateTotalsSelling, selectedDate]);

  return {
    setUpdateStateTotalsSelling,
    selectedStateVendor,
    statesInfo,
    numSteppers,
    selectedStateTab,
    setSelectedStateTab,
    totalPending,
    pageShouldRerender,
    stateTabsAreReady,
    updatedTotalsAreReady,
    setUpdatedTotalsAreReady
  };
};
