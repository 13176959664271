import React, { useState } from "react";
import { List, makeStyles } from "@material-ui/core";
import { WHITE } from "../../../theme";
import { LogOut, FileText, BarChart, Mail, HelpCircle, AlertCircle, Search, ShoppingBag } from "react-feather";
import { routes } from "../../../shared/utils/Constants";
import { Redirect } from "react-router-dom";
import SidebarItem from "./SidebarItem";
import NestedSidebarListItems from "./NestedSidebarListItems";
import NextYearOrderbookIcon from "../../../shared/img/WalletIcon.svg";
import SVGIcon from "../../../shared/components/SVGIcon";

const ConsultantSidebarListItems = props => {
  const { setOpenDrawer, logout } = props;
  const [redirect, setRedirect] = useState(null);

  const classes = useStyles();

  return (
    <List>
      <SidebarItem
        icon={<FileText color={WHITE} size={28} className="pl-1" />}
        name="Mercado Sólido"
        handleClick={() => {
          setOpenDrawer(false);
          setRedirect(<Redirect to={routes.solidMarket} />);
        }}
        divider
      />

      <SidebarItem
        icon={<BarChart color={WHITE} size={28} className="pl-1" />}
        name="Potencial"
        handleClick={() => {
          setOpenDrawer(false);
          setRedirect(<Redirect to={routes.potential} />);
        }}
        divider
      />

      <SidebarItem
        icon={
          <SVGIcon
            src={NextYearOrderbookIcon}
            alt="Carteira Virada"
            className={`${classes.nextYearOrderbookIcon} pl-1`}
          />
        }
        name="Carteira Virada"
        handleClick={() => {
          setOpenDrawer(false);
          setRedirect(<Redirect to={routes.nextYearOrderbook} />);
        }}
        divider
      />

      <NestedSidebarListItems 
        name="Saldo a vender" 
        divider 
        icon={<ShoppingBag className="mr-4" color={WHITE} size={28} />}
      >
        <SidebarItem
          icon={<AlertCircle color={WHITE} size={28} className="pl-1" />}
          name="Limites e Forecast"
          handleClick={() => {
            setOpenDrawer(false);
            setRedirect(<Redirect to={routes.sellingBalance} />);
          }}
          divider
          isSubMenuItem
        />
        <SidebarItem
          icon={<Search color={WHITE} size={28} className="pl-1" />}
          name="Pesquisa"
          handleClick={() => {
            setOpenDrawer(false);
            setRedirect(<Redirect to={routes.sellingBalanceSearch} />);
          }}
          divider
          isSubMenuItem
        /> 
      </NestedSidebarListItems>

      <SidebarItem
        icon={<Mail color={WHITE} size={28} className="pl-1" />}
        name="Mensagens"
        handleClick={() => {
          setOpenDrawer(false);
          setRedirect(<Redirect to={routes.consultantMessage} />);
        }}
        divider
      />

      <SidebarItem
        icon={<HelpCircle color={WHITE} size={28} className="pl-1" />}
        name="Ajuda"
        handleClick={() => {
          window.open(
            "https://yara.sharepoint.com/teams/MarketTracker/SitePages/Consultores.aspx",
            "_blank"
          );
        }}
        divider
      />

      <SidebarItem
        icon={<LogOut color={WHITE} size={28} className="pl-1" />}
        name="Sair"
        handleClick={logout}
      />

      {redirect}
    </List>
  );
};

const useStyles = makeStyles(() => ({
  nextYearOrderbookIcon: {
    width: 28,
    height: 28,
    backgroundColor: WHITE
  }
}));

export default ConsultantSidebarListItems;
