import axios from "../axiosInstance";

class StateVendorCropService {
  async listCropSellings(stateVendorId, startDate, endDate) {
    return await axios.get(`crop/cropsellings/${stateVendorId}`, {
      params: { startDate, endDate }
    });
  }

  async listCropPotentials(stateVendorId, year) {
    return await axios.get(`crop/cropPotential/${stateVendorId}`, {
      params: { year }
    });
  }

  async listCropNextYearOrderbook(stateVendorId, year) {
    return await axios.get(`crop/cropNextYearOrderbook/${stateVendorId}`, {
      params: { year }
    });
  }

  async listCropsByStateVendor(stateVendorId) {
    return await axios.get(`crop/${stateVendorId}`);
  }

  async verifyCropHasVolume(stateVendorCropId) {
    return await axios.get(`crop/verifyhasvolume/${stateVendorCropId}`);
  }
}

export default new StateVendorCropService();
