import React, { useState } from "react";
import ManagementVendorSelects from "../../../shared/components/ManagementVendorSelects";
import { Briefcase } from "react-feather";
import { Grid, makeStyles, Typography, Button } from "@material-ui/core";
import { ChevronDown, ChevronRight } from "react-feather";
import { SECONDARY_COLOR } from "../../../theme";
import CollapsibleTable from "../../../shared/components/CustomTable/CollapsibleTable";
import AddVendorManagementModal from "./AddVendorManagementModal";
import useAssociations from "../customHooks/useAssociations";
import useListAssociationsSelects from "../customHooks/useListAssociationsSelects";
import useAddVendorManagementModal from "../customHooks/useAddVendorManagementModal";
import useRedirectListAssociations from "../customHooks/useRedirectListAssociations";
import { managementInitials, vendorInitials, activeStatus } from "../../../shared/utils/enum";
import GeneralLoading from "../../../shared/components/GeneralLoading";
import { connect } from "react-redux";
import * as action from "../../alertMessage/redux/alertMessageActions";
import PageTitle from "../../../shared/components/PageTitle";
import Select from "../../../shared/components/Select";

const ListAssociations = props => {
  const classes = useStyles();

  const { showSuccessNotification } = props;

  const [shoudReloadManagements, setShoudReloadManagements] = useState(true);

  const {
    selectedVendor,
    setSelectedVendor,
    selectedManagement,
    setSelectedManagement,
    selectedStatus,
    setSelectedStatus,
    openTabs,
    setOpenTabs,
    closeTab,
    setCloseTab
  } = useListAssociationsSelects();

  const {
    associations,
    setAssociations,
    shouldGetVendorData,
    setShouldGetVendorData,
    dataIsReady
  } = useAssociations(selectedVendor, selectedManagement, selectedStatus);

  const { redirect, handleRedrectToEditVendor } = useRedirectListAssociations();

  const handleListItems = () => {
    setAssociations([]);
    setShouldGetVendorData(!shouldGetVendorData);

    const filtered =
      (selectedManagement && selectedManagement.initials !== managementInitials.all) ||
      (selectedVendor && selectedVendor.initials !== vendorInitials.all);

    setOpenTabs(filtered);
  };

  const {
    openedAddVendorManagementModal,
    setOpenedAddVendorManagementModal,
    handleAddVendor,
    handleAddManagement,
    loadingModalAddButton
  } = useAddVendorManagementModal(
    handleRedrectToEditVendor,
    setSelectedManagement,
    setShoudReloadManagements,
    handleListItems,
    showSuccessNotification
  );

  return dataIsReady ? (
    <Grid container className={`pl-8 pt-5 pr-2 pb-4`}>
      <Grid item container xs={12} className="pb-4 pt-3 pr-6">
        <Grid item xs={9}>
          <PageTitle
            title="Gerências e Consultorias"
            icon={
              <Briefcase
                color={SECONDARY_COLOR}
                size={56}
                className={`pr-4 pt-3 ${classes.icon}`}
              />
            }
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="outlined"
            color="secondary"
            className={`${classes.button} ${classes.positionOnEnd}`}
            onClick={() => setOpenedAddVendorManagementModal(true)}
          >
            ADICIONAR
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" align="left" className="pb-2">
          Pesquisar
        </Typography>
      </Grid>
      <Grid item container xs={12} spacing={3}>
        <Grid container item xs={6}>
          <ManagementVendorSelects
            selectedManagement={selectedManagement}
            setSelectedManagement={setSelectedManagement}
            selectedVendor={selectedVendor}
            setSelectedVendor={setSelectedVendor}
            shouldHaveAllVendor
            shoudReloadManagements={shoudReloadManagements}
            setShoudReloadManagements={setShoudReloadManagements}
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            label="Status"
            code={selectedStatus.id}
            maxHeightMenu="150"
            options={activeStatus}
            handleChange={e => setSelectedStatus(e)}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            returnFullObject
            disabled={selectedVendor && selectedVendor.initials !== vendorInitials.all}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            color="primary"
            className={`${classes.button} ${classes.positionOnEnd}`}
            onClick={handleListItems}
          >
            PESQUISAR
          </Button>
        </Grid>
      </Grid>
      <Grid item container xs={12} className="pt-4 pb-4">
        <Grid item xs={2}>
          <Button className={"pl-0"} color="secondary" onClick={() => setOpenTabs(true)}>
            <ChevronDown size={24} className="mr-1" />
            EXPANDIR ABAS
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            color="secondary"
            className={"pl-0"}
            onClick={() => {
              setOpenTabs(false);
              setCloseTab(true);
            }}
          >
            <ChevronRight size={24} className="mr-1" />
            FECHAR ABAS
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} className="pb-4 pr-6">
        <CollapsibleTable
          headers={headers}
          options={tableCellOptions}
          data={associations}
          edit
          loading={shouldGetVendorData}
          expandableEditAction={handleRedrectToEditVendor}
          openTabs={openTabs}
          closeTab={closeTab}
          setCloseTab={setCloseTab}
        />
      </Grid>
      {openedAddVendorManagementModal && (
        <AddVendorManagementModal
          openedModal={openedAddVendorManagementModal}
          closeModal={() => setOpenedAddVendorManagementModal(false)}
          handleAddVendor={handleAddVendor}
          handleAddManagement={handleAddManagement}
          loadingModalAddButton={loadingModalAddButton}
        />
      )}

      {redirect}
    </Grid>
  ) : (
    <GeneralLoading />
  );
};

const headers = [
  {
    field: "management",
    name: "Gerência",
    width: "10%"
  },
  {
    field: "vendor",
    name: "Consultoria",
    width: "10%"
  },
  {
    field: "states",
    name: "Estados",
    width: "53%"
  },
  {
    field: "active",
    name: "Status",
    width: "27%"
  }
];

const tableCellOptions = {
  import: {
    hasImageToggle: false
  }
};

const useStyles = makeStyles(theme => ({
  icon: {
    verticalAlign: "bottom"
  },
  button: {
    width: "180px",
    height: "56px"
  },
  positionOnEnd: {
    display: "flex",
    marginLeft: "auto"
  }
}));

const mapDispatchToProps = dispatch => {
  return {
    showSuccessNotification: message => dispatch(action.showSuccessNotification(message))
  };
};

export default connect(null, mapDispatchToProps)(ListAssociations);
