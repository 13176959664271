import React, { useState } from "react";
import { List, makeStyles } from "@material-ui/core";
import { WHITE } from "../../../theme";
import {
  LogOut,
  BarChart2,
  UploadCloud,
  Users,
  Briefcase,
  Mail,
  PieChart,
  HelpCircle,
  CheckCircle
} from "react-feather";
import { routes } from "../../../shared/utils/Constants";
import { Redirect } from "react-router-dom";
import SidebarItem from "./SidebarItem";
import NestedSidebarListItems from "./NestedSidebarListItems";
import EntriesManagementIcon from "../../../shared/img/FileSettings.svg";
import BookIconWhite from "../../../shared/img/BookIconWhite.svg";

const BackofficeSidebarListItems = props => {
  const { setOpenDrawer, logout } = props;
  const [redirect, setRedirect] = useState(null);

  const classes = useStyles();

  return (
    <List>
      <NestedSidebarListItems name="Mercado sólido" divider>
        <SidebarItem
          icon={
            <img
              src={EntriesManagementIcon}
              alt="Gestão de lançamentos"
              className={`${classes.img} pl-1`}
            />
          }
          name="Gestão de lançamentos"
          handleClick={() => {
            setOpenDrawer(false);
            setRedirect(<Redirect to={routes.entriesManagement} />);
          }}
          divider
          isSubMenuItem
        />

        <SidebarItem
          icon={
            <img src={BookIconWhite} alt="Relatório de vendas" className={`${classes.img} pl-1`} />
          }
          name="Relatório de vendas"
          handleClick={() => {
            setOpenDrawer(false);
            setRedirect(<Redirect to={routes.sellingsReport} />);
          }}
          isSubMenuItem
        />
      </NestedSidebarListItems>

      <NestedSidebarListItems name="Potencial" divider>
        <SidebarItem
          icon={<BarChart2 color={WHITE} size={28} className="pl-1" />}
          name="Gestão de potencial"
          handleClick={() => {
            setOpenDrawer(false);
            setRedirect(<Redirect to={routes.potentialManager} />);
          }}
          divider
          isSubMenuItem
        />

        <SidebarItem
          icon={<UploadCloud color={WHITE} size={28} className="pl-1" />}
          name="Importação de potencial"
          handleClick={() => {
            setOpenDrawer(false);
            setRedirect(<Redirect to={routes.potentialImport} />);
          }}
          isSubMenuItem
        />
      </NestedSidebarListItems>

      <NestedSidebarListItems name="Configurações" divider>
        <SidebarItem
          icon={<PieChart color={WHITE} size={28} className="pl-1" />}
          name="Concorrentes, Clientes e Culturas"
          handleClick={() => {
            setOpenDrawer(false);
            setRedirect(<Redirect to={routes.competitorCustomerCrop} />);
          }}
          divider
          isSubMenuItem
        />

        <SidebarItem
          icon={<Briefcase color={WHITE} size={28} className="pl-1" />}
          name="Gerências e consultorias"
          handleClick={() => {
            setOpenDrawer(false);
            setRedirect(<Redirect to={routes.associationsManagement} />);
          }}
          divider
          isSubMenuItem
        />

        <SidebarItem
          icon={<Users color={WHITE} size={28} className="pl-1" />}
          name="Gestão de usuários"
          handleClick={() => {
            setOpenDrawer(false);
            setRedirect(<Redirect to={routes.userManagement} />);
          }}
          isSubMenuItem
        />
      </NestedSidebarListItems>

      <SidebarItem
        icon={<Mail color={WHITE} size={28} className="pl-1" />}
        name="Mensagens"
        handleClick={() => {
          setOpenDrawer(false);
          setRedirect(<Redirect to={routes.backOfficeMessage} />);
        }}
        divider
      />

      <SidebarItem
        icon={<CheckCircle color={WHITE} size={28} className="pl-1" />}
        name="Aprovação de cadastros"
        handleClick={() => {
          setOpenDrawer(false);
          setRedirect(<Redirect to={routes.preRegistered} />);
        }}
        divider
      />

      <SidebarItem
        icon={<HelpCircle color={WHITE} size={28} className="pl-1" />}
        name="Ajuda"
        handleClick={() => {
          window.open(
            "https://yara.sharepoint.com/teams/MarketTracker/SitePages/Consultores.aspx",
            "_blank"
          );
        }}
        divider
      />

      <SidebarItem
        icon={<LogOut color={WHITE} size={28} className="pl-1" />}
        name="Sair"
        handleClick={logout}
      />
      {redirect}
    </List>
  );
};

const useStyles = makeStyles(() => ({
  img: {
    width: 28,
    color: WHITE
  }
}));

export default BackofficeSidebarListItems;
