import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { GRAY, SECONDARY_COLOR } from "../../../theme";
import { UploadCloud } from "react-feather";
import DropzoneFiles from "./DropzoneFiles";
import { connect } from "react-redux";
import { useModals } from "../customHooks/useModals";
import { useImportEvents } from "../customHooks/useImportEvents";
import Modal from "../../../shared/components/Modal";
import ErrorImportPotential from "../../../shared/img/ErrorImportPotential.svg";
import CustomTable from "../../../shared/components/CustomTable/CustomTable";
import FileError from "../../../shared/img/ImportedError.svg";
import FileSuccess from "../../../shared/img/ImportedSuccess.svg";
import PotentialImportManager from "../../potentialImport/PotentialImportManager";
import GeneralLoading from "../../../shared/components/GeneralLoading";
import PageTitle from "../../../shared/components/PageTitle";

const MAX_NUM_IMPORT_EVENTS = 4;

const getPotentialReports = async importEventsId => {
  await PotentialImportManager.getPotentialReport(importEventsId);
};

const PotentialImport = props => {
  const { userId } = props;
  const classes = useStyles();

  const { importEvents, setReloadImportEvents, dataIsReady } = useImportEvents(
    MAX_NUM_IMPORT_EVENTS
  );

  const {
    handleOpenCloseSuccessModal,
    handleOnUploadSuccess,
    handleOnUploadError,
    errorModalMessage,
    successModalOpen,
    errorModalOpen
  } = useModals(userId, setReloadImportEvents);

  const headers = [
    {
      field: "importDate",
      name: "Data de importação"
    },
    {
      field: "userName",
      name: "Usuário"
    },
    {
      field: "status",
      name: "Status"
    },
    {
      field: "import",
      name: "Importado",
      align: "center",
      alignContent: "center"
    }
  ];

  const tableCellOptions = {
    import: {
      hasImageToggle: true,
      fieldToggle: "dataError",
      imageSuccess: FileSuccess,
      imageError: FileError,
      imageSuccessAlt: "Planilha importada com sucesso",
      imageErrorAlt: "Planilha não importada",
      onClick: getPotentialReports,
      onClickParams: ["id"]
    }
  };

  return dataIsReady ? (
    <Grid container className={`pl-8 pt-8 pr-8 pb-4`}>
      <Grid item xs={12}>
        <PageTitle
          title="Importação de Potencial"
          icon={
            <UploadCloud
              color={SECONDARY_COLOR}
              size={48}
              className={`${classes.iconUploadCloud} pr-3`}
            />
          }
        />
      </Grid>
      <Grid item xs={12} className="pb-2">
        <Typography variant="h5" align="left">
          Importação
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography align="left" className={classes.subtitlePage} variant="body1">
          Para fazer a importação o arquivo deverá estar em .xlsx.
        </Typography>
      </Grid>
      <Grid item xs={5} className="pt-4">
        <DropzoneFiles
          onUploadError={message => handleOnUploadError(message)}
          onUploadSuccess={async uploadedFiles => await handleOnUploadSuccess(uploadedFiles)}
          maxQuantityFiles={1}
          acceptedTypes=".xlsx"
          maxSizeQuantityFiles={2}
          descriptionText={
            "Arraste e solte a planilha aqui ou clique abaixo para selecionar o arquivo"
          }
        />
        <Modal
          openedModal={successModalOpen}
          closeModal={() => handleOpenCloseSuccessModal()}
          title="Potencial importado com sucesso!"
          closeIcon
          primaryButton={{
            action: () => handleOpenCloseSuccessModal(),
            text: "FECHAR"
          }}
          titleIcon={
            <UploadCloud
              color={SECONDARY_COLOR}
              size={56}
              className={`${classes.iconUploadCloud} pr-1`}
            />
          }
        >
          <Typography align="center" className={classes.contentModal}>
            O arquivo de potencial foi importado com sucesso.
          </Typography>
        </Modal>
        <Modal
          openedModal={errorModalOpen}
          closeModal={() => handleOnUploadError()}
          title="Erro no arquivo"
          closeIcon
          primaryButton={{
            action: () => handleOnUploadError(),
            text: "FECHAR"
          }}
          titleIcon={<img src={ErrorImportPotential} alt="Error import potential" />}
        >
          <Typography align="center" className={classes.contentModal}>
            {errorModalMessage}
          </Typography>
        </Modal>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" align="left" className="pt-8 pb-3">
          Últimas importações
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomTable headers={headers} data={importEvents} options={tableCellOptions} />
      </Grid>
    </Grid>
  ) : (
    <GeneralLoading />
  );
};

const useStyles = makeStyles(theme => ({
  iconUploadCloud: {
    verticalAlign: "bottom",
    color: SECONDARY_COLOR
  },
  subtitlePage: {
    color: GRAY
  },
  contentModal: {
    color: GRAY
  }
}));

const mapStateToProps = state => {
  return {
    userId: state.user.id
  };
};

export default connect(mapStateToProps, null)(PotentialImport);
