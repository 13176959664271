import React from "react";
import { makeStyles, TableCell, TableRow, Tooltip, Checkbox } from "@material-ui/core";
import { LIGHT_GRAY } from "../../../theme";
import CheckedCheckbox from "../../../shared/img/CheckedCheckbox.svg";

const SelectableRow = props => {
  const { data, isTypeCompetitor, selectedItem, selectNewItem } = props;
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell>
        <label className={`${classes.lightGrayColor} ${classes.label} pr-4`}>Nome</label>
        <Tooltip title={data.name.toUpperCase()} placement="top-start">
          <span className={classes.truncateField}>
            {data && data.name && data.name.toUpperCase()}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <label className={`${classes.lightGrayColor} ${classes.label} pr-4`}>
          {isTypeCompetitor ? "POR" : "Tipo"}
        </label>
        <Tooltip
          title={
            isTypeCompetitor
              ? data && data.lastChangeUser && data.lastChangeUser.toUpperCase()
              : data && data.customerType && data.customerType.toUpperCase()
          }
          placement="top-start"
        >
          <span className={classes.truncateField}>
            {isTypeCompetitor
              ? data && data.lastChangeUser && data.lastChangeUser.toUpperCase()
              : data && data.customerType && data.customerType.toUpperCase()}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <label className={`${classes.lightGrayColor} ${classes.label} pr-4`}>Selecionar</label>
        <Checkbox
          checked={!!data.checked}
          onChange={() => {
            selectedItem && selectedItem.id === data.id ? selectNewItem() : selectNewItem(data);
          }}
          inputProps={{ "aria-label": "Select correspondent item" }}
          className={`p-0 ${classes.checkbox}`}
          checkedIcon={
            <img src={CheckedCheckbox} alt="Checked icon" className={classes.checkdIcon} />
          }
        />
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles(theme => ({
  label: {
    textTransform: "uppercase",
    float: "left"
  },
  truncateField: {
    display: "block",
    float: "left",
    width: "65%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  lightGrayColor: {
    color: LIGHT_GRAY
  },
  checkdIcon: {
    width: "22px",
    height: "24px",
    marginLeft: "1px",
    marginRight: "1px"
  },
  checkbox: {
    display: "flex"
  }
}));

export default SelectableRow;
