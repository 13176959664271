import MessageService from "../../services/MessageService";
import UserService from "../../services/UserService";
import StateService from "../../services/StateService";
import StateVendorCropService from "../../services/StateVendorCropService";
import DirectMessageReasonService from "../../services/DirectMessageReasonService";
import VendorService from "../../services/VendorService";
import SellingBalanceService from "../../services/SellingBalanceService";

class BackofficeMessageManager {
  async listMessageType() {
    return MessageService.listMessageType().then(response => response && response.data);
  }
  async listUsersByVendorIds(vendorIds) {
    return UserService.listUsersByVendorIds(vendorIds).then(
      response => response && response.data
    );
  }
  async listStatesVendorByVendorIds(vendorIds) {
    return StateService.getStatesFromVendorIds(vendorIds).then(
      response => response && response.data
    );
  }
  async listCropsByStateVendorId(stateVendorId) {
    return StateVendorCropService.listCropsByStateVendor(stateVendorId).then(
      response => response && response.data
    );
  }
  async listAdjustmentReasons() {
    return MessageService.listMessageReason().then(response => response && response.data);
  }
  async createMessage(message, messageTypeId) {
    return MessageService.createMessage(message, messageTypeId).then(
      response => response && response.data
    );
  }
  async listAdjustmentMessages(parameters = []) {
    parameters.forEach(param => {
      if (param.label === "startDate" || param.label === "endDate")
        param.value = param.value.format("YYYY-MM-DD HH:mm:ss");
    });

    return MessageService.listAdjustmentMessages(parameters).then(
      response => response && response.data
    );
  }

  async listSellingBalanceMessages(parameters = []) {
    parameters.forEach(param => {
      if (param.label === "startDate" || param.label === "endDate")
        param.value = param.value.format("YYYY-MM-DD HH:mm:ss");
    });

    return MessageService.listSellingBalanceMessages(parameters).then(
      response => response && response.data
    );
  }
  
  async listReportMessages(parameters = []) {
    parameters.forEach(param => {
      if (param.label === "startDate" || param.label === "endDate")
        param.value = param.value.format("YYYY-MM-DD HH:mm:ss");
    });

    return MessageService.listReportMessages(parameters).then(response => {
      if (!response || !response.data) {
        return;
      }

      const xlsxMessageReportFile = new Blob([response.data], {
        type: response.headers["content-type"]
      });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(xlsxMessageReportFile, "Relatório de Mensagens");
      } else {
        const URLfile = URL.createObjectURL(xlsxMessageReportFile);
        const link = document.createElement("a");
        link.setAttribute("download", "Relatorio de Mensagens.xlsx");
        link.href = URLfile;
        link.click();
      }
    });
  }
  async listDirectMessagesSentByConsultant(userId, parameters = []) {
    userId && parameters.push({ label: "userId", value: userId });
    parameters.forEach(param => {
      if (param.label === "startDate" || param.label === "endDate")
        param.value = param.value.format("YYYY-MM-DD HH:mm:ss");
    });

    return MessageService.listDirectMessagesSentByConsultant(parameters).then(
      response => response && response.data
    );
  }
  async listDirectMessagesSentByBackoffice(parameters = []) {
    parameters.forEach(param => {
      if (param.label === "startDate" || param.label === "endDate")
        param.value = param.value.format("YYYY-MM-DD HH:mm:ss");
    });

    return MessageService.listDirectMessagesSentByBackoffice(parameters).then(
      response => response && response.data
    );
  }

  async listDirectMessageReasons() {
    return DirectMessageReasonService.listDirectMessageReasons().then(
      response => response && response.data
    );
  }

  async listSellingBalanceMessageReasons() {
    return MessageService.listSellingBalanceMessageReason().then(
      response => response && response.data
    );
  }

  async listCropCompetitorCustomerByTermAndVendorIds(vendorIds, term) {
    return VendorService.listCropCompetitorCustomerByTermAndVendorIds(vendorIds, term).then(
      response => response && response.data
    );
  }

  async getSellingBalanceEntities(vendorIds, term) {
    return SellingBalanceService.getEntitiesByVendorIdsAndTerm(vendorIds, term).then(
      response => response && response.data
    );
  }
}

export default new BackofficeMessageManager();
