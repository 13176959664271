import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CustomTable from '../../../shared/components/CustomTable/CustomTable';
import Loading from '../../../shared/components/Loading';
import { sellingBalanceSearchPeriods } from '../../../shared/utils/enum';
import { SECONDARY_COLOR } from '../../../theme';
import CompletionAndBalanceList from './CompletionAndBalanceList';
import ProductGroupTableCell from './ProductGroupTableCell';

const ForecastGroupsTable = props => {
  const {
    isMobile,
    forecastGroupsData,
    isLoading,
    searchPeriod,
  } = props;

  const classes = useStyles();
  const [forecastGroups, setForecastGroups] = useState([]);

  useEffect(() => {
    if (forecastGroupsData) {
      setForecastGroups(forecastGroupsData.map(data => ({
        siteInitials: (
          <Typography variant={isMobile ? "subtitle2" : "h6"}>
            {data.siteInitials}
          </Typography>
        ),
        productGroup: (
          <ProductGroupTableCell
            isMobile={isMobile}
            productGroup={data.productGroup}
            siteInitials={data.siteInitials}
            rawMaterials={data.rawMaterials}
          />
        ),
        completionAndBalance: (
          <CompletionAndBalanceList
            isMobile={isMobile}
            completionAndBalancesData={data.forecastEvolutions}
            productGroup={data.productGroup}
            siteInitials={data.siteInitials}
            searchPeriod={searchPeriod}
          />
        ),
      })));
    }
  }, [forecastGroupsData, isMobile, searchPeriod]);

  const tableHeaders = [
    {
      field: "siteInitials",
      name: <Typography className={classes.headers}>Planta</Typography>
    },
    {
      field: "productGroup",
      name: <Typography className={classes.headers}>Produto</Typography>
    },
    {
      field: "completionAndBalance",
      name: (
        <Typography className={classes.headers} align="center">
          {searchPeriod === sellingBalanceSearchPeriods.weekly ? "Realizado + Carteira" : "Realizado / Saldo"}
        </Typography>
      ),
    },
  ]

  const options = {
    siteInitials: {
      columnClassName: classes.columns,
    },
    productGroup: {
      columnClassName: classes.columns,
    },
    completionAndBalance: {
      columnClassName: classes.completionAndBalanceColumn,
    },
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        {isLoading ? (
          <Loading size={32} loading={isLoading} />
        ) : (
          <CustomTable
            headers={tableHeaders}
            data={forecastGroups}
            options={options}
            shouldOverflow={false}
          />
        )}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  headers: {
    color: SECONDARY_COLOR,
    fontSize: "0.875rem",
  },
  columns: {
    verticalAlign: "top",
  },
  completionAndBalanceColumn: {
    verticalAlign: "top",
    minWidth: "45%",
  },
}));

export default ForecastGroupsTable;