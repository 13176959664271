export const IDENTITY_PROVIDER_STORAGE_KEY = "MarketTracker-IdentityProvider";

export const routes = {
  solidMarket: "/solidMarket",
  potential: "/potential",
  sellingBalance: "/sellingBalance",
  sellingBalanceSearch: "/sellingBalanceSearch",
  potentialImport: "/potentialImport",
  potentialManager: "/potentialManager",
  entriesManagement: "/entriesManagement",
  logIn: "/login/",
  userManagement: "/userManagement",
  nextYearOrderbook: "/nextYearOrderbook",
  sellingsReport: "/sellingsReport",
  editUser: "/userManagement/editUser",
  associationsManagement: "/associationsManagement",
  editVendor: "/associationsManagement/editVendor",
  backOfficeMessage: "/backofficeMessage",
  competitorCustomerCrop: "/competitorCustomerCrop",
  consultantMessage: "/consultantMessage",
  newDirectMessageConsultant: "/consultantMessage/newMessage",
  preRegistered: "/preRegistered",
  approvePreRegistered: "preRegistered/ApprovePreRegistered/"
};

export const dialogNotificationBox = {
  width: 360,
  maxWidth: 324,
  dividerWidth: 316,
  maxHeight: 340,
  minItemHeight: 62,
  widthNotificationBar: 2,
  maxCropCharacters: 8
};

export const competitorYaraName = "YARA";
