import CompetitorCustomerCropManager from "../CompetitorCustomerCropManager";

const useCreateCrop = () => {
  const handleCreateCrop = async crop => {
    const response = await CompetitorCustomerCropManager.createCrop(crop);

    return response;
  };

  return {
    handleCreateCrop
  };
};

export default useCreateCrop;
