import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import Modal from "./Modal";
import { GRAY, PRIMARY_COLOR, SECONDARY_COLOR } from "../../theme";
import Loading from "./Loading";

const ConfirmationModal = props => {
  const classes = useStyles();

  const {
    openedModal,
    handleOpenClose,
    title,
    subtitle,
    titleIcon,
    secondaryButtonText,
    secondaryAction,
    primaryButtonText,
    primaryAction,
    loading
  } = props;

  return (
    openedModal && (
      <Modal
        primaryButton={{
          action: () => !loading && primaryAction(),
          text: primaryButtonText,
          variant: "contained",
          className: classes.primaryButtonColor,
          disabled: loading
        }}
        secondaryButton={{
          action: () => !loading && secondaryAction(),
          text: secondaryButtonText,
          variant: "outlined",
          disabled: loading
        }}
        openedModal={openedModal}
        closeModal={() => !loading && handleOpenClose()}
        title={!loading && title}
        titleIcon={titleIcon}
        closeIcon
      >
        {!loading ? (
          <Typography variant="body1" align="center" className={classes.colorSubtitle}>
            {subtitle}
          </Typography>
        ) : (
          <Loading size={40} loading={loading} className={classes.loading} />
        )}
      </Modal>
    )
  );
};

const useStyles = makeStyles(theme => ({
  colorSubtitle: {
    color: GRAY
  },
  primaryButtonColor: {
    backgroundColor: PRIMARY_COLOR,
    width: "100%",
    height: "56px"
  },
  loading: {
    marginTop: "32px",
    margin: "auto",
    display: "flex",
    color: SECONDARY_COLOR
  }
}));

export default ConfirmationModal;
