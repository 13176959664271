import React, { useEffect, useState, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch } from "react-router-dom";
import { Layout } from "./features/layout";
import Demo from "./features/pallete";
import Login from "./features/login";
import SolidMarket from "./features/solidMarket";
import Potential from "./features/potential";
import NextYearOrderbook from "./features/nextYearOrderbook";
import PotentialImport from "./features/potentialImport";
import PotentialManager from "./features/potentialManager";
import NewDirectMessageConsultant from "./features/newDirectMessageConsultant";
import DeniedAccess from "./shared/components/DeniedAccess";
import LoginError from "./shared/components/LoginError";
import PageNotFound from "./shared/components/PageNotFound";
import DefaultRoute from "./shared/components/DefaultRoute";
import { profiles, accessType } from "./shared/utils/enum";
import * as UserActions from "./store/UserStore";
import LoginManager from "./features/login/LoginManager";
import EntriesManagement from "./features/entriesManagement";
import SellingsReport from "./features/sellingsReport";
import BackofficeMessage from "./features/backofficeMessage";
import ConsultantMessage from "./features/consultantMessage";
import ExpiredSection from "./features/expiredSectionModal";
import { ListAssociations, EditVendor } from "./features/associationsManagement";
import { EditUser, UserManagement } from "./features/userManagement";
import { CompetitorCustomerCrop } from "./features/competitorCustomerCrop";
import ApprovePreRegistered from "./features/approvePreRegistered";
import ListPreRegistereds from "./features/listPreRegistereds";
import SellingBalance from "./features/sellingBalance";
import SellingBalanceSearch from "./features/sellingBalanceSearch";
import { useAccount, useIsAuthenticated } from "@azure/msal-react";
import GeneralLoading from "./shared/components/GeneralLoading";
import { handlePopupError } from "./shared/utils/AuthUtil";
import { IDENTITY_PROVIDER_STORAGE_KEY } from "./shared/utils/Constants";

const Routes = props => {
  const {
    authenticateUser,
    isAuthenticated,
    userProfiles,
    updateUser,
    msalInstance,
    protectedResources,
    unauthenticateUser
  } = props;
  
  const currentIdentityProvider = sessionStorage.getItem(IDENTITY_PROVIDER_STORAGE_KEY);
  const loggedInUsername = useRef("");

  const account = useAccount(msalInstance.getAllAccounts()[0] || {});
  const isAuthenticatedMsal = useIsAuthenticated(account);

  const [messageError, setMessageError] = useState("");
  const [loading, setLoading] = useState(false);
  const [shouldAttemptSilentSso, setShouldAttemptSilentSso] = useState(!!currentIdentityProvider);
  const [authenticationError, setAuthenticationError] = useState(false);

  const handleLogout = async () => {
    loggedInUsername.current = "";

    const logoutRequest = {
      account: account,
      postLogoutRedirectUri: window.location.origin,
    }

    try {
      unauthenticateUser();
      sessionStorage.clear();
      await msalInstance.logoutPopup(logoutRequest);
    }
    catch (error) {
      handlePopupError(error);
    }
  }

  const getUserData = useCallback(async () => {
    setLoading(true);
    setAuthenticationError(false);

    const data = await LoginManager.getUserData();

    if (!data || data.unauthorized) {
      sessionStorage.clear();
      loggedInUsername.current = "";
      setAuthenticationError(true);
    }
    else if (data.requestError) {
      sessionStorage.clear();
      loggedInUsername.current = "";
      setMessageError(data.message);
    }
    else {
      updateUser(data);
      authenticateUser();
    }

    setLoading(false);
  }, [updateUser, authenticateUser]);

  const attemptSilentSso = useCallback(async (instance, scopes) => {
    try {
      await instance.ssoSilent({
        scopes
      });
    }
    finally {
      setShouldAttemptSilentSso(false);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticatedMsal && account) {
      msalInstance.setActiveAccount(account);

      if (account.username !== loggedInUsername.current) {
        getUserData();
        loggedInUsername.current = account.username;
      }
    }
    else if (shouldAttemptSilentSso) {
      attemptSilentSso(msalInstance, protectedResources.mainApi.scopes);
    }
  }, [
    isAuthenticatedMsal,
    account,
    getUserData,
    msalInstance,
    protectedResources,
    attemptSilentSso,
    shouldAttemptSilentSso
  ]);

  const isConsultant =
    userProfiles &&
    userProfiles.find(
      p =>
        p.initials === profiles.commercialConsultant.initials ||
        p.initials === profiles.hortiFrutiConsultant.initials
    );

  const isBackOffice =
    userProfiles && userProfiles.find(p => p.initials === profiles["backOffice"].initials);

  const isRepresentative =
    userProfiles && userProfiles.find(p => p.initials === profiles["representative"].initials);

  const hasUserProfile = userProfiles && userProfiles.length > 0;

  const PrivateRoute = ({ Comp, accesses, ...rest }) => {
    return (
      <Route
        {...rest}
        render={componentProps =>
          (isConsultant && accesses.includes(accessType.consultant)) ||
          (isBackOffice && accesses.includes(accessType.backOffice)) ||
          (isRepresentative && accesses.includes(accessType.representative)) ? (
            <Comp {...componentProps} />
          ) : (
            <Redirect to={{ pathname: "/deniedAccess" }} />
          )
        }
      />
    );
  };

  if (loading || shouldAttemptSilentSso) {
    return <GeneralLoading />;
  }

  if (!isAuthenticatedMsal || authenticationError) {
    return (
      <Layout>
        <Switch>
          <Route exact path="/login/" component={Login} />
          <Route exact path="/pallete/" component={Demo} />
          <Redirect to="/login/" />
        </Switch>
      </Layout>
    );
  } else if (messageError) {
    return (
      <Switch>
        <Route
          exact
          path="/loginError/"
          component={() => <LoginError messageError={messageError} logout={handleLogout} />}
        />
        <Redirect to="/loginError/" />
      </Switch>
    );
  } else if (isAuthenticatedMsal && hasUserProfile) {
    return (
      <Layout showHeader={isAuthenticated} showSidebar={isAuthenticated} logout={handleLogout}>
        <ExpiredSection />
        <Switch>
          <Route exact path="/" component={DefaultRoute} />
          <Route exact path="/login/" component={Login} />
          <PrivateRoute
            exact
            path="/solidMarket/"
            Comp={SolidMarket}
            accesses={[accessType.consultant]}
          />
          <PrivateRoute
            exact
            path="/potential/"
            Comp={Potential}
            accesses={[accessType.consultant]}
          />
          <PrivateRoute
            exact
            path="/nextYearOrderbook/"
            Comp={NextYearOrderbook}
            accesses={[accessType.consultant]}
          />
          <PrivateRoute
            exact
            path="/consultantMessage/"
            Comp={ConsultantMessage}
            accesses={[accessType.consultant, accessType.representative]}
          />
          <PrivateRoute
            exact
            path="/consultantMessage/newMessage/"
            Comp={NewDirectMessageConsultant}
            accesses={[accessType.consultant]}
          />
          <PrivateRoute
            exact
            path="/sellingBalance/"
            Comp={SellingBalance}
            accesses={[accessType.consultant, accessType.representative]}
          />
          <PrivateRoute
            exact
            path="/sellingBalanceSearch/"
            Comp={SellingBalanceSearch}
            accesses={[accessType.consultant, accessType.representative]}
          />
          <PrivateRoute
            exact
            path="/potentialImport/"
            Comp={PotentialImport}
            accesses={[accessType.backOffice]}
          />
          <PrivateRoute
            exact
            path="/potentialManager/"
            Comp={PotentialManager}
            accesses={[accessType.backOffice]}
          />
          <PrivateRoute
            exact
            path="/userManagement/"
            Comp={UserManagement}
            accesses={[accessType.backOffice]}
          />
          <PrivateRoute
            exact
            path="/entriesManagement/"
            Comp={EntriesManagement}
            accesses={[accessType.backOffice]}
          />
          <PrivateRoute
            exact
            path="/userManagement/editUser/:userId?"
            Comp={EditUser}
            accesses={[accessType.backOffice]}
          />
          <PrivateRoute
            exact
            path="/sellingsReport/"
            Comp={SellingsReport}
            accesses={[accessType.backOffice]}
          />
          <PrivateRoute
            exact
            path="/associationsManagement/"
            Comp={ListAssociations}
            accesses={[accessType.backOffice]}
          />
          <PrivateRoute
            exact
            path="/associationsManagement/editVendor/:vendorId"
            Comp={EditVendor}
            accesses={[accessType.backOffice]}
          />
          <PrivateRoute
            exact
            path="/competitorCustomerCrop/"
            Comp={CompetitorCustomerCrop}
            accesses={[accessType.backOffice]}
          />
          <PrivateRoute
            exact
            path="/backofficeMessage"
            Comp={BackofficeMessage}
            accesses={[accessType.backOffice]}
          />
          <PrivateRoute
            exact
            path="/preRegistered/ApprovePreRegistered/:type/:preRegisteredId"
            Comp={ApprovePreRegistered}
            accesses={[accessType.backOffice]}
          />
          <PrivateRoute
            exact
            path="/preRegistered"
            Comp={ListPreRegistereds}
            accesses={[accessType.backOffice]}
          />
          <Route exact path="/deniedAccess" component={DeniedAccess} />
          <Route exact path="/notFound" component={PageNotFound} />

          <Redirect to="/notFound" />
        </Switch>
      </Layout>
    );
  } else {
    return (
      <GeneralLoading />
    );
  }
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.user.isAuthenticated,
    msalInstance: state.authentication.msalInstance,
    protectedResources: state.authentication.protectedResources
  };
};

const mapDispatchToProps = dispatch => {
  return {
    authenticateUser: () => dispatch(UserActions.authenticateUser()),
    unauthenticateUser: () => dispatch(UserActions.unauthenticateUser()),
    updateUser: data => dispatch(UserActions.updateUser(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
