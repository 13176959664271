import axios from "../axiosInstance";
import qs from "qs";

class VendorService {
  async listVendorsByManagementIds(managementIds, active) {
    var params = {
      active
    };

    if (managementIds) params.managementIds = managementIds;

    return axios.get("vendor", {
      params: params,
      paramsSerializer: (paramsObj) => qs.stringify(paramsObj)
    });
  }

  async listVendors() {
    return axios.get("vendor/");
  }

  async listCropCompetitorCustomerByTermAndVendorIds(vendorIds, term) {
    return axios.get(`vendor/entities`, {
      params: {
        term,
        vendorIds
      },
      paramsSerializer: (params) => qs.stringify(params)
    });
  }

  async getVendorByVendorId(vendorId) {
    return axios.get(`vendor/${vendorId}`);
  }

  async saveEditVendor(data, vendorId) {
    return axios.put(`vendor/${vendorId}`, data);
  }

  async createVendor(data) {
    return axios.post(`vendor/`, data);
  }
}

export default new VendorService();
