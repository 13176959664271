import React, { useState } from "react";
import { Grid, makeStyles, Typography, Button } from "@material-ui/core";
import { SECONDARY_COLOR } from "../../../theme";
import { Users } from "react-feather";
import CustomTable from "../../../shared/components/CustomTable/CustomTable";
import useUserManagement from "../customHooks/useUserManagement";
import { routes } from "../../../shared/utils/Constants";
import { Redirect } from "react-router-dom";
import GeneralLoading from "../../../shared/components/GeneralLoading";
import PageTitle from "../../../shared/components/PageTitle";

const UserManagement = () => {
  const classes = UseStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [redirect, setRedirect] = useState(null);

  const { users, total, dataIsReady } = useUserManagement();

  const paginate = () => {
    let items = [];

    const skip = page * rowsPerPage;

    for (let i = skip; i < skip + rowsPerPage; i++) {
      if (users[i]) items.push(users[i]);
    }

    return items;
  };

  return dataIsReady ? (
    <Grid container className={`pl-8 pt-8 pr-8 pb-4`}>
      <Grid item container xs={12} className="pt-2 pb-2">
        <Grid item xs={10}>
          <PageTitle
            title="Gestão de usuários"
            icon={<Users color={SECONDARY_COLOR} size={56} className={`${classes.icon} pr-3`} />}
          />
        </Grid>
        <Grid className={classes.containerButton} item xs={2} justify="flex-end">
          <Button
            variant="outlined"
            color="secondary"
            className={classes.newUserButton}
            onClick={() => setRedirect(<Redirect to={routes.editUser} />)}
          >
            NOVO USUÁRIO
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" className="pt-2 pb-2">
          Resultados
        </Typography>
        <CustomTable
          headers={headers}
          data={paginate()}
          options={tableCellOptions}
          page={page}
          hover
          edit
          onClickEdit={id => setRedirect(<Redirect to={`${routes.editUser}/${id}`} />)}
          remove
          onClickRemove={() => null}
          pagination
          rowsPerPage={rowsPerPage}
          total={total}
          handleChangeRowsPerPage={e => setRowsPerPage(e.target.value)}
          handleChangePage={(e, newPage) => setPage(newPage)}
        />
      </Grid>

      {redirect}
    </Grid>
  ) : (
    <GeneralLoading />
  );
};

const headers = [
  {
    field: "name",
    name: "Nome",
    width: "20%"
  },
  {
    field: "email",
    name: "E-mail",
    width: "20%"
  },
  {
    field: "profileName",
    name: "Cargo(s)",
    width: "20%"
  },
  {
    field: "managersAndVendors",
    name: "Gerências - Consultorias",
    width: "30%"
  }
];

const tableCellOptions = {
  profileName: {
    hasTooltip: true,
    toolTipLength: 20,
    toolTipWidth: 150
  },
  managersAndVendors: {
    hasTooltip: true,
    toolTipLength: 25,
    toolTipWidth: 210
  }
};

const UseStyles = makeStyles(theme => ({
  icon: {
    verticalAlign: "bottom"
  },
  newUserButton: {
    width: "180px",
    height: "56px"
  },
  containerButton: {
    display: "flex"
  }
}));

export default UserManagement;
