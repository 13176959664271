import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { PALE_BLUE_GRAY, ORANGE, hexToRgba } from "../../../theme";
import SellingBalanceLastUpdateManager from "../SellingBalanceLastUpdateManager";
import { WarningRounded } from '@material-ui/icons'

const SellingBalanceLastUpdate = props => {
  const {
    isMobile,
  } = props;

  const classes = useStyles();
  const [lastUpdateDateTime, setLastUpdateDateTime] = useState(null)

  useEffect(() => {
    async function getLastUpdateDateTime() {
      const response = await SellingBalanceLastUpdateManager.getLastUpdateDateTime();
      setLastUpdateDateTime(response)
    }

    getLastUpdateDateTime();
  }, [])

  return (
    <Grid container className={classes.container} justify="flex-start" alignItems="center">
        <WarningRounded className={classes.icon}/>
        <Typography variant="body2">
          Última atualização dos dados {isMobile && <br/>} realizada em {' '}
          <b>
            {lastUpdateDateTime 
              ? 
                <>
                  {lastUpdateDateTime.getDate()}/{lastUpdateDateTime.getMonth() + 1} às {' '}
                  {lastUpdateDateTime.getHours()}:{lastUpdateDateTime.getMinutes()}
                </>
              : 
                "--/-- às --:--"
            }
          </b>
        </Typography>
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: hexToRgba(PALE_BLUE_GRAY, '0.4'),
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    border: "1px solid",
    borderColor: hexToRgba(ORANGE, '0.4'),
  },
  icon: {
    fill: ORANGE,
    marginRight: "12px"
  },
}));

export default SellingBalanceLastUpdate;