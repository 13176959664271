import React from 'react';
import { Grid } from '@material-ui/core';
import Select from '../../../shared/components/Select';

const SearchFilters = props => {
  const {
    isMobile,
    filters,
    filterOptions,
    onChangeFilters,
  } = props;

  return (
    <Grid container spacing={2} className="pb-4">
      <Grid item xs={7}>
        <Select
          label="Grupo de família"
          options={filterOptions.familyGroup}
          code={filters.familyGroup}
          handleChange={familyGroup => onChangeFilters({ ...filters, familyGroup: familyGroup })}
          maxHeightMenu="150"
          isMobile={isMobile}
        />
      </Grid>
      <Grid item xs={7}>
        <Select
          label="Produto"
          options={filterOptions.productGroup}
          code={filters.productGroup}
          handleChange={productGroup => onChangeFilters({ ...filters, productGroup: productGroup })}
          maxHeightMenu="150"
          isMobile={isMobile}
          disabled={!filters.familyGroup}
        />
      </Grid>
      <Grid item xs={5}>
        <Select
          label="Planta"
          options={filterOptions.siteInitials}
          code={filters.siteInitials}
          handleChange={siteInitials => onChangeFilters({ ...filters, siteInitials: siteInitials })}
          maxHeightMenu="150"
          isMobile={isMobile}
          disabled={!filters.familyGroup}
        />
      </Grid>
    </Grid>
  );
}

export default SearchFilters;