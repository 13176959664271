import React, { useState, useEffect, useRef } from "react";
import { Typography, Divider, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "../../../shared/components/Autocomplete";
import { LIGHT_GRAY, GRAY } from "../../../theme";
import _ from "lodash";

let loadOptions;

const asyncLoadOptions = _.debounce((input, callback) => {
  loadOptions(input, callback);
}, 1000);

const AboutAutocomplete = props => {
  const {
    handleChangeItem,
    fetchOptions,
    shouldClear,
    width,
    disabled,
    required,
    placeholder
  } = props;

  const classes = useStyles({ width: width });

  const [selectedItem, setSelectedItem] = useState(null);
  const [searchText, setSearchText] = useState("");

  const refHandleChangeItem = useRef(handleChangeItem);
  useEffect(() => {
    refHandleChangeItem.current = handleChangeItem;
  });

  useEffect(() => {
    setSelectedItem(null);
    setSearchText("");
    refHandleChangeItem.current(null);
  }, [shouldClear]);

  loadOptions = async (inputValue, callback) => {
    if (!inputValue) {
      callback([]);
      return;
    }

    const optionsList = await fetchOptions(
      inputValue
    );

    const reactSelectObject =
      optionsList == null
        ? []
        : optionsList.map((item, index) => {
            return {
              ...item,
              value: index,
              label: layoutItemOptionsList(item, index === optionsList.length - 1)
            };
          });

    callback(reactSelectObject);
  };

  const layoutItemOptionsList = (item, isLastItem) => (
    <Box>
      <Box className={`${!isLastItem && "mb-3"} ${classes.listLine}`}>
        <Typography className={classes.selectColor}>{item.name}</Typography>
        {item.entityType && (
          <Typography className={`ml-2 ${classes.selectColor}`} variant="caption">
            {item.entityType}
          </Typography>
        )}
      </Box>
      {!isLastItem && <Divider className={classes.divider} />}
    </Box>
  );

  const getSelectedLabel = item => {
    return (
      <div className={classes.listLine}>
        <Typography>{item.name}</Typography>
        <Typography className="ml-2" variant="caption">
          {item.entityType}
        </Typography>
      </div>
    );
  };

  const selectItem = item => {
    setSelectedItem(item);
    handleChangeItem(item);
    if (item && item.name) item.label = getSelectedLabel(item);
    setSearchText(item && item.name ? item.item : "");
  };

  const handleInputChange = (inputValue, event) => {
    if (event.action !== "input-blur" && event.action !== "menu-close") {
      setSearchText(inputValue);

      if (inputValue === "") {
        setSelectedItem(null);
        handleChangeItem(null);
      }

      if (selectedItem && selectedItem.name !== inputValue) {
        setSelectedItem(null);
      }
    }
  };

  return (
    <Autocomplete
      id="Search"
      placeholder={`${placeholder} ${required && "*"}`}
      clearable
      onChange={selectItem}
      loadOptions={asyncLoadOptions}
      onInputChange={handleInputChange}
      inputValue={searchText}
      value={selectedItem}
      lineInput
      hasLabel={true}
      label={`Sobre ${required && "*"}`}
      className={classes.autocomplete}
      disableField={disabled}
      outlined={true}
    />
  );
};

const useStyles = makeStyles({
  selectColor: {
    color: GRAY
  },
  listLine: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  autocomplete: props => ({
    width: props.width || "100%"
  }),
  divider: {
    height: 1,
    backgroundColor: LIGHT_GRAY
  }
});

export default AboutAutocomplete;
