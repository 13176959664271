import { useState } from "react";
import { DataType } from "../../../shared/utils/enum";
import AssociationsManager from "../AssociationsManager";

const useEditVendorTable = (states, setStates, selectedState, showNotification) => {
  const [selectedTypeTab, setSelectedTypeTab] = useState(DataType["competitor"].id);
  const [confirmDeleteTableItemModal, setConfirmDeleteTableItemModal] = useState(false);
  const [itemToBeDeleted, setItemToBeDeleted] = useState(null);
  const [selectedItemToBeAdded, setSelectedItemToBeAdded] = useState(null);
  const [shouldClearAutocomplete, setShouldClearAutocomplete] = useState(false);
  const [loadingHasVolume, setLoadingHasVolume] = useState(false);
  const [itemHasVolume, setItemHasVolume] = useState(false);

  const handleChangeTab = (event, newValue) => setSelectedTypeTab(newValue);

  const verifyDataHasVolume = async stateVendorTypeId => {
    const getData = async () => {
      switch (selectedTypeTab) {
        case DataType["customer"].id:
          return await AssociationsManager.verifyCustomerHasVolume(stateVendorTypeId);
        case DataType["crop"].id:
          return await AssociationsManager.verifyCropHasVolume(stateVendorTypeId);
        case DataType["competitor"].id:
          return await AssociationsManager.verifyCompetitorHasVolume(stateVendorTypeId);
        default:
          return false;
      }
    };

    setLoadingHasVolume(true);
    const data = await getData();
    setItemHasVolume(data);
    setLoadingHasVolume(false);
  };

  const handleOpenCloseConfirmDeleteItemModal = async (itemId, stateVendorTypeId) => {
    setItemToBeDeleted(itemId);
    setConfirmDeleteTableItemModal(!confirmDeleteTableItemModal);
    stateVendorTypeId && (await verifyDataHasVolume(stateVendorTypeId));
  };

  const handleConfirmDeleteItem = () => {
    if (itemToBeDeleted) {
      const statesClone = [...states];
      statesClone[selectedState][getSelectedTypeName().name] = getSelectedTypeData(
        statesClone[selectedState]
      ).filter(t => t.id !== itemToBeDeleted);

      setStates(statesClone);
      handleOpenCloseConfirmDeleteItemModal();
    }
  };

  const handleAddItem = () => {
    if (selectedItemToBeAdded) {
      const statesClone = [...states];

      const itemToBeAddAlreadyExists =
        statesClone[selectedState][getSelectedTypeName().name].filter(
          t => t.id === selectedItemToBeAdded.id
        ).length > 0;

      if (itemToBeAddAlreadyExists) {
        showNotification(
          `Esse(a) ${getSelectedTypeName().displayName} já está associado a este estado!`
        );
      } else {
        statesClone[selectedState][getSelectedTypeName().name].unshift(selectedItemToBeAdded);
        setStates(statesClone);
      }

      setSelectedItemToBeAdded(null);
      setShouldClearAutocomplete(!shouldClearAutocomplete);
    }
  };

  const getSelectedTypeData = state => {
    switch (selectedTypeTab) {
      case DataType["customer"].id:
        return state["customers"];
      case DataType["crop"].id:
        return state["crops"];
      case DataType["competitor"].id:
        return state["competitors"];
      default:
        return [];
    }
  };

  const getSelectedTypeName = () => {
    switch (selectedTypeTab) {
      case DataType["customer"].id:
        return { name: "customers", displayName: "Cliente" };
      case DataType["crop"].id:
        return { name: "crops", displayName: "Cultura" };
      case DataType["competitor"].id:
        return { name: "competitors", displayName: "Concorrente" };
      default:
        return { name: "", displayName: "" };
    }
  };

  return {
    selectedTypeTab,
    handleChangeTab,
    handleOpenCloseConfirmDeleteItemModal,
    confirmDeleteTableItemModal,
    getSelectedTypeName,
    handleConfirmDeleteItem,
    setSelectedItemToBeAdded,
    selectedItemToBeAdded,
    handleAddItem,
    shouldClearAutocomplete,
    loadingHasVolume,
    itemHasVolume
  };
};

export default useEditVendorTable;
