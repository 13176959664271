import { useState, useEffect } from "react";
import { activeStatus, vendorInitials } from "../../../shared/utils/enum";

const useListAssociationsSelects = () => {
  const [selectedManagement, setSelectedManagement] = useState();
  const [selectedVendor, setSelectedVendor] = useState();
  const [selectedStatus, setSelectedStatus] = useState(activeStatus.find(e => e.active));
  const [openTabs, setOpenTabs] = useState(false);
  const [closeTab, setCloseTab] = useState(false);
  const [disabledStatus, setdisabledStatus] = useState();

  useEffect(() => {
    selectedVendor &&
      selectedVendor.initials !== vendorInitials.all &&
      setSelectedStatus(activeStatus.find(e => e.name === "Todas"));
  }, [selectedVendor]);

  return {
    selectedManagement,
    setSelectedManagement,
    selectedVendor,
    setSelectedVendor,
    selectedStatus,
    setSelectedStatus,
    openTabs,
    setOpenTabs,
    closeTab,
    setCloseTab,
    disabledStatus,
    setdisabledStatus
  };
};

export default useListAssociationsSelects;
