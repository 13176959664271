import { useState } from "react";
import CompetitorCustomerCropManager from "../CompetitorCustomerCropManager";

const useListCustomer = () => {
  const [listCustomer, setListCustomer] = useState({});
  const [loadingData, setLoadingData] = useState(false);

  const handleListCustomer = async (searchName, rowsPerPage, page) => {
    setLoadingData(true);

    const result = await CompetitorCustomerCropManager.listCustomer(searchName, rowsPerPage, page);
    if (result) setListCustomer(result);

    setLoadingData(false);
  };

  return {
    listCustomer,
    loadingData,
    handleListCustomer
  };
};

export default useListCustomer;
