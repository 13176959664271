import SellingBalanceService from "../../services/SellingBalanceService";

class SellingBalanceLastUpdateManager {
  async getLastUpdateDateTime() {
    const answer = await SellingBalanceService.getLastUpdateDateTime();

    return answer && new Date(answer.data); 
  }
}

export default new SellingBalanceLastUpdateManager();