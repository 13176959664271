import { useState } from "react";
import { DataType } from "../../../shared/utils/enum";

export const useNextYearOrderbookTypeTabs = () => {
  const [customerTotalVolume, setCustomerTotalVolume] = useState(0);
  const [competitorTotalVolume, setCompetitorTotalVolume] = useState(0);
  const [cropTotalVolume, setCropTotalVolume] = useState(0);

  const [selectedTypeTab, setSelectedTypeTabs] = useState(DataType["competitor"].id);

  const handleChangeTab = (event, newValue) => setSelectedTypeTabs(newValue);

  const updateTotalVolume = (index, newValue) => {
    switch (index) {
      case DataType["competitor"].id:
        setCompetitorTotalVolume(newValue);
        return;
      case DataType["customer"].id:
        setCustomerTotalVolume(newValue);
        return;
      case DataType["crop"].id:
        setCropTotalVolume(newValue);
        return;
      default:
        return;
    }
  };

  return {
    selectedTypeTab,
    handleChangeTab,
    customerTotalVolume,
    competitorTotalVolume,
    cropTotalVolume,
    updateTotalVolume
  };
};
