import axios from "../axiosInstance";

class SellingsReportService {
  async getSellingsReportFile(initialDate, finalDate) {
    return await axios.get("sellings/report", {
      responseType: "blob",
      params: { initialDate, finalDate }
    });
  }
}

export default new SellingsReportService();
