import React, { useState, useEffect } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import ListPreRegisteredsManager from "../ListPreRegisteredsManager";
import GeneralLoading from "../../../shared/components/GeneralLoading";
import { CheckCircle } from "react-feather";
import PageTitle from "../../../shared/components/PageTitle";
import { SECONDARY_COLOR } from "../../../theme";
import ListTable from "./ListTable";

const ListPreRegistered = () => {
  const classes = useStyles();

  const [preRegisteredItems, setPreRegisteredItems] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [redirect, setRedirect] = useState();

  useEffect(() => {
    const getData = async () => {
      setPageLoading(true);
      const data = await ListPreRegisteredsManager.ListPreRegisteredItems();
      setPageLoading(false);
      data && setPreRegisteredItems(data);
    };

    getData();
  }, []);

  return (
    <>
      {pageLoading ? (
        <GeneralLoading />
      ) : (
        <Grid container className={`pl-8 pt-5 pr-8 pb-4`}>
          <Grid container item xs={12} spacing={2} className="pb-3">
            <Grid item xs={12}>
              <PageTitle
                title={`Aprovação de novos cadastros`}
                icon={
                  <CheckCircle
                    color={SECONDARY_COLOR}
                    size={56}
                    className={`pr-4 pt-3 ${classes.icon}`}
                  />
                }
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <Typography variant="h5" className="pb-4">
              Solicitações
            </Typography>
            <Grid container item xs={12}>
              <ListTable preRegisteredItems={preRegisteredItems} setRedirect={setRedirect} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {redirect}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  icon: {
    verticalAlign: "bottom"
  }
}));

export default ListPreRegistered;
