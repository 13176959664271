import _ from "lodash";

export const FormatDataToUpdate = (
  competitorPotentials,
  customerPotentials,
  cropPotentials,
  stateVendorId,
  userId,
  vendorId
) => {
  competitorPotentials &&
    competitorPotentials.forEach(competitorPotential => {
      if (_.isObject(competitorPotential.name)) {
        competitorPotential.newItemId = competitorPotential.name.id || 0;
        competitorPotential.name = competitorPotential.name.name;
        if (_.isString(competitorPotential.id)) competitorPotential.id = 0;
      }
    });
  customerPotentials &&
    customerPotentials.forEach(customerPotential => {
      if (_.isObject(customerPotential.name)) {
        customerPotential.newItemId = customerPotential.name.id || 0;
        customerPotential.customerTypeId = customerPotential.name.customerType.id;
        customerPotential.name = customerPotential.name.name;
        if (_.isString(customerPotential.id)) customerPotential.id = 0;
      }
    });

  return {
    cropPotential: cropPotentials,
    competitorPotential: competitorPotentials,
    customerPotential: customerPotentials,
    year: new Date().getFullYear(),
    stateVendorId: stateVendorId,
    userId: userId,
    vendorId: vendorId
  };
};
