import StateService from "../../services/StateService";
import StateVendorCompetitorService from "../../services/StateVendorCompetitorService";
import StateVendorCropService from "../../services/StateVendorCropService";
import StateVendorCustomerService from "../../services/StateVendorCustomerService";
import NextYearOrderbookService from "../../services/NextYearOrderbookService";

class NextYearOrderbookManager {
  async getStatesFromVendorId(vendorId) {
    return await StateService.getStatesFromVendorIds(vendorId, true).then(
      answer => answer && answer.data
    );
  }

  async listCompetitorNextYearOrderbook(stateVendorId, year) {
    return await StateVendorCompetitorService.listCompetitorNextYearOrderbook(
      stateVendorId,
      year
    ).then(answer => answer && answer.data);
  }

  async listCropNextYearOrderbook(stateVendorId, year) {
    return await StateVendorCropService.listCropNextYearOrderbook(stateVendorId, year).then(
      answer => answer && answer.data
    );
  }

  async listCustomerNextYearOrderbook(stateVendorId, year) {
    return await StateVendorCustomerService.listCustomerNextYearOrderbook(stateVendorId, year).then(
      answer => answer && answer.data
    );
  }

  async listStatesNextYearOrderbookTotals(year, stateVendorIds) {
    return await NextYearOrderbookService.listStatesNextYearOrderbookTotals(
      year,
      stateVendorIds
    ).then(answer => answer && answer.data);
  }

  async updateNextYearOrderbook(data) {
    return await NextYearOrderbookService.updateNextYearOrderbook(data).then(
      answer => answer && answer.data
    );
  }
}

export default new NextYearOrderbookManager();
