import React, { useState } from "react";
import Logo from "../../../shared/img/Logo.svg";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import SelectVendors from "./SelectVendors";
import { WHITE } from "../../../theme";

const UserInfo = props => {
  const classes = useStyles();
  const { userName, isBackOffice, selectedVendor, listVendors, updateVendor } = props;

  const [openSelectVendors, setOpenSelectVendors] = useState(false);

  return (
    <Grid container item className="pl-7">
      <Grid item xs={3}>
        <img className={`${classes.img} pr-3`} src={Logo} alt="Logo Yara" />
      </Grid>
      <Grid container item xs={9}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={`${classes.userName} pt-2`}>
            {userName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {isBackOffice ? (
            <Typography variant="caption" className={classes.userProfile}>
              BackOffice
            </Typography>
          ) : (
            <SelectVendors
              setOpenSelectVendors={setOpenSelectVendors}
              openSelectVendors={openSelectVendors}
              selectedVendor={selectedVendor}
              listVendors={listVendors}
              updateVendor={updateVendor}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  userProfile: {
    flexGrow: 1,
    color: WHITE
  },
  img: {
    width: 50,
    height: 50
  },
  userName: {
    flexGrow: 1,
    color: WHITE,
    lineHeight: 1
  }
}));

export default UserInfo;
