import axios from "../axiosInstance";

class SellingService {
  async listStateTotalsSelling(stateVendorIds, startDate, endDate) {
    return await axios.get(`sellings/totalsselling`, {
      params: { stateVendorIds, startDate, endDate }
    });
  }

  async updateSellings(data) {
    return await axios.post("sellings/sellings", data);
  }
  async getSolidMarketEntriesReport(date) {
    return await axios.get(`sellings/exportSolidMarketEntries`, {
      responseType: "blob",
      params: { date }
    });
  }
}

export default new SellingService();
