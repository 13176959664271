import axios from "../axiosInstance";

class MessageService {
  async listMessageType() {
    return axios.get("message/type");
  }

  async listMessageReason() {
    return axios.get("message/adjustmentreason");
  }

  async createMessage(message, messageTypeId) {
    return axios.post("message", message, { params: { messageTypeId } });
  }

  async createMessageSentByConsultant(message, messageTypeId) {
    return axios.post("message/consultant", message, { params: { messageTypeId } });
  }

  async listRecentMessagesByUserIdAndVendorId(userId, vendorId) {
    return axios.get("message/byUserIdAndVendorId", { params: { userId, vendorId } });
  }

  async listAdjustmentMessages(parameters = []) {
    let query = "";
    let concatIdentifier = "";

    parameters.forEach((parameter, index) => {
      concatIdentifier = index === 0 ? "?" : "&";
      query += concatIdentifier + parameter.label + "=" + parameter.value;
    });

    return axios.get(`message/listAdjustmentMessages/${query}`);
  }

  async listSellingBalanceMessages(parameters = []) {
    let query = "";
    let concatIdentifier = "";

    parameters.forEach((parameter, index) => {
      concatIdentifier = index === 0 ? "?" : "&";
      query += concatIdentifier + parameter.label + "=" + parameter.value;
    });

    return axios.get(`message/listSellingBalanceMessages/${query}`);
  }

  async listReportMessages(parameters = []) {
    let query = "";
    let concatIdentifier = "";

    parameters.forEach((parameter, index) => {
      concatIdentifier = index === 0 ? "?" : "&";
      query += concatIdentifier + parameter.label + "=" + parameter.value;
    });

    return axios.get(`message/createmessagesreport/${query}`, { responseType: "blob" });
  }
  async listDirectMessagesSentByBackoffice(parameters = []) {
    let query = "";
    let concatIdentifier = "";

    parameters.forEach((parameter, index) => {
      concatIdentifier = index === 0 ? "?" : "&";
      query += concatIdentifier + parameter.label + "=" + parameter.value;
    });

    return axios.get(`message/listDirectMessagesSentByBackoffice/${query}`);
  }
  async listDirectMessagesSentByConsultant(parameters = []) {
    let query = "";
    let concatIdentifier = "";

    parameters.forEach((parameter, index) => {
      concatIdentifier = index === 0 ? "?" : "&";
      query += concatIdentifier + parameter.label + "=" + parameter.value;
    });

    return axios.get(`message/listDirectMessagesSentByConsultant/${query}`);
  }

  async listMessagesByUserIdAndVendorIdPaged(userId, vendorId, page) {
    return axios.get(`message/byUserIdAndVendorId?page=${page}`, {
      params: { userId, vendorId }
    });
  }

  async listVendorSentMessages(vendorId, page) {
    return axios.get(`message/vendorSentMessages/${vendorId}?page=${page}`);
  }

  async insertMessageReadDate(messageId, userId) {
    return axios.patch(`message/insertReadDate/${messageId}`, null, { params: { userId } });
  }

  async listSellingBalanceMessageReason() {
    return axios.get("message/sellingbalancereason");
  }
}

export default new MessageService();
