import React, { useState, useEffect, useRef } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "../../../shared/components/Autocomplete";
import TypeAutocompleteManager from "../TypeAutocompleteManager";
import _ from "lodash";
import { DataType } from "../../../shared/utils/enum";

let loadOptions;

const asyncLoadOptions = _.debounce((input, callback) => {
  loadOptions(input, callback);
}, 1000);

const TypeAutocomplete = props => {
  const { handleChangeItem, typeId, shouldClear, width, disabled } = props;

  const classes = useStyles({ width: width });

  const [selectedItem, setSelectedItem] = useState(null);
  const [searchText, setSearchText] = useState("");

  const refHandleChangeItem = useRef(handleChangeItem);
  useEffect(() => {
    refHandleChangeItem.current = handleChangeItem;
  });

  useEffect(() => {
    setSelectedItem(null);
    setSearchText("");
    refHandleChangeItem.current(null);
  }, [typeId, shouldClear]);

  loadOptions = async (inputValue, callback) => {
    if (!inputValue) {
      callback([]);
      return;
    }

    let optionsList;

    if (typeId === DataType["competitor"].id)
      optionsList = await TypeAutocompleteManager.searchCompetitorByTerm(inputValue);
    else if (typeId === DataType["customer"].id)
      optionsList = await TypeAutocompleteManager.searchCustomerByTerm(inputValue);
    else if (typeId === DataType["crop"].id)
      optionsList = await TypeAutocompleteManager.searchCropByTerm(inputValue);

    const reactSelectObject =
      optionsList == null
        ? []
        : optionsList.map((item, index) => {
            return {
              ...item,
              value: index,
              label: layoutItemOptionsList(item)
            };
          });

    callback(reactSelectObject);
  };

  const layoutItemOptionsList = item => (
    <div className={classes.listLine}>
      <Typography>{item.name}</Typography>
      {(item.customerType || item.cropType) && (
        <Typography variant="caption">{`${item.customerType || item.cropType}`}</Typography>
      )}
    </div>
  );

  const getSelectedLabel = item => {
    return (
      <div className={classes.listLine}>
        <Typography>{item.name}</Typography>
      </div>
    );
  };

  const selectItem = item => {
    setSelectedItem(item);
    handleChangeItem(item);
    if (item && item.name) item.label = getSelectedLabel(item);
    setSearchText(item && item.name ? item.item : "");
  };

  const handleInputChange = (inputValue, event) => {
    if (event.action !== "input-blur" && event.action !== "menu-close") {
      setSearchText(inputValue);

      if (inputValue === "") {
        setSelectedItem(null);
        handleChangeItem(null);
      }

      if (selectedItem && selectedItem.name !== inputValue) {
        setSelectedItem(null);
      }
    }
  };

  return (
    <Autocomplete
      id="Search"
      placeholder="Pesquise um nome para associar"
      clearable
      onChange={selectItem}
      loadOptions={asyncLoadOptions}
      onInputChange={handleInputChange}
      inputValue={searchText}
      value={selectedItem}
      lineInput
      hasLabel={false}
      className={classes.autocomplete}
      disableField={disabled}
    />
  );
};

const useStyles = makeStyles({
  listLine: {
    display: "flex",
    justifyContent: "space-between"
  },
  autocomplete: props => ({
    width: props.width || "100%"
  })
});

export default TypeAutocomplete;
