import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography, Grid } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Bell } from "react-feather";
import NotificationIcon from "../../../shared/img/BellNotificationIcon.svg";
import { GRAY, WHITE } from "../../../theme";
import { configureEnvironment } from "../../../config/env";
import { environment, profiles, screenSizes } from "../../../shared/utils/enum";
import Breadcrumb from "./Breadcrumb";
import * as actions from "../../../store/MessageStore";
import MessageCard from "../../../shared/components/MessageNotification/views/MessageCard";
import useMessageNotifications from "../../../shared/components/MessageNotification/customHooks/useMessageNotifications";
import useListRecentMessages from "../../../shared/components/MessageNotification/customHooks/useListRecentMessages";

const Header = props => {
  const {
    openDrawer,
    setOpenDrawer,
    userProfile,
    userId,
    vendorId,
    listRecentMessages,
    messages,
    windowWidth
  } = props;
  const isMobile = windowWidth < screenSizes.webScreenSize;
  const classes = useStyles();

  const UATEnvironment =
    configureEnvironment().REACT_APP_ENVIRONMENT_NAME === environment.homologation;
  const DEVEnvironment =
    configureEnvironment().REACT_APP_ENVIRONMENT_NAME === environment.development;

  const isBackOffice =
    userProfile && userProfile.find(element => element.initials === profiles.backOffice.initials);

  const { setRefresh, loadingRefreshButton } = useListRecentMessages(
    userId,
    vendorId,
    listRecentMessages
  );

  const {
    showNotificationCard,
    setShowNofiticationCard,
    numberOfNewMessages,
    hasRecentMessages,
    hasNewMessage
  } = useMessageNotifications(messages);

  return (
    <AppBar elevation={0}>
      <Toolbar disableGutters className={isMobile ? "pl-4 pr-4" : "pl-8 pr-8"}>
        <Grid container>
          <Grid item xs={10} className={`${classes.container} pt-3`}>
            <MenuIcon
              className={`${classes.menuButton} hover-icon mr-8`}
              onClick={() => setOpenDrawer(!openDrawer)}
              role="button"
              aria-label="Abrir Menu"
              aria-hidden="false"
              tabIndex="0"
            />
            {!isMobile ? (
              <Breadcrumb />
            ) : (
              <Typography variant="h6" align="center" className={classes.title}>
                Market Tracker
              </Typography>
            )}
          </Grid>
          {!isBackOffice && (
            <Grid item xs={1} sm={2} className="pt-3">
              {hasNewMessage ? (
                <img
                  className={`${classes.notificationIcon} hover-icon`}
                  src={NotificationIcon}
                  alt="New Notification"
                  onClick={() => setShowNofiticationCard(!showNotificationCard)}
                />
              ) : (
                <Bell
                  className={`${classes.notificationIcon} hover-icon`}
                  onClick={() => setShowNofiticationCard(!showNotificationCard)}
                />
              )}
            </Grid>
          )}
        </Grid>
      </Toolbar>
      {(UATEnvironment || DEVEnvironment) && (
        <Toolbar className={classes.envBar}>
          <Grid item xs sm align="center">
            <Typography className={classes.envTitle}>
              {DEVEnvironment && "Ambiente DEV"}
              {UATEnvironment && "Ambiente UAT"}
            </Typography>
          </Grid>
        </Toolbar>
      )}
      <MessageCard
        showCard={showNotificationCard}
        setShowCard={setShowNofiticationCard}
        numberOfMessages={numberOfNewMessages}
        hasRecentMessages={hasRecentMessages}
        setRefresh={setRefresh}
        windowWidth={windowWidth}
        isMobile={isMobile}
        loadingRefreshButton={loadingRefreshButton}
        messages={messages}
        userId={userId}
      />
    </AppBar>
  );
};

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    color: WHITE
  },
  envBar: {
    backgroundColor: GRAY,
    minHeight: 15
  },
  envTitle: {
    color: WHITE,
    fontSize: theme.spacing(1.5)
  },
  notificationIcon: {
    width: 24,
    height: 24,
    marginLeft: "auto",
    display: "flex"
  },
  container: {
    display: "flex",
    alignItems: "center"
  }
}));

const mapStateToProps = state => {
  return {
    userName: state.user.name,
    userProfile: state.user.profiles,
    vendorId: state.selectedVendor.id,
    userId: state.user.id,
    messages: state.messages,
    windowWidth: state.window.width
  };
};

const mapDispatchToProps = dispatch => {
  return {
    listRecentMessages: data => dispatch(actions.listRecentMessages(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
