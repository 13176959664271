import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import PageTitle from "../../../shared/components/PageTitle";
import { connect } from "react-redux";
import { SECONDARY_COLOR, GRAY } from "../../../theme";
import StateTabs from "./StateTabs";
import EntriesContainer from "./EntriesContainer";
import { useStateTabs } from "../customHooks/useStateTabs";
import { useCompetitorNextYearOrderbook } from "../customHooks/useCompetitorNextYearOrderbook";
import { useCropNextYearOrderbook } from "../customHooks/useCropNextYearOrderbook";
import { useCustomerNextYearOrderbook } from "../customHooks/useCustomerNextYearOrderbook";
import { useNextYearOrderbookTypeTabs } from "../customHooks/useNextYearOrderbookTypeTabs";
import { useModals } from "../customHooks/useModals";
import WalletIcon from "../../../shared/img/WalletIcon.svg";
import SVGIcon from "../../../shared/components/SVGIcon";
import Modal from "../../../shared/components/Modal";
import SuccessNextYearOrderBook from "../../../shared/img/SuccessNextYearOrderBook.svg";
import SaveError from "../../../shared/img/SaveError.svg";
import { screenSizes } from "../../../shared/utils/enum";
import GeneralLoading from "../../../shared/components/GeneralLoading";
import { usePageScroll } from "../../../shared/utils/usePageScroll";

const NextYearOrderbook = props => {
  const classes = useStyles();
  const { userId, windowWidth, selectedVendor } = props;

  const { isEndOfScreen } = usePageScroll();

  const {
    selectedStateVendor,
    setUpdateStateTotals,
    numSteppers,
    pageShouldRerender,
    statesInfo,
    selectedStateTab,
    setSelectedStateTab,
    stateTabsAreReady
  } = useStateTabs(selectedVendor);

  const {
    selectedTypeTab,
    handleChangeTab,
    customerTotalVolume,
    competitorTotalVolume,
    cropTotalVolume,
    updateTotalVolume
  } = useNextYearOrderbookTypeTabs();

  const {
    competitorNextYearOrderbook,
    loadingCompetitorTable,
    updateCompetitorData,
    competitorTotal
  } = useCompetitorNextYearOrderbook(selectedStateVendor, updateTotalVolume);

  const {
    cropNextYearOrderbook,
    loadingCropTable,
    cropTotal,
    updateCropData
  } = useCropNextYearOrderbook(selectedStateVendor, updateTotalVolume);

  const {
    customerNextYearOrderbook,
    loadingCustomerTable,
    customerTotal,
    updateCustomerData
  } = useCustomerNextYearOrderbook(selectedStateVendor, updateTotalVolume);

  const {
    handleSuccessModalOpenClose,
    handleErrorModalOpenClose,
    successModalOpen,
    errorModalOpen
  } = useModals(setUpdateStateTotals);

  const isMobile = windowWidth < screenSizes.webScreenSize;

  return competitorNextYearOrderbook &&
    cropNextYearOrderbook &&
    customerNextYearOrderbook &&
    stateTabsAreReady ? (
    <Grid container className={!isMobile ? "pl-8 pt-8 pr-8 pb-4" : "pl-4 pt-8 pr-4 pb-4"}>
      <PageTitle
        title="Carteira virada"
        subtitle="Volume em mil toneladas"
        className={classes.title}
        icon={<SVGIcon src={WalletIcon} alt="Carteira Virada" className={`${classes.icon} mr-1`} />}
        isMobile={isMobile}
      />

      <Grid item xs={!isMobile ? 2 : 12}>
        <Typography
          variant={!isMobile ? "h5" : "h6"}
          align={!isMobile ? "right" : "center"}
          className="pt-3"
        >
          {new Date().getFullYear() - 1} / {new Date().getFullYear()}
        </Typography>
      </Grid>

      <StateTabs
        isMobile={isMobile}
        numSteppers={numSteppers}
        pageShouldRerender={pageShouldRerender}
        statesInfo={statesInfo}
        selectedStateTab={selectedStateTab}
        setSelectedStateTab={setSelectedStateTab}
      />

      <EntriesContainer
        loadingCompetitorTable={loadingCompetitorTable}
        loadingCropTable={loadingCropTable}
        loadingCustomerTable={loadingCustomerTable}
        updateCompetitorData={updateCompetitorData}
        competitorTotalVolume={competitorTotalVolume}
        competitorTotal={competitorTotal}
        customerTotalVolume={customerTotalVolume}
        updateCropsData={updateCropData}
        cropTotalVolume={cropTotalVolume}
        cropTotal={cropTotal}
        updateCustomerData={updateCustomerData}
        customerTotal={customerTotal}
        stateVendorId={selectedStateVendor}
        userId={userId}
        selectedTypeTab={selectedTypeTab}
        handleChangeTab={handleChangeTab}
        customerNextYearOrderbook={customerNextYearOrderbook}
        cropNextYearOrderbook={cropNextYearOrderbook}
        competitorNextYearOrderbook={competitorNextYearOrderbook}
        handleSuccessModalOpenClose={handleSuccessModalOpenClose}
        handleErrorModalOpenClose={handleErrorModalOpenClose}
        isMobile={isMobile}
        isEndOfScreen={isEndOfScreen}
      />

      <Modal
        primaryButton={{
          action: () => handleSuccessModalOpenClose(),
          text: "FECHAR"
        }}
        openedModal={successModalOpen}
        closeModal={handleSuccessModalOpenClose}
        title="Carteira virada enviada!"
        titleIcon={<img src={SuccessNextYearOrderBook} alt="Success send volume" />}
        closeIcon
      >
        <Typography align="center" className={classes.colorSubtitle}>
          O volume alterado na carteira virada foi salvo com sucesso.
        </Typography>
      </Modal>
      <Modal
        primaryButton={{
          action: () => handleErrorModalOpenClose(),
          text: "FECHAR"
        }}
        openedModal={errorModalOpen}
        closeModal={handleErrorModalOpenClose}
        title="Não foi possível salvar!"
        titleIcon={<img src={SaveError} alt="error send volume" />}
        closeIcon
      >
        <Typography align="center" className={classes.colorSubtitle}>
          Os totais de concorrentes, clientes e culturas devem ser iguais.
        </Typography>
      </Modal>
    </Grid>
  ) : (
    <GeneralLoading />
  );
};

const useStyles = makeStyles(() => ({
  icon: {
    width: 32,
    height: 32,
    backgroundColor: SECONDARY_COLOR,
    verticalAlign: "middle",
    display: "inline-block"
  },
  colorSubtitle: {
    color: GRAY
  }
}));

const mapStateToProps = state => {
  return {
    userId: state.user.id,
    windowWidth: state.window.width,
    selectedVendor: state.selectedVendor.id
  };
};

export default connect(mapStateToProps, null)(NextYearOrderbook);
