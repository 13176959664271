import { useState } from "react";
import CompetitorCustomerCropManager from "../CompetitorCustomerCropManager";

const useListCompetitor = () => {
  const [listCompetitor, setListCompetitor] = useState({});
  const [loadingData, setLoadingData] = useState(false);

  const handleListCompetitor = async (searchName, rowsPerPage, page) => {
    setLoadingData(true);

    const result = await CompetitorCustomerCropManager.listCompetitor(
      searchName,
      rowsPerPage,
      page
    );
    if (result) setListCompetitor(result);

    setLoadingData(false);
  };

  return {
    listCompetitor,
    loadingData,
    handleListCompetitor
  };
};

export default useListCompetitor;
