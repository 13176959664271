import React from 'react';
import { Grid, Typography, Box, makeStyles, Tab, Tabs as MuiTabs } from "@material-ui/core";
import { PALE_BLUE_GRAY, WHITE, SECONDARY_COLOR, BRIGHT_BLUE } from "../../../theme";

const Tabs = props => {
  const {
    selectedTab,
    handleChangeTab,
    isMobile,
    tabsEnum,
  } = props;
  
  const classes = useStyles();

  const getLabelTab = (type) => {
    return (
      <Grid container>
        <Grid container item xs={!isMobile ? 5 : 12}>
          {tabsEnum[type].icon}
        </Grid>
        <Grid item xs={!isMobile ? 7 : 12} className={!isMobile ? "pl-2 pt-4" : null}>
          <Typography
            variant="body1"
            className={
              selectedTab === tabsEnum[type].id
                ? classes.textTabColorSelected
                : classes.textTabColor
            }
            align={!isMobile ? "left" : "center"}
          >
            {tabsEnum[type].name}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box className={classes.box + " pb-2"}>
      <MuiTabs
        value={selectedTab}
        indicatorColor="secondary"
        textColor="secondary"
        onChange={handleChangeTab}
        className={classes.tabs}
      >
        {Object.keys(tabsEnum).map((enumKey) => (
          <Tab
            key={enumKey}
            label={getLabelTab(enumKey)}
            className={`${classes.borderBetweenTabs} ${classes.tabWidth}`}  
          />
        ))}
      </MuiTabs>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  box: {
    width: "100%"
  },
  tabs: {
    "& .MuiTab-root": {
      padding: "6px 10px",
    },
    "& .MuiTabs-fixed": {
      margin: "auto",
      display: "flex",
      maxWidth: "100%"
    },
    "& .MuiTabs-flexContainer": {
      margin: "auto",
      display: "flex",
      width: "100%"
    },
    "& .MuiTabs-indicator": {
      top: 0,
      height: "4px"
    }
  },
  borderBetweenTabs: {
    borderRight: `1px solid ${PALE_BLUE_GRAY}`,
    borderImage: `radial-gradient(${PALE_BLUE_GRAY} 80%, ${WHITE} 20%)`,
    borderImageSlice: 1
  },
  tabWidth: {
    maxWidth: "calc(100% /2)",
    minWidth: "calc(100% /2)"
  },
  textTabColor: ({ isMobile }) => ({
    color: BRIGHT_BLUE,
    textTransform: "none",
    fontSize: isMobile ? "0.75rem" : "1rem"
  }),
  textTabColorSelected: ({ isMobile }) => ({
    color: SECONDARY_COLOR,
    textTransform: "none",
    fontSize: isMobile ? "0.75rem" : "1rem"
  }),
  tabIcon: {
    height: theme.spacing(4.5),
    width: theme.spacing(7.5),
    color: BRIGHT_BLUE,
    marginLeft: "auto"
  },
  tabIconMobileSize: {
    height: theme.spacing(2.5),
    width: theme.spacing(5.5),
    color: BRIGHT_BLUE,
    marginLeft: "auto",
    marginRight: "auto"
  },
  selectedColor: {
    color: SECONDARY_COLOR
  },
}));

export default Tabs;