import AxiosCache from "./AxiosCache";

export default class AxiosWrapper {
  constructor(axiosInstance, options) {
    this.axios = axiosInstance;
    this.defaultErrorHandler = options && options.defaultErrorHandler;
    this.cache = new AxiosCache();
  }

  async request(config) {
    return await this.doRequest(config, "request", arguments);
  }

  async get(url, config) {
    return await this.doRequest(config, "get", arguments);
  }

  async post(url, data, config) {
    return await this.doRequest(config, "post", arguments);
  }

  async put(url, data, config) {
    return await this.doRequest(config, "put", arguments);
  }

  async delete(url, config) {
    return await this.doRequest(config, "delete", arguments);
  }

  async head(url, config) {
    return await this.doRequest(config, "head", arguments);
  }

  async options(url, config) {
    return await this.doRequest(config, "options", arguments);
  }

  async patch(url, data, config) {
    return await this.doRequest(config, "patch", arguments);
  }

  async doRequest(config, method, params) {
    let shouldCache =
      method === "get" && config && config.hasOwnProperty("cache") && config.cache === true;
    let cacheKey = !!shouldCache && this._getCacheKey(method, params);
    let cachedValue = this._getCachedValue(shouldCache, cacheKey);

    let shouldNotHandleError =
      config && config.hasOwnProperty("handleError") && config.handleError === false;
    let promisse;

    if (cachedValue) return await cachedValue;

    if (shouldNotHandleError) {
      promisse = this.axios[method].apply(this.axios, params);
      return await this._cacheRequest(shouldCache, cacheKey, promisse);
    }

    try {
      promisse = this.axios[method].apply(this.axios, params);
      return await this._cacheRequest(shouldCache, cacheKey, promisse);
    } catch (e) {
      this.defaultErrorHandler(e);
    }
  }

  _cacheRequest(shouldCache, key, promisse) {
    if (shouldCache) {
      promisse.then(response => {
        if (response.status === 200 && response.data) this.cache.put(key, response.data);
      });
    }

    return promisse;
  }

  _getCachedValue(shouldCache, key) {
    if (!shouldCache) return;

    let cachedValue = this.cache.get(key);

    if (cachedValue) return { status: 200, data: cachedValue };
  }

  _getCacheKey(method, params) {
    return this.cache.getKey(params[0], method, params[1] && params[1].params);
  }
}
