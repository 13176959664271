import React, { useState } from "react";
import { Grid, makeStyles, Button, Typography, Tabs, Tab } from "@material-ui/core";
import { Mail } from "react-feather";
import { GRAY, SECONDARY_COLOR } from "../../../theme";
import useCreateMessageModal from "../customHooks/useCreateMessageModal";
import useMessagesFilter from "../customHooks/useMessagesFilter";
import PageFilter from "./PageFilter";
import CreateMessageModal from "./CreateMessageModal";
import CustomTableAdjustmentMessage from "./CustomTableAdjustmentMessage";
import CustomTableMessageStandard from "./CustomTableMessageStandard";
import DrawerMessagesStandard from "./DrawerMessagesStandard";
import PageTitle from "../../../shared/components/PageTitle";
import GeneralLoading from "../../../shared/components/GeneralLoading";
import { messageType } from "../../../shared/utils/enum";
import { connect } from "react-redux";
import Loading from "../../../shared/components/Loading";

const Messages = props => {
  const { userId } = props;
  const classes = UseStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const [currentMessageType, setCurrentMessageType] = useState(messageType.adjustment);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [openDrawerMessagesStandard, setOpenDrawerMessagesStandard] = useState(false);

  const { createMessageModalOpen, handleOpenCloseCreateMessageModal, messageTypes } =
    useCreateMessageModal();

  const {
    selectedVendor,
    setSelectedVendor,
    selectedManagement,
    setSelectedManagement,
    selectedReadingStatus,
    setSelectedReadingStatus,
    messages,
    handleSubmitFilter,
    handleExportMessageReport,
    loadingData,
    setReloadMessages,
    selectedRangeDate,
    setSelectedRangeDate,
    setMessages,
    insertMessageReadDate,
    loadingExport
  } = useMessagesFilter(selectedTab, userId);

  const handleChangeSelectedTab = (event, newValue) => {
    if (selectedTab !== newValue) {
      setSelectedTab(newValue);

      switch (newValue) {
        case messageType.adjustment.tabValue:
          setCurrentMessageType(messageType.adjustment);
          break;
        case messageType.communication.tabValue:
          setCurrentMessageType(messageType.communication);
          break;
        case messageType.receivedDirect.tabValue:
          setCurrentMessageType(messageType.receivedDirect);
          break;
        case messageType.sentDirect.tabValue:
          setCurrentMessageType(messageType.sentDirect);
          break;
        default:
          setCurrentMessageType(messageType.sellingBalance);
      }

      setMessages([]);
    }
  };

  return loadingData ? (
    <GeneralLoading />
  ) : (
    <Grid container className={`pl-8 pt-8 pr-8 pb-4`}>
      <Grid item xs={12} className={`${classes.headerMessage}`}>
        <PageTitle
          title="Mensagens"
          icon={
            <Mail color={SECONDARY_COLOR} size={36} className={`${classes.iconMail} pr-1 mr-3`} />
          }
        />

        <Button
          variant="outlined"
          color="secondary"
          className={classes.button}
          onClick={() => handleOpenCloseCreateMessageModal()}
        >
          Nova Mensagem
        </Button>
      </Grid>

      <PageFilter
        selectedManagement={selectedManagement}
        setSelectedManagement={setSelectedManagement}
        selectedVendor={selectedVendor}
        setSelectedVendor={setSelectedVendor}
        selectedReadingStatus={selectedReadingStatus}
        setSelectedReadingStatus={setSelectedReadingStatus}
        selectedRangeDate={selectedRangeDate}
        setSelectedRangeDate={setSelectedRangeDate}
      />

      <Grid item xs={12} className={classes.containerFilterButton}>
        <Button
          variant="outlined"
          color="secondary"
          className={`${classes.button} ${classes.exportButton}`}
          onClick={() => handleExportMessageReport()}
          disabled={loadingExport}
        >
          {loadingExport ? (
            <Loading size={20} loading={loadingExport} className={classes.loadingPosition} />
          ) : (
            "Exportar"
          )}
        </Button>

        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleSubmitFilter}
        >
          Filtrar
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Tabs value={selectedTab} onChange={handleChangeSelectedTab} textColor="secondary">
          <Typography variant="h5" className="pt-2 pb-2">
            Histórico
          </Typography>
          <Tab
            className={
              selectedTab === messageType.adjustment.tabValue ? classes.selectedTab : classes.tab
            }
            label={messageType.adjustment.description}
            value={messageType.adjustment.tabValue}
          />
          <Tab
            className={
              selectedTab === messageType.sentDirect.tabValue ? classes.selectedTab : classes.tab
            }
            label={messageType.sentDirect.description}
            value={messageType.sentDirect.tabValue}
          />
          <Tab
            className={
              selectedTab === messageType.receivedDirect.tabValue
                ? classes.selectedTab
                : classes.tab
            }
            label={messageType.receivedDirect.description}
            value={messageType.receivedDirect.tabValue}
          />
          <Tab
            className={
              selectedTab === messageType.sellingBalance.tabValue ? classes.selectedTab : classes.tab
            }
            label={messageType.sellingBalance.description}
            value={messageType.sellingBalance.tabValue}
          />
        </Tabs>
        {selectedTab === messageType.adjustment.tabValue && (
          <CustomTableAdjustmentMessage loading={loadingData} messages={messages} />
        )}
        {selectedTab === messageType.sentDirect.tabValue && (
          <CustomTableMessageStandard
            loading={loadingData}
            messages={messages}
            setOpenDrawerMessagesStandard={setOpenDrawerMessagesStandard}
            setSelectedMessage={setSelectedMessage}
            messagesFieldName={messageType.sentDirect.fieldName}
          />
        )}
        {selectedTab === messageType.receivedDirect.tabValue && (
          <CustomTableMessageStandard
            loading={loadingData}
            messages={messages}
            isMessagesSentByConsultant
            setOpenDrawerMessagesStandard={setOpenDrawerMessagesStandard}
            setSelectedMessage={setSelectedMessage}
            insertLocalMessageReadDate={insertMessageReadDate}
            messagesFieldName={messageType.receivedDirect.fieldName}
          />
        )}
        {selectedTab === messageType.sellingBalance.tabValue && (
          <CustomTableMessageStandard
            loading={loadingData}
            messages={messages}
            setOpenDrawerMessagesStandard={setOpenDrawerMessagesStandard}
            setSelectedMessage={setSelectedMessage}
            messagesFieldName={messageType.sellingBalance.fieldName}
          />
        )}
      </Grid>

      <CreateMessageModal
        openedModal={createMessageModalOpen}
        closeModal={handleOpenCloseCreateMessageModal}
        messageTypes={messageTypes}
        reloadMessages={setReloadMessages}
      />

      <DrawerMessagesStandard
        openDrawer={openDrawerMessagesStandard}
        setOpenDrawer={setOpenDrawerMessagesStandard}
        message={selectedMessage}
        title={currentMessageType.description}
        messagesFieldName={currentMessageType.fieldName}
      />
    </Grid>
  );
};

const UseStyles = makeStyles(theme => ({
  iconMail: {
    verticalAlign: "bottom"
  },
  headerMessage: {
    display: "flex",
    justifyContent: "space-between"
  },
  button: {
    width: "180px",
    height: "56px",
    textTransform: "uppercase"
  },
  exportButton: {
    marginRight: "24px"
  },
  containerFilterButton: {
    display: "flex",
    justifyContent: "flex-end"
  },
  selectedTab: {
    textTransform: "none",
    color: SECONDARY_COLOR,
    fontSize: "1.25rem",
    fontWeight: 600,
    lineHeight: 1.6
  },
  loadingPosition: {
    margin: theme.spacing(2),
    position: "relative",
    color: SECONDARY_COLOR
  },
  tab: {
    textTransform: "none",
    color: GRAY,
    fontSize: "1.25rem",
    fontWeight: 600,
    lineHeight: 1.6
  }
}));
const mapStateToProps = state => {
  return {
    userId: state.user.id
  };
};

export default connect(mapStateToProps, null)(Messages);
