import React from "react";
import { Grid, Typography, makeStyles, Button } from "@material-ui/core";
import DeniedAccessIcon from "../img/DeniedAccessIcon.svg";

const LoginError = props => {
  const { messageError, logout } = props;
  const classes = useStyles();

  return (
    <Grid container className="pl-4 pt-8 pr-4 pb-4">
      <Grid item xs={12} className={`${classes.spacingImage} pb-8`}>
        <img src={DeniedAccessIcon} alt="Denied Access" className={classes.image} />
      </Grid>
      <Grid item xs={12} className="pb-4">
        <Typography variant="h3" align="center">
          Falha ao Entrar
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" align="center" color="secondary" className="pb-1">
          {messageError}
        </Typography>
        <Grid item xs={12} className="pl-6 pr-6 pb-8 pt-8">
          <Button variant="contained" color="primary" className={classes.button} onClick={logout}>
            SAIR
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  image: {
    display: "flex",
    margin: "auto",
    width: "160px"
  },
  spacingImage: {
    paddingTop: theme.spacing(8)
  },
  button: {
    width: "180px",
    height: "56px",
    margin: "auto",
    display: "flex"
  }
}));

export default LoginError;
