import React, { useEffect } from "react";
import Select from "../../../shared/components/Select";
import Modal from "../../../shared/components/Modal";
import UserCreateError from "../../../shared/img/UserCreateError.svg";
import UserCreateSuccess from "../../../shared/img/UserCreateSuccess.svg";
import Loading from "../../../shared/components/Loading";
import { Grid, makeStyles, Typography, TextField, Button, Tooltip } from "@material-ui/core";
import { PRIMARY_COLOR, SECONDARY_COLOR, WHITE, GRAY } from "../../../theme";
import { useModals } from "../customHooks/useModals";
import { UserPlus, Edit } from "react-feather";
import CollapsibleTable from "../../../shared/components/CustomTable/CollapsibleTable";
import { profiles as profilesEnum } from "../../../shared/utils/enum";
import { routes } from "../../../shared/utils/Constants";
import { Redirect } from "react-router-dom";
import { validateEmail, validatePhone, validateADUserId } from "../../../shared/utils/Utils";
import PhoneMask from "../../../shared/components/PhoneMask";
import useEditUserTextfields from "../customHooks/useEditUserTextfields";
import useEditUserSelects from "../customHooks/useEditUserSelects";
import useEditUser from "../customHooks/useEditUser";
import UserManager from "../UserManager";
import useTable from "../customHooks/useTable";
import ConfirmationModal from "../../../shared/components/ConfirmationModal";
import RemoveAssociationIcon from "../../../shared/img/RemoveAssociationIcon.svg";
import PageTitle from "../../../shared/components/PageTitle";
import _ from "lodash";

const EditUser = props => {
  const classes = useStyles();

  const {
    match: { params: parametrosRota }
  } = props;

  const isEditFlow = Boolean(parametrosRota.userId);
  const { userId } = parametrosRota;

  const pageTitle = isEditFlow ? "Edição de usuário" : "Novo usuário";
  const buttonText = isEditFlow ? "SALVAR" : "ADICIONAR";

  const pageIcon = isEditFlow ? (
    <Edit color={SECONDARY_COLOR} size={56} className={`${classes.icon} pr-3`} />
  ) : (
    <UserPlus color={SECONDARY_COLOR} size={56} className={`${classes.icon} pr-3`} />
  );

  const { name, setName, email, setEmail, phone, setPhone, ADUserId, setADUserId } =
    useEditUserTextfields();

  const {
    selectedProfile,
    setSelectedProfile,
    selectedManagement,
    setSelectedManagement,
    selectedVendor,
    setSelectedVendor,
    profiles,
    managements,
    vendors
  } = useEditUserSelects();

  const {
    associations,
    setAssociations,
    openedConfirmationModal,
    handleOpenCloseConfirmationModal,
    generateAssociation,
    openConfirmationModal,
    removeAssociation
  } = useTable(selectedProfile, vendors, selectedManagement, selectedVendor);

  const {
    handleSuccessModalOpenClose,
    handleErrorModalOpenClose,
    successModalOpen,
    errorModalOpen
  } = useModals();

  const {
    associateButtonDisabled,
    addButtonDisabled,
    redirect,
    setRedirect,
    saveUserButtonLoading,
    editUser,
    handleOpenToolTip,
    handleCloseToolTip,
    openAssociateToolTip
  } = useEditUser(
    name,
    email,
    phone,
    selectedProfile,
    selectedManagement,
    selectedVendor,
    associations,
    isEditFlow,
    userId,
    handleSuccessModalOpenClose,
    handleErrorModalOpenClose,
    ADUserId
  );

  useEffect(() => {
    const generateAssociationData = listVendor => {
      const formatedVendors = listVendor.map(v => ({
        managementId: v.management.id,
        management: v.management.initials,
        vendor: v.initials,
        vendorId: v.id,
        states: v.states
      }));

      const groupedVendors = _.groupBy(formatedVendors);
      const associationArray = [];

      for (const key in groupedVendors) {
        associationArray.push(groupedVendors[key]);
      }

      return associationArray;
    };

    const getUserFromId = async () => {
      const data = await UserManager.getUserFromId(userId);
      if (data) {
        setName(data.name);
        setEmail(data.email);
        setPhone(data.phone);
        setSelectedProfile(data.profiles[0]);
        setADUserId(data.adUserId);
        setAssociations(generateAssociationData(data.listVendor));
      }
    };

    if (userId && isEditFlow) getUserFromId();
  }, [
    userId,
    isEditFlow,
    setName,
    setEmail,
    setPhone,
    setSelectedProfile,
    setADUserId,
    setAssociations
  ]);

  return (
    <Grid container className={`pl-8 pt-8 pr-2 pb-4`}>
      <Grid item xs={12}>
        <PageTitle title={pageTitle} icon={pageIcon} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" align="left" className="pb-1">
          Dados gerais
        </Typography>
      </Grid>
      <Grid item xs={12} className="pb-5">
        <Typography variant="body1" align="left">
          Digite o nome, e-mail e telefone do usuário
        </Typography>
      </Grid>
      <Grid item container xs={12} spacing={3} className="pb-2">
        <Grid item xs={3}>
          <TextField
            className={classes.textField}
            variant="outlined"
            label="Nome"
            inputProps={{ maxLength: 255 }}
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            className={classes.textField}
            variant="outlined"
            label="E-mail"
            inputProps={{ maxLength: 255 }}
            value={email}
            error={email !== "" && !validateEmail(email)}
            helperText={(email !== "" && !validateEmail(email) && "Insira um email válido") || " "}
            onChange={e => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            className={classes.textField}
            variant="outlined"
            label="Yara Id"
            value={ADUserId}
            inputProps={{ maxLength: 7 }}
            error={ADUserId !== "" && !validateADUserId(ADUserId)}
            helperText={
              (ADUserId !== "" && !validateADUserId(ADUserId) && "Insira um Yara ID válido") || " "
            }
            onChange={e => setADUserId(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            className={classes.textField}
            variant="outlined"
            label="Telefone"
            value={phone}
            error={!phone !== "" && phone && validatePhone(phone)}
            helperText={
              !phone !== "" && phone && validatePhone(phone) && "Insira um telefone válido"
            }
            onChange={e => setPhone(e.target.value)}
            InputProps={{
              inputComponent: PhoneMask,
              inputProps: { length: phone ? phone.length : 0 }
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Select
            label="Perfil"
            options={profiles}
            code={selectedProfile && selectedProfile.initials}
            handleChange={e => setSelectedProfile(e)}
            getOptionValue={option => option.initials}
            getOptionLabel={option => option.name}
            maxHeightMenu="150"
            returnFullObject
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className="pb-3">
        <Typography variant="h5" align="left">
          Gerências - Consultorias associadas
        </Typography>
      </Grid>
      <Grid item container xs={12} spacing={3} className="pb-4">
        <Grid item xs={3}>
          <Select
            label={
              selectedProfile && selectedProfile.initials === profilesEnum.backOffice.initials
                ? "Todas"
                : "Gerências"
            }
            disabled={
              !selectedProfile ||
              (selectedProfile && selectedProfile.initials === profilesEnum.backOffice.initials)
            }
            code={selectedManagement && { value: selectedManagement }}
            options={managements}
            handleChange={e => setSelectedManagement(e)}
            getOptionValue={option => option}
            getOptionLabel={option => option.initials}
            maxHeightMenu="150"
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            label={
              selectedProfile &&
              (selectedProfile.initials === profilesEnum.manager.initials ||
                selectedProfile.initials === profilesEnum.backOffice.initials)
                ? "Todas"
                : "Consultorias"
            }
            disabled={
              !selectedProfile ||
              (selectedProfile &&
                (selectedProfile.initials === profilesEnum.manager.initials ||
                  selectedProfile.initials === profilesEnum.backOffice.initials))
            }
            code={selectedVendor && { value: selectedVendor }}
            options={vendors}
            handleChange={e => setSelectedVendor(e)}
            getOptionValue={option => option}
            getOptionLabel={option => option.initials}
            maxHeightMenu="150"
            returnFullObject
          />
        </Grid>
        <Grid item xs={3}>
          <Tooltip
            title="Para associar, os campos perfil, gerência e consultoria devem estar preenchidos."
            aria-label="Para associar, os campos perfil, gerência e consultoria devem estar preenchidos."
            onOpen={handleOpenToolTip}
            onClose={handleCloseToolTip}
            open={openAssociateToolTip}
            placement="top"
          >
            <span>
              <Button
                variant="outlined"
                color="secondary"
                className={classes.button}
                onClick={() => generateAssociation()}
                disabled={associateButtonDisabled}
              >
                ASSOCIAR
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item xs={12} className="pb-4 pr-6">
        <CollapsibleTable
          headers={headers}
          options={tableCellOptions}
          data={associations}
          edit
          remove={openConfirmationModal}
          buttonsDisabled={
            selectedProfile && selectedProfile.initials === profilesEnum.manager.initials
          }
        />
      </Grid>
      <Grid container item xs={12} spacing={3} justify="flex-end">
        <Grid item xs={2}>
          <Button
            className={`${classes.button} ${classes.buttonsAligment}`}
            color="primary"
            onClick={() => setRedirect(<Redirect to={routes.userManagement} />)}
          >
            VOLTAR
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            className={`${classes.button} ${classes.addButton} ${classes.buttonsAligment}`}
            onClick={() => editUser()}
            disabled={addButtonDisabled || saveUserButtonLoading}
          >
            {saveUserButtonLoading ? (
              <Loading
                size={20}
                loading={saveUserButtonLoading}
                className={classes.loadingPosition}
              />
            ) : (
              buttonText
            )}
          </Button>
        </Grid>
      </Grid>
      <ConfirmationModal
        openedModal={openedConfirmationModal}
        title="Desassociar consultoria/gerência"
        subtitle="Tem certeza que deseja desassociar a consultoria/gerência do usuário?"
        secondaryButtonText="NÃO"
        secondaryAction={handleOpenCloseConfirmationModal}
        primaryButtonText="SIM"
        primaryAction={removeAssociation}
        handleOpenClose={handleOpenCloseConfirmationModal}
        titleIcon={<img src={RemoveAssociationIcon} alt="error remove association" />}
      />
      <Modal
        primaryButton={{
          action: () => handleErrorModalOpenClose(),
          text: "FECHAR"
        }}
        openedModal={errorModalOpen}
        closeModal={handleErrorModalOpenClose}
        title="Usuário já existente"
        titleIcon={<img src={UserCreateError} alt="User already registered" />}
        closeIcon
      >
        <Typography align="center" className={classes.colorSubtitle}>
          Não foi possível criar o usuário pois já existe um associado a este e-mail.
        </Typography>
      </Modal>
      <Modal
        primaryButton={{
          action: () => handleSuccessModalOpenClose(),
          text: "FECHAR"
        }}
        redirect
        openedModal={successModalOpen}
        closeModal={handleSuccessModalOpenClose}
        title={isEditFlow ? "Usuário salvo!" : "Usuário adicionado!"}
        titleIcon={<img src={UserCreateSuccess} alt="User created successfully" />}
        closeIcon
      >
        <Typography align="center" className={classes.colorSubtitle}>
          {isEditFlow
            ? "A edição do usuário e a suas associações foram salvas com sucesso."
            : "O usuário e a suas associações foram adicionadas com sucesso."}
        </Typography>
      </Modal>
      {redirect}
    </Grid>
  );
};

const headers = [
  {
    field: "management",
    name: "Gerência",
    width: "20%"
  },
  {
    field: "vendor",
    name: "Consultoria",
    width: "20%"
  },
  {
    field: "states",
    name: "Estados",
    width: "50%"
  }
];

const tableCellOptions = {
  import: {
    hasImageToggle: false
  }
};

const useStyles = makeStyles(theme => ({
  icon: {
    verticalAlign: "bottom"
  },
  textField: {
    width: "100%",
    height: "100%"
  },
  button: {
    width: "180px",
    height: "56px"
  },
  addButton: {
    backgroundColor: PRIMARY_COLOR,
    color: WHITE,
    "&:hover": {
      backgroundColor: PRIMARY_COLOR
    }
  },
  loadingPosition: {
    margin: theme.spacing(2),
    position: "relative",
    color: SECONDARY_COLOR
  },
  colorSubtitle: {
    color: GRAY
  },
  buttonsAligment: {
    display: "flex",
    marginLeft: "auto"
  }
}));

export default EditUser;
