import { useState, useEffect } from "react";
import PotentialImportManager from "../PotentialImportManager";

export const useImportEvents = maxNumberOfEvents => {
  const [importEvents, setImportEvents] = useState();
  const [reloadImportEvents, setReloadImportEvents] = useState(true);
  const [dataIsReady, setDataIsReady] = useState(false);

  useEffect(() => {
    const getImportEvents = async () => {
      const result = await PotentialImportManager.listLastImportEvents(maxNumberOfEvents);
      if (result) setImportEvents(result);
      setReloadImportEvents(false);
      setDataIsReady(true);
    };

    reloadImportEvents && getImportEvents();
  }, [maxNumberOfEvents, reloadImportEvents]);

  return {
    importEvents,
    setReloadImportEvents,
    dataIsReady
  };
};
