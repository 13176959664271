import React from "react";
import { Tabs, Tab, makeStyles, Typography, Grid, Box } from "@material-ui/core";
import { DataType } from "../../../shared/utils/enum";
import SaleTypeIcons from "./SaleTypeIcons";
import { numberWithSeparators } from "../../../shared/utils/Utils";
import { SECONDARY_COLOR, BRIGHT_BLUE, PALE_BLUE_GRAY, WHITE, LIGHT_GRAY3 } from "../../../theme";

const SaleTypeTabs = props => {
  const {
    competitorTotals,
    selectedTypeTab,
    handleChangeTab,
    customerTotals,
    cropTotals,
    isMobile
  } = props;

  const classes = useStyles({ isMobile: isMobile });

  const currentYear = new Date().getFullYear();
  const firstNextYear = new Date().getFullYear() + 1;
  const secondNextYear = new Date().getFullYear() + 2;
  const thirdNextYear = new Date().getFullYear() + 3;

  const getLabelTab = type => {
    return (
      <Grid container>
        <Grid item xs={!isMobile ? 6 : 12}>
          <SaleTypeIcons
            selected={selectedTypeTab === DataType[type].id}
            icon={DataType[type].icon}
            isMobile={isMobile}
          />
        </Grid>
        <Grid item xs={!isMobile ? 6 : 12} className={!isMobile ? "pl-2 pt-4" : null}>
          <Typography
            variant="body1"
            className={
              selectedTypeTab === DataType[type].id
                ? classes.textTabColorSelected
                : classes.textTabColor
            }
            align={!isMobile ? "left" : "center"}
          >
            {DataType[type].name}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const lineTotalYear = (competitorValue, customerValue, cropValue, year) => {
    const lastDigit = year.toString().split("").pop();

    return (
      <Grid container>
        <Grid item xs={4} className={classes.adjustYearPosition}>
          <Typography variant="body1" className={classes.textTabColor} align="center">
            {numberWithSeparators(competitorValue)}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body1" className={classes.textTabColor} align="center">
            <span className={classes.year}>{year}</span>
            <span className={lastDigit !== "1" ? classes.adjustValuePosition : undefined}>
              {numberWithSeparators(customerValue)}
            </span>
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body1" className={classes.textTabColor} align="center">
            <span className={classes.year}>{year}</span>
            <span className={lastDigit !== "1" ? classes.adjustValuePosition : undefined}>
              {numberWithSeparators(cropValue)}
            </span>
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box className="pb-2">
      <Tabs
        value={selectedTypeTab}
        indicatorColor="secondary"
        textColor="secondary"
        onChange={handleChangeTab}
        className={classes.tabs}
      >
        <Tab
          label={getLabelTab("competitor")}
          className={`${classes.borderBetweenTabs} ${classes.tabWidth}`}
        />
        <Tab
          label={getLabelTab("customer")}
          className={`${classes.borderBetweenTabs} ${classes.tabWidth}`}
        />
        <Tab label={getLabelTab("crop")} className={classes.tabWidth} />
      </Tabs>
      {lineTotalYear(
        competitorTotals.currentYearTotal,
        customerTotals.currentYearTotal,
        cropTotals.currentYearTotal,
        currentYear
      )}
      {lineTotalYear(
        competitorTotals.firstNextYearTotal,
        customerTotals.firstNextYearTotal,
        cropTotals.firstNextYearTotal,
        firstNextYear
      )}
      {lineTotalYear(
        competitorTotals.secondNextYearTotal,
        customerTotals.secondNextYearTotal,
        cropTotals.secondNextYearTotal,
        secondNextYear
      )}
      {lineTotalYear(
        competitorTotals.thirdNextYearTotal,
        customerTotals.thirdNextYearTotal,
        cropTotals.thirdNextYearTotal,
        thirdNextYear
      )}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  tabs: {
    "& .MuiTabs-fixed": {
      margin: "auto",
      display: "flex",
      maxWidth: "100%"
    },
    "& .MuiTabs-flexContainer": {
      margin: "auto",
      display: "flex",
      width: "100%"
    },
    "& .MuiTabs-indicator": {
      top: 0,
      height: "4px"
    }
  },
  borderBetweenTabs: {
    borderRight: `1px solid ${PALE_BLUE_GRAY}`,
    borderImage: `radial-gradient(${PALE_BLUE_GRAY} 80%, ${WHITE} 20%)`,
    borderImageSlice: 1
  },
  tabWidth: {
    maxWidth: "calc(100% /3)",
    minWidth: "calc(100% /3)"
  },
  textTabColor: props => ({
    color: BRIGHT_BLUE,
    textTransform: "none",
    fontSize: props.isMobile ? "0.75rem" : "1rem"
  }),
  textTabColorSelected: props => ({
    color: SECONDARY_COLOR,
    textTransform: "none",
    fontSize: props.isMobile ? "0.75rem" : "1rem"
  }),
  adjustYearPosition: props => ({
    marginRight: props.isMobile ? "-16px" : "-20px"
  }),
  year: {
    color: LIGHT_GRAY3,
    float: "left"
  },
  adjustValuePosition: {
    marginLeft: "-4px"
  }
}));

export default SaleTypeTabs;
