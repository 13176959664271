import React from "react";
import { Grid, makeStyles, Typography, Button } from "@material-ui/core";
import { SECONDARY_COLOR, PALE_BLUE_GRAY } from "../../../theme";
import InsertNotAvailableIcon from "../../../shared/img/InsertNotAvailableIcon.svg";

const InvalidInsertionMessage = props => {
  const { backToLastWeek } = props;

  const classes = useStyles();

  return (
    <Grid container item xs={12} className={`pt-6 ${classes.contentAligment}`}>
      <Grid item xs={12} align="center" className={`pb-2 pt-6`}>
        <img src={InsertNotAvailableIcon} alt="" aria-hidden="true" />
      </Grid>

      <Grid item xs={12} align="center">
        <Typography variant="h4">Lançamentos não disponíveis</Typography>
      </Grid>
      <Grid item xs={12} align="center">
        <Typography className={classes.alertMessage}>
          A semana corrente será disponibilizada na terça feira. Para ajustes, selecione a semana
          desejada no calendário.
        </Typography>
      </Grid>
      <Grid item xs={12} align="center">
        <Button
          variant="outlined"
          color="primary"
          className={`mt-4 mb-7 ${classes.Button}`}
          onClick={backToLastWeek}
        >
          SEMANA ANTERIOR
        </Button>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  contentAligment: {
    width: "100%",
    border: `1px solid ${PALE_BLUE_GRAY}`,
    borderRadius: theme.spacing(1)
  },
  alertMessage: {
    color: SECONDARY_COLOR
  }
}));

export default InvalidInsertionMessage;
