import axios from "../axiosInstance";

class PotentialService {
  async updatePotentials(data) {
    return await axios.post("potential/potentials", data);
  }

  async importPotentialSheet(file, userId) {
    const formData = new FormData();
    formData.append("file", file);
    try {
      return await axios.post("/potential/insertdatawithsheets", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        params: {
          userId: userId
        },
        handleError: false
      });
    } catch (error) {
      const message = error.response && error.response.data && error.response.data.message;
      return { data: { requestError: true, message: message } };
    }
  }

  async listStatesPotentialTotals(year, stateVendorIds) {
    return await axios.get("potential/statetotals", {
      params: { year, stateVendorIds }
    });
  }
}

export default new PotentialService();
