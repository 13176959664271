import React, { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { isValidTypePillar } from "../../../shared/utils/Utils";
import { preRegisteredTypes } from "../../../shared/utils/enum";
import PageNotFound from "../../../shared/components/PageNotFound";
import PageTitle from "../../../shared/components/PageTitle";
import GeneralLoading from "../../../shared/components/GeneralLoading";
import Request from "./Request";
import Treatment from "./Treatment";
import FinishButton from "./FinishButton";
import AcceptTermModal from "./AcceptTermModal";
import SearchBox from "./SearchBox";
import { CheckCircle } from "react-feather";
import { SECONDARY_COLOR } from "../../../theme";
import ApprovePreRegisteredManager from "../ApprovePreRegisteredManager";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { routes } from "../../../shared/utils/Constants";

const ApprovePreRegistered = props => {
  const {
    match: { params: routeParams },
    userId
  } = props;

  const { type, preRegisteredId } = routeParams;
  const classes = useStyles();

  const isValidType = isValidTypePillar(type);
  const isTypeCompetitor = preRegisteredTypes.competitor === type;

  const [pageLoading, setPageLoading] = useState(true);
  const [isValidPreRegisteredId, setIsValidPreRegisteredId] = useState(true);
  const [preRegisteredData, setPreRegisteredData] = useState();
  const [shouldGetData, setShouldGetData] = useState(isValidType);
  const [treatmentRadio, setTreatmentRadio] = useState("new");
  const [isNewRegister, setIsNewRegister] = useState(true);
  const [selectedItem, setSelectedItem] = useState();
  const [openedModal, setOpenedModal] = useState(false);
  const [completePreRegisteredLoading, setCompletePreRegisteredLoading] = useState(false);
  const [redirect, setRedirect] = useState();

  const handleOpenCloseModal = () => {
    setOpenedModal(!openedModal);
  };

  useEffect(() => {
    selectedItem ? setTreatmentRadio("exists") : setTreatmentRadio("new");
  }, [selectedItem]);

  useEffect(() => {
    if (treatmentRadio === "new") {
      setIsNewRegister(true);
      setSelectedItem();
    } else {
      setIsNewRegister(false);
    }
  }, [treatmentRadio]);

  useEffect(() => {
    const getData = async () => {
      setShouldGetData(false);
      const data = isTypeCompetitor
        ? await ApprovePreRegisteredManager.getPreRegisteredCompetitor(preRegisteredId)
        : await ApprovePreRegisteredManager.getPreRegisteredCustomer(preRegisteredId);

      !data && setIsValidPreRegisteredId(false);
      setPreRegisteredData(data);
      setPageLoading(false);
    };

    shouldGetData && getData();
  }, [shouldGetData, preRegisteredId, isTypeCompetitor]);

  const completePreRegistered = async () => {
    setCompletePreRegisteredLoading(true);
    const data = isTypeCompetitor
      ? await ApprovePreRegisteredManager.completePreRegisteredCompetitor(
          preRegisteredId,
          userId,
          selectedItem && selectedItem.id
        )
      : await ApprovePreRegisteredManager.completePreRegisteredCustomer(
          preRegisteredId,
          userId,
          selectedItem && selectedItem.id
        );
    handleOpenCloseModal();
    setCompletePreRegisteredLoading(false);
    data && setRedirect(<Redirect to={`${routes.preRegistered}`} />);
  };

  return (
    <>
      {!isValidType || !isValidPreRegisteredId || pageLoading ? (
        <>
          {!pageLoading && <PageNotFound />}
          {pageLoading && <GeneralLoading />}
        </>
      ) : (
        <Grid container className={`pl-8 pt-5 pr-8 pb-4`}>
          <Grid container item xs={12} spacing={2} className="pb-3">
            <Grid item xs={12}>
              <PageTitle
                title={`Aprovação de novo ${isTypeCompetitor ? "concorrente" : "cliente"}`}
                icon={
                  <CheckCircle
                    color={SECONDARY_COLOR}
                    size={56}
                    className={`pr-4 pt-3 ${classes.icon}`}
                  />
                }
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={4}>
            <Grid container item xs={6} spacing={1}>
              <Grid container item xs={12}>
                <Request
                  preRegisteredData={preRegisteredData}
                  isTypeCompetitor={isTypeCompetitor}
                />
              </Grid>
              <Grid container item xs={12}>
                <Treatment treatmentRadio={treatmentRadio} setTreatmentRadio={setTreatmentRadio} />
              </Grid>
              <Grid container item xs={12}>
                <FinishButton
                  selectedItem={selectedItem}
                  isNewRegister={isNewRegister}
                  clickButton={handleOpenCloseModal}
                />
              </Grid>
            </Grid>
            <Grid container item xs={6} spacing={1}>
              <SearchBox
                isTypeCompetitor={isTypeCompetitor}
                setSelectedItem={setSelectedItem}
                selectedItem={selectedItem}
              />
            </Grid>
          </Grid>
          <AcceptTermModal
            openedModal={openedModal}
            handleOpenClose={handleOpenCloseModal}
            secondaryAction={handleOpenCloseModal}
            isNewRegister={isNewRegister}
            preRegisteredData={preRegisteredData}
            selectedItem={selectedItem}
            loading={completePreRegisteredLoading}
            primaryAction={completePreRegistered}
          />
        </Grid>
      )}
      {redirect}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  icon: {
    verticalAlign: "bottom"
  }
}));

const mapStateToProps = state => {
  return {
    userId: state.user.id
  };
};

export default connect(mapStateToProps, null)(ApprovePreRegistered);
