import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Collapse,
  Grid,
  Box,
  makeStyles
} from "@material-ui/core";
import { ChevronDown, ChevronUp } from "react-feather";
import { WHITE } from "../../../theme";

const SelectVendors = props => {
  const classes = useStyles();
  const {
    openSelectVendors,
    setOpenSelectVendors,
    selectedVendor,
    listVendors,
    updateVendor
  } = props;

  const verifySelectedVendor = vendor =>
    vendor.initials === (selectedVendor && selectedVendor.initials);

  return (
    <>
      <Grid
        container
        onClick={() => setOpenSelectVendors(!openSelectVendors)}
        className="hover-icon"
      >
        <Grid item xs={4}>
          <Typography variant="caption" className={`${classes.typographyColorWhite}`}>
            {selectedVendor && selectedVendor.initials}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          {openSelectVendors ? (
            <ChevronUp color={WHITE} size={20} />
          ) : (
            <ChevronDown color={WHITE} size={20} />
          )}
        </Grid>
      </Grid>

      <Collapse in={openSelectVendors} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {listVendors &&
            listVendors.map((vendor, index) => {
              return (
                <Box key={index}>
                  <ListItem
                    disableGutters
                    button
                    onClick={() => {
                      setOpenSelectVendors(false);
                      updateVendor(vendor);
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          variant={verifySelectedVendor(vendor) ? "body2" : "caption"}
                          className={`${classes.typographyColorWhite} ${
                            verifySelectedVendor(vendor) && "bold"
                          }`}
                        >
                          {verifySelectedVendor(vendor) && "• "} {vendor.initials}
                        </Typography>
                      }
                    />
                  </ListItem>
                  {index !== listVendors.length - 1 && (
                    <Divider light className={classes.divider} />
                  )}
                </Box>
              );
            })}
        </List>
      </Collapse>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  divider: {
    backgroundColor: WHITE
  },
  typographyColorWhite: {
    color: WHITE
  }
}));

export default SelectVendors;
