import { useState, useEffect } from "react";
import NewDirectMessageConsultantManager from "../NewDirectMessageConsultantManager";
import { messageType as MessageTypeEnum } from "../../../shared/utils/enum";
import {
  formatDataToCreateDirectMessage,
  formatDirectMessage
} from "../FormatDataToCreateDirectMessage";

const useNewDirectMessageConsultant = (
  userSenderId,
  selectedVendorId,
  selectedReason,
  description,
  selectedAbout
) => {
  const [redirect, setRedirect] = useState(null);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);

  const createMessage = async () => {
    setSendingMessage(true);

    const directMessage = formatDirectMessage(selectedReason, description, selectedAbout);

    const bodyMessage = formatDataToCreateDirectMessage(
      userSenderId,
      selectedVendorId,
      directMessage
    );

    const data = await NewDirectMessageConsultantManager.createMessageSentByConsultant(
      bodyMessage,
      MessageTypeEnum.sentDirect.id
    );

    setSuccessModalOpen(data);
    setSendingMessage(false);
  };

  const fetchAboutOptions = async (term) => {
    return NewDirectMessageConsultantManager.listCropCompetitorCustomerByTermAndVendorIds([selectedVendorId], term);
  }

  useEffect(() => {
    setSendButtonDisabled(!selectedReason || !description || !selectedAbout || sendingMessage);
  }, [selectedReason, description, selectedAbout, sendingMessage]);

  return {
    setRedirect,
    createMessage,
    sendButtonDisabled,
    sendingMessage,
    successModalOpen,
    redirect,
    fetchAboutOptions
  };
};

export default useNewDirectMessageConsultant;
