import React from 'react';
import TabIconFactory from '../../shared/components/Tabs/TabIconFactory';
import { Calendar } from 'react-feather';

export const createSellingBalanceSearchTabsEnum = props => {
  const CalendarIcon = TabIconFactory(Calendar);

  return {
    monthly: {
      id: 0,
      name: "Mês",
      icon: <CalendarIcon {...props} tabIndex={0} />,
    },
    weekly: {
      id: 1,
      name: "Semana",
      icon: <CalendarIcon {...props} tabIndex={1} />,
    },
  };
};