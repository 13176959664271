import React, { useState } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import SECONDARY_COLOR from "../../../theme";
import EntriesManagementIcon from "../../../shared/img/EntriesManagementIcon.svg";
import PageFilter from "./PageFilter";
import FilterButtons from "../../../shared/components/FilterButtons";
import EntriesManagementManager from "../EntriesManagementManager";
import PageTitle from "../../../shared/components/PageTitle";

const EntriesManagement = props => {
  const classes = useStyles();

  const [loadingExport, setLoadingExport] = useState(false);

  const getEntriesReport = async () => {
    setLoadingExport(true);
    await EntriesManagementManager.getPotentialReport(new Date());
    setLoadingExport(false);
  };

  return (
    <Grid container className={`pl-8 pt-8 pr-8 pb-4`}>
      <PageTitle
        title="Gestão de lançamentos"
        icon={
          <img
            src={EntriesManagementIcon}
            alt="Gestão de Lançamentos"
            className={`${classes.iconTextSettings} pr-3 pt-1`}
          />
        }
      />
      <Grid item xs={12}>
        <Typography variant="h5" align="left" className="pb-2">
          Lançamentos
        </Typography>
      </Grid>

      <PageFilter />
      <FilterButtons
        secondaryDisabled
        secondaryText="FILTRAR"
        primaryText="EXPORTAR"
        primaryAction={getEntriesReport}
        primaryLoading={loadingExport}
        primaryDisabled={loadingExport}
      />
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  iconTextSettings: {
    verticalAlign: "bottom",
    color: SECONDARY_COLOR,
    height: "48px",
    width: "48px"
  }
}));

export default EntriesManagement;
