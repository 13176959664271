import DateFnsUtils from "@date-io/date-fns";
import locale from "../../../shared/utils/Locale";
import React from 'react';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import theme from "../../../theme";

const YearPicker = props => {
  const {
    onChangeDates,
    startDate
  } = props;

  const handleChangeYear = (value) => {
    const newStartDate = new Date(value.getFullYear(), 0, 1);
    const newEndDate = new Date(value.getFullYear(), 11, 31);

    onChangeDates(newStartDate, newEndDate);
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
      <ThemeProvider theme={materialTheme}>
        <DatePicker
          cancelLabel="VOLTAR"
          value={startDate}
          onChange={handleChangeYear}
          minDate={new Date(new Date().getFullYear(), 0, 1)}
          maxDate={new Date().setFullYear(new Date().getFullYear() + 1)}
          label="Ano entrega"
          views={["year"]}
          inputVariant="outlined"
          minDateMessage="Selecione uma data válida"
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

const materialTheme = createMuiTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiPickersYearSelection: {
      container: {
        height: "auto",
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        minHeight: "0px",
      }
    },
  },
});

export default YearPicker;