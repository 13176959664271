import { useState } from "react";
import ConsultantMessageManager from "../ConsultantMessageManager";

const useListMessages = (setLoadingData, setHasMore) => {
  const [listReceivedMessages, setListReceivedMessages] = useState([]);
  const [listSentMessages, setListSentMessages] = useState([]);

  const handleListReceivedMessages = async (userId, vendorId, page) => {
    if (!page) setLoadingData(true);

    const response = await ConsultantMessageManager.listMessagesByUserIdAndVendorIdPaged(
      userId,
      vendorId,
      page
    );

    if (response) {
      setListReceivedMessages(response);
      setHasMore(response.length >= 15);
    }

    if (!page) setLoadingData(false);
  };

  const handleListSentMessages = async (vendorId, page) => {
    if (!page) setLoadingData(true);

    const response = await ConsultantMessageManager.listVendorSentMessages(vendorId, page);

    if (response) {
      setListSentMessages(response);
      setHasMore(response.length >= 15);
    }

    if (!page) setLoadingData(false);
  };

  return {
    listReceivedMessages,
    handleListReceivedMessages,
    listSentMessages,
    handleListSentMessages
  };
};

export default useListMessages;
