import { useState } from "react";
import CompetitorCustomerCropManager from "../CompetitorCustomerCropManager";

const useUpdateCrop = () => {
  const [loadingData, setLoadingData] = useState(false);

  const handleUpdateCrop = async (crop, cropId) => {
    setLoadingData(true);

    const response = await CompetitorCustomerCropManager.updateCrop(crop, cropId);

    setLoadingData(false);

    return response;
  };

  return {
    handleUpdateCrop,
    loadingData
  };
};

export default useUpdateCrop;
