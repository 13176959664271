import React from "react";
import { PieChart } from "react-feather";
import { Grid, makeStyles } from "@material-ui/core";
import { SECONDARY_COLOR } from "../../../theme";
import CustomTable from "./CustomTable";
import PageTitle from "../../../shared/components/PageTitle";

const CompetitorCustomerCrop = props => {
  const classes = useStyles();

  return (
    <Grid container className={`pl-8 pt-5 pr-2 pb-4`}>
      <Grid item container xs={12} className="pb-5 pt-3 pr-6">
        <Grid item xs={12}>
          <PageTitle
            title="Concorrentes, Clientes e Culturas"
            icon={
              <PieChart color={SECONDARY_COLOR} size={56} className={`pr-4 pt-3 ${classes.icon}`} />
            }
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} className="pr-6">
        <CustomTable />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  icon: {
    verticalAlign: "bottom"
  }
}));

export default CompetitorCustomerCrop;
