import axios from "../axiosInstance";
import qs from "qs";

class SellingBalanceService {
  async getSellingLimits(vendorInitials, startDate, endDate) {
    return axios.get("sellingbalance/sellinglimits", {
      params: {
        vendorInitials: vendorInitials,
        startDate: startDate,
        endDate: endDate
      }
    });
  }
  
  async getForecastAndEvolutions(vendorInitials, startDate, endDate) {
    return axios.get("sellingbalance/forecastevolutions", {
      params: {
        vendorInitials: vendorInitials,
        startDate: startDate,
        endDate: endDate
      }
    });
  }

  async getForecastGroups(vendorInitials, familyGroup, productGroup, siteInitials, searchPeriod) {
    return axios.get("sellingbalance/forecastgroups", {
      params: {
        vendorInitials,
        familyGroup,
        productGroup,
        siteInitials,
        searchPeriod,
      }
    });
  }

  async getDollarExchange() {
    return axios.get("sellingbalance/dollarexchange");
  }

  async getLastUpdateDateTime() {
    return axios.get("sellingbalance/lastupdatedatetime");
  }

  async getEntitiesByVendorIdsAndTerm(vendorIds, term) {
    return axios.get("sellingbalance/entities", {
      params: {
        vendorIds,
        term,
      },
      paramsSerializer: (params) => qs.stringify(params)
    })
  }
}

export default new SellingBalanceService();
