import { useState } from "react";

const useEditVendorStates = showNotification => {
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(0);
  const [statesList, setStatesList] = useState([]);
  const [addStateModalOpen, setAddStateModalOpen] = useState(false);
  const [addSelectedState, setAddSelectedState] = useState();
  const [removeStateModalOpen, setRemoveStateModalOpen] = useState(false);
  const [stateToBeRemoved, setStateToBeRemoved] = useState();

  const handleChangeSelectedStateTab = (event, newValue) => setSelectedState(newValue);

  const handleOpenCloseAddStateModal = () => {
    setAddStateModalOpen(!addStateModalOpen);
    setAddSelectedState();
  };

  const handleAddState = () => {
    if (!states.find(s => s.id === addSelectedState.id)) {
      const cloneStates = [...states];
      cloneStates.push({ ...addSelectedState, competitors: [], crops: [], customers: [] });
      setStates(cloneStates);
      setSelectedState(cloneStates.length - 1);
      handleOpenCloseAddStateModal();
    } else {
      showNotification(
        `${addSelectedState && addSelectedState.name} já está vinculado à esta consultoria.`
      );
    }
  };

  const handleOpenCloseRemoveStateModal = stateId => {
    setRemoveStateModalOpen(!removeStateModalOpen);
    setStateToBeRemoved(stateId);
  };

  const handleConfirmRemoveStateModal = () => {
    if (stateToBeRemoved) {
      const newStates = states.filter(s => s.id !== stateToBeRemoved);
      setSelectedState(0);
      setStates(newStates);
    }
    handleOpenCloseRemoveStateModal();
  };

  return {
    states,
    setStates,
    statesList,
    setStatesList,
    handleAddState,
    handleOpenCloseAddStateModal,
    addStateModalOpen,
    setAddSelectedState,
    addSelectedState,
    selectedState,
    handleChangeSelectedStateTab,
    removeStateModalOpen,
    handleOpenCloseRemoveStateModal,
    handleConfirmRemoveStateModal
  };
};

export default useEditVendorStates;
