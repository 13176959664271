import React, { useState } from "react";
import { connect } from "react-redux";
import { Dialog, Grid, Divider, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SECONDARY_COLOR, PALE_BLUE_GRAY, GRAY } from "../../../../theme";
import { PlusCircle, X } from "react-feather";
import { dialogNotificationBox } from "../../../utils/Constants";
import { screenSizes } from "../../../utils/enum";

const AddNewCustomerCompetitorCard = props => {
  const { className, windowWidth, handleAddNewCompetitor, handleAddNewCustomer } = props;

  const isMobile = windowWidth < screenSizes.webScreenSize;

  const relativePosition = isMobile
    ? (windowWidth - dialogNotificationBox.width) / 2 - 12
    : windowWidth - (dialogNotificationBox.width + 20);

  const classes = useStyles({
    relativePosition: relativePosition,
    maxWidth: dialogNotificationBox.maxWidth
  });

  const [openCard, setOpenCard] = useState(false);

  return (
    <>
      <Button color="secondary" className={className} onClick={() => setOpenCard(!openCard)}>
        <PlusCircle size={16} className={`mr-2 ${classes.plusIcon}`} />
        {!isMobile && "ADICIONAR CONCORRENTE/CLIENTE"}
      </Button>
      <Dialog
        onClose={() => setOpenCard(!openCard)}
        open={openCard}
        classes={{
          paper: `${classes.cardPosition}`
        }}
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
      >
        <Grid container>
          <Grid item xs={10} className="pt-3 pl-4">
            <Typography variant="h6">Novo</Typography>
          </Grid>
          <Grid item xs={2}>
            <X
              size={24}
              color={SECONDARY_COLOR}
              className="mt-4 ml-4 hover-icon"
              onClick={() => setOpenCard(false)}
            />
          </Grid>
          <Grid item xs={12} className="pt-2">
            <Divider className={classes.divider} />
          </Grid>
          <Grid
            item
            xs={12}
            className={`${classes.containerButtonOption} pt-2 pl-4`}
            onClick={() => {
              handleAddNewCompetitor();
              setOpenCard(false);
            }}
          >
            <Typography variant="subtitle1" className={classes.buttonOption}>
              Concorrente
            </Typography>
          </Grid>
          <Grid item xs={12} className="pt-2 pl-4 pr-4">
            <Divider className={classes.divider} />
          </Grid>
          <Grid
            item
            xs={12}
            className={`${classes.containerButtonOption} pt-2 pl-4`}
            onClick={() => {
              handleAddNewCustomer();
              setOpenCard(false);
            }}
          >
            <Typography variant="subtitle1" className={classes.buttonOption}>
              Cliente
            </Typography>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles(() => ({
  cardPosition: props => ({
    position: "absolute",
    left: `${props.relativePosition}px`,
    top: "310px",
    maxWidth: `${props.maxWidth}px`,
    width: "100%",
    maxHeight: "145px",
    height: "100%"
  }),
  divider: {
    height: 1,
    backgroundColor: PALE_BLUE_GRAY
  },
  buttonOption: {
    color: GRAY
  },
  containerButtonOption: {
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      opacity: "0.6"
    }
  },
  plusIcon: {
    verticalAlign: "middle"
  }
}));

const mapStateToProps = state => {
  return {
    windowWidth: state.window.width
  };
};

export default connect(mapStateToProps, null)(AddNewCustomerCompetitorCard);
