import { startOfWeek, endOfWeek, format } from "date-fns";
import _ from "lodash";

export const FormatDataToUpdate = (
  cropSales,
  competitorSales,
  customerSales,
  selectedDate,
  userId,
  vendorId,
  stateVendorId
) => {
  competitorSales &&
    competitorSales.forEach(competitorSale => {
      if (_.isObject(competitorSale.name)) {
        competitorSale.newItemId = competitorSale.name.id || 0;
        competitorSale.name = competitorSale.name.name;
      }
      if (_.isString(competitorSale.id)) competitorSale.id = 0;
    });
  customerSales &&
    customerSales.forEach(customerSale => {
      if (_.isObject(customerSale.name)) {
        customerSale.newItemId = customerSale.name.id || 0;
        customerSale.newItemTypeId = customerSale.name.customerType.id;
        customerSale.name = customerSale.name.name;
        if (_.isString(customerSale.id)) customerSale.id = 0;
      }
    });

  return {
    cropSales,
    competitorSales,
    customerSales,
    startDate: format(startOfWeek(selectedDate), "yyyy-MM-dd"),
    endDate: format(endOfWeek(selectedDate), "yyyy-MM-dd"),
    userId,
    vendorId,
    stateVendorId
  };
};
