import UserService from "../../services/UserService";
import ProfileService from "../../services/ProfileService";
import ManagementService from "../../services/ManagementService";
import VendorService from "../../services/VendorService";

class UsersManager {
  async listUsers() {
    return UserService.listUsers().then(response => response && response.data);
  }

  async listProfiles() {
    return ProfileService.listProfiles().then(response => response && response.data);
  }

  async listManagements() {
    return ManagementService.listManagements().then(response => response && response.data);
  }

  async listVendorsByManagementIds(managementIds) {
    return VendorService.listVendorsByManagementIds(managementIds, true).then(
      response => response && response.data
    );
  }

  async getUserFromId(userId) {
    return UserService.getUserFromId(userId).then(response => response && response.data);
  }

  async createUser(user) {
    return UserService.createUser(user).then(response => response && response.data);
  }

  async updateUser(user, userId) {
    return UserService.updateUser(user, userId).then(response => response && response.data);
  }
}

export default new UsersManager();
