import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { profiles } from "../utils/enum";
import { routes } from "../utils/Constants";
import Loading from "./Loading";
import { Box } from "@material-ui/core";

const DefaultRoute = props => {
  const { userProfiles } = props;

  if (userProfiles.length === 0) {
    return (
      <Box className="pt-4">
        <Loading loading size={50} />
      </Box>
    );
  }

  const isBackOffice = userProfiles.find(p => p.initials === profiles["backOffice"].initials);

  const isConsultant = userProfiles.find(
    p =>
      p.initials === profiles.commercialConsultant.initials ||
      p.initials === profiles.hortiFrutiConsultant.initials
  );

  if (isBackOffice) {
    return (
      <Redirect to={routes.potentialImport} />
    );
  } else if (isConsultant) {
    return (
      <Redirect to={routes.solidMarket} />
    );
  }
  else {
    return (
      <Redirect to={routes.sellingBalance} />
    );
  }
};

const mapStateToProps = state => {
  return {
    userProfiles: state.user.profiles
  };
};

export default connect(mapStateToProps, null)(DefaultRoute);
