import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import GeneralLoadingGif from "../img/GeneralLoading.gif";

const GeneralLoading = () => {
  const classes = useStyles();

  return (
    <Grid container item xs={12} className={classes.loadingGifCustomMargin}>
      <Grid item xs={12}>
        <img src={GeneralLoadingGif} alt="Page is loading" className={classes.loadingGifPosition} />
      </Grid>
      <Grid item xs={12}>
        <Typography align="center" variant="h4" className={classes.loadingTextCustomPosition}>
          Loading...
        </Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  loadingGifPosition: {
    display: "flex",
    margin: "auto"
  },
  loadingGifCustomMargin: {
    marginTop: "112px"
  },
  loadingTextCustomPosition: {
    position: "relative",
    top: "-40px"
  }
});

export default GeneralLoading;
