import UserService from "../../services/UserService";
import axios from "axios";
import store from "../../store";
import { alertMessageActions } from "../alertMessage/redux";
import { authenticationErrorMessage } from "../../shared/utils/RequestUtil";

class LoginManager {
  async getUserData() {
    try {
      const response = await UserService.getUserData(); 
      
      if (response && response.data != null) {
        return response.data;
      }
    }
    catch (e) {
      if (axios.isCancel(e)) {
        return { unauthorized: true };
      }

      if (e.response?.status === 400) {
        return {
          requestError: true,
          message: e.response.data?.message
        };
      }
      
      store.dispatch(alertMessageActions.showNotification(authenticationErrorMessage));

      if (e.response?.status === 401) {
        return { unauthorized: true };
      }

      throw e;
    }
  }
}

export default new LoginManager();
