import React from "react";
import { Grid, Button, makeStyles } from "@material-ui/core";
import { SECONDARY_COLOR } from "../../theme";
import Loading from "./Loading";

const FilterButtons = props => {
  const {
    primaryText,
    primaryAction,
    primaryLoading,
    primaryDisabled,
    secondaryText,
    secondaryAction,
    secondaryLoading,
    secondaryDisabled
  } = props;
  const classes = UseStyles();

  return (
    <Grid container item>
      <Grid item xs={6} />
      <Grid item xs={12} align="right">
        <Button
          variant="outlined"
          color="secondary"
          className={`mr-8 mt-3 ${classes.button}`}
          disabled={primaryDisabled}
          onClick={() => primaryAction && primaryAction()}
        >
          {primaryLoading ? (
            <Loading size={20} loading={primaryLoading} className={classes.loadingPosition} />
          ) : (
            primaryText
          )}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={`mt-3 ${classes.button}`}
          disabled={secondaryDisabled}
          onClick={() => secondaryAction && secondaryAction()}
        >
          {secondaryLoading ? (
            <Loading size={20} loading={secondaryLoading} className={classes.loadingPosition} />
          ) : (
            secondaryText
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

const UseStyles = makeStyles(theme => ({
  button: {
    width: "180px",
    height: "56px"
  },
  loadingPosition: {
    margin: theme.spacing(2),
    position: "relative",
    color: SECONDARY_COLOR
  }
}));

export default FilterButtons;
