import axios from "../axiosInstance";

class ImportEventsService {
  async listLastImportEvents(maxNumberOfEvents) {
    return await axios.get(`importevents/listLastImportEvents`, {
      params: { maxNumberOfEvents }
    });
  }
}

export default new ImportEventsService();
