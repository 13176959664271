import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { PALE_BLUE_GRAY, GRAY } from "../../../theme";
import { LocalAtm } from '@material-ui/icons'
import DollarExchangeMananger from "../DollarExchangeManager";

const DollarExchange = props => {
  const {
    isMobile,
  } = props;

  const classes = useStyles();
  const [dollarExchange, setDollarExchange] = useState(null)

  useEffect(() => {
    async function getDollarExchange() {
      let response = await DollarExchangeMananger.getDollarExchange();
      setDollarExchange(response)
    }

    getDollarExchange();
  }, [])

  return (
    <Grid container className={classes.container} >
      <Grid container item xs={6} alignItems="center">
        <Grid container item xs={isMobile ? 2 : 1} alignItems="center">
          <LocalAtm fontSize="default" className={classes.sideContent} />
        </Grid>
        <Grid container item xs={isMobile ? 10 : 11} alignItems="flex-end">
          <Typography variant="subtitle1" className="mr-1">R$</Typography>
          <Typography variant="h6">{dollarExchange ? dollarExchange.toFixed(2) : "-"}</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={6} justify="flex-end" className={classes.containerWarning}>
        <Grid item xs={12}>
          <Typography variant="body2" className={classes.sideContent}>Câmbio do dia</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" className={classes.sideContent}>*sujeito a alterações</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    backgroundColor: PALE_BLUE_GRAY,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    flexDirection: "row"
  },
  containerWarning: {
    textAlign: "right"
  },
  sideContent: {
    color: GRAY
  }
}));

export default DollarExchange;