import { useState } from "react";

export const useModals = () => {
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const handleSuccessModalOpenClose = () => {
    setSuccessModalOpen(!successModalOpen);
  };
  const handleErrorModalOpenClose = () => {
    setErrorModalOpen(!errorModalOpen);
  };

  return {
    handleSuccessModalOpenClose,
    handleErrorModalOpenClose,
    successModalOpen,
    errorModalOpen
  };
};
