import { useState } from "react";

export const useWeeks = () => {
  const [weekNumber, setWeekNumber] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());

  return {
    weekNumber,
    selectedDate,
    setWeekNumber,
    setSelectedDate
  };
};
