export const FormatDataToUpdate = (
  NextYearOrderbookCrop,
  NextYearOrderbookCompetitor,
  NextYearOrderbookCustomer
) => {
  return {
    NextYearOrderbookCrop,
    NextYearOrderbookCompetitor,
    NextYearOrderbookCustomer,
    year: new Date().getFullYear()
  };
};
