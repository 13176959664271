import React from 'react';
import { Grid, LinearProgress, makeStyles, Typography } from "@material-ui/core";
import { GRAY, RED } from '../../../theme';
import { sellingBalanceSearchPeriods } from "../../../shared/utils/enum";
import { monthValues } from '../../../shared/utils/Locale';

const CompletionAndBalanceItem = props => {
  const {
    completionPercentage,
    balance,
    forecast,
    submitted,
    searchPeriod,
    period,
    isMobile,
  } = props;

  const classes = useStyles({ balance, isMobile });

  const isMonthly = searchPeriod === sellingBalanceSearchPeriods.monthly;

  const getPeriodLabel = () => {
    if (searchPeriod === sellingBalanceSearchPeriods.weekly) {
      return `Sem. ${period}`;
    }

    return monthValues.abbreviated[period - 1].toUpperCase();
  }

  return (
    <Grid container>
      <Grid container item xs={12} alignItems="center">
        <Grid item xs={isMonthly ? 12 : isMobile ? 5 : 3}>
          <Typography variant={isMobile ? "overline" : "subtitle2"} className={isMobile ? classes.period : ""}>
            {getPeriodLabel()}
          </Typography>
        </Grid>
        <Grid container item xs={isMonthly ? 12 : isMobile ? 7 : 9}>
          {!isMonthly
            ? <Grid container item xs={12} justify="flex-end">
                <Typography className={classes.submitted}>{submitted || 0}</Typography>
              </Grid>
            : <>
                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    <Typography className={classes.submitted}>{submitted || 0}</Typography>
                  </Grid>
                  <Grid container item xs={6} justify="flex-end">
                    <Typography className={classes.balance}>{balance || 0}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <LinearProgress variant="determinate" value={completionPercentage == null ? 0 : completionPercentage > 100 ? 100 : completionPercentage} />
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    <Typography variant="caption">Total</Typography>
                  </Grid>
                  <Grid container item xs={6} justify="flex-end">
                    <Typography variant="caption">{forecast || 0}</Typography>
                  </Grid>
                </Grid>
              </>
          }
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  balance: ({ balance, isMobile }) => {
    return {
      color: balance < 0 ? RED : GRAY,
      fontWeight: "600",
      fontSize: isMobile ? "0.75rem" : "0.875rem",
    }
  },
  period: {
    fontWeight: "600",
  },
  submitted: ({ isMobile }) => {
    return {
      fontSize: isMobile ? "0.75rem" : "0.875rem",
    }
  },
}));

export default CompletionAndBalanceItem;