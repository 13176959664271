import React, { useState } from "react";
import { List } from "@material-ui/core";
import { WHITE } from "../../../theme";
import { LogOut, HelpCircle, AlertCircle, Search, ShoppingBag, Mail } from "react-feather";
import { routes } from "../../../shared/utils/Constants";
import { Redirect } from "react-router-dom";
import SidebarItem from "./SidebarItem";
import NestedSidebarListItems from "./NestedSidebarListItems";

const RepresentativeSidebarListItems = props => {
  const { setOpenDrawer, logout } = props;
  const [redirect, setRedirect] = useState(null);

  return (
    <List>
      <NestedSidebarListItems 
        name="Saldo a vender" 
        divider 
        icon={<ShoppingBag className="mr-4" color={WHITE} size={28} />}
      >
        <SidebarItem
          icon={<AlertCircle color={WHITE} size={28} className="pl-1" />}
          name="Limites e Forecast"
          handleClick={() => {
            setOpenDrawer(false);
            setRedirect(<Redirect to={routes.sellingBalance} />);
          }}
          divider
          isSubMenuItem
        />
        <SidebarItem
          icon={<Search color={WHITE} size={28} className="pl-1" />}
          name="Pesquisa"
          handleClick={() => {
            setOpenDrawer(false);
            setRedirect(<Redirect to={routes.sellingBalanceSearch} />);
          }}
          divider
          isSubMenuItem
        /> 
      </NestedSidebarListItems>

      <SidebarItem
        icon={<Mail color={WHITE} size={28} className="pl-1" />}
        name="Mensagens"
        handleClick={() => {
          setOpenDrawer(false);
          setRedirect(<Redirect to={routes.consultantMessage} />);
        }}
        divider
      />

      <SidebarItem
        icon={<HelpCircle color={WHITE} size={28} className="pl-1" />}
        name="Ajuda"
        handleClick={() => {
          window.open(
            "https://yara.sharepoint.com/teams/MarketTracker/SitePages/Consultores.aspx",
            "_blank"
          );
        }}
        divider
      />

      <SidebarItem
        icon={<LogOut color={WHITE} size={28} className="pl-1" />}
        name="Sair"
        handleClick={logout}
      />

      {redirect}
    </List>
  );
};

export default RepresentativeSidebarListItems;
