import React from "react";
import { Tabs, Tab, makeStyles, Typography, Grid } from "@material-ui/core";
import { DataType } from "../../../shared/utils/enum";
import NextYearOrderbookTypeIcon from "./NextYearOrderbookTypeIcon";
import { numberWithSeparators } from "../../../shared/utils/Utils";
import { SECONDARY_COLOR, BRIGHT_BLUE, PALE_BLUE_GRAY, WHITE } from "../../../theme";

const NextYearOrderbookTypeTabs = props => {
  const {
    selectedTypeTab,
    handleChangeTab,
    competitorTotalVolume,
    customerTotalVolume,
    cropTotalVolume,
    isMobile
  } = props;

  const getLabelTab = (type, totalVolume) => {
    return (
      <>
        <Grid container>
          <Grid item xs={!isMobile ? 6 : 12}>
            <NextYearOrderbookTypeIcon
              selected={selectedTypeTab === DataType[type].id}
              icon={DataType[type].icon}
              isMobile={isMobile}
            />
          </Grid>
          <Grid item container xs={!isMobile ? 6 : 12} className={!isMobile && "pl-2"}>
            <Grid item xs={12}>
              <Typography
                variant={!isMobile ? "body1" : "caption"}
                className={
                  selectedTypeTab === DataType[type].id
                    ? classes.textTabColorSelected
                    : classes.textTabColor
                }
                align={!isMobile ? "left" : "center"}
              >
                {DataType[type].name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant={!isMobile ? "body1" : "caption"}
                className={
                  selectedTypeTab === DataType[type].id
                    ? classes.textTabColorSelected
                    : classes.textTabColor
                }
                align={!isMobile ? "left" : "center"}
              >
                {numberWithSeparators(totalVolume)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  const classes = useStyles();

  return (
    <>
      <Tabs
        value={selectedTypeTab}
        indicatorColor="secondary"
        textColor="secondary"
        onChange={handleChangeTab}
        className={classes.tabs}
      >
        <Tab
          label={getLabelTab("competitor", competitorTotalVolume)}
          className={`${classes.borderBetweenTabs} ${classes.tabWidth}`}
        />
        <Tab
          label={getLabelTab("customer", customerTotalVolume)}
          className={`${classes.borderBetweenTabs} ${classes.tabWidth}`}
        />
        <Tab label={getLabelTab("crop", cropTotalVolume)} className={classes.tabWidth} />
      </Tabs>
    </>
  );
};
const useStyles = makeStyles(theme => ({
  tabs: {
    "& .MuiTabs-fixed": {
      margin: "auto",
      display: "flex",
      maxWidth: "100%"
    },
    "& .MuiTabs-flexContainer": {
      margin: "auto",
      display: "flex",
      width: "100%"
    },
    "& .MuiTabs-indicator": {
      top: 0,
      height: "4px"
    }
  },
  borderBetweenTabs: {
    borderRight: `1px solid ${PALE_BLUE_GRAY}`,
    borderImage: `radial-gradient(${PALE_BLUE_GRAY} 80%, ${WHITE} 20%)`,
    borderImageSlice: 1
  },
  tabWidth: {
    maxWidth: "calc(100% /3)",
    minWidth: "calc(100% /3)"
  },
  textTabColor: {
    color: BRIGHT_BLUE,
    textTransform: "none"
  },
  textTabColorSelected: {
    color: SECONDARY_COLOR,
    textTransform: "none"
  }
}));

export default NextYearOrderbookTypeTabs;
