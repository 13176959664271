import axios from "../axiosInstance";

class ProductGroupService {
  async getProductGroupOptions(familyGroup) {
    return axios.get(`productgroup/byfamilygroup`, {
      params: {
        familyGroup,
      }
    });
  }
}

export default new ProductGroupService();
