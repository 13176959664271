import React from "react";
import { Typography, Grid } from "@material-ui/core";
import Loading from "../../Loading";
import { RefreshCw } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";
import { SECONDARY_COLOR, GRAY, MID_BLUE } from "../../../../theme";

const UnreadCounter = props => {
  const { isLoading, reloadFunction, unreadCount, isNotification = true, isMobile } = props;
  const classes = useStyles(isMobile, isNotification);

  return (
    <Grid className={classes.container}>
      <Grid className={`mr-2 ${classes.containerFlex}`}>
        {isLoading ? (
          <Loading
            loading
            size={!isMobile && !isNotification ? 24 : 12}
            className={classes.refreshIcon}
          />
        ) : (
          <RefreshCw
            onClick={() => reloadFunction(true)}
            className={`${classes.refreshIcon} hover-icon`}
            size={!isMobile && !isNotification ? 24 : 12}
          />
        )}
      </Grid>
      <Grid className={classes.containerFlex}>
        <Typography
          variant={!isNotification && !isMobile ? "h5" : "caption"}
          className={`mr-1 ${classes.counter}`}
        >
          {unreadCount}
        </Typography>
        <Typography
          variant={!isNotification && !isMobile ? "h7" : "caption"}
          className={classes.subTitle}
        >
          Não lidas
        </Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "flex-end"
  },
  refreshIcon: {
    color: SECONDARY_COLOR
  },
  counter: props => ({
    color: !props.isNotification ? MID_BLUE : GRAY
  }),
  subTitle: {
    color: GRAY,
    paddingRight: "5px"
  },
  containerFlex: {
    display: "flex",
    alignItems: "center"
  }
}));

export default UnreadCounter;
