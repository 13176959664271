import React from "react";
import MaskedInput from "react-text-mask";

const cellphoneRegex = [
  "(",
  /[0-9]/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const phoneRegex = [
  "(",
  /[0-9]/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const PhoneMask = props => {
  let { inputRef, length, ...other } = props;
  let isPhone = length <= 14;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      id="phoneMask"
      guide={false}
      mask={isPhone ? phoneRegex : cellphoneRegex}
    />
  );
};

export default PhoneMask;
